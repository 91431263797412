import { createApp } from "vue";
import "./App.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@mdi/font/css/materialdesignicons.css";
import "vue3-toastify/dist/index.css";
import "katex/dist/katex.min.css";
import VueApexCharts from "vue3-apexcharts";
import CanvasJSChart from "@canvasjs/vue-charts";
import DayJsAdapter from "@date-io/dayjs";

import "@/variable.css";

import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import mitt from "mitt";
import { isDeviceLaptop } from "./wallet/solana/Solana";
import VueCountdown from "@chenfengyuan/vue-countdown";

const emitter = mitt();
if (isDeviceLaptop()) {
  // store.commit("setInfoReward", null);
  window.localStorage.removeItem("user-information");
}
const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: "mdi", // Sets default icon font to Material Design Icons
  },
  date: {
    adapter: DayJsAdapter,
  },
});

const app = createApp(App);

app.component(VueCountdown.name || "", VueCountdown);
app.use(store);
app.use(router);
app.use(vuetify);
app.use(CanvasJSChart);
app.use(VueApexCharts);
app.config.globalProperties.emitter = emitter;
app.mount("#app");
(() => {
  const urlParams = new URLSearchParams(window.location.search);
  const ref = urlParams.get("ref") ?? null;
  if (ref) {
    const refDecode = decodeURIComponent(ref);
    const refData = refDecode.split(":");
    const affiliateId = refData[0];
    const campaignId = refData[1];
    if (campaignId && affiliateId) {
      window.localStorage.setItem("campaign_id", campaignId);
      window.localStorage.setItem("aff_id", affiliateId);
      console.log("referral", "Set campaign_id and aff_id");
    }
  } else {
    const campaignId = urlParams.get("campaign_id") ?? null;
    const affiliateId = urlParams.get("aff_id") ?? null;
    if (campaignId && affiliateId) {
      window.localStorage.setItem("campaign_id", campaignId);
      window.localStorage.setItem("aff_id", affiliateId);
      console.log("referral", "Set campaign_id and aff_id");
    }
  }
})();
