import { userClient } from "@/graphql/client/user-client";
import { checkMyAffStatusQuery } from "@/graphql/query/homeStatictis";
import useToastMessage from "@/hook/useToastMessage";
import store from "@/store";
import { provideApolloClient, useQuery } from "@vue/apollo-composable";
import translation from "./translation";

const message = useToastMessage();

export default function checkMyAffStatus(
  isRefetch?: boolean,
  throwMessage?: boolean,
  doneCallback?: (data?: any) => any,
  notDoneCallback?: (data?: any) => any
) {
  const {
    result: resultCheckAff,
    refetch: refetchCheckAff,
    onResult: onResultCheckAff,
  } = provideApolloClient(userClient)(() => useQuery(checkMyAffStatusQuery));
  try {
    if (!store.state?.user?.token) throw new Error("no token");
    refetchCheckAff();
    onResultCheckAff(() => {
      // eslint-disable-next-line no-constant-condition
      if (resultCheckAff.value?.checkMyAffStatus?.status) {
        store.commit("setIsSubAffDone", true);
        doneCallback && doneCallback(resultCheckAff.value?.checkMyAffStatus);
      } else {
        store.commit("setIsSubAffDone", false);
        store.commit(
          "setOpenInviteCodeModalData",
          resultCheckAff.value?.checkMyAffStatus
        );
        notDoneCallback &&
          notDoneCallback(resultCheckAff.value?.checkMyAffStatus);
      }
    });
  } catch (err) {
    console.error("checkMyAffStatus", err);
  }
}
