<template>
  <n-modal
    v-model:show="store.state.phantomWarning"
    preset="card"
    :title="translation('nav_phantom_wn_title_popup')"
    :bordered="false"
    :style="{
      width: '400px',
      borderRadius: '24px',
      border: '2px black solid',
      maxWidth: 'calc(100vw - 32px)',
      backgroundColor: isDark() ? '#2B2B2B' : '#fff',
    }"
  >
    <div class="flex flex-col gap-3">
      <!-- <div>{{ translation("nav_phantom_wn_title") }}</div> -->
      <p class="wn_des">
        <span>{{ translation("nav_phantom_wn_des_before") }}</span>
        <a
          :href="translation('nav_phantom_wn_post_link')"
          style="
            text-decoration: currentColor underline solid;
            font-weight: 500;
          "
          >{{ translation("nav_phantom_wn_post_link_label") }}</a
        >
        <span>{{ translation("nav_phantom_wn_des_after") }}</span>
      </p>
      <div class="flex items-center wn_des">
        <v-checkbox
          v-model="isNotRepeat"
          :style="{
            color: isDark() ? 'white' : 'black',
          }"
          hide-details
        >
        </v-checkbox>
        <div>{{ translation("nav_phantom_wn_no_repeat") }}</div>
      </div>
      <PrimaryButton @click="handleClickIgnoreButton" class="w-full">{{
        translation("nav_phantom_wn_ignore")
      }}</PrimaryButton>
    </div>
  </n-modal>
</template>

<script setup lang="ts">
import translation from "@/common/function/translation";
import PrimaryButton from "./PrimaryButton.vue";
import { NModal } from "naive-ui";
import { defineProps, ref } from "vue";
import { useStore } from "vuex";
import isDark from "@/common/function/isDark";
const store = useStore();
const props = defineProps<{
  onNext?: (data?: any) => any;
}>();
const isNotRepeat = ref(false);
const handleClickIgnoreButton = () => {
  store.commit("setPhantomWarning", false);
  props.onNext && props.onNext();
  if (isNotRepeat.value) {
    localStorage.setItem("isNotRepeatPhantomWarning", "true");
  }
};
</script>

<style scoped>
.dark .wn_des {
  color: var(--text-color-dark-mode);
}
.wn_des {
  color: var(--Neutral-03, #636363);
  font-family: "GT Walsheim";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 28.8px */
}
</style>
