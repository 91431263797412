import gql from "graphql-tag";

const subscription = gql`
  subscription Subscription {
    transactionStatus
  }
`;

const subscriptionMetricsSolana = gql`
  subscription Subscription {
    metricsSolana
  }
`;

export { subscription, subscriptionMetricsSolana };
