import store from "@/store";
import { NavItem } from "./type";
export const DEFAULT_REWARD_RANK = { rank: 0, rank_name: "_newbie" };
export const MOBILE_BREAK_POINT = 1279;
export const USER_KEY = "user-information";

const BASE_URL = process.env.VUE_APP_DOMAIN_SERVER;
export const BASE_URL_BANNER = `${BASE_URL}/assets/`;
export const LOGGED_NAV = [
  {
    name: "nav_Mining",
    icon: "nav_Mining",
    href: "#mining",
  },
  {
    name: "nav_Stake",
    icon: "nav_Stake",
    href: "#statistics",
  },
  {
    name: "nav_Sell",
    icon: "nav_Sell",
    href: "#sell",
  },
  {
    name: "nav_Referral",
    icon: "nav_Referral",
    href: "#link",
  },
  {
    name: "nav_Hierarchy",
    icon: "nav_Hierarchy",
    href: "#hierarchy",
  },
  {
    name: "nav_History",
    icon: "nav_History",
    href: "#history",
  },
];
export const NOT_LOGGED_NAV = [
  {
    name: "nav_Mining",
    icon: "nav_Mining",
    href: "#mining",
  },
  {
    name: "nav_Stake",
    icon: "nav_Stake",
    href: "#statistics",
  },
  {
    name: "nav_Sell",
    icon: "nav_Sell",
    href: "#sell",
  },
];

export const BOTTOM_NAVBAR_ITEMS = [
  {
    name: "nav_Referral",
    icon: "nav_Referral",
    href: "#link",
  },
  {
    name: "nav_Hierarchy",
    icon: "nav_Hierarchy",
    href: "#hierarchy",
  },
  {
    name: "nav_statistic",
    icon: "",
    href: "",
  },
  {
    name: "nav_History",
    icon: "nav_History",
    href: "#history",
  },
  {
    name: "nav_Home",
    icon: "nav_home",
    href: "home_page_link",
  },
];

export const SUBSCRIPTION_TYPE = {
  CLAIM: "withdraw",
  STAKE: "stake",
  SWAP: "swap",
  CLAIM_BONUS: "withdraw-bonus",
  CLAIM_BONUS_STAKE: "withdraw-bonus-stake",
  BONUS_COMMISSION: "bonus-commission",
  BONUS_RANKING: "bonus-ranking",
  BONUS_UP_RANKING: "bonus-up-ranking",
  UPDATE_POOL_VALUE: "update-pool-value",
  CLAIM_INTEREST: "claim-staking-interest",
  CLAIM_PRINCIPLE: "claim-staking-principle",
  LOAN: "loan",
  BONUS_AFF: "bonus-aff",
  METRICS_SOLANA: "metrics-solana",
  SUBSCRIBE_AFF: "subscribe-aff",
  CLAIM_SOLX_INIT: "claim-solx-init",
  UPDATE_SOLX_SALE_GROUP: "update-solx-sale-group",
  INVESTMENT: "investment",
  CLAIM_INVESTMENT_INTEREST: "claim-investment-interest",
  CLAIM_INVESTMENT_PRINCIPLE: "claim-investment-principle",
  SEND_NOTI_UPDATE_AFF: "send-noti-updated-affiliate",
};
// export const SUBSCRIPTION_TYPE = {
//   CLAIM: "withdraw",
//   STAKE: "stake",
//   SWAP: "swap",
//   CLAIM_BONUS: "withdraw-bonus",
//   CLAIM_BONUS_STAKE: "withdraw-bonus",
//   BONUS_COMMISSION: "bonus-commission",
//   BONUS_RANKING: "bonus-ranking",
//   BONUS_UP_RANKING: "bonus-up-ranking",
//   UPDATE_POOL_VALUE: "update-pool-value",
//   CLAIM_INTEREST: "claim-staking-interest",
//   CLAIM_PRINCIPLE: "claim-staking-principle",
//   LOAN: "loan",
//   BONUS_AFF: "bonus-aff",
//   METRICS_SOLANA: "metrics-solana",
//   SUBSCRIBE_AFF: "subscribe-aff",
//   CLAIM_SOLX_INIT: "claim-solx-init",
//   UPDATE_SOLX_SALE_GROUP: "update-solx-sale-group",
//   INVESTMENT: "investment",
//   CLAIM_INVESTMENT_INTEREST: "claim-investment-interest",
//   CLAIM_INVESTMENT_PRINCIPLE: "claim-investment-principle",
//   SEND_NOTI_UPDATE_AFF: "send-noti-updated-affiliate",
// };
