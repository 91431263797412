import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  concat,
  HttpLink,
  split,
  from,
} from "@apollo/client/core";

export const BASE_URL = process.env.VUE_APP_BASE_URL;

import store from "@/store";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { getToken } from "@/service/statictis";
import { onError } from "@apollo/client/link/error";

const httpLink = new HttpLink({
  uri: BASE_URL,
});

//for test

// const testToken =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ3YWxsZXRfYWRkcmVzcyI6IjEyQk51UmFhbVpzb1Q0SHNkTktZcnpKcHpwUUFYUlJMU1lWdVN6ZGtVRGVDIiwiaWF0IjoxNzE4MDg1MDQ5LCJleHAiOjE3MTgxNzE0NDl9.y-zy5DNVjSC6hgSqxq9XO3n2c-U4nE3lJoq-uLgN_80";
const testToken = undefined;
// "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ3YWxsZXRfYWRkcmVzcyI6IjY0Zkdtcm1rTFVleUxra1QxTlFrd3JGTkVaNHJ6TWZaZ01leWkzcmVkTVZyIiwiaWF0IjoxNzE2NjUyMDgxLCJleHAiOjE3MTY3Mzg0ODF9.tniYrRT6W4GJIHYgTwjl9NZlJUdtxcomqZKPU1IPr40";
// "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ3YWxsZXRfYWRkcmVzcyI6IjdTQUVqeGlNRzh1N1dzaDR5Q1ZOM2hSQ0JjN3pFWFN2RHhlSDhRd01CRmY3IiwiaWF0IjoxNzE2NDgzMzEyLCJleHAiOjE3MTY1Njk3MTJ9.RUeo_iHBvwEWHPNxtD58ypfo-5a6eks8xsbEgODbnYw";

// const authMiddleware = new ApolloLink((operation, forward) => {
//   operation.setContext({
//     headers: {
//       authorization:
//         `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ3YWxsZXRfYWRkcmVzcyI6IjNNSnRvWFFGS0w4QXppMkY0Q2Z0QXA2Q2RtcUVaNExUYnczNnRMSENIN2NmIiwiaWF0IjoxNzEwMzAxMDAyLCJleHAiOjE3MTI4OTMwMDJ9.yX6ITMgHTRciDcHr6yrYDGq-0KovG1nnFU1Urd0OOtA`,
//     },
//   });
//   return forward(operation);
// });

const authMiddleware = new ApolloLink((operation, forward) => {
  // eslint-disable-next-line no-prototype-builtins
  // const customHeaders = operation.getContext().hasOwnProperty("headers")
  //   ? operation.getContext().headers
  //   : {};
  // console.log("🚀 ~ authMiddleware ~ customHeaders:", customHeaders);

  // operation.setContext({
  //   headers: {
  //     ...customHeaders,
  //     authorization: testToken
  //       ? `Bearer ${testToken}`
  //       : getToken()
  //       ? `Bearer ${getToken()}`
  //       : store?.state?.user?.token
  //       ? `Bearer ${store?.state?.user.token ?? ""}`
  //       : "Bearer",
  //   },
  // });

  operation.setContext(({ headers = {} }) => {
    return {
      headers: {
        ...headers,
        authorization: testToken
          ? `Bearer ${testToken}`
          : getToken()
          ? `Bearer ${getToken()}`
          : store?.state?.user?.token
          ? `Bearer ${store?.state?.user.token ?? ""}`
          : "Bearer",
      },
    };
  });
  return forward(operation);
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.VUE_APP_WSS as string,
    connectionParams() {
      return {
        Authorization: store?.state?.user?.token
          ? `Bearer ${store?.state?.user.token ?? ""}`
          : "Bearer",
      };
    },
  })
);
const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const logoutLink = onError((err) => {
  console.log("🚀 ~ onError authen global ~ err:", (err as any)?.message);
});

export const userClient = new ApolloClient({
  link: from([authMiddleware, httpLink, logoutLink]),
  cache: new InMemoryCache(),
});

export const subscriptionClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});
