<template>
  <div
    class="container"
    @click="
      () => {
        store.commit('setOpenInviteCodeModal', false);
      }
    "
  >
    <div class="modal_invite_code" @click.stop="() => undefined">
      <div class="header">
        <h1 class="title">{{ translation("com_invite_code_title") }}</h1>
        <div
          @click="() => store.commit('setOpenInviteCodeModal', false)"
          class="cursor_pointer"
        >
          <img
            loading="lazy"
            class="copy_aff_icon"
            :src="getIcon(isDark() ? 'close_white' : 'close')"
            alt=""
          />
        </div>
      </div>
      <div class="body">
        <div class="link_g">
          <input
            type="text"
            :placeholder="translation('community_code_placeholder')"
            @input="(e) => (inviteCodeValue = (e.target as any).value)"
            v-model="inviteCodeValue"
          />
          <div @click="handlePassValue" class="cursor_pointer">
            <img
              loading="lazy"
              class="copy_aff_icon"
              :src="getIcon('content_paste')"
              alt=""
            />
          </div>
        </div>
        <p class="body_des">{{ translation("com_des_invite_code_input") }}</p>
      </div>
      <PrimaryButton
        :disabled="!inviteCodeValue"
        :loading="isSubmitting"
        class="btn"
        @click="handleSubmitInviteCode"
        >{{ translation("community_confirm") }}</PrimaryButton
      >
      <!-- <div class="footer">
        <div class="left_footer">
          <h1 class="left_footer_title">
            {{ translation("title_join_community") }}
          </h1>
          <p class="left_footer_des">
            {{ translation("des_join_community") }}
          </p>
        </div>
        <PrimaryButton
          @click="handleJoinCommunity"
          custom-background="#CAFF04"
          >{{ translation("community_join") }}</PrimaryButton
        >
      </div> -->
    </div>
  </div>
</template>

<script lang="ts" setup>
import translation from "@/common/function/translation";
import PrimaryButton from "./PrimaryButton.vue";
import getIcon from "@/common/function/getIcon";
import { getCurrentInstance, onMounted, onUnmounted, ref } from "vue";
// import store from "@/store";
import login from "@/common/function/login";
import useSolana from "@/hook/useSolana";
import { useMessage } from "naive-ui";
import gql from "graphql-tag";
import { provideApolloClient, useMutation } from "@vue/apollo-composable";
import { userClient } from "@/graphql/client/user-client";
import useToastMessage from "@/hook/useToastMessage";
import checkMyAffStatus from "@/common/function/checkMyAffStatus";
const inviteCodeValue = ref<string>("");
const solana = useSolana();
const message = useToastMessage();
const isSubmitting = ref<boolean>(false);
import { Emitter } from "mitt";
import isDark from "@/common/function/isDark";
import { useStore } from "vuex";
const handlePassValue = async () => {
  const text = await navigator.clipboard.readText();
  inviteCodeValue.value = text;
};
const store = useStore();

const mutation = gql`
  mutation SubAffByCode($affId: String!) {
    subAffByCode(aff_id: $affId)
  }
`;

const handleSubmitInviteCode = async () => {
  isSubmitting.value = true;
  const { mutate } = provideApolloClient(userClient)(() =>
    useMutation(mutation, {
      variables: {
        affId: inviteCodeValue.value,
      },
    })
  );
  const res = await mutate();
  if (res?.data?.subAffByCode?.status === "processing") {
    isSubmitting.value = false;
    store.commit("setOpenInviteCodeModal", false);
    message.warning(translation("_sub_aff_processing"));
  } else {
    isSubmitting.value = false;
    store.commit("setOpenInviteCodeModal", false);
    message.error(translation("_sub_aff_failed"));
  }
};
</script>

<style scoped>
.container {
  position: fixed;
  inset: 0;
  z-index: 99999;
  background: rgba(149, 149, 149, 0.64);
  backdrop-filter: blur(12px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dark .modal_invite_code {
  background-color: var(--dark-black-light);
  border-color: var(--limit-color-dark-mode);
}
.modal_invite_code {
  display: flex;
  width: 400px;
  max-width: calc(100vw - 32px) !important;
  padding: 24px 28px 16px 28px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  border-radius: 24px;
  border: 2px solid var(--Neutral-01, #141416);
  background: var(--Neutral-09, #fff);
}
@media (max-width: 600px) {
  .modal_invite_code {
    padding: 16px !important;
  }
}
.group_swap_ralative {
  border-bottom: 2px var(--app-questn-com-black) solid;
  display: flex;
  align-items: stretch;
  width: 100%;
  gap: 5%;
  justify-content: space-between;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.dark .title,
.dark .left_footer_des,
.dark .body_des {
  color: var(--text-color-dark-mode);
}
.title {
  color: var(--Neutral-01, #141416);
  font-family: "GT Walsheim";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 32px */
  text-transform: capitalize;
}
.body {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.btn {
  width: 100%;
}
.footer {
  display: flex;
  padding-bottom: 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.modal_invite_code {
  display: flex;
  width: 400px;
  padding: 24px 28px 16px 28px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.link_g {
  border-bottom: 1px solid var(--Neutral-07, #d1d1d1);
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 20px */
}
.left_footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}
.left_footer_title {
  color: var(--Neutral-01, #141416);
  font-family: "GT Walsheim";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 32px */
  text-transform: capitalize;
  display: flex;
}
.left_footer_des {
  color: var(--Neutral-03, #636363);
  font-family: "GT Walsheim";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 28.8px */
}

.body_des {
  color: var(--Neutral-01, #141416);
  font-family: "GT Walsheim";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  padding-top: 6px;
}
.dark input {
  color: var(--text-color-dark-mode);
}
input {
  flex: 1;
  outline: none;
  padding-bottom: 6px;
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  /* 18px */
  text-transform: capitalize;
  width: 60%;
}
</style>
