<template>
  <footer>
    <!-- <div class="column_group">
      <FooterColumn
        :title="translation('foo_explore')"
        :items="[
          { name: translation('foo_SolX_Account'), href: '#' },
          { name: translation('foo_SolX_Transactions'), href: '#' },
          { name: translation('foo_SolX_Validation'), href: '#' },
          { name: translation('foo_SolX_Nominator_Pools'), href: '#' },
          { name: translation('foo_SolX_Jettons'), href: '#' },
        ]"
      />
      <FooterColumn
        :title="translation('foo_community')"
        :items="[
          { name: translation('foo_SolX_Whitepaper'), href: '#' },
          { name: translation('foo_SolX_Open_Network'), href: '#' },
          { name: translation('foo_SolX_Foundation'), href: '#' },
          { name: translation('foo_SolX_Github'), href: '#' },
        ]"
      />
      <div class="two_columns_container">
        <FooterColumn
          :title="translation('foo_support')"
          :items="[
            { name: translation('foo_Contact_Us'), href: '#' },
            { name: translation('foo_SolX_Docs'), href: '#' },
          ]"
        />
        <FooterColumn
          :title="translation('foo_Company')"
          :items="[{ name: translation('foo_Cookie_Policy'), href: '#' }]"
        />
      </div>
      <FooterColumn
        :title="translation('foo_Made_by')"
        :items="[
          {
            name: translation(
              'foo_Based_in_Switzerland__Bastion_Digital_is_the_leading_institutional_technology_platform_for_The_Open_Network__SolX__'
            ),
            href: '#',
          },
        ]"
      />
    </div> -->
    <div class="row_1">
      <button
        v-for="(e, i) in store.state.appSetting?.footer"
        :key="i"
        @click="
          () => {
            !e.redirect && store.commit('setShowTermsModal', true);
          }
        "
        class="foo_item"
      >
        <div v-if="!e.redirect">{{ translation(e.name) }}</div>
        <a v-if="e.redirect" :href="e.url" class="foo_item" target="_blank">{{
          translation(e.name)
        }}</a>
        <!-- <a href="" class="foo_item">{{ translation("foo_solx_Contract") }}</a> -->
      </button>
    </div>
    <div class="license_group">
      <div class="license">
        {{ translation("foo_SolX") }} © {{ new Date().getFullYear() }}
      </div>
      <a href="#home" class="back_top">
        <span>{{ translation("foo_Back_to_Top") }}</span>
        <img loading="lazy" :src="getIcon('arrow_top')" alt="" />
      </a>
    </div>
    <div class="flex items-center justify-center mb-9">
      <div>
        <a href="https://t.me/solx_community" target="_blank">
          <img
            :src="
              getIcon(
                `footer_telegram${
                  store.state.themeMode == 'dark' ? '_white' : ''
                }`
              )
            "
            alt=""
            class="icon_24_24_20_20"
          />
        </a>
      </div>
    </div>
    <div v-if="store.state.dynamicConfigs?.showTestFormat === 'true'">
      <FormatterNumber v-if="testValue" :value="testValue" />
      <div class="!border-1 !border-[#00000055]">
        <input
          type="text"
          class="p-4"
          placeholder="Type number to test"
          v-model="testValue"
        />
      </div>
    </div>
    <img
      loading="lazy"
      class="banner"
      :src="
        getIcon(
          `footer_banner${store.state.themeMode == 'dark' ? '_white' : ''}`
        )
      "
      alt=""
    />
  </footer>
</template>

<script setup lang="ts">
import getIcon from "@/common/function/getIcon";
import FooterColumn from "./FooterColumn.vue";
import translation from "@/common/function/translation";
import { useStore } from "vuex";
import FormatterNumber from "./FormatterNumber.vue";
import { ref } from "vue";
const store = useStore();
const testValue = ref("");
</script>

<style scoped>
.row_1 {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.foo_item:hover {
  opacity: 1;
  transform: translateY(-5px);
}
.dark .foo_item,
.dark .license {
  color: var(--text-color-dark-mode);
}
.foo_item {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */ /* 25.6px */
  opacity: 0.86;
  transition: 300ms;
}
.dark footer {
  border-top: 2px solid var(--limit-color-dark-mode);
  background: var(--dark-black);
}
footer {
  border-top: 2px solid var(--app-questn-com-black, #000);
  background: var(--app-questn-com-nero, #fff);
  padding: 48px 120px 0 120px;
}
.column_group {
  display: flex;
  gap: 64px;
}
.license_group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 48px 0;
  gap: 24px;
}
.banner {
  transform: translate(0, 8px);
  width: 100%;
}
.two_columns_container {
  margin-right: auto;
  display: flex;
  flex-direction: column;
  gap: 36px;
}
.license {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
  opacity: 0.86;
}
.back_top {
  color: var(--app-questn-com-japanese-laurel, #2069ff);
  font-family: "GT Walsheim";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
  opacity: 0.86;
  display: flex;
  align-items: center;
  gap: 4px;
}
@media (max-width: 1279px) {
  .column_group {
    gap: 36px;
    flex-direction: column;
    align-items: center;
  }
  .two_columns_container {
    gap: 36px;
    align-items: center;
    margin-right: 0;
  }
  .license_group {
    justify-content: center;
    margin: 36px 0;
  }
  footer {
    padding: 48px 16px 0 16px;
  }
  .row_1 {
    flex-direction: column;
    gap: 16px;
  }
}
</style>
