import store from "@/store";

type MessageType = 'green' | 'orange' | 'red';

export default function useTextMessage(message: string, type?: MessageType, lifeTime = 2000) {
  try {
    store.commit('setMessage', { message, type });
    store.commit('showMessage');
    const showMessageTimeOut = setTimeout(() => {
      store.commit('close');
      clearTimeout(showMessageTimeOut);
    }, lifeTime);
    const messageTimeOut = setTimeout(() => {
      store.commit('closeMessage', undefined);
      clearTimeout(messageTimeOut);
    }, lifeTime + 1000);
  }
  catch (err) {
    console.log("🚀 ~ useTextMessage ~ err:", err)

  }

}