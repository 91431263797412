<template>
  <div>
    <div
      v-if="!hiddenDateDescription"
      class="text-neutral-400 text-sm font-normal font-['GT Walsheim'] leading-tight mb-2"
    >
      <span class="whitespace-nowrap">{{
        formatDateTo(appliedDates[0]?.toISOString(), false)
      }}</span>
      <span v-if="appliedDates.length > 1" class="px-1">{{
        translation("date_space")
      }}</span>
      <span v-if="appliedDates.length > 1" class="whitespace-nowrap">{{
        formatDateTo(
          appliedDates[appliedDates.length - 1]?.toISOString(),
          false
        )
      }}</span>
    </div>
    <div class="flex items-center gap-1">
      <div
        v-if="!isHiddenOption"
        class="button_group_date_picker flex items-center justify-start gap-1"
      >
        <RoundButton
          v-if="!hideOneDay"
          background="#00000000"
          :active="checkDates(1)"
          @click="
            () => {
              setDates(1);
              autoSelectDays = 1;
              apply();
            }
          "
          >{{ translation(`${prefixKey}_outside_1D`) }}</RoundButton
        >
        <RoundButton
          background="#00000000"
          :active="checkDates(7)"
          @click="
            () => {
              setDates(7, dates[dates.length - 1]);
              autoSelectDays = 7;
              apply();
            }
          "
          >{{ translation(`${prefixKey}_outside_7D`) }}</RoundButton
        >
        <RoundButton
          background="#00000000"
          :active="checkDates(30)"
          @click="
            () => {
              setDates(30, dates[dates.length - 1]);
              autoSelectDays = 30;
              apply();
            }
          "
          >{{ translation(`${prefixKey}_outside_30D`) }}</RoundButton
        >
        <!-- <RoundButton
          background="#00000000"
          :active="checkDates(365)"
          @click="
            () => {
              setDates(365);
              apply();
            }
          "
          >{{ translation(`${prefixKey}_365D`) }}</RoundButton
        >
        <RoundButton
          v-if="!notUseAll"
          background="#00000000"
          :active="appliedDates.length == 0"
          @click="
            () => {
              dates = [];
              apply();
            }
          "
          >{{ translation(translation(`${prefixKey}_all`)) }}</RoundButton
        > -->
      </div>
      <v-menu v-model="showFilter" class="filter_container">
        <template v-slot:activator="{ props }">
          <div v-bind="props" class="flex">
            <RoundButton
              class="!bg-[#F3BA2F] !text-black !border-black"
              :class="{
                '!size-[44.8px] !aspect-square !p-0 !min-w-0 flex  items-center justify-center ':
                  (appliedDates.length <= 0 || useAddIconIgnore) &&
                  !useCalendarIcon,
                '!pl-4 !pr-2 !min-w-0':
                  appliedDates.length <= 0 && !useAddIconIgnore,
              }"
              v-if="!isHiddenButton"
              background="#00000000"
              active-background="#F3BA2F"
              :active="
                !checkDates(1) &&
                !checkDates(7) &&
                !checkDates(30) &&
                !checkDates(365) &&
                appliedDates.length > 0
              "
            >
              <span
                v-if="
                  (appliedDates.length <= 0 || useAddIconIgnore) &&
                  !useCalendarIcon
                "
              >
                <img :src="getIcon('add_date_black')" class="size-6" />
              </span>
              <span
                class="flex items-center gap-1"
                v-if="appliedDates.length <= 0 && !useAddIconIgnore"
              >
                <img :src="getIcon(`calendar`)" alt="" class="action_icon" />
                <img
                  :src="getIcon(`arrow_bottom`)"
                  alt=""
                  class="action_icon duration-200"
                  :style="{
                    transform: `rotate(${showFilter ? 0 : 180}deg)`,
                  }"
                />
              </span>
              <span v-if="appliedDates.length > 0 && !useAddIconIgnore">
                <span class="whitespace-nowrap">{{
                  formatDateTo(appliedDates[0]?.toISOString(), false)
                }}</span>
                <span v-if="appliedDates.length > 1" class="px-1">{{
                  translation("date_space")
                }}</span>
                <span
                  v-if="appliedDates.length > 1"
                  class="whitespace-nowrap"
                  >{{
                    formatDateTo(
                      appliedDates[appliedDates.length - 1]?.toISOString(),
                      false
                    )
                  }}</span
                >
              </span>
            </RoundButton>
          </div>
        </template>
        <v-list
          class="pb-0 date_picker_wrapper !border-2 !border-black !rounded-[12px] bg-white my-3 !max-w-[330px]"
        >
          <div
            @click.stop="() => undefined"
            :id="id"
            class="flex flex-col items-center"
          >
            <div class="">
              <v-date-picker
                :key="datePickerKey"
                v-model="dates"
                v-model:month="month"
                v-model:year="year"
                @update:view-mode="handleCssOnChange"
                multiple="range"
                :allowed-dates="(val) => !dayjs(val).isAfter()"
                hide-header
                color="#F3BA2F"
                :bg-color="isDark() ? '#1E1E1E' : '#fff'"
              ></v-date-picker>
            </div>
            <div
              v-if="!isHideInsideOption"
              class="button_group_date_picker flex items-center justify-start gap-1 w-[calc(100%-32px)] mx-4 py-5 border-t-2 border-black border_dark"
            >
              <RoundButton
                v-if="!hideOneDay"
                background="#00000000"
                :active="checkDatesRealtimeSelect(1)"
                @click="
                  () => {
                    setDates(1);
                    autoSelectDays = 1;
                    // apply();
                  }
                "
                >{{ translation(`${prefixKey}_1D`) }}</RoundButton
              >
              <RoundButton
                background="#00000000"
                :active="checkDatesRealtimeSelect(7)"
                @click="
                  () => {
                    setDates(7, dates[dates.length - 1]);
                    autoSelectDays = 7;
                    // apply();
                  }
                "
                >{{ translation(`${prefixKey}_7D`) }}</RoundButton
              >
              <RoundButton
                background="#00000000"
                :active="checkDatesRealtimeSelect(30)"
                @click="
                  () => {
                    setDates(30, dates[dates.length - 1]);
                    autoSelectDays = 30;
                    // apply();
                  }
                "
                >{{ translation(`${prefixKey}_30D`) }}</RoundButton
              >
              <!-- <RoundButton
          background="#00000000"
          :active="checkDates(365)"
          @click="
            () => {
              setDates(365);
              apply();
            }
          "
          >{{ translation(`${prefixKey}_365D`) }}</RoundButton
        >
        <RoundButton
          v-if="!notUseAll"
          background="#00000000"
          :active="appliedDates.length == 0"
          @click="
            () => {
              dates = [];
              apply();
            }
          "
          >{{ translation(translation(`${prefixKey}_all`)) }}</RoundButton
        > -->
            </div>
            <div class="flex items-center gap-3 px-4 pb-4 w-full">
              <PrimaryButton
                @click="
                  () => {
                    autoSelectDays = 0;
                    handleClear();
                    setDates(defaultDate);

                    // apply();
                  }
                "
                class="flex-1"
                >{{ translation("filter_clear") }}</PrimaryButton
              >
              <PrimaryButton
                @click="
                  () => {
                    apply();
                  }
                "
                class="flex-[3]"
                custom-background="#F0FFB3"
                :loading="isApplying"
                >{{ translation("filter_apply") }}</PrimaryButton
              >
            </div>
          </div>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script setup lang="ts">
import getIcon from "@/common/function/getIcon";
import translation from "@/common/function/translation";
import {
  ref,
  watch,
  defineProps,
  defineEmits,
  withDefaults,
  onMounted,
} from "vue";
import RoundButton from "./RoundButton.vue";
import dayjs, { Dayjs } from "dayjs";
import DayJsAdapter from "@date-io/dayjs";
import isDark from "@/common/function/isDark";
import PrimaryButton from "./PrimaryButton.vue";
import { uuid } from "uuidv4";
import formatDateTo from "@/common/function/formatDate";
export type Dates = Dayjs[];
const id = uuid();
const autoSelectDays = ref(0);
interface FilterDatePickerProps {
  show: boolean;
  isHiddenOption?: boolean;
  isHiddenButton?: boolean;
  onChange?: (dates: Dates, oldDates: Dates) => any;
  onStatusChange?: (status: boolean, dates: Dates, oldDates: Dates) => any;
  onApply?: (dates: Dates, oldDates: Dates, onDone?: (data: any) => any) => any;
  prefixKey?: string;
  notUseAll?: boolean;
  defaultDate?: number;
  hiddenDateDescription?: boolean;
  isHideInsideOption?: boolean;
  hideOneDay?: boolean;
  useAddIconIgnore?: boolean;
  useCalendarIcon?: boolean;
}
const showFilter = ref(false);
const props = withDefaults(defineProps<FilterDatePickerProps>(), {
  show: false,
});

const datePickerId = uuid();
const datePickerKey = ref(datePickerId);

const handleClear = () => {
  // const start = document.querySelector(".only_one_date_selected");
  // const last = document.querySelector(".last_date_selected");
  // const selected = start ?? last;
  // if (selected) {
  //   console.dir(selected);
  //   const button = selected.querySelector("button");
  //   if (button) {
  //     button.click();
  //   }
  // }
  const newId = uuid();
  datePickerKey.value = newId;
};

const apply = () => {
  closeFilter();
  isApplying.value = true;
  appliedDates.value = dates.value;
  props.onApply &&
    props.onApply(
      dates.value,
      oldDates.value,
      () => (isApplying.value = false)
    );
};

onMounted(() => {
  if (props.defaultDate) {
    setDates(props.defaultDate);
    apply();
  }
});

watch(
  () => props.show,
  () => {
    showFilter.value = props.show;
  },
  { immediate: true }
);
watch(
  () => showFilter.value,
  () => {
    emitFilterStatus(showFilter.value);
  }
);
const isApplying = ref<boolean>(false);
const dates = ref<Dates>([]);
const appliedDates = ref<Dates>([]);
const month = ref<any>();
const year = ref<any>();
const oldDates = ref<Dates>([]);
const adapter = new DayJsAdapter();

const emit = defineEmits(["update:show"]);

const emitFilterStatus = (status?: boolean) => {
  emit("update:show", status ?? !props.show);
};

const handleCssOnChange = () => {
  // reset previous state
  const preTimeout = setInterval(() => {
    let selectedDates: any = document
      .getElementById(id)
      ?.querySelectorAll(`.v-date-picker-month__day`);
    if (selectedDates) {
      selectedDates = Array.from(selectedDates);
      selectedDates.forEach((item: any) => {
        item.classList.remove("selected_date");
        item.classList.remove("only_one_date_selected");
        item.classList.remove("first_date_selected");
        item.classList.remove("last_date_selected");
      });
    }
    clearInterval(preTimeout);
  }, 10);
  //set new state
  const timeout = setInterval(() => {
    let selectedDates: any = document
      .getElementById(id)
      ?.querySelectorAll(
        `.v-date-picker-month__day.v-date-picker-month__day--selected`
      );
    if (selectedDates) {
      selectedDates = Array.from(selectedDates);
      selectedDates.forEach((item: any, index: number) => {
        item.classList.add("selected_date");
        if (selectedDates.length == 1) {
          item.classList.add("only_one_date_selected");
        }
        if (index == 0) {
          item.classList.add("first_date_selected");
          return;
        }
        if (index == selectedDates.length - 1) {
          item.classList.add("last_date_selected");
          return;
        }
      });
    }
    const clearIntervalTimeout = setTimeout(() => {
      clearInterval(timeout);
      clearTimeout(clearIntervalTimeout);
    }, 1000);
  }, 50);
};

// watch(dates, (newValue, oldValue) => {
//   if (autoSelectDays.value == 0) return;
//   if (
//     newValue[0] &&
//     oldValue[0] &&
//     !adapter.isSameDay(newValue[0], oldValue[0])
//   ) {
//     setDates(autoSelectDays.value, dates.value[0]);
//   }
//   if (
//     newValue[newValue.length - 1] &&
//     oldValue[oldValue.length - 1] &&
//     !adapter.isSameDay(
//       newValue[newValue.length - 1],
//       oldValue[newValue.length - 1]
//     )
//   ) {
//     setDates(autoSelectDays.value, dates.value[dates.value.length - 1]);
//   }
// });
watch(
  [() => dates.value?.length, () => adapter.date(dates.value[0]).date()],
  (newValue, oldValue) => {
    if (autoSelectDays.value != 0) {
      setDates(autoSelectDays.value, dates.value[dates.value.length - 1]);
    }
  }
);
watch([dates, month, year], (newValue, oldValue) => {
  props.onChange && props.onChange(newValue[0], oldValue);
  handleCssOnChange();
});

watch([() => props.show, showFilter], () => {
  handleCssOnChange();
  if (!showFilter.value) {
    const adapter = new DayJsAdapter();
    const start = dates.value[0];
    const startApplied = appliedDates.value[0];
    if (
      dates.value.length == appliedDates.value.length &&
      (start == startApplied || adapter.isSameDay(start, startApplied))
    ) {
      return;
    }
    apply();
  }
  props.onStatusChange &&
    props.onStatusChange(props.show, dates.value, oldDates.value);
  dates.value = appliedDates.value;
});

const closeFilter = () => {
  const timeout = setTimeout(() => {
    emit("update:show", false);
    clearTimeout(timeout);
  }, 200);
};

const setDates = (numOfDate: number, startDate?: Dayjs) => {
  dates.value = [];
  // const current = dayjs();
  const adapter = new DayJsAdapter();
  const current = startDate ?? adapter.date();
  handleClear();
  if (numOfDate == 0) {
    return;
  }
  if (!numOfDate) {
    dates.value = [];
    return;
  }
  if (numOfDate == 1) {
    dates.value.unshift(adapter.startOfDay(adapter.date()));
    return;
  }
  for (let i = 0; i < numOfDate; i++) {
    if (i == 0) {
      dates.value.unshift(adapter.endOfDay(current));
      continue;
    }
    dates.value.unshift(adapter.startOfDay(current).subtract(i, "day"));
  }
};
const checkDates = (numOfDate: number) => {
  // return (
  //   current.isSame(appliedDates.value[appliedDates.value.length - 1], "day") &&
  //   appliedDates.value.length == numOfDate
  // );
  const current = adapter.date();

  if (numOfDate == 1)
    return (
      dates.value.length == numOfDate &&
      adapter.isSameDay(dates.value[0], current)
    );
  return appliedDates.value.length == numOfDate;
};
const checkDatesRealtimeSelect = (numOfDate: number) => {
  // return (
  //   current.isSame(dates.value[dates.value.length - 1], "day") &&
  //   dates.value.length == numOfDate
  // );
  const current = adapter.date();

  if (numOfDate == 1)
    return (
      dates.value.length == numOfDate &&
      adapter.isSameDay(dates.value[0], current)
    );
  return dates.value.length == numOfDate;
};
</script>

<style scoped>
.show_filter {
  transform: scale(1) !important;
  opacity: 1 !important;
}
.dark .filter_button_container {
  background-color: var(--text-color-dark-mode);
}
.dark .date_picker_wrapper {
  background-color: var(--dark-black-light-more) !important;
  border: 2px var(--limit-color-dark-mode) solid !important;
}
.dark .button_group_date_picker {
  border-color: var(--limit-color-dark-mode) !important;
}
</style>
