export default class Congratulation {
  private el: HTMLElement | undefined;
  private containerEl: HTMLElement | undefined;
  private confettiFrequency = 3;
  private confettiColors = ['#EF2964', '#00C09D', '#2D87B0', '#48485E', '#EFFF1D'];
  private confettiAnimations = ['slow', 'medium', 'fast'];
  public constructor(element: HTMLElement) {
    console.dir(element)
    this.el = element;
    this.el && (this.el.style.display = 'block');
    this.containerEl = undefined;
    this._setupElements();
    this._renderConfetti();
    const timeOut = setTimeout(() => {
      if (this.el) {
        this.el.remove();
      }
      clearTimeout(timeOut);
    }, 4000)
  }
  private _setupElements() {
    const containerEl = document.createElement('div');
    const elPosition = this.el?.style.position;

    if ((elPosition !== 'relative' || `${elPosition}` !== 'absolute') && this.el) {
      this.el.style.position = 'relative';
    }

    containerEl.classList.add('confetti-container');

    this.el?.appendChild(containerEl);

    this.containerEl = containerEl;
  }
  private _renderConfetti() {
    setInterval(() => {
      const confettiEl = document.createElement('div');
      const confettiSize = (Math.floor(Math.random() * 3) + 7) + 'px';
      const confettiBackground = this.confettiColors[Math.floor(Math.random() * this.confettiColors.length)];
      const confettiLeft = (Math.floor(Math.random() * (this.el?.offsetWidth ?? 0))) + 'px';
      const confettiAnimation = this.confettiAnimations[Math.floor(Math.random() * this.confettiAnimations.length)];

      confettiEl.classList.add('confetti', 'confetti--animation-' + confettiAnimation);
      confettiEl.style.left = confettiLeft;
      confettiEl.style.width = confettiSize;
      confettiEl.style.height = confettiSize;
      confettiEl.style.backgroundColor = confettiBackground;

      (confettiEl as any).removeTimeout = setTimeout(function () {
        (confettiEl as any).parentNode.removeChild(confettiEl);
      }, 3000);

      this.containerEl?.appendChild(confettiEl);
    }, 10);
  }
}
