import { basicClient } from "@/graphql/client/basic-client";
import { getAppSettingQuery } from "@/graphql/query/getAppSettingQuery";
import store from "@/store";
import { provideApolloClient, useQuery } from "@vue/apollo-composable";

export default function fetchAppSettingData(locale: string) {
  try {
    setLanguageData();
    const { result, refetch, onResult } = provideApolloClient(basicClient)(() =>
      useQuery(getAppSettingQuery, { locale })
    );
    onResult(() => {
      const languageData = result.value?.getAppSetting?.data_language;
      if (languageData) {
        store.commit(
          "setLanguageData",
          result.value?.getAppSetting?.data_language
        );
      }
      store.commit("setAppSetting", result.value?.getAppSetting);
      const dynamicConfigs = {};
      result.value?.getAppSetting?.dynamic_config?.forEach((e: any) => {
        Object.assign(dynamicConfigs, e);
      });
      store.commit("setDynamicConfigs", dynamicConfigs);
    });
    refetch();
  } catch (err) {
    console.error(err);
  }
}

const setLanguageData = () => {
  const data = localStorage.getItem("languageData");
  data && store.commit("setLanguageData", JSON.parse(data));
};
