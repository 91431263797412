import gql from "graphql-tag";

export const getClaim = gql`
  query Query {
    getClaim
  }
`;

export const getPoolInfo = gql`
  query Query {
    poolInfo
  }
`;

export const connectBeQuery = gql`
  mutation Mutation($data: String) {
    connectBE(data: $data)
  }
`;
export const saveSessionWalletTelegram = gql`
  mutation SaveSessionWallet($saveSessionWallet: SaveSessionWallet) {
    saveSessionWallet(saveSessionWallet: $saveSessionWallet)
  }
`;

export const staking = gql`
  mutation TopUp($topUpInput: TopUpInput!) {
    topUp(topUpInput: $topUpInput)
  }
`;

export const claim = gql`
  mutation Claim($hashData: String!) {
    claim(hash_data: $hashData)
  }
`;

export const swapInfo = gql`
  query Query {
    swapInfo
  }
`;
export const checkMyAffStatusQuery = gql`
  query Query {
    checkMyAffStatus
  }
`;

export const writeLogSwap = gql`
  mutation Swap($hash: String!) {
    swap(hash: $hash)
  }
`;

export const news = gql`
  query Query {
    news
  }
`;

export const getStakeInfo = gql`
  query Query {
    stakeData
  }
`;

export const stakeCycle = gql`
  query Query {
    stakeCycle
  }
`;

export const stakeHistory = gql`
  query Query {
    stakeHistory
  }
`;

export const saveStake = gql`
  mutation SaveStake($saveStakeInput: SaveStakeInput) {
    saveStake(saveStakeInput: $saveStakeInput)
  }
`;

export const claimProfit = gql`
  mutation ClaimInterest($claimStakeInput: ClaimStakeInput) {
    claimInterest(claimStakeInput: $claimStakeInput)
  }
`;

export const claimOrigin = gql`
  mutation ClaimPrinciple($claimStakeInput: ClaimStakeInput) {
    claimPrinciple(claimStakeInput: $claimStakeInput)
  }
`;

export const swapBE = gql`
  mutation SwapBE($swapInput: SwapInput) {
    swapBE(SwapInput: $swapInput)
  }
`;

export const price24h = gql`
  query Query {
    price24h
  }
`;

export const statistic = gql`
  query Query {
    statistic
  }
`;

export const having = gql`
  query Query {
    halvingInfo
  }
`;

export const permission = gql`
  query Query {
    featurePermission
  }
`;

export const getClaimSolxSettings = gql`
  query Query {
    getClaimSolxSettings
  }
`;

export const boostFromMint = gql`
  mutation Mutation($value: String) {
    boostFromMint(value: $value)
  }
`;

export const settingFeeIn = gql`
  query Query {
    getUserFee
  }
`;

export const estMint = gql`
  mutation Mutation($input: String!) {
    estClaim(input: $input)
  }
`;

export const estMintUser = gql`
  mutation Mutation($input: String!) {
    estClaimUser(input: $input)
  }
`;

export const sessionTelegram = gql`
  mutation SessionWallet($telegramId: String) {
    sessionWallet(telegramId: $telegramId)
  }
`;
export const priceChart = gql`
  mutation PriceChart($priceChartInput: PriceChartInput) {
    priceChart(priceChartInput: $priceChartInput)
  }
`;
