<template>
  <span
    :key="refId"
    @mouseenter="(e) => !useResponsive().isMobileScreen && handleShowTooltip(e)"
    @mouseleave="
      (e) => !useResponsive().isMobileScreen && handleHiddenTooltip(e)
    "
  >
    <v-menu v-model="showTooltip" :location="'top'">
      <template v-slot:activator="{ props }">
        <span v-bind="props">
          <span>
            <span v-if="!useSlot">{{
              `${contents[0]}${subContent ?? ""}`
            }}</span>
            <slot></slot>
          </span>
        </span>
      </template>

      <v-list
        class="!p-0 my-2 !border-[0px] !bg-[#000] !w-fit !rounded-[10px] text-[1.5em]"
        v-if="
          !disableTooltip &&
          (contents[1] || contents[2] || contents[3] || useTooltipSlot)
        "
      >
        <div class="font-normal bg-[#000] text-white p-2">
          <span v-if="!useTooltipSlot">
            <span>{{ contents[1] }}</span>
            <KatexComponent v-if="contents[2]" :katex-text="contents[2]" />
            <span>{{ contents[3] }}</span>
          </span>
          <slot name="tooltip"></slot>
        </div>
      </v-list>
    </v-menu>
  </span>
</template>

<script setup lang="ts">
import translation from "@/common/function/translation";
import KatexComponent from "./KatexComponent.vue";
import { defineProps, ref, watch } from "vue";
import useResponsive from "@/hook/useResponsive";
import { uuid } from "uuidv4";
import { useStore } from "vuex";
const id = uuid();
const refId = ref(id);
const showTooltip = ref(false);
const props = defineProps<{
  value?: string;
  useSlot?: boolean;
  useTooltipSlot?: boolean;
  noUseTranslate?: boolean;
  subContent?: string;
  disableTooltip?: boolean;
}>();
const store = useStore();
const handleShowTooltip = (e: any) => {
  const timeOut = setTimeout(() => {
    showTooltip.value = true;
    clearTimeout(timeOut);
  }, 200);
};
const handleHiddenTooltip = (e: any) => {
  const timeOut = setTimeout(() => {
    showTooltip.value = false;
    clearTimeout(timeOut);
  }, 200);
};
const contents = ref<string[]>([]);

watch(
  [() => props.value, () => store.state.languageData],
  () => {
    if (!props.value) {
      contents.value = [""];
      return;
    }
    const temp = props?.noUseTranslate ? props.value : translation(props.value);
    if (temp?.includes("use_tooltip-split-")) {
      contents.value = temp.split("-split-")?.slice(1);
      return;
    }
    contents.value = [temp];
  },
  { immediate: true }
);
// watch(
//   () => store.state.languageData,
//   () => {
//     console.error("rerender tooltip");
//     const newId = uuid();
//     refId.value = newId;
//   },
//   { immediate: true }
// );
</script>

<style scoped>
.formatter_value {
  display: inline-flex;
  align-items: baseline;
  gap: v-bind(4 + "px");
}
.show_tooltip {
  opacity: 1 !important;
  transform: translateY(-100%) !important;
  scale: 1;
}
</style>
