import { isAncestorOf } from "./isAncestorOf";

export const getUserById = (data: Array<any>, id: string) => {
  let result = -1;
  const length = data?.length;
  // console.log('getUserById', data);
  try {
    for (let i = 0; i < length; i++) {
      if (isAncestorOf(data[i], id)) {
        if (data[i]?.userId == id) {
          // console.log('id is found: ', data[i], data[i].level);
          result = data[i];
          return result;
        } else {
          result = getUserById(data[i]?.children, id);
          return result;
        }
      } else {
        continue;
      }
    }
    return -1;
  } catch (err) {
    console.log(err);
    return -1;
  }
};
