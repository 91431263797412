<template>
  <d @click.stop="copy" class="cursor_pointer">
    <slot></slot>
  </d>
</template>

<script setup lang="ts">
import useTextMessage from "@/hook/useTextMessage";
import { defineProps } from "vue";
interface CopyText {
  value: string;
}
const props = defineProps<CopyText>();
const copy = async () => {
  try {
    console.log("🚀 ~ copy ~ window.navigator.clipboard:", window.navigator);
    window.navigator.clipboard.writeText(props.value);
    useTextMessage("Copied", "green");
  } catch (err) {
    useTextMessage("Text hasn't been copied yet", "orange");
    console.log(err);
  }
};
</script>

<style scoped></style>
