import store from "@/store";

export default function translation(key: string) {
  try {
    if (store.state.languageData && store.state.languageData[key]) {
      return store.state.languageData[key];
    } else return key;
  } catch (err) {
    console.error(err);
    return key;
  }
}
