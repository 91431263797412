export interface NavItem {
  name: string;
  href: string;
}

type DynamicFunction = (data?: any) => any;

export interface ICurrency {
  id: number;
  status: string;
  date_created: string;
  date_updated: string;
  currency_name: string;
  network: number;
  enable: boolean;
  symbol: string;
  last_update: string;
  logo: null | string;
  contract_address: string;
}

export interface ITransactionHistory {
  id: number;
  status: "success" | "processing" | "failed";
  date_created: string;
  date_updated: string;
  title: string;
  transaction_code: string;
  user: string;
  currency_transaction: number;
  currency_fee: number;
  amount: string;
  pool: null | string;
  type: string;
  fee_amount: string;
  icon: string;
  hash: string;
  hash_fee: string;
  total_value_update: string;
  method: "add" | "subtract";
  currency_transaction_currency_feeTocurrency: ICurrency;
  currency_transaction_currency_transactionTocurrency: ICurrency;
}
export enum TRANSACTION_TYPE {
  STAKE = "stake",
  WITHDRAW = "withdraw",
  WITHDRAW_BONUS = "withdraw-bonus",
  WITHDRAW_BONUS_STAKE = "withdraw-bonus-stake",
  SWAP = "swap",
  LOAN = "loan",
  CLAIM_STAKING_INTEREST = "claim-staking-interest",
  CLAIM_STAKING_PRINCIPLE = "claim-staking-principle",
}

export interface IAffiliateHistory {
  title: string;
  date_created: string;
  referee: string;
  icon: string;
  type?: string;
}

export interface IMyReferral {
  aff_id: string;
  aff_link: string;
  referral_earned_available: string;
  total_referral_earned: string;
  total_referral_withdrawn: string;
  unlock_time: string;
  social: any[];
  solReward: any;
  solxReward: any;
  knowledge_url: string;
  status?: boolean;
  minimum_mint?: string;
  your_mint?: string | number;
  solContribution: string | number;
  solxBoosting: string | number;
}
export type ALLIANCE_GROUP_STATUS =
  | "SEARCHING"
  | "SEARCHING_NOT_FOUND"
  | "SEARCHING_HAS_DATA"
  | "LOADING_DATA"
  | "NO_DATA"
  | "HAS_DATA";

export interface IAllianceGroup {
  ranking: number;
  nickName: string;
  children: [];
  totalPurchase: number;
  affiliateGroup: number;
  userId: string;
  id: string;
  knowledge_url: string;
}

export interface IRoadmap {
  id: number;
  status: string;
  date_created: string;
  date_updated: string;
  phase: string;
  header: string;
  body: string[] | string;
  pool: number;
  image: string;
}

export interface IAffiliateBonusInfo {
  id: number;
  status: string;
  level: number;
  rankName: string;
  icon: string;
  bonus: string;
  description: string;
}
