import store from "@/store";
import getStandardNumber from "./getStandardNumber";
import removeTrailingZeros from "./removeTrailingZeros";

function formatNumber(num: number) {
  if (num >= 1_000_000_000_000) {
    return formatter((num / 1_000_000_000_000).toFixed(2)) + "T";
  } else if (num >= 1_000_000_000) {
    return formatter((num / 1_000_000_000).toFixed(2)) + "B";
  } else if (num >= 1_000_000) {
    return formatter((num / 1_000_000).toFixed(2)) + "M";
  } else if (num >= 1_000) {
    return formatter((num / 1_000).toFixed(2)) + "K";
  } else {
    return num.toString();
  }
}

const formatter = (
  value: string | number,
  showUnit = false,
  useCompact = true,
  useCompactOnThousand = false,
  lengthAllowedPassed?: number
): string => {
  try {
    const lengthAllowed =
      lengthAllowedPassed ??
      store.state.dynamicConfigs?.displayLengthAllowed ??
      8;
    const numOfDecimalPart = 4;
    const decimalSeparator = ".";
    const thousandsSeparator = ",";
    const unitDirection: "left" | "right" = "right";
    const currencyObject: CurrencyType = {
      unit_symbol: "$",
      usd_rate: +1,
    };

    const number = Number(value.toString());

    if (number > 1000 && useCompactOnThousand) {
      return formatNumber(number);
    }
    if (number > 1000000 && useCompact) {
      return formatNumber(number);
    }

    const numberToString = getStandardNumber(number);
    if (numberToString.includes(".")) {
      // is not natural number
      const indexOfDecimalDot = numberToString.indexOf(".");
      const before = numberToString.slice(0, indexOfDecimalDot);
      const after = numberToString.slice(
        indexOfDecimalDot + 1,
        indexOfDecimalDot +
          1 +
          (lengthAllowed - before.length - 1 <= 1
            ? 2
            : lengthAllowed - before.length - 1)
      );
      const beforeFormatted = before.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        thousandsSeparator
      );
      const afterFormatted = Number(`0.${after}`)
        ?.toString()
        ?.replace("0.", "");
      return `${beforeFormatted}${+afterFormatted ? "." + afterFormatted : ""}`;
      // return afterFormatted;
    } else {
      //is natural number
      return `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);
    }
  } catch (err) {
    console.error(err);
    return "Loading...";
  }
};

// export interface FormatterInput {
//   value: string | number;
//   numOfDecimalPart?: number;
//   showUnit?: boolean;
// }

export interface CurrencyType {
  unit_symbol: string;
  usd_rate: number;
}

export default formatter;

export const ex = `
1.123456789 -> 1.123456 (tổng là 8 kí tự)
12.123456789 -> 12.12345 (tổng là 8 kí tự)
123.123456789 -> 123.1234 (tổng là 8 kí tự)
...
1234567.123456789 -> 1234567.12 (ít nhất là 2 số thập phân)
12345678.123456789 -> 12345678.12 (ít nhất là 2 số thập phân)
...
123456789999.123456789 -> 123456789999.12 (ít nhất là 2 số thập phân)
`;
