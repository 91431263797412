<template>
  <div
    class="relative flex items-end justify-between py-2.5 px-4 rounded-[12px] border_2 z-[99999] bg-white bg_dark"
  >
    <div
      class="w-[calc(16%+10px)] aspect-square border_2 rounded-full absolute top-0 left-1/2 -translate-y-1/2 -translate-x-1/2 big_moon bg_dark"
    ></div>
    <v-menu v-model="showHomeMenu" location="top">
      <template v-slot:activator="{ props }">
        <BottomNavbarItem
          v-bind="props"
          class="w-[20%]"
          :text="translation('nav_Home')"
          icon="nav_home"
          :active="route.hash == '#statistic'"
        ></BottomNavbarItem>
      </template>
      <v-list
        class="!rounded-[16px] border_2 flex flex-col gap-2 min-w-[200px] my-2 bg-white bg_dark !p-2"
      >
        <v-list-item class="!p-0 h-fit !min-h-0" @click="handleClickHome">
          <OverviewListOption
            textKey="home_home"
            :icon="getIcon('home_home' + (isDark() ? '_dark' : ''))"
          ></OverviewListOption>
        </v-list-item>
        <div class="divider"></div>
        <v-list-item
          class="!p-0 h-fit !min-h-0"
          @click="
            () => {
              handleNavigate({ href: '#statistic' });
            }
          "
        >
          <OverviewListOption
            :active="route.hash == '#statistic'"
            textKey="home_statistics"
            :icon="getIcon('home_statistics' + (isDark() ? '_dark' : ''))"
          ></OverviewListOption>
        </v-list-item>
      </v-list>
    </v-menu>
    <BottomNavbarItem
      class="w-[20%]"
      text="nav_History"
      icon="nav_History"
      :active="route.fullPath.includes('#history')"
      @click="() => handleNavigate({ href: '#history' })"
    ></BottomNavbarItem>
    <v-menu v-model="showMenu" location="top">
      <template v-slot:activator="{ props }">
        <div
          v-bind="props"
          class="absolute bg_dark top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 border_2 w-[16%] aspect-square rounded-full p-1"
        >
          <div
            class="absolute inset-1 rounded-full bg-cover bg-no-repeat bg-center"
            :style="{
              opacity: showMenu ? 1 : 0,
              backgroundImage: `url(${getIcon('overview_close')})`,
            }"
          ></div>
          <div
            class="absolute inset-1 rounded-full bg-cover bg-no-repeat bg-center"
            :style="{
              opacity: showMenu ? 0 : 1,
              backgroundImage: `url(${getIcon('solx_logo')})`,
            }"
          ></div>

          <div
            class="list_board duration-[250ms] flex flex-col gap-2 min-w-[200px] my-2 bg-white bg_dark absolute top-0 left-1/2 -translate-y-[calc(100%+15px)] -translate-x-1/2"
            :class="{
              'scale-0': !showMenu,
              'scale-1': showMenu,
            }"
            style="padding: 8px !important; transform-origin: bottom center"
          >
            <v-list-item
              class="!p-0 h-fit !min-h-0"
              @click="
                () => {
                  setTab('powering');
                }
              "
            >
              <OverviewListOption
                :active="
                  route.hash == '#statistics' &&
                  store.state.tabPowering == 'powering'
                "
                textKey="ovv_contribute"
                :icon="getIcon('ovv_contribute' + (isDark() ? '_dark' : ''))"
              ></OverviewListOption>
            </v-list-item>
            <div class="divider"></div>
            <v-list-item
              class="!p-0 h-fit !min-h-0"
              @click="() => setTab('loan')"
              ><OverviewListOption
                :active="
                  route.hash == '#statistics' &&
                  (store.state.tabPowering == 'loan' ||
                    store.state.tabPowering == 'loan_withdraw')
                "
                textKey="ovv_boosting"
                :icon="getIcon('ovv_boosting' + (isDark() ? '_dark' : ''))"
              ></OverviewListOption
            ></v-list-item>
            <div class="divider"></div>
            <v-list-item
              class="!p-0 h-fit !min-h-0"
              @click="() => setTab('sell')"
            >
              <OverviewListOption
                :active="
                  route.hash == '#statistics' &&
                  store.state.tabPowering == 'sell'
                "
                textKey="ovv_swap"
                :icon="getIcon('ovv_swap' + (isDark() ? '_dark' : ''))"
              ></OverviewListOption>
            </v-list-item>
          </div>
        </div>
      </template>

      <!-- <v-list>
        <div class="border min-w-[200px]"></div>
      </v-list> -->
    </v-menu>
    <BottomNavbarItem
      class="w-[20%]"
      :text="translation('nav_statistic')"
    ></BottomNavbarItem>
    <v-menu v-model="showRewardMenu" location="top">
      <template v-slot:activator="{ props }">
        <BottomNavbarItem
          v-bind="props"
          class="w-[20%]"
          :text="translation('nav_Reward')"
          icon="nav_Reward"
          :active="
            route.fullPath.includes('#link') ||
            route.fullPath.includes('#hierarchy')
          "
        ></BottomNavbarItem>
      </template>
      <v-list
        class="!rounded-[16px] border_2 flex flex-col gap-2 min-w-[200px] my-2 bg-white bg_dark !p-2"
      >
        <v-list-item
          class="!p-0 h-fit !min-h-0"
          @click="
            () => {
              handleNavigate({ href: '#link' });
            }
          "
        >
          <OverviewListOption
            :active="route.fullPath.includes('#link')"
            textKey="nav_Referral"
            :icon="getIcon('nav_Referral' + (isDark() ? '_dark' : ''))"
          ></OverviewListOption>
        </v-list-item>
        <div class="divider"></div>
        <v-list-item
          class="!p-0 h-fit !min-h-0"
          @click="
            () => {
              handleNavigate({ href: '#hierarchy' });
            }
          "
        >
          <OverviewListOption
            :active="route.fullPath.includes('#hierarchy')"
            textKey="nav_Hierarchy"
            :icon="getIcon('nav_Hierarchy' + (isDark() ? '_dark' : ''))"
          ></OverviewListOption>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu v-model="showSettingMenu" location="top">
      <template v-slot:activator="{ props }">
        <BottomNavbarItem
          v-bind="props"
          class="w-[20%]"
          :text="translation('nav_setting')"
          icon="nav_setting"
          :active="false"
        ></BottomNavbarItem>
      </template>
      <v-list
        class="!rounded-[16px] border_2 flex flex-col gap-2 min-w-[200px] my-2 bg-white bg_dark !p-2"
      >
        <v-list-item
          v-for="(e, i) in store.state.appSetting?.footer"
          :key="i"
          class="!p-0 h-fit !min-h-0"
        >
          <OverviewListOption
            v-if="!e.redirect"
            @click="
              () => {
                store.commit('setShowTermsModal', true);
              }
            "
            :textKey="e.name"
            :icon="getIcon(e.name + (isDark() ? '_dark' : ''))"
          ></OverviewListOption>
          <OverviewListOption
            v-if="e.redirect"
            @click="
              () => {
                handleNavigate({ href: e.url });
              }
            "
            :textKey="e.name"
            :icon="getIcon(e.name + (isDark() ? '_dark' : ''))"
          ></OverviewListOption>
          <div class="divider mt-2"></div>
        </v-list-item>
        <v-list-item
          class="!p-0 h-fit !min-h-0"
          @click="
            () => {
              if (store.state.themeMode == 'dark') {
                store.commit('setThemeMode', 'light');
                return;
              }
              store.commit('setThemeMode', 'dark');
            }
          "
        >
          <OverviewListOption
            :textKey="isDark() ? 'turn_on_the_light' : 'turn_off_the_light'"
            :icon="getIcon(isDark() ? 'mode_active_dark' : 'light_mode_active')"
          ></OverviewListOption>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog v-model="dialog" max-width="400">
      <v-card
        :text="translation('connect_wallet_is_required_des')"
        :title="translation('connect_wallet_is_required')"
      >
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false">
            {{ translation("connect_require_ok") }}
          </v-btn>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { BOTTOM_NAVBAR_ITEMS } from "@/common/const";
import BottomNavbarItem from "./BottomNavbarItem.vue";
import getIcon from "@/common/function/getIcon";
import OverviewListOption from "./OverviewListOption.vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import translation from "@/common/function/translation";
import isDark from "@/common/function/isDark";
const showMenu = ref<boolean>(false);
const showHomeMenu = ref<boolean>(false);
const showRewardMenu = ref<boolean>(false);
const showSettingMenu = ref<boolean>(false);
const dialog = ref<boolean>(false);
const items = BOTTOM_NAVBAR_ITEMS;
const store = useStore();
const router = useRouter();
const route = useRoute();
const setTab = (tabName?: string) => {
  window.location.hash = "#empty";
  window.location.hash = "#statistics";
  store.commit("setPowering", tabName);
  const timeout = setTimeout(() => {
    store.commit("setShowNavbar", false);
    clearTimeout(timeout);
  }, 1000);
};
const handleClickHome = () => {
  window.open(translation("home_page_link"), "_self");
};
const handleNavigate = (e: any) => {
  if (!e.href?.includes("#")) {
    window.open(translation(e.href), "_self");
    return;
  }
  if (!store.state.user?.token) {
    dialog.value = true;
    return;
  }
  window.location.hash = "#empty";
  window.location.hash = e.href;
  const timeout = setTimeout(() => {
    store.commit("setShowNavbar", false);
    clearTimeout(timeout);
  }, 1000);
};
</script>

<style scoped>
.big_moon {
  background: #fff;
  clip-path: polygon(
    0% calc(50% - 2px),
    0% 100%,
    100% 100%,
    100% calc(50% - 2px)
  );
}
</style>
