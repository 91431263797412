<template>
  <div class="chart_tree_container relative">
    <div id="chart_tree" class="appear_animation appear_animation_mobile"></div>
    <div
      v-if="
        nodesClicked[0] &&
        globalSeries?.dataItems?.getIndex(0)?.children?.length > 0
      "
      class="absolute bottom-2 lg:!bottom-4 left-1/2 -translate-x-1/2 text-base lg:text-lg scale-[0.75] lg:scale-[1]"
    >
      <div class="flex items-center justify-between gap-2 text_dark">
        <span class="text-base lg:text-lg"
          ><b>{{ nodesClicked[0]?.name }}</b></span
        >
        <div class="flex items-center gap-0.5">
          <span class="text-base lg:text-lg flex items-center gap-1 justify-end"
            ><b>{{ nodesClicked[0]?.referral }}</b
            ><img
              :src="
                getIcon(
                  isDark() ? 'referral_directive_white' : 'referral_directive'
                )
              "
              alt=""
              class="block w-6 h-6 relative top-[-1px]"
          /></span>
          <span class="text-base lg:text-lg flex"><b>/</b></span>
          <span class="text-base lg:text-lg flex items-center gap-1 justify-end"
            ><b>{{ nodesClicked[0]?.totalReferral }}</b
            ><img
              :src="getIcon(isDark() ? 'referral_white' : 'referral')"
              alt=""
              class="block w-6 h-6 relative top-[-1px]"
          /></span>
        </div>
      </div>
      <div class="flex items-center gap-4">
        <button
          class=""
          @click="handleClickPrevious"
          :class="{
            disabled:
              nodesClicked[0]?.collapsed ||
              limits[nodesClicked[0]?.name] <= perLimit,
          }"
        >
          <div
            class="hover:!bg-[#d3ff35] hover:-translate-x-[5px] bg-[#CAFF04] duration-200 flex flex-col py-1 px-4 items-center rounded-full border_2"
          >
            <span class="flex items-center">
              <v-icon
                :color="isDark() ? '#000' : '#000'"
                icon="mdi-skip-previous-outline"
                size="default"
              ></v-icon>
              <b>{{ translation("Previous") }}</b>
            </span>
            <span class="whitespace-nowrap"
              >({{
                limits[nodesClicked[0] && nodesClicked[0].name] - perLimit < 0
                  ? 0
                  : limits[nodesClicked[0] && nodesClicked[0].name] - perLimit
              }}
              {{
                (limits[nodesClicked[0] && nodesClicked[0].name] - perLimit < 0
                  ? 0
                  : limits[nodesClicked[0] && nodesClicked[0].name] -
                    perLimit) > 1
                  ? translation("aff_button_members")
                  : translation("aff_button_member")
              }})</span
            >
          </div>
        </button>
        <button
          class=""
          @click="handleClickNext"
          :class="{
            disabled:
              nodesClicked[0]?.collapsed ||
              limits[nodesClicked[0]?.name] >=
                +nodesClicked[0]?.children?.length,
          }"
        >
          <div
            class="hover:!bg-[#d3ff35] hover:translate-x-[5px] bg-[#CAFF04] duration-200 flex flex-col py-1 px-4 items-center rounded-full border_2"
          >
            <span class="flex items-center">
              <b>{{ translation("Next") }}</b>
              <v-icon
                style="transform: rotateY(180deg)"
                :color="isDark() ? '#000' : '#000'"
                icon="mdi-skip-previous-outline"
                size="default"
              ></v-icon>
            </span>
            <span class="whitespace-nowrap"
              >({{
                nodesClicked[0] &&
                nodesClicked[0].children.length -
                  limits[nodesClicked[0] && nodesClicked[0].name] <
                  0
                  ? 0
                  : nodesClicked[0] &&
                    nodesClicked[0].children.length -
                      limits[nodesClicked[0] && nodesClicked[0].name]
              }}
              {{
                (nodesClicked[0] &&
                nodesClicked[0].children.length -
                  limits[nodesClicked[0] && nodesClicked[0].name] <
                  0
                  ? 0
                  : nodesClicked[0] &&
                    nodesClicked[0].children.length -
                      limits[nodesClicked[0] && nodesClicked[0].name]) > 1
                  ? translation("aff_button_members")
                  : translation("aff_button_member")
              }})</span
            >
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import getIcon from "@/common/function/getIcon";
import isDark from "@/common/function/isDark";
import translation from "@/common/function/translation";
import useResponsive from "@/hook/useResponsive";
import store from "@/store";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4plugins_forceDirected from "@amcharts/amcharts4/plugins/forceDirected";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { onMounted, defineProps, watch, ref } from "vue";
am4core.useTheme(am4themes_animated);

const perLimit = ref(2);
const nodesClicked = ref([]);
const globalSeries = ref();
const blink_color_interval = ref();
const hierarchyColors = [
  "#E57373", // Red
  "#F06292", // Pink
  "#BA68C8", // Purple
  "#9575CD", // Deep Purple
  "#7986CB", // Indigo
  "#64B5F6", // Blue
  "#4DD0E1", // Cyan
  "#4DB6AC", // Teal
  "#81C784", // Green
  "#AED581", // Light Green
  "#DCE775", // Lime
  "#FFF176", // Yellow
  "#FFD54F", // Amber
  "#FFB74D", // Orange
  "#FF8A65", // Deep Orange
];
const props = defineProps(["data"]);
const limits = ref({});
const handleClickNext = () => {
  console.log(nodesClicked.value[0]);
  if (nodesClicked.value[0].collapsed) return;
  if (
    limits.value[nodesClicked.value[0].name] >=
    +nodesClicked.value[0].children.length
  )
    return;
  limits.value[nodesClicked.value[0].name] =
    (limits.value[nodesClicked.value[0].name]
      ? limits.value[nodesClicked.value[0].name]
      : 0) + perLimit.value;
  handleHitNode(nodesClicked.value[0]);
};
const handleClickPrevious = () => {
  if (nodesClicked.value[0].collapsed) return;
  limits.value[nodesClicked.value[0].name] =
    (limits.value[nodesClicked.value[0].name]
      ? limits.value[nodesClicked.value[0].name]
      : perLimit.value) - perLimit.value;
  if (limits.value[nodesClicked.value[0].name] < perLimit.value) {
    limits.value[nodesClicked.value[0].name] = perLimit.value;
  }
  handleHitNode(nodesClicked.value[0]);
};

watch(
  () => store.state.dynamicConfigs,
  () => {
    +store.state.dynamicConfigs?.limitNextPreviousGraphAff &&
      (perLimit.value = +store.state.dynamicConfigs?.limitNextPreviousGraphAff);
  },
  {
    immediate: true,
  }
);

const blinkTime = 500;
const createGradient = (startColor, endColor) => {
  let gradient = new am4core.LinearGradient();
  let start = am4core.color(startColor);
  let end = am4core.color(endColor);
  start.lighten(0.1); // Lighten the start color
  end.lighten(0.1); // Lighten the end color
  gradient.addColor(start);
  gradient.addColor(end);
  return gradient;
};
const gradient = createGradient("#FF00FF99", "#0000FF99");
// const gradient = am4core.color("#000");

const resetNode = (node, resetBorder = true) => {
  const level = node.level || 0;
  node.node.circle.animate(
    {
      property: "fill",
      to: am4core.color(hierarchyColors[level % hierarchyColors.length]),
    },
    200,
    am4core.ease.cubicInOut
  );
  if (resetBorder) {
    node.node.stroke = am4core.color(
      hierarchyColors[level % hierarchyColors.length]
    );
    node.node.strokeWidth = 0;
  }
};
const highlightNode = (node, highlightBorder = true) => {
  const level = node.level || 0;
  // node.node.circle.animate(
  //   {
  //     property: "fill",
  //     to: am4core.color(hierarchyColors[level % hierarchyColors.length]),
  //   },
  //   200,
  //   am4core.ease.cubicInOut
  // );
  if (highlightBorder) {
    node.node.stroke = gradient;
    node.node.strokeWidth = 4;
  }
};

watch(
  () => nodesClicked.value[0],
  (newValue, oldValue) => {
    if (oldValue) {
      resetNode(oldValue);
    }

    blink_color_interval.value & clearInterval(blink_color_interval.value);

    newValue &&
      (blink_color_interval.value = setInterval(() => {
        nodesClicked.value[0] &&
          globalSeries.value?.dataItems?.getIndex(0)?.children?.length > 0 &&
          highlightNode(newValue);
        // newValue.node.stroke = gradient;
        // newValue.node.strokeWidth = 3;
        const timeout = setTimeout(() => {
          // const level = newValue.level || 0;
          // newValue.node.circle.animate(
          //   {
          //     property: "fill",
          //     to: am4core.color(
          //       hierarchyColors[level % hierarchyColors.length]
          //     ),
          //   },
          //   200,
          //   am4core.ease.cubicInOut
          // );
          // newValue.node.stroke = am4core.color(
          //   hierarchyColors[level % hierarchyColors.length]
          // );
          resetNode(newValue);
          clearTimeout(timeout);
        }, blinkTime);
      }, blinkTime * 2));
  }
);

const handleHitNode = (node) => {
  node.children.each((e, i) => {
    e.collapsed = true;
    if (
      i >= limits.value[node.name] ||
      i < limits.value[node.name] - perLimit.value ||
      node.collapsed
    ) {
      e.node.hide();
    } else {
      e.node.show();
    }
  });
};

const createNodes = (data) => {
  let chart = am4core.create(
    "chart_tree",
    am4plugins_forceDirected.ForceDirectedTree
  );
  let series = chart.series.push(
    new am4plugins_forceDirected.ForceDirectedSeries()
  );
  chart.data = [data];

  const maxLevel = 2;

  series.dataFields.value = "value";
  series.dataFields.name = "name";
  series.dataFields.commission = "commission";
  series.dataFields.referral = "refferal";
  series.dataFields.totalReferral = "totalReferral";
  series.dataFields.ranking = "ranking";
  series.dataFields.rank_name = "rank_name";
  series.dataFields.deposit = "sol";
  series.dataFields.deposit_g = "sol_g";
  series.dataFields.loans = "solx";
  series.dataFields.loans_g = "solx_g";
  series.dataFields.wallet_address = "wallet_address";
  series.maxLevels = maxLevel;
  // series.dataFields.children = [];
  series.dataFields.children = "children";
  series.nodes.template.tooltipText = `{name} {rank_name}\n\n {deposit} ${translation(
    "hie_SOL_Deposited"
  )}\n\n {loans} ${translation("hie_Loans")}\n\n-----------\n\n${translation(
    "hie_group_analytic"
  )}\n\n {deposit_g} ${translation(
    "hie_SOL_Deposited_g"
  )}\n\n {loans_g} ${translation("hie_Loans_g")}\n`;
  series.nodes.template.fillOpacity = 1;

  series.nodes.template.label.text = "{name}";
  series.nodes.template.label.fill = am4core.color(isDark() ? "#fff" : "#000");
  series.fontSize = useResponsive(10, 16).value;

  series.links.template.distance = 1.5;

  series.events.on("inited", function () {
    globalSeries.value = series;
    series.dataItems.each(function (dataItem) {
      dataItem.children.each((e) => {
        e.collapsed = true;
        resetNode(e);
      });
    });
    const rootNode = series.dataItems.getIndex(0);
    nodesClicked.value.unshift(rootNode);
    limits.value[nodesClicked.value[0].name] = limits.value[
      nodesClicked.value[0].name
    ]
      ? limits.value[nodesClicked.value[0].name]
      : perLimit.value;
    handleHitNode(nodesClicked.value[0]);
  });

  series.nodes.template.events.on("hit", function (ev) {
    var node = ev.target.dataItem;
    if (!node || !node.children || node.children.length <= 0) return;
    // const children = [];
    // node.parent?.children.each((e) => children.push(e.name));
    // alert(children.join("-"));
    // const index = children.indexOf(node.name);
    // alert(index);
    // if (
    //   index >= limits.value[node.name] ||
    //   index < limits.value[node.name] - perLimit.value
    // ) {
    //   alert("return");
    //   return;
    // }
    node.children.each((e) => resetNode(e));
    node.collapsed = !node.collapsed;
    if (node.collapsed) {
      limits.value[node.name] = perLimit.value;
      {
        const parent = nodesClicked.value?.find(
          (e) => e.name == node.parent?.name
        );
        const parentIndex = nodesClicked.value
          ?.map((e) => e.name)
          ?.lastIndexOf(parent?.name);
        if (parentIndex > -1) {
          nodesClicked.value.splice(0, parentIndex);
        } else {
          nodesClicked.value = [];
        }
      }
      console.error(nodesClicked.value);
      return;
    }
    nodesClicked.value.unshift(node);
    limits.value[nodesClicked.value[0].name] = limits.value[
      nodesClicked.value[0].name
    ]
      ? limits.value[nodesClicked.value[0].name]
      : perLimit.value;
    handleHitNode(node);
    console.error(nodesClicked.value);
  });
  let hoverState = series.links.template.states.create("hover");
  hoverState.properties.strokeWidth = 3;
  hoverState.properties.strokeOpacity = 1;

  series.nodes.template.events.on("over", function (event) {
    event.target.dataItem.childLinks.each(function (link) {
      link.isHover = true;
    });
    if (event.target.dataItem.parentLink) {
      event.target.dataItem.parentLink.isHover = true;
    }
  });

  series.nodes.template.events.on("out", function (event) {
    event.target.dataItem.childLinks.each(function (link) {
      link.isHover = false;
    });
    if (event.target.dataItem.parentLink) {
      event.target.dataItem.parentLink.isHover = false;
    }
  });
  //remove logo
  const removeLogoInterval = setInterval(() => {
    const gs = Array.from(document.querySelectorAll("g"))?.filter(
      (e) => !!e.getAttribute("filter")
    );
    if (gs[1]) {
      gs[1]?.remove();
      clearInterval(removeLogoInterval);
    }
  }, 1);
};
onMounted(() => {
  props.data && createNodes(props.data);
});

watch([() => props.data, () => "store.state.themeMode"], () => {
  props.data && createNodes(props.data);
});
watch(
  () => store.state.themeMode,
  () => {
    if (globalSeries.value) {
      globalSeries.value.nodes?.each(function (node) {
        node.label.fill = am4core.color(isDark() ? "#fff" : "#000");
      });
    }
  }
);
</script>

<style scoped>
.dark #chart_tree {
  border: 2px solid var(--limit-color-dark-mode);
  background: var(--dark-black-light);
  /* background: #397255; */
}
#chart_tree {
  width: 100%;
  border-radius: 16px;
  border: 2px solid var(--app-questn-com-black, #000);
  background: var(--app-questn-com-purple-pizzazz, #f5ffce);
}
@keyframes appear {
  0% {
    height: 0px;
    opacity: 0;
  }
  100% {
    height: 800px;
    opacity: 1;
  }
}
@keyframes appear_mobile {
  0% {
    height: 0px;
    opacity: 0;
  }
  100% {
    height: 500px;
    opacity: 1;
  }
}

@media (max-width: 1279px) {
  .appear_animation_mobile {
    animation-name: appear_mobile !important;
  }
}
.appear_animation {
  animation-name: appear;
  animation-duration: 600ms;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}
.disabled {
  filter: grayscale(100%);
  pointer-events: none;
}
</style>
