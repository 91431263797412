import { AffiliateItemData } from "../type";

export default function determineParent(data: AffiliateItemData) {
  try {
    if (data.children && data.children.length > 0) {
      data.children.forEach((child: AffiliateItemData) => {
        child.parent = data;
        determineParent(child);
      });
    }
  } catch (err) {
    console.error(err);
  }
}
