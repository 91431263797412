import gql from "graphql-tag";
export const getHistoryTrans = gql`
  query Query($timezone: String) {
    transactionHistory(timezone: $timezone)
  }
`;

interface InputTransaction {
  timezone: string;
}

export const getHistoryAffiliate = gql`
  query Query {
    affHistory
  }
`;
