import store from "@/store";
import { toast, ToastPosition } from "vue3-toastify";

interface Config {
  duration?: number;
  position?: ToastPosition;
}

const defaultDuration = 6000;

interface UseToastMessage {
  success: (content: string, config?: Config) => any;
  error: (content: string, config?: Config) => any;
  loading: (content: string, config?: Config) => any;
  warning: (content: string, config?: Config) => any;
}

export default function useToastMessage(): UseToastMessage {
  return {
    success: (
      content: string,
      config = { duration: defaultDuration, position: "top-right" }
    ) => {
      toast(content, {
        theme: store.state.themeMode ?? "light",
        type: "success",
        autoClose: config?.duration,
        transition: "slide",
        position: config.position || "top-right",
        dangerouslyHTMLString: true,
      });
    },
    error: (
      content: string,
      config = { duration: defaultDuration, position: "top-right" }
    ) => {
      toast(content, {
        theme: store.state.themeMode ?? "light",
        type: "error",
        autoClose: config?.duration,
        transition: "slide",
        position: config.position || "top-right",
        dangerouslyHTMLString: true,
      });
    },
    loading: (
      content: string,
      config = { duration: defaultDuration, position: "top-right" }
    ) => {
      toast(content, {
        theme: store.state.themeMode ?? "light",
        type: "info",
        autoClose: config?.duration,
        transition: "slide",
        position: config.position || "top-right",
        dangerouslyHTMLString: true,
      });
    },
    warning: (
      content: string,
      config = { duration: defaultDuration, position: "top-right" }
    ) => {
      toast(content, {
        theme: store.state.themeMode ?? "light",
        type: "warning",
        autoClose: config?.duration,
        transition: "slide",
        position: config.position || "top-right",
        dangerouslyHTMLString: true,
      });
    },
  };
}
