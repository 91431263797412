<template>
  <div class="wallet_container">
    <img loading="lazy" v-if="icon" :src="icon" alt="" class="wallet_icon" />
    <div
      class="wallet_name"
      :class="{
        wallet_name_color: walletNameColor,
      }"
    >
      {{ name }}
    </div>
    <img
      loading="lazy"
      v-if="showDownArrow"
      :src="getIcon('down_full_arrow')"
      alt=""
      style="width: 24px; height: 24px; aspect-ratio: 1"
    />
    <img
      loading="lazy"
      v-if="selected"
      :src="getIcon('checked')"
      alt=""
      style="width: 24px; height: 24px; aspect-ratio: 1"
    />
  </div>
</template>

<script setup lang="ts">
import getIcon from "@/common/function/getIcon";
import { defineProps, withDefaults } from "vue";
interface WalletItemProps {
  name?: string;
  icon?: string;
  gap: number;
  showDownArrow?: boolean;
  selected?: boolean;
  walletNameColor?: string;
}
const props = withDefaults(defineProps<WalletItemProps>(), {
  gap: 16,
});

console.log("props", props);
</script>

<style scoped>
.wallet_container {
  display: flex;
  align-items: center;
  gap: v-bind(gap + "px");
}
.dark .wallet_name {
  color: var(--text-color-dark-mode);
}
.wallet_name_color {
  color: v-bind(walletNameColor) !important;
}
.wallet_name {
  color: var(--app-questn-com-black);
  font-family: Aeonik;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  text-transform: capitalize;
  margin-right: auto;
}
.wallet_icon {
  width: 24px;
  height: 24px;
  display: block;
}
</style>
