export function isElementInViewport(el) {
  const rect = el?.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}
export default function scrollToElementIfNeeded(el) {
  if (!el) return;
  console.dir(el);
  if (!isElementInViewport(el)) {
    el.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  }
}
