import { Buffer } from "buffer";
import { struct, u8, blob } from "@solana/buffer-layout";
import type {
  ConfirmOptions,
  Connection,
  Keypair,
  TransactionSignature,
} from "@solana/web3.js";
import {
  PublicKey,
  SystemProgram,
  Transaction,
  TransactionInstruction,
  sendAndConfirmTransaction,
} from "@solana/web3.js";
import { u64, publicKey } from "@solana/buffer-layout-utils";
import { SWAP_PROGRAM_ID } from "../statictis";
import { BN, web3 } from "@project-serum/anchor";
import {
  TOKEN_2022_PROGRAM_ID,
  createAssociatedTokenAccountInstruction,
  createCloseAccountInstruction,
  createHarvestWithheldTokensToMintInstruction,
  createSyncNativeInstruction,
  createTransferCheckedInstruction,
  getAssociatedTokenAddressSync,
} from "@solana/spl-token";
const token_fee: any = process.env.VUE_APP_TOKEN_FEE;
export interface RawTokenSwap {
  version: number;
  isInitialized: boolean;
  bumpSeed: number;
  poolTokenProgramId: PublicKey;
  tokenAccountA: PublicKey;
  tokenAccountB: PublicKey;
  tokenPool: PublicKey;
  mintA: PublicKey;
  mintB: PublicKey;
  feeAccount: PublicKey;
  tradeFeeNumerator: bigint;
  tradeFeeDenominator: bigint;
  ownerTradeFeeNumerator: bigint;
  ownerTradeFeeDenominator: bigint;
  ownerWithdrawFeeNumerator: bigint;
  ownerWithdrawFeeDenominator: bigint;
  hostFeeNumerator: bigint;
  hostFeeDenominator: bigint;
  curveType: number;
  curveParameters: Uint8Array;
}
export const WSOL = new web3.PublicKey(token_fee);
export const TokenSwapLayout = struct<RawTokenSwap>([
  u8("version"),
  u8("isInitialized"),
  u8("bumpSeed"),
  publicKey("poolTokenProgramId"),
  publicKey("tokenAccountA"),
  publicKey("tokenAccountB"),
  publicKey("tokenPool"),
  publicKey("mintA"),
  publicKey("mintB"),
  publicKey("feeAccount"),
  u64("tradeFeeNumerator"),
  u64("tradeFeeDenominator"),
  u64("ownerTradeFeeNumerator"),
  u64("ownerTradeFeeDenominator"),
  u64("ownerWithdrawFeeNumerator"),
  u64("ownerWithdrawFeeDenominator"),
  u64("hostFeeNumerator"),
  u64("hostFeeDenominator"),
  u8("curveType"),
  blob(32, "curveParameters"),
]);

export interface CreateInstruction {
  instruction: number;
  tradeFeeNumerator: bigint;
  tradeFeeDenominator: bigint;
  ownerTradeFeeNumerator: bigint;
  ownerTradeFeeDenominator: bigint;
  ownerWithdrawFeeNumerator: bigint;
  ownerWithdrawFeeDenominator: bigint;
  hostFeeNumerator: bigint;
  hostFeeDenominator: bigint;
  curveType: number;
  curveParameters: Uint8Array;
}

export interface SwapInstruction {
  instruction: number;
  amountIn: bigint;
  minimumAmountOut: bigint;
}

export interface DepositAllInstruction {
  instruction: number;
  poolTokenAmount: bigint;
  maximumTokenA: bigint;
  maximumTokenB: bigint;
}

export interface WithdrawAllInstruction {
  instruction: number;
  poolTokenAmount: bigint;
  minimumTokenA: bigint;
  minimumTokenB: bigint;
}

export interface DepositSingleTokenTypeInstruction {
  instruction: number;
  sourceTokenAmount: bigint;
  minimumPoolTokenAmount: bigint;
}

export interface WithdrawSingleTokenTypeInstruction {
  instruction: number;
  destinationTokenAmount: bigint;
  maximumPoolTokenAmount: bigint;
}

export const CurveType = Object.freeze({
  ConstantProduct: 0, // Constant product curve, Uniswap-style
  ConstantPrice: 1, // Constant price curve, always X amount of A token for 1 B token, where X is defined at init
  Offset: 2, // Offset curve, like Uniswap, but with an additional offset on the token B side
});

/**
 * A program to exchange tokens against a pool of liquidity
 */
export class TokenSwap {
  /**
   * Create a Token object attached to the specific token
   *
   * @param connection The connection to use
   * @param tokenSwap The token swap account
   * @param swapProgramId The program ID of the token-swap program
   * @param poolTokenProgramId The program ID of the token program for the pool tokens
   * @param poolToken The pool token
   * @param authority The authority over the swap and accounts
   * @param tokenAccountA The token swap's Token A account
   * @param tokenAccountB The token swap's Token B account
   * @param mintA The mint of Token A
   * @param mintB The mint of Token B
   * @param tradeFeeNumerator The trade fee numerator
   * @param tradeFeeDenominator The trade fee denominator
   * @param ownerTradeFeeNumerator The owner trade fee numerator
   * @param ownerTradeFeeDenominator The owner trade fee denominator
   * @param ownerWithdrawFeeNumerator The owner withdraw fee numerator
   * @param ownerWithdrawFeeDenominator The owner withdraw fee denominator
   * @param hostFeeNumerator The host fee numerator
   * @param hostFeeDenominator The host fee denominator
   * @param curveType The curve type
   * @param payer Pays for the transaction
   */
  private connection: Connection;
  constructor(
    connection: Connection // public tokenSwap: PublicKey, // public swapProgramId: PublicKey, // public poolTokenProgramId: PublicKey, // public poolToken: PublicKey, // public feeAccount: PublicKey, // public authority: PublicKey, // public tokenAccountA: PublicKey, // public tokenAccountB: PublicKey, // public mintA: PublicKey, // public mintB: PublicKey, // public tradeFeeNumerator: bigint, // public tradeFeeDenominator: bigint, // public ownerTradeFeeNumerator: bigint, // public ownerTradeFeeDenominator: bigint, // public ownerWithdrawFeeNumerator: bigint, // public ownerWithdrawFeeDenominator: bigint, // public hostFeeNumerator: bigint, // public hostFeeDenominator: bigint, // public curveType: number, // public payer: Keypair
  ) {
    this.connection = connection;
    // this.tokenSwap = tokenSwap;
    // this.swapProgramId = swapProgramId;
    // this.poolTokenProgramId = poolTokenProgramId;
    // this.poolToken = poolToken;
    // this.feeAccount = feeAccount;
    // this.authority = authority;
    // this.tokenAccountA = tokenAccountA;
    // this.tokenAccountB = tokenAccountB;
    // this.mintA = mintA;
    // this.mintB = mintB;
    // this.tradeFeeNumerator = tradeFeeNumerator;
    // this.tradeFeeDenominator = tradeFeeDenominator;
    // this.ownerTradeFeeNumerator = ownerTradeFeeNumerator;
    // this.ownerTradeFeeDenominator = ownerTradeFeeDenominator;
    // this.ownerWithdrawFeeNumerator = ownerWithdrawFeeNumerator;
    // this.ownerWithdrawFeeDenominator = ownerWithdrawFeeDenominator;
    // this.hostFeeNumerator = hostFeeNumerator;
    // this.hostFeeDenominator = hostFeeDenominator;
    // this.curveType = curveType;
    // this.payer = payer;
  }

  /**
   * Get the minimum balance for the token swap account to be rent exempt
   *
   * @return Number of lamports required
   */
  static async getMinBalanceRentForExemptTokenSwap(
    connection: Connection
  ): Promise<number> {
    return await connection.getMinimumBalanceForRentExemption(
      TokenSwapLayout.span
    );
  }
  /**
   * Swap token A for token B
   *
   * @param userSource User's source token account
   * @param poolSource Pool's source token account
   * @param poolDestination Pool's destination token account
   * @param userDestination User's destination token account
   * @param sourceMint Mint for the source token
   * @param destinationMint Mint for the destination token
   * @param sourceTokenProgramId Program id for the source token
   * @param destinationTokenProgramId Program id for the destination token
   * @param hostFeeAccount Host account to gather fees
   * @param userTransferAuthority Account delegated to transfer user's tokens
   * @param amountIn Amount to transfer from source account
   * @param minimumAmountOut Minimum amount of tokens the user will receive
   */
  // async swap(
  //   userSource: PublicKey,
  //   poolSource: PublicKey,
  //   poolDestination: PublicKey,
  //   userDestination: PublicKey,
  //   sourceMint: PublicKey,
  //   destinationMint: PublicKey,
  //   sourceTokenProgramId: PublicKey,
  //   destinationTokenProgramId: PublicKey,
  //   hostFeeAccount: PublicKey | null,
  //   userTransferAuthority: Keypair,
  //   amountIn: bigint,
  //   minimumAmountOut: bigint,
  //   confirmOptions?: ConfirmOptions
  // ): Promise<TransactionSignature> {
  //   return await sendAndConfirmTransaction(
  //     this.connection,
  //     new Transaction().add(
  //       TokenSwap.swapInstruction(
  //         this.tokenSwap,
  //         this.authority,
  //         userTransferAuthority.publicKey,
  //         userSource,
  //         poolSource,
  //         poolDestination,
  //         userDestination,
  //         this.poolToken,
  //         this.feeAccount,
  //         hostFeeAccount,
  //         sourceMint,
  //         destinationMint,
  //         this.swapProgramId,
  //         sourceTokenProgramId,
  //         destinationTokenProgramId,
  //         this.poolTokenProgramId,
  //         amountIn,
  //         minimumAmountOut
  //       )
  //     ),
  //     [this.payer, userTransferAuthority],
  //     confirmOptions
  //   );
  // }

  static swapInstruction(
    tokenSwap: PublicKey,
    authority: PublicKey,
    userTransferAuthority: PublicKey,
    userSource: PublicKey,
    poolSource: PublicKey,
    poolDestination: PublicKey,
    userDestination: PublicKey,
    poolMint: PublicKey,
    feeAccount: PublicKey,
    hostFeeAccount: PublicKey | null,
    sourceMint: PublicKey,
    destinationMint: PublicKey,
    swapProgramId: PublicKey,
    sourceTokenProgramId: PublicKey,
    destinationTokenProgramId: PublicKey,
    poolTokenProgramId: PublicKey,
    amountIn: bigint,
    minimumAmountOut: bigint
  ): TransactionInstruction {
    const dataLayout = struct<SwapInstruction>([
      u8("instruction"),
      u64("amountIn"),
      u64("minimumAmountOut"),
    ]);

    const data = Buffer.alloc(dataLayout.span);
    dataLayout.encode(
      {
        instruction: 1, // Swap instruction
        amountIn,
        minimumAmountOut,
      },
      data
    );

    const keys = [
      { pubkey: tokenSwap, isSigner: false, isWritable: false },
      { pubkey: authority, isSigner: false, isWritable: false },
      { pubkey: userTransferAuthority, isSigner: true, isWritable: false },
      { pubkey: userSource, isSigner: false, isWritable: true },
      { pubkey: poolSource, isSigner: false, isWritable: true },
      { pubkey: poolDestination, isSigner: false, isWritable: true },
      { pubkey: userDestination, isSigner: false, isWritable: true },
      { pubkey: poolMint, isSigner: false, isWritable: true },
      { pubkey: feeAccount, isSigner: false, isWritable: true },
      { pubkey: sourceMint, isSigner: false, isWritable: false },
      { pubkey: destinationMint, isSigner: false, isWritable: false },
      { pubkey: sourceTokenProgramId, isSigner: false, isWritable: false },
      { pubkey: destinationTokenProgramId, isSigner: false, isWritable: false },
      { pubkey: poolTokenProgramId, isSigner: false, isWritable: false },
    ];
    if (hostFeeAccount !== null) {
      keys.push({ pubkey: hostFeeAccount, isSigner: false, isWritable: true });
    }
    return new TransactionInstruction({
      keys,
      programId: swapProgramId,
      data,
    });
  }

  async createSwapTransaction(
    payer: web3.PublicKey,
    pool: web3.PublicKey,
    srcMint: TokenInput,
    dstMint: TokenInput,
    route: TokenSwapPool | any,
    amountIn: number,
    minimumAmountOut: number
  ) {
    const aToB = srcMint.mint.equals(route.mintA);
    console.log("new BN(amountIn, 10)", Number(new BN(amountIn, 10)));

    console.log("createSwapTransaction", {
      amountIn,
      minimumAmountOut,
      srcMint: srcMint.mint.toString(),
      dstMint: dstMint.mint.toString(),
      routeSrc: route.mintA.toString(),
      routeDst: route.mintB.toString(),
      aToBo: aToB,
    });
    const mintAInfo = await this.connection.getParsedAccountInfo(srcMint.mint);
    console.log(
      "🚀 ~ TokenSwap ~ mintAInfo:",
      mintAInfo.value?.owner.toBase58()
    );
    const mintBInfo = await this.connection.getParsedAccountInfo(dstMint.mint);
    if (!mintAInfo.value?.owner || !mintBInfo.value?.owner) {
      console.log("no mintBInfo");
      return;
    }
    const transaction = new web3.Transaction();

    const [authority] = web3.PublicKey.findProgramAddressSync(
      [pool.toBuffer()],
      SWAP_PROGRAM_ID
    );
    console.log("🚀 ~ TokenSwap ~ authority:", authority);

    const userSource = getAssociatedTokenAddressSync(
      srcMint.mint,
      payer,
      false,
      mintAInfo.value?.owner
    );
    const userDestination = getAssociatedTokenAddressSync(
      dstMint.mint,
      payer,
      false,
      mintBInfo.value?.owner
    );
    const userDestinationInfo = await this.connection.getParsedAccountInfo(
      userDestination
    );

    const poolSource = aToB ? route.tokenAccountA : route.tokenAccountB;
    const poolDestination = aToB ? route.tokenAccountB : route.tokenAccountA;
    console.log("🚀 ~ TokenSwap ~ poolDestination:", poolDestination);

    if (srcMint.mint.equals(WSOL)) {
      //Do sync native checks
      const ixs = await this.getWrapSOLInstructions(
        payer,
        Math.ceil(srcMint.amount)
      );
      if (ixs.length > 0) transaction.add(...ixs);
    }

    if (!userDestinationInfo.value) {
      transaction.add(
        createAssociatedTokenAccountInstruction(
          payer,
          userDestination,
          payer,
          dstMint.mint,
          mintBInfo.value?.owner
        )
      );
    }
    console.log("🚀 ~ TokenSwap ~ userDestinationInfo:", userDestinationInfo);
    console.log("pool", pool.toBase58());
    console.log("tokenPool", route.tokenPool.toBase58());

    transaction.add(
      TokenSwap.swapInstruction(
        pool,
        authority,
        payer,
        userSource,
        poolSource,
        poolDestination,
        userDestination,
        route.tokenPool,
        route.feeAccount,
        null, //hostFeeAccount,
        srcMint.mint,
        dstMint.mint,
        SWAP_PROGRAM_ID,
        mintAInfo.value?.owner,
        mintBInfo.value?.owner,
        TOKEN_2022_PROGRAM_ID,
        BigInt(amountIn),
        BigInt(minimumAmountOut)
      )
    );

    if (dstMint.mint.equals(WSOL)) {
      //Do sync native checks
      transaction.add(this.getUnwrapSOLInstruction(payer));
    }

    if (this.hasTransferFeeConfig(mintAInfo))
      transaction.add(
        createHarvestWithheldTokensToMintInstruction(srcMint.mint, [
          userSource,
          poolSource,
        ])
      );

    if (this.hasTransferFeeConfig(mintBInfo))
      transaction.add(
        createHarvestWithheldTokensToMintInstruction(dstMint.mint, [
          userDestination,
          poolDestination,
        ])
      );

    return transaction;
  }

  async getWrapSOLInstructions(
    owner: web3.PublicKey,
    amount: number
  ): Promise<web3.TransactionInstruction[]> {
    const ixs: web3.TransactionInstruction[] = [];
    const ata = getAssociatedTokenAddressSync(WSOL, owner, false);
    const ataInfo = await this.connection
      .getTokenAccountBalance(ata)
      .catch(() => {
        console.log("errr");
      });

    if (ataInfo) {
      if (Number(ataInfo?.value.amount) >= amount) return ixs;
    }

    if (!ataInfo) {
      ixs.push(
        createAssociatedTokenAccountInstruction(owner, ata, owner, WSOL)
      );
    }
    if (amount > 0)
      ixs.push(
        ...[
          web3.SystemProgram.transfer({
            fromPubkey: owner,
            toPubkey: ata,
            lamports: amount - Number((ataInfo as any)?.value.amount || 0),
          }),
          createSyncNativeInstruction(ata),
        ]
      );

    return ixs;
  }

  getUnwrapSOLInstruction(owner: web3.PublicKey): web3.TransactionInstruction {
    const ata = getAssociatedTokenAddressSync(WSOL, owner, false);
    return createCloseAccountInstruction(ata, owner, owner);
  }

  hasTransferFeeConfig(mintInfo: any): boolean {
    if (!TOKEN_2022_PROGRAM_ID.equals(mintInfo.value?.owner)) return false;

    return (
      mintInfo.value?.data.parsed?.info.extensions?.filter(
        (ex: any) => ex.extension === "transferFeeConfig"
      ).length > 0
    );
  }

  //

  static depositAllTokenTypesInstruction(
    tokenSwap: web3.PublicKey,
    authority: web3.PublicKey,
    userTransferAuthority: web3.PublicKey,
    sourceA: web3.PublicKey,
    sourceB: web3.PublicKey,
    intoA: web3.PublicKey,
    intoB: web3.PublicKey,
    poolToken: web3.PublicKey,
    poolAccount: web3.PublicKey,
    mintA: web3.PublicKey,
    mintB: web3.PublicKey,
    swapProgramId: web3.PublicKey,
    tokenProgramIdA: web3.PublicKey,
    tokenProgramIdB: web3.PublicKey,
    poolTokenProgramId: web3.PublicKey,
    poolTokenAmount: BN,
    maximumTokenA: BN,
    maximumTokenB: BN
  ): web3.TransactionInstruction {
    const dataLayout = struct<DepositAllInstruction>([
      u8("instruction"),
      u64("poolTokenAmount"),
      u64("maximumTokenA"),
      u64("maximumTokenB"),
    ]);

    const data = Buffer.alloc(dataLayout.span);
    dataLayout.encode(
      {
        instruction: 2, // Deposit instruction
        poolTokenAmount: poolTokenAmount,
        maximumTokenA: maximumTokenA,
        maximumTokenB: maximumTokenB,
      },
      data
    );

    const keys = [
      { pubkey: tokenSwap, isSigner: false, isWritable: false },
      { pubkey: authority, isSigner: false, isWritable: false },
      { pubkey: userTransferAuthority, isSigner: true, isWritable: false },
      { pubkey: sourceA, isSigner: false, isWritable: true },
      { pubkey: sourceB, isSigner: false, isWritable: true },
      { pubkey: intoA, isSigner: false, isWritable: true },
      { pubkey: intoB, isSigner: false, isWritable: true },
      { pubkey: poolToken, isSigner: false, isWritable: true },
      { pubkey: poolAccount, isSigner: false, isWritable: true },
      { pubkey: mintA, isSigner: false, isWritable: false },
      { pubkey: mintB, isSigner: false, isWritable: false },
      { pubkey: tokenProgramIdA, isSigner: false, isWritable: false },
      { pubkey: tokenProgramIdB, isSigner: false, isWritable: false },
      { pubkey: poolTokenProgramId, isSigner: false, isWritable: false },
    ];
    return new web3.TransactionInstruction({
      keys,
      programId: swapProgramId,
      data,
    });
  }

  async createAddLiquidityTransaction(
    payer: web3.PublicKey,
    pool: web3.PublicKey,
    route: TokenSwapPool | any,
    srcMint: TokenInput,
    dstMint: TokenInput,
    poolTokenAmount: any,
    initTokenPrice = false,
    priorityFee: number
  ) {
    console.log("🚀 ~ TokenSwap ~ poolTokenAmount:", poolTokenAmount);
    const mintAInfo = await this.connection.getParsedAccountInfo(route.mintA);
    const mintBInfo = await this.connection.getParsedAccountInfo(route.mintB);
    if (!mintAInfo.value?.owner || !mintBInfo.value?.owner) {
      console.log("no mintBInfo");
      return;
    }
    const [authority] = web3.PublicKey.findProgramAddressSync(
      [pool.toBuffer()],
      SWAP_PROGRAM_ID
    );

    const userAccountA = getAssociatedTokenAddressSync(
      route.mintA,
      payer,
      false,
      mintAInfo.value?.owner
    );
    const userAccountB = getAssociatedTokenAddressSync(
      route.mintB,
      payer,
      false,
      mintBInfo.value?.owner
    );
    const userPoolTokenAccount = getAssociatedTokenAddressSync(
      route.tokenPool,
      payer,
      false,
      TOKEN_2022_PROGRAM_ID
    );

    const balanceInfo = await this.connection.getMultipleParsedAccounts(
      [userPoolTokenAccount],
      { commitment: "confirmed" }
    );
    const [userPoolTokenAccountInfo] = balanceInfo.value;

    const unitLimit = 100_000;
    const unitPrice = Math.floor(priorityFee / unitLimit);
    console.log("🚀 ~ TokenSwap ~ unitPrice:", unitPrice);

    const transaction = new web3.Transaction().add(
      web3.ComputeBudgetProgram.setComputeUnitPrice({
        microLamports: 1,
      })
    );

    if (route.mintA.equals(WSOL)) {
      //Do sync native checks
      const ixs = await this.getWrapSOLInstructions(payer, srcMint.amount);
      if (ixs.length > 0) transaction.add(...ixs);
    }

    if (route.mintB.equals(WSOL)) {
      //Do sync native checks
      const ixs = await this.getWrapSOLInstructions(payer, dstMint.amount);
      if (ixs.length > 0) transaction.add(...ixs);
    }

    if (!userPoolTokenAccountInfo)
      transaction.add(
        createAssociatedTokenAccountInstruction(
          payer,
          userPoolTokenAccount,
          payer,
          route.tokenPool,
          TOKEN_2022_PROGRAM_ID
        )
      );

    console.debug("depositAllTokenTypesInstruction", {
      pool,
      mintA: route.mintA,
      mintB: route.mintB,
      poolTokenAmount: poolTokenAmount,
      tokenAAmount: srcMint.amount,
      tokenBAmount: dstMint.amount,
      srcMint,
      dstMint,
    });

    if (initTokenPrice) {
      const ratio = dstMint.amount / srcMint.amount;

      //ts-ignore
      poolTokenAmount = 1_000_000_000;

      console.log("initTokenPrice", {
        poolTokenAmount: poolTokenAmount,
        tokenA: route.mintA.toString(),
        tokenB: route.mintB.toString(),
        ratio, //b to a
        amountA: srcMint.amount,
        amountB: dstMint.amount,
        eAmountA: srcMint.amount / 2,
        eAmountB: dstMint.amount / 2,
        exactAmountInA: srcMint.amountInExact(srcMint.amount / 2),
        exactAmountInB: dstMint.amountInExact(dstMint.amount / 2),
      });

      transaction.add(
        createTransferCheckedInstruction(
          userAccountA,
          route.mintA,
          route.tokenAccountA,
          payer,
          srcMint.amountInExact(srcMint.amount / 2),
          (mintAInfo.value?.data as any)?.parsed?.info.decimals,
          [],
          mintAInfo.value?.owner
        )
      );

      transaction.add(
        createTransferCheckedInstruction(
          userAccountB,
          route.mintB,
          route.tokenAccountB,
          payer,
          dstMint.amountInExact(dstMint.amount / 2),
          (mintBInfo.value?.data as any)?.parsed?.info.decimals,
          [],
          mintBInfo.value?.owner
        )
      );

      // srcMint.amount -= srcMint.exactAmountIn(srcMint.amount/2)
      // dstMint.amount -= dstMint.exactAmountIn(dstMint.amount/2)
      srcMint.amount = srcMint.amount / 2;
      dstMint.amount = dstMint.amount / 2;
    }

    transaction.add(
      TokenSwap.depositAllTokenTypesInstruction(
        pool,
        authority,
        payer,
        userAccountA,
        userAccountB,
        route.tokenAccountA,
        route.tokenAccountB,
        route.tokenPool,
        userPoolTokenAccount,
        route.mintA,
        route.mintB,
        SWAP_PROGRAM_ID,
        mintAInfo.value?.owner,
        mintBInfo.value?.owner,
        TOKEN_2022_PROGRAM_ID,
        new BN(poolTokenAmount, 10),
        new BN(srcMint.amount, 10),
        new BN(dstMint.amount, 10)
      )
    );

    if (route.mintB.equals(WSOL)) {
      //Do sync native checks
      transaction.add(await this.getUnwrapSOLInstruction(payer));
    }

    return transaction;
  }
}

export class TokenInput {
  mint: web3.PublicKey;
  amount: number;
  programID: web3.PublicKey = TOKEN_2022_PROGRAM_ID;

  transferFeeConfig: any;

  constructor(
    mint: web3.PublicKey,
    amount = 0,
    programID: web3.PublicKey = TOKEN_2022_PROGRAM_ID,
    transferFeeConfig: any = null
  ) {
    this.mint = mint;
    this.amount = amount;
    this.programID = programID;
    this.transferFeeConfig = transferFeeConfig;
  }

  async getMintInfo(connection: web3.Connection) {
    return connection.getTokenSupply(this.mint);
  }

  getTransferFeePct() {
    if (!this.transferFeeConfig) return 0;

    return this.transferFeeConfig;
  }

  getSwapFee(amount = this.amount) {
    if (!this.transferFeeConfig) return 0;

    const feeRate =
      this.transferFeeConfig?.transferFeeBasisPoints / 10_000 || 0;
    const inputAmount = Number(amount);
    return Math.min(this.transferFeeConfig?.maximumFee, inputAmount * feeRate);
  }

  //Returns the amount post transfer fees
  amountInReal(amount = this.amount) {
    if (!this.transferFeeConfig) return amount;

    const feeMultiplier =
      1 - this.transferFeeConfig?.transferFeeBasisPoints / 10_000;
    return amount * feeMultiplier;
  }

  //Returns the exactAmount in to get the amount defined including the xfer fee
  amountInExact(amount = this.amount) {
    if (!this.transferFeeConfig) return amount;

    const feeMultiplier =
      1 - this.transferFeeConfig?.transferFeeBasisPoints / 10_000;
    return amount / feeMultiplier;
  }
}

export interface SwapInstruction {
  instruction: number;
  amountIn: bigint;
  minimumAmountOut: bigint;
}

export type TokenSwapPoolLayout = typeof TokenSwapLayout;
export type TokenSwapPool = TokenSwapPoolLayout;
