<template>
  <apexchart
    type="area"
    height="100%"
    :options="options"
    :series="data"
  ></apexchart>
</template>

<script setup lang="ts">
import formatter from "@/common/function/formatter";
import isDark from "@/common/function/isDark";
import { ref, watch, defineProps } from "vue";
import { useStore } from "vuex";
const store = useStore();
const props = defineProps<{
  data: any[];
  categories: string[];
}>();
const options = ref<any>({});
watch(
  [() => store.state.themeMode, () => props.data],
  () => {
    options.value = {
      chart: {
        type: "area",
        height: "100%",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },
      },

      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "string",
        categories: props.categories,
        labels: {
          // formatter: function (e) {
          //   return e + "k";
          // },
          style: {
            colors: isDark() ? "#fff" : "#000",
          },
        },
      },
      yaxis: {
        labels: {
          formatter: function (e) {
            return formatter(e, false, false, true);
          },
          style: {
            colors: isDark() ? "#fff" : "#000",
          },
        },
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    };
  },
  { immediate: true }
);

const styleOptions = {
  width: "100%",
  height: "100%",
};
</script>

<style scoped></style>
