import { USER_KEY } from "../const";

export default function isLogged(): any | false {
  try {
    const user = localStorage.getItem(USER_KEY);
    if (user) {
      const userParsed: any = JSON.parse(user);
      return userParsed;
    }
    return false;
  } catch (err) {
    console.log("isLogged", err);
    return false;
  }
}
