<template>
  <div class="inline-block">
    <div
      class="formatter_value relative"
      @mouseenter="handleShowTooltip"
      @mouseleave="handleHiddenTooltip"
      @click="
        () => {
          if (showTooltip) {
            handleHiddenTooltip();
          } else {
            handleShowTooltip();
          }
        }
      "
      v-if="value !== '' && !isNaN(valueDisplay)"
    >
      <div
        class="flex items-baseline !w-fit justify-end"
        v-if="valueDisplay > 0 && valueDisplay < 0.000001"
      >
        <span
          class="flex items-baseline"
          v-if="getStandardNumber(valueDisplay)?.length > lengthAllowed"
        >
          <div class="flex items-baseline flex-nowrap">
            <span>{{ (start ? start : "") + "0.0" }}</span>
            <sub>{{
              lengthFromDotToFirstNonZero(getStandardNumber(valueDisplay))
            }}</sub>
            <span
              >{{
                getStandardNumber(valueDisplay).slice(
                  2 +
                    lengthFromDotToFirstNonZero(
                      getStandardNumber(valueDisplay)
                    ),
                  lengthAllowed -
                    2 +
                    lengthFromDotToFirstNonZero(getStandardNumber(valueDisplay))
                )
              }}
            </span>
          </div>
          <span v-if="unit" class="ml-1">{{ unit }} </span>
        </span>
        <span v-if="getStandardNumber(valueDisplay)?.length <= lengthAllowed">{{
          (start ? start : "") +
          getStandardNumber(valueDisplay) +
          (unit ? ` ${unit}` : "")
        }}</span>
      </div>
      <div v-if="valueDisplay <= 0 || valueDisplay >= 0.000001">
        {{
          valueDisplay || valueDisplay == 0
            ? (start ? start : "") +
              formatter(valueDisplay, false, false, false, lengthAllowed) +
              (unit ? ` ${unit}` : "")
            : "--" + (unit ? ` ${unit}` : "")
        }}
      </div>
    </div>
    <div class="flex items-baseline" v-if="value === '' || isNaN(valueDisplay)">
      <div class="loader"></div>
      <div class="loader"></div>
      <div class="loader"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref, onMounted, withDefaults } from "vue";
interface FormatterNumberProps {
  value?: string | number;
  gap?: number;
  unit?: string;
  useChangeAnimation?: boolean;
  disableTooltip?: boolean;
  start?: string;
  keyLoading?: string;
  useCompactNumber?: boolean;
  tooltipPlace?: "top-left" | "top-right";
  lengthAllowed?: number;
}
const props = withDefaults(defineProps<FormatterNumberProps>(), {
  gap: 4,
  useCompactNumber: true,
  tooltipPlace: "top-right",
});
import getIcon from "@/common/function/getIcon";
import compactDecimalPartOfNumber from "@/common/function/compactDecimalPartOfNumber";
import formatter from "@/common/function/formatter";
import { watch } from "vue";
import getStandardNumber from "@/common/function/getStandardNumber";
import deleteMeaninglessZeros from "@/common/function/deleteMeaninglessZeros";
import lengthFromDotToFirstNonZero from "@/common/function/lengthFromDotToFirstNonZero";
import { useStore } from "vuex";
import translation from "@/common/function/translation";
const valueDisplay = ref<number>(0);
const showTooltip = ref<boolean>(false);
const handleShowTooltip = () => {
  const timeOut = setTimeout(() => {
    showTooltip.value = true;
    clearTimeout(timeOut);
  }, 200);
};
const handleHiddenTooltip = () => {
  const timeOut = setTimeout(() => {
    showTooltip.value = false;
    clearTimeout(timeOut);
  }, 200);
};
const store = useStore();
onMounted(() => {
  valueDisplay.value = Number(props.value);
});
watch(
  () => props.value,
  () => {
    if (props.useChangeAnimation && valueDisplay.value != Number(props.value)) {
      const oldValue = valueDisplay.value;
      const newValue = Number(props.value);
      const timePerStep = 1; //ms
      const totalTime = 1500; //ms
      const numOfStep = totalTime / timePerStep; //500
      const stepValue = (newValue - oldValue) / numOfStep;
      for (let i = 1; i <= numOfStep; i++) {
        const timeOut = setTimeout(() => {
          valueDisplay.value = valueDisplay.value + stepValue;
          clearTimeout(timeOut);
        }, timePerStep * i);
      }
      setTimeout(() => {
        valueDisplay.value = Number(props.value);
      }, totalTime);
    } else {
      valueDisplay.value = Number(props.value);
    }
  }
);
</script>

<style scoped>
.formatter_value {
  display: inline-flex;
  align-items: baseline;
  gap: v-bind(gap + "px");
}
.show_tooltip {
  opacity: 1 !important;
  transform: translateY(-100%) !important;
  scale: 1;
}
.skeleton {
  color: transparent;
  padding: 0 8px;
  display: inline-block;
  background: linear-gradient(-45deg, #d0d0d0, #c0c0c0, #d0d0d0);
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s infinite;
  border-radius: 4px;
}

@keyframes skeleton-loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
.loader {
  width: 16px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, currentColor 90%, #0000);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }
  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }
  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%;
  }
  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%;
  }
}
</style>
