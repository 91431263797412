<template>
  <div
    id="chart_line"
    class="h-[100px] relative w-[100%] mt-4"
    ref="chartContainer"
  >
    <apexchart
      v-if="activeFilter == 'custom' && dataCustom.length > 0"
      height="100%"
      type="line"
      :options="optionsCustom"
      :series="[
        {
          name: '$',
          data: dataCustom,
        },
      ]"
    />
    <div
      v-if="activeFilter == 'custom' && dataCustom.length <= 0"
      class="h-full flex items-center justify-center"
    >
      {{ translation("No_data_chart_statistic") }}
    </div>
    <div
      v-if="
        activeFilter != 'custom' ||
        (activeFilter == 'custom' && dataCustom.length > 0)
      "
      class="value max absolute top-0"
      :style="{ left: `${maxData.left > 50 ? 70 : 35}%` }"
    >
      <FormatterNumber
        :value="maxData?.value_num ?? 0"
        :start="translation('dollar_chart_unit')"
      />
    </div>
    <div
      v-if="
        activeFilter != 'custom' ||
        (activeFilter == 'custom' && dataCustom.length > 0)
      "
      class="value min absolute bottom-[-10px]"
      :style="{ left: `${minData.left > 50 ? 70 : 45}%` }"
    >
      <FormatterNumber
        :value="minData?.value_num ?? 0"
        :start="translation('dollar_chart_unit')"
      />
    </div>
  </div>
  <div v-if="activeFilter != 'day'" class="flex gap-1 style-dates mt-4">
    <span v-if="dates.length > 1">{{ translation("From") }} </span>
    <span
      >{{ formatDate(new Date(dates[0]?.toString()).getTime() / 1000, true) }}
      {{
        dates.length > 1
          ? " - " +
            formatDate(
              new Date(dates[dates.length - 1]?.toString()).getTime() / 1000,
              true
            )
          : ""
      }}</span
    >
  </div>
  <div class="flex flex-wrap items-center gap-2 mt-4 w-full">
    <!-- <RoundButton
      @click="() => changeTab(e)"
      :active="activeFilter === e.value && !activeFilterCustom"
      v-for="(e, i) in filters"
      :key="i"
      >{{ translation(e.label) }}</RoundButton
    >

    <RoundButton
      @click="
        () => {
          showFilter = !showFilter;
        }
      "
      :active="activeFilterCustom"
      >{{ translation("+") }}</RoundButton
    > -->
    <FilterDatePicker
      :default-date="1"
      v-model:show="showFilter"
      :on-apply="handleFilterStatusChanged"
      prefix-key="statistic"
      :is-hidden-option="false"
      :is-hidden-button="false"
      :hidden-date-description="true"
      :use-add-icon-ignore="true"
    />

    <!-- {{ data }}
    {{ index }} -->
  </div>
</template>

<script setup lang="ts">
import translation from "@/common/function/translation";
import RoundButton from "./RoundButton.vue";
import { onMounted, onUpdated, ref, defineProps } from "vue";
import { watch } from "vue";
import FormatterNumber from "./FormatterNumber.vue";
import store from "@/store";
import { formatDate, getPriceChart } from "@/service/statictis";
import FilterDatePicker, { Dates } from "./FilterDatePicker.vue";
import dayjs from "dayjs";
import MobileDetect from "mobile-detect";
const isMobile = new MobileDetect(window.navigator.userAgent).mobile();
const showFilter = ref<boolean>(false);

let data = ref<number[]>([]);
let dataWeek = ref<number[]>([]);
let dataMonth = ref<number[]>([]);
let dataYear = ref<number[]>([]);
let dataCustom = ref<number[]>([]);

let index = ref<any[]>([]);
let indexWeek = ref<any[]>([]);
let indexMonth = ref<any[]>([]);
let indexYear = ref<any[]>([]);
let indexCustom = ref<any[]>([]);
const dates = ref<Dates | string[]>([]);
const chartContainer = ref<HTMLElement | null>(null);

const activeFilter = ref<string>("custom");
const activeFilterCustom = ref<boolean>(false);
async function sleep(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}
const handleFilterStatusChanged = async (
  newDates: Dates,
  oldDates: Dates,
  onDone?: (data: any) => any
) => {
  try {
    const parseDates = newDates.map((date) => {
      return dayjs(date)?.toString();
    });
    console.log("🚀 ~ parseDates ~ parseDates:", parseDates);
    dates.value = parseDates;
    activeFilter.value = "";

    const startDay = new Date(parseDates[0]).toString();
    console.log("🚀 ~ setTimeout ~ startDay:", startDay);
    const endDay = new Date(parseDates[parseDates.length - 1]).toString();
    console.log("🚀 ~ setTimeout ~ endDay:", endDay);
    let dataYearTemp: any;
    if (parseDates.length == 1) {
      dataYearTemp = await getPriceChart(
        new Date(parseDates[0]).toString(),
        new Date().toString()
      );
    } else {
      dataYearTemp = await getPriceChart(
        new Date(parseDates[0]).toString(),
        new Date(parseDates[parseDates.length - 1]).toString()
      );
    }
    dataYearTemp = dataYearTemp?.data.priceChart.result;
    //
    console.log("🚀 ~ setTimeout ~ data:", dataYearTemp);
    setTimeout(async () => {
      let indexCustomTemp = [];
      let dataCustomTemp = [];
      dataYearTemp?.forEach((item, indexChart) => {
        dataCustomTemp.push(
          Number(item?.price) * store.state.currentPrice?.sol
        );
        indexCustomTemp.push(
          formatDate(new Date(item.date_created).getTime() / 1000)
        );
      });
      indexCustom.value = indexCustomTemp;
      dataCustom.value = dataCustomTemp;
      const optionsCustomTemp = optionsCustom.value;
      optionsCustomTemp.xaxis.categories = indexCustomTemp;

      optionsCustom.value = optionsCustomTemp;
      getMinMaxValue();

      onDone && onDone(null);
      activeFilter.value = "custom";
      store.commit("setShowCustomeDateChartStatistic", true);
      activeFilterCustom.value = true;
      let call = 0;
      let maxCall = 5;
      while (call == 0 && maxCall > 0) {
        if (store.state.poolInfo?.solx_price) {
          store.commit("setDataCustomeDateChartStatistic", {
            numberDate: parseDates.length,
            percent: dataYearTemp[0]?.price
              ? (Number(store.state.poolInfo?.solx_price?.price_to_sol) /
                  Number(dataYearTemp[0]?.price) -
                  1) *
                100
              : "no_data",
          });
          call++;
        }
        await sleep(1000);
        --maxCall;
        console.log("🚀 ~ setTimeout ~ maxCall:", maxCall);
      }
    }, 500);
  } catch (err) {
    console.log("🚀 ~ handleFilterStatusChanged ~ err:", err);
  }
};
const maxData = ref<{
  value_num: number;
  left: number;
  index: number;
}>({ value_num: 0, left: 0, index: 0 });
const minData = ref<{
  value_num: number;
  left: number;
  index: number;
}>({ value_num: 0, left: 0, index: 0 });
// watch(dates, (newDates, oldDates) => {
//   //console.log("🚀 ~ watch ~ newDates:", newDates);
//   getMinMaxValue();
// });
watch(activeFilter, () => {
  getMinMaxValue();
});
const setFilterChart = (e) => {
  store.commit("setFilteChart", e.value);
};

const getMinMaxValue = () => {
  let currentData;

  switch (activeFilter.value) {
    case "day": {
      currentData = data.value;
      break;
    }
    case "week": {
      currentData = dataWeek.value;
      break;
    }
    case "month": {
      currentData = dataMonth.value;
      break;
    }
    case "year": {
      currentData = dataYear.value;
      break;
    }
    case "custom": {
      currentData = dataCustom.value;
      break;
    }
    default: {
      currentData = [];
    }
  }
  const length = currentData.length;
  if (length > 0) {
    const maxValue = Math.max(...currentData);
    const minValue = Math.min(...currentData);
    maxData.value = {
      value_num: maxValue,
      left: (currentData.indexOf(maxValue) / length) * 100,
      index: currentData.indexOf(maxValue),
    };
    minData.value = {
      value_num: minValue,
      left: (currentData.indexOf(minValue) / length) * 100,
      index: currentData.indexOf(minValue),
    };
  }
};
onMounted(() => {
  //theme
  const newTheme = window.localStorage.getItem("theme-mode") || "light";
  //console.log("🚀 ~ onMounted ~ newTheme:", newTheme);

  if (newTheme == "dark") {
    options.value = {
      ...options.value,
      tooltip: {
        theme: "dark", // Options: 'dark', 'light'
        style: {
          backgroundColor: "#f4f4f4", // Custom background color
        },
      },
    };
    optionsWeek.value = {
      ...optionsWeek.value,
      tooltip: {
        theme: "dark", // Options: 'dark', 'light'
        style: {
          backgroundColor: "#f4f4f4", // Custom background color
        },
      },
    };
    optionsMonth.value = {
      ...optionsMonth.value,
      tooltip: {
        theme: "dark", // Options: 'dark', 'light'
        style: {
          backgroundColor: "#f4f4f4", // Custom background color
        },
      },
    };
    optionsYear.value = {
      ...optionsYear.value,
      tooltip: {
        theme: "dark", // Options: 'dark', 'light'
        style: {
          backgroundColor: "#f4f4f4", // Custom background color
        },
      },
    };
    optionsCustom.value = {
      ...optionsCustom.value,
      tooltip: {
        theme: "dark", // Options: 'dark', 'light'
        style: {
          backgroundColor: "#f4f4f4", // Custom background color
        },
      },
    };
  } else {
    options.value = {
      ...options.value,
      tooltip: {
        theme: "light", // Options: 'dark', 'light'
        style: {
          backgroundColor: "#f4f4f4", // Custom background color
        },
      },
    };
    optionsWeek.value = {
      ...optionsWeek.value,
      tooltip: {
        theme: "light", // Options: 'dark', 'light'
        style: {
          backgroundColor: "#f4f4f4", // Custom background color
        },
      },
    };
    optionsMonth.value = {
      ...optionsMonth.value,
      tooltip: {
        theme: "light", // Options: 'dark', 'light'
        style: {
          backgroundColor: "#f4f4f4", // Custom background color
        },
      },
    };
    optionsYear.value = {
      ...optionsYear.value,
      tooltip: {
        theme: "light", // Options: 'dark', 'light'
        style: {
          backgroundColor: "#f4f4f4", // Custom background color
        },
      },
    };
    optionsCustom.value = {
      ...optionsCustom.value,
      tooltip: {
        theme: "light", // Options: 'dark', 'light'
        style: {
          backgroundColor: "#f4f4f4", // Custom background color
        },
      },
    };
  }
  //
});
watch(
  () => store.state.themeMode,
  (newTheme, oldTheme) => {
    if (newTheme == "dark") {
      options.value = {
        ...options.value,
        tooltip: {
          theme: "dark", // Options: 'dark', 'light'
          style: {
            backgroundColor: "#f4f4f4", // Custom background color
          },
        },
      };
      optionsWeek.value = {
        ...optionsWeek.value,
        tooltip: {
          theme: "dark", // Options: 'dark', 'light'
          style: {
            backgroundColor: "#f4f4f4", // Custom background color
          },
        },
      };
      optionsMonth.value = {
        ...optionsMonth.value,
        tooltip: {
          theme: "dark", // Options: 'dark', 'light'
          style: {
            backgroundColor: "#f4f4f4", // Custom background color
          },
        },
      };
      optionsYear.value = {
        ...optionsYear.value,
        tooltip: {
          theme: "dark", // Options: 'dark', 'light'
          style: {
            backgroundColor: "#f4f4f4", // Custom background color
          },
        },
      };
      optionsCustom.value = {
        ...optionsCustom.value,
        tooltip: {
          theme: "dark", // Options: 'dark', 'light'
          style: {
            backgroundColor: "#f4f4f4", // Custom background color
          },
        },
      };
      optionsYear.value = {
        ...optionsYear.value,
        tooltip: {
          theme: "dark", // Options: 'dark', 'light'
          style: {
            backgroundColor: "#f4f4f4", // Custom background color
          },
        },
      };
    } else {
      options.value = {
        ...options.value,
        tooltip: {
          theme: "light", // Options: 'dark', 'light'
          style: {
            backgroundColor: "#f4f4f4", // Custom background color
          },
        },
      };
      optionsWeek.value = {
        ...optionsWeek.value,
        tooltip: {
          theme: "light", // Options: 'dark', 'light'
          style: {
            backgroundColor: "#f4f4f4", // Custom background color
          },
        },
      };
      optionsMonth.value = {
        ...optionsMonth.value,
        tooltip: {
          theme: "light", // Options: 'dark', 'light'
          style: {
            backgroundColor: "#f4f4f4", // Custom background color
          },
        },
      };
      optionsYear.value = {
        ...optionsYear.value,
        tooltip: {
          theme: "light", // Options: 'dark', 'light'
          style: {
            backgroundColor: "#f4f4f4", // Custom background color
          },
        },
      };
      optionsCustom.value = {
        ...optionsCustom.value,
        tooltip: {
          theme: "light", // Options: 'dark', 'light'
          style: {
            backgroundColor: "#f4f4f4", // Custom background color
          },
        },
      };
      optionsYear.value = {
        ...optionsYear.value,
        tooltip: {
          theme: "light", // Options: 'dark', 'light'
          style: {
            backgroundColor: "#f4f4f4", // Custom background color
          },
        },
      };
    }
  }
);
const options = ref<any>({
  chart: {
    type: "line",
    toolbar: {
      show: false,
    },
    redrawOnParentResize: true,
    redrawOnWindowResize: true,
    zoom: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  stroke: {
    width: 1.5,
    curve: "smooth",
  },
  grid: {
    show: false,
  },
  xaxis: {
    labels: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    // type: "datetime",
    categories: index.value,

    tickAmount: 1,
    axisBorder: {
      show: false,
    },
  },

  yaxis: {
    tickAmount: 4,
    lables: false,
    show: false,
  },
  tooltip: {
    theme: "light", // Options: 'dark', 'light'
    style: {
      backgroundColor: "#f4f4f4", // Custom background color
    },
  },
  colors: ["#1ECC97"],
});
const optionsWeek = ref<any>({
  chart: {
    type: "line",
    toolbar: {
      show: false,
    },
    redrawOnParentResize: true,
    redrawOnWindowResize: true,
    zoom: {
      enabled: false,
    },
  },
  stroke: {
    width: 1.5,
    curve: "smooth",
  },
  grid: {
    show: false,
  },
  xaxis: {
    labels: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    // type: "numeric",
    categories: indexWeek.value,

    tickAmount: 1,
    axisBorder: {
      show: false,
    },
  },

  yaxis: {
    tickAmount: 4,
    show: false,
  },

  tooltip: {
    theme: "light", // Options: 'dark', 'light'
    style: {
      backgroundColor: "#f4f4f4", // Custom background color
    },
  },

  colors: ["#1ECC97"],
});

const optionsMonth = ref<any>({
  chart: {
    type: "line",
    toolbar: {
      show: false,
    },
    redrawOnParentResize: true,
    redrawOnWindowResize: true,
    zoom: {
      enabled: false,
    },
  },
  stroke: {
    width: 1.5,
    curve: "smooth",
  },
  grid: {
    show: false,
  },
  xaxis: {
    labels: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    // type: "numeric",
    categories: indexMonth.value,

    tickAmount: 1,
    axisBorder: {
      show: false,
    },
  },

  yaxis: {
    tickAmount: 4,
    show: false,
  },

  tooltip: {
    theme: "light", // Options: 'dark', 'light'
    style: {
      backgroundColor: "#f4f4f4", // Custom background color
    },
  },

  colors: ["#1ECC97"],
});

const optionsYear = ref<any>({
  chart: {
    type: "line",
    toolbar: {
      show: false,
    },
    redrawOnParentResize: true,
    redrawOnWindowResize: true,
    zoom: {
      enabled: false,
    },
  },
  stroke: {
    width: 1.5,
    curve: "smooth",
  },
  grid: {
    show: false,
  },
  xaxis: {
    labels: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    // type: "numeric",
    categories: indexYear.value,

    tickAmount: 1,
    axisBorder: {
      show: false,
    },
  },

  yaxis: {
    tickAmount: 4,
    show: false,
  },

  tooltip: {
    theme: "light", // Options: 'dark', 'light'
    style: {
      backgroundColor: "#f4f4f4", // Custom background color
    },
  },

  colors: ["#1ECC97"],
});
const optionsCustom = ref<any>({
  chart: {
    type: "line",
    toolbar: {
      show: false,
    },
    redrawOnParentResize: true,
    redrawOnWindowResize: true,
    zoom: {
      enabled: false,
    },
  },
  stroke: {
    width: 1.5,
    curve: "smooth",
  },
  grid: {
    show: false,
  },
  xaxis: {
    labels: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    // type: "numeric",
    categories: indexCustom.value,

    tickAmount: 1,
    axisBorder: {
      show: false,
    },
  },

  yaxis: {
    tickAmount: 4,
    show: false,
  },

  tooltip: {
    theme: "light", // Options: 'dark', 'light'
    style: {
      backgroundColor: "#f4f4f4", // Custom background color
    },
  },

  colors: ["#1ECC97"],
});
const filters = [
  {
    label: "_day",
    value: "day",
  },
  {
    label: "_week",
    value: "week",
  },
  {
    label: "_month",
    value: "month",
  },
  // {
  //   label: "_year",
  //   value: "year",
  // },
  // {
  //   label: "+",
  //   value: "custom",
  // },
];
</script>
<style scoped>
.vue-apexchart {
  width: 100% !important;
}
.style-dates {
  color: var(--Neutral-05, #939393);
  font-family: "GT Walsheim";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
</style>
