<template>
  <div
    :style="{
      width: 'calc(100vw - 32px)',
      borderRadius: '24px',
      border: '2px black solid',
      maxWidth: '650px !important',
      backgroundColor: isDark() ? '#2B2B2B' : '#fff',
    }"
    class="p-6"
  >
    <div
      class="self-stretch rounded-2xl flex-col justify-start items-start gap-2 flex"
    >
      <div class="flex justify-between items-center w-full pb-2">
        <span class="text-[20px]" :class="{ '!text-[#fff]': isDark() }">{{
          translation("confirm_withdraw_principal_overdue")
        }}</span>
        <v-icon
          icon="mdi-close"
          :color="isDark() ? '#fff' : ''"
          @click="closeModal"
        ></v-icon>
      </div>
      <div
        class="h-[2px] bg-[#141416] w-full mb-3 opacity-15"
        :class="{ '!bg-[#fff]': isDark() }"
      ></div>
      <div class="self-stretch justify-between items-center inline-flex">
        <div>
          <span
            class="text-[#141416] text-[13px] lg:text-[18px] font-medium font-['GT Walsheim'] leading-[140%]"
            :class="{ '!text-[#fff]': isDark() }"
          >
            {{ translation("Completed_Principal") }}</span
          >
        </div>
        <div
          class="text-[#141416] text-[15px] lg:text-[20px] font-bold font-['GT Walsheim'] leading-[21px]"
        >
          <FormatterNumber
            :class="{ '!text-[#fff]': isDark() }"
            class="number_receive"
            :value="dataStakeSolx?.principalLatest"
          />
        </div>
      </div>
      <div class="self-stretch justify-between items-center inline-flex">
        <div>
          <span
            class="text-[#141416] text-[13px] lg:text-[18px] font-medium font-['GT Walsheim'] leading-[140%]"
            :class="{ '!text-[#fff]': isDark() }"
          >
            {{ translation("Available_Interest") }}</span
          >
        </div>
        <div
          class="text-[#141416] text-[15px] lg:text-[20px] font-bold font-['GT Walsheim'] leading-[21px]"
        >
          <FormatterNumber
            :class="{ '!text-[#fff]': isDark() }"
            class="number_receive"
            :value="dataStakeSolx.interest"
          />
        </div>
      </div>
      <div
        class="self-stretch h-fit py-3 bg-[#f2f2f2] rounded-2xl border-2 border-[#141416] flex-col justify-start items-start gap-2 flex"
      >
        <div class="self-stretch px-3 justify-between items-center inline-flex">
          <div
            class="text-[#141416] text-[13px] lg:text-[18px] font-medium font-['GT Walsheim'] leading-[140%]"
          >
            {{ translation("Price_impact") }}
          </div>
          <div class="justify-start items-center gap-0.5 flex">
            <div
              class="text-[#141416] text-[15px] lg:text-[20px] font-normal font-['SF Pro Display'] leading-[21px]"
            >
              ±
            </div>
            <div
              class="text-[#141416] text-[15px] lg:text-[20px] font-bold font-['GT Walsheim'] leading-[21px]"
            >
              <FormatterNumber
                :value="
                  ((Number(dataStakeSolx?.principalLatest) +
                    Number(dataStakeSolx.interest)) /
                    store.state.poolInfo?.solx_reserved) *
                    100 <
                  0.01
                    ? '0.01'
                    : ((Number(dataStakeSolx?.principalLatest) +
                        Number(dataStakeSolx.interest)) /
                        store.state.poolInfo?.solx_reserved) *
                      100
                "
                :unit="'%'"
                :start="
                  ((Number(dataStakeSolx?.principalLatest) +
                    Number(dataStakeSolx.interest)) /
                    store.state.poolInfo?.solx_reserved) *
                    100 <
                  0.01
                    ? '<'
                    : ''
                "
              />
            </div>
          </div>
        </div>
        <div
          class="self-stretch px-3 flex-col justify-start items-start gap-2 flex"
        >
          <div
            class="self-stretch justify-between items-center inline-flex cursor-pointer"
            @click="() => (showDetailFee = !showDetailFee)"
          >
            <div
              class="text-[#141416] text-[13px] lg:text-[18px] font-medium font-['GT Walsheim'] leading-[140%]"
            >
              {{ translation("Total_Fee") }}
            </div>
            <div class="justify-start items-center gap-1.5 flex">
              <div
                class="text-[#141416] text-[15px] lg:text-[20px] font-bold font-['GT Walsheim'] leading-[21px]"
              >
                <FormatterNumber
                  :value="dataFee?.feeNetwork + dataFee?.feeSystem ?? 0"
                />
              </div>
              <svg
                class="duration-200 relative -top-[1px]"
                :class="{
                  'rotate-[180deg]': showDetailFee,
                }"
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.28854 0.357356L10 5.06881L9.22604 5.84277L5.28854 1.90527L1.35104 5.84277L0.577083 5.06881L5.28854 0.357356Z"
                  fill="#1C1B1F"
                />
              </svg>
            </div>
          </div>
          <div
            class="flex items-stretch w-full duration-300 overflow-hidden"
            :style="{
              maxHeight: showDetailFee ? '100px' : '0px',
            }"
          >
            <div class="relative ml-1">
              <div class="arrow"></div>
            </div>
            <div class="flex flex-col gap-2 w-full">
              <div
                class="self-stretch pl-3 justify-between items-center inline-flex"
              >
                <div
                  class="text-[#929292] text-[13px] lg:text-[18px] font-medium font-['GT Walsheim'] leading-[140%]"
                >
                  {{ translation("System_Fee") }}
                </div>
                <div
                  class="text-[#636363] text-[15px] lg:text-[20px] font-medium font-['GT Walsheim'] leading-[21px]"
                >
                  <FormatterNumber :value="dataFee?.feeSystem ?? 0" />
                </div>
              </div>
              <div
                class="self-stretch pl-3 justify-between items-center inline-flex"
              >
                <div
                  class="text-[#929292] text-[13px] lg:text-[18px] font-medium font-['GT Walsheim'] leading-[140%]"
                >
                  {{ translation("GAS_Fee") }}
                </div>
                <div
                  class="text-[#636363] text-[15px] lg:text-[20px] font-medium font-['GT Walsheim'] leading-[21px]"
                >
                  <FormatterNumber :value="dataFee?.feeNetwork ?? 0" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="self-stretch h-[0px] !border-t border-[#141416] border-dashed"
        :class="{ '!border-[#fff]': isDark() }"
      ></div>
      <div class="self-stretch justify-between items-center inline-flex">
        <div>
          <span
            class="text-[#8247e5] text-[15px] lg:text-[20px] font-medium font-['GT Walsheim'] leading-[21px]"
            >{{ translation("Total_SOLX_Claim") }}</span
          >
        </div>
        <div
          class="text-[#8247e5] text-[15px] lg:text-[20px] font-bold font-['GT Walsheim'] leading-[21px]"
        >
          <FormatterNumber
            class="number_receive"
            :value="
              Number(dataStakeSolx?.principalLatest) +
              Number(dataStakeSolx.interest)
            "
          />
        </div>
      </div>
      <div class="self-stretch justify-between items-center inline-flex">
        <div>
          <span
            class="text-[#141416] text-[13px] lg:text-[18px] font-medium font-['GT Walsheim'] leading-[140%]"
            :class="{ '!text-[#fff]': isDark() }"
            >{{ translation("Est.SOLX_Claim") }}
          </span>
        </div>
        <div
          class="text-[#141416] text-[18px] lg:text-[20px] font-bold font-['GT Walsheim'] leading-snug"
          :class="{ '!text-[#fff]': isDark() }"
        >
          <FormatterNumber
            class="number_receive"
            :value="
              Number(dataStakeSolx?.principalLatest) +
              Number(dataStakeSolx.interest)
            "
          />
        </div>
      </div>
    </div>
    <PrimaryButton
      :custom-background="isDark() ? '' : '#F0FFB3'"
      :loading="isLoadingOrigin"
      :is-transaction="true"
      :disabled="
        (!getAddress() &&
          !store.state.poolInfo?.system_enable_claim_priciple) ||
        (getAddress() &&
          (!store.state.permission ||
            (store.state.permission && store.state.permission?.success == true
              ? !store.state.permission?.data['claim-solx-principle']
              : true)))
      "
      @click="onWithdrawOrigin"
      class="w-full my-4 lg:my-6"
      >{{ translation(pagePrefix + "button") }}</PrimaryButton
    >
  </div>
</template>

<script lang="ts" setup>
import translation from "@/common/function/translation";
import PrimaryButton from "./PrimaryButton.vue";
import { ref, defineProps, watch } from "vue";
import isDark from "@/common/function/isDark";
import FormatterNumber from "./FormatterNumber.vue";
import store from "@/store";
import { useWallet } from "solana-wallets-vue";
import { isDeviceLaptop } from "@/wallet/solana/Solana";
interface WithdrawOriginBoostProps {
  dataStakeSolx?: any;
  dataFee?: any;
  isLoadingOrigin?: boolean;
  isDisable?: boolean;
  triggerTabBoost: any;
  closeModal: () => void;
  onWithdrawOrigin?: () => void;
}
const props = defineProps<WithdrawOriginBoostProps>();
const pagePrefix = "confirm_modal_";
const showDetailFee = ref(false);
const getAddress = () => {
  let address = null;
  if (isDeviceLaptop()) {
    const { publicKey } = useWallet();

    address = publicKey.value?.toString() || null;
  } else {
    address = store.state.sessionSolana?.public_key?.toString() || null;
  }
  return address;
};
watch(
  () => props.triggerTabBoost,
  (newData, oldData) => {
    props.closeModal();
  }
);
</script>

<style scoped>
.arrow {
  color: #636363;
  position: absolute;
  top: 8px;
  bottom: 8px;
  left: 50%;
  border-left: 2px currentColor dashed;
}

.arrow::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 999px;
  background: currentColor;
  position: absolute;
  transform: translate(calc(-50% - 1px), 0%);
}

.arrow::after {
  content: "";
  display: block;
  width: 12px;
  height: 8px;
  background: currentColor;
  position: absolute;
  bottom: 0;
  transform: translate(calc(-50% - 1px), 1.5px);
  clip-path: polygon(0% 0%, 50% 40%, 100% 0%, 50% 100%, 0% 0%);
}
</style>
