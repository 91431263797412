export default function lengthFromDotToFirstNonZero(numString: string): number {
  // Tìm vị trí của dấu .
  const dotIndex = numString.indexOf(".");
  if (dotIndex === -1) {
    return -1; // Trường hợp không có dấu .
  }

  // Tìm số đầu tiên khác 0 sau dấu .
  let firstNonZeroIndex = dotIndex + 1;
  while (
    firstNonZeroIndex < numString.length &&
    numString[firstNonZeroIndex] === "0"
  ) {
    firstNonZeroIndex++;
  }

  if (firstNonZeroIndex >= numString.length) {
    return -1; // Trường hợp không tìm thấy số nào khác 0 sau dấu .
  }

  // Tính độ dài chuỗi từ dấu . đến số đầu tiên khác 0
  return firstNonZeroIndex - dotIndex - 1;
}
