<template>
  <div class="flex flex-col gap-3">
    <div class="bn_md_des">{{ translation("an_modal_des") }}</div>
    <div class="flex flex-col gap-3">
      <AnalyticCard
        v-model:chartType="rewardChartType"
        :name="translation('anl_reward_earn')"
        :tooltip-content="translation('anl_reward_earn_tooltip')"
        :data="store.state.analyticChart?.commission_earned_chart"
        v-slot="{ data }"
      >
        <div class="w-full h-[250px] overflow-x-scroll">
          <div
            class="h-full"
            :style="{
              minWidth:
                data?.chartData?.xAxisLabel?.length > 13
                  ? '800px !important'
                  : '0px !important',
            }"
          >
            <StackColumnChart
              :data="
                (data?.chartData?.data ?? []).map((e, i) => ({
                  ...e,
                  name: translation('anl_reward_earn' + e?.name),
                }))
              "
              :categories="data?.chartData?.xAxisLabel ?? []"
            />
          </div>
        </div>
      </AnalyticCard>
      <!-- <AnalyticCard
        v-model:chartType="amountChartType"
        :name="translation('anl_amount')"
        :tooltip-content="translation('anl_amount_tooltip')"
        is-type-prioritize
        :data="store.state.analyticChart?.amount_chart"
        v-slot="{ data }"
      >
        <div class="w-full h-[250px] overflow-x-scroll">
          <div
            class="h-full"
            :style="{
              minWidth:
                data?.chartData?.xAxisLabel?.length > 13
                  ? '800px !important'
                  : '0px !important',
            }"
          >
            <StackColumnChart
              :data="
                (data?.chartData?.data ?? []).map((e, i) => ({
                  ...e,
                  name: translation('anl_amount' + e.name),
                }))
              "
              :categories="data?.chartData?.xAxisLabel ?? []"
            />
          </div>
        </div>
      </AnalyticCard> -->
      <!-- <AnalyticCard
        chartType=""
        :name="translation('anl_transaction')"
        :tooltip-content="translation('anl_transaction_tooltip')"
        is-type-prioritize
        :data="store.state.analyticChart?.activity_chart"
        v-slot="{ data }"
      >
        <div class="w-full h-[250px] overflow-x-scroll">
          <div
            class="h-full"
            :style="{
              minWidth:
                data?.chartData?.xAxisLabel?.length > 13
                  ? '800px !important'
                  : '0px !important',
            }"
          >
            <AreaChart
              :data="
                (data?.chartData?.data ?? []).map((e, i) => ({
                  ...e,
                  name: translation('anl_transaction' + e.name),
                }))
              "
              :categories="data?.chartData?.xAxisLabel ?? []"
            />
          </div>
        </div>
      </AnalyticCard> -->
      <AnalyticCardTransaction
        :name="translation('anl_transaction')"
        :data="store.state.analyticChart?.activity_chart"
      />
      <!-- <AnalyticCard
        :name="translation('anl_rank')"
        :tooltip-content="translation('anl_rank_tooltip')"
        is-pie-chart-type
        hidden-type-menu
        :data="store.state.analyticChart?.rank_chart"
        v-slot="{ data }"
      >
        <div class="flex justify-center gap-4 flex-col lg:flex-row py-4">
          <PieChart :data="data.chartData?.dataRankLeft" />
          <PieChart :data="data.chartData?.dataRankRight" />
        </div>
      </AnalyticCard> -->
    </div>
  </div>
</template>

<script setup lang="ts">
import StackColumnChart from "./StackColumnChart.vue";
import AreaChart from "./AreaChart.vue";
import PieChart from "./PieChart.vue";
import getIcon from "@/common/function/getIcon";
import translation from "@/common/function/translation";
import { useStore } from "vuex";
import ListItemCheck from "./ListItemCheck.vue";
import AnalyticCard from "./AnalyticCard.vue";
import AnalyticCardTransaction from "./AnalyticCardTransaction.vue";
import { ref, watch } from "vue";
import fetchAnalyticChart from "@/common/function/fetchAnalyticChart";
const store = useStore();
type Unit = "sol" | "solx" | "usd";
export interface UnitRequest {
  toCurrencyReward: Unit;
  toCurrencyAmount: Unit;
}

// const chart

const getUnit = (chartType?: string) => {
  switch (chartType) {
    case "all":
      return "usd";
    case "stake":
      return "sol";
    default:
      return "solx";
  }
};

const rewardChartType = ref<string>("all");
const amountChartType = ref<string>("stake");

watch([rewardChartType, amountChartType], () => {
  fetchAnalyticChart({
    toCurrencyReward: getUnit(rewardChartType.value),
    toCurrencyAmount: getUnit(amountChartType.value),
  });
});
</script>

<style scoped>
.bn_md_des {
  color: var(--Neutral-03, #636363);
  font-family: "GT Walsheim";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 28.8px */
}
._title {
  color: var(--Neutral-01, #141416);
  font-family: "GT Walsheim";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
  text-transform: capitalize;
}
</style>
