import { userClient } from "@/graphql/client/user-client";
import { getAllianceDataQuery } from "@/graphql/query/getAllianceDataQuery";
import store from "@/store";
import { provideApolloClient, useQuery } from "@vue/apollo-composable";
import filterByRank from "./filterByRank";
import { assignLevelAndBackgroundColor } from "./assignLevelAndBackgroundColor";
import { affDataFake } from "@/mock-data/affiliate";
import determineParent from "@/components/Affiliate/function/determineParent";
import { getUserById } from "./getUserById";
import createRankObject from "./createRankObject";
import { watch } from "vue";
import gql from "graphql-tag";

export const query = gql`
  query Query(
    $timezone: String
    $toCurrencyReward: String
    $toCurrencyAmount: String
  ) {
    statistic_charts(
      timezone: $timezone
      toCurrencyReward: $toCurrencyReward
      toCurrencyAmount: $toCurrencyAmount
    )
  }
`;
export default function fetchAnalyticChart(unitRequest?: any) {
  try {
    // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // const { result, refetch, onResult } = provideApolloClient(userClient)(() =>
    //   useQuery(query, {
    //     timezone: timezone,
    //     toCurrencyReward: "SOLX",
    //     toCurrencyAmount: "SOL",
    //     ...unitRequest,
    //   })
    // );
    // refetch();
    // onResult(() => {
    //   const res = result.value;
    //   const data = res?.statistic_charts?.result;
    //   store.commit("setAnalyticChart", data);
    //   console.error(">>>>>>>>>>>>>>>>>>>>>>analytic chart: ", data);
    // });
  } catch (err) {
    console.error(err);
  }
}
