<!-- eslint-disable vue/no-deprecated-v-on-native-modifier -->
<template>
  <v-dialog v-model="showModalConfirmWithdrawInterest" max-width="1200">
    <div class="flex justify-center">
      <ConfirmWithdrawInterest
        :data-stake-solx="dataStakeSolx"
        :is-loading-profit="loadingWithdrawProfit"
        :is-disable="
          !getAddress() ||
          Number(dataStakeSolx.principal) <= 0 ||
          Number(dataStakeSolx.interest) <= 0
        "
        :data-fee="dataFee"
        :on-withdraw-profit="() => withdrawProfits()"
        :close-modal="() => (showModalConfirmWithdrawInterest = false)"
      />
    </div>
  </v-dialog>

  <v-dialog
    v-model="showModalConfirmWithdrawInterestContribute"
    max-width="1200"
  >
    <div class="flex justify-center">
      <ConfirmWithdrawInterestContribute
        class="relative z-[9999]"
        :data-reward-solx="estInterestContributeSetting"
        :is-loading-profit="loadingClaim"
        :status-claim="statusClaim"
        :data-fee="dataFee"
        :on-withdraw-profit="() => claimReward()"
        :close-modal="
          () => (showModalConfirmWithdrawInterestContribute = false)
        "
      />
    </div>
  </v-dialog>
  <v-dialog v-model="showModalConfirmSwap" max-width="1200">
    <div class="flex justify-center">
      <ConfirmSwap
        :solx-input="amountSolx"
        :sol-output="amountSolOut"
        :is-loading-swap="loading"
        :is-disable="
          !availableSolx ||
          amountSolOut.value == 'loading' ||
          store.state.swapInfo?.swap_enable == false
        "
        :data-fee="dataFee"
        :on-swap="() => swapSOLXToSOL()"
        :close-modal="() => (showModalConfirmSwap = false)"
      />
    </div>
  </v-dialog>

  <div class="home_statistic_container">
    <ModalWithdrawBoost
      :poolStakeInfo="poolStakeInfo"
      :total_stake="Number(store.state.poolInfo?.total_solx_stake)"
      :on-withdraw-profit="() => withdrawProfits()"
      :on-withdraw-origin="(option) => withdrawOrigin(option)"
      :data-stake-solx="dataStakeSolx"
      :is-loading-origin="loadingWithdrawOrigin"
      :is-loading-profit="loadingWithdrawProfit"
      :trigger-tab-boost="triggerTabBoost"
      :data-fee="dataFee"
      v-if="store.state.openWithdrawBoost"
    />

    <div id="statistic"></div>
    <section-title :title="translation('statistics_Statistics')">
      <template #default>
        <div class="guide_button_container">
          <a class="guide_title" target="_blank" :href="poolUrlSolana">{{
            translation("guide_Go_to_Quick_Guide")
          }}</a>
          <img
            loading="lazy"
            class="guide_arrow"
            :src="getIcon(isDark() ? 'arrow_right_white' : 'arrow_right')"
            alt=""
          />
        </div>
      </template>
    </section-title>
    <div class="row1">
      <div class="row_1">
        <CardContainer
          bg-color="purple-pizzazz"
          :displacement-level="0"
          class="card card1 card_price_solx"
        >
          <div class="flex justify-between w-full">
            <div
              class="r1 !border-b-2 !border-black pb-4 w-full"
              style="display: flex; align-items: center; gap: 8px"
            >
              <img class="solx_logo" :src="getIcon('full_logo')" alt="" />
              <div class="flex flex-col gap-1.5">
                <span>
                  {{ translation("statistics_SOLX Price") }}
                </span>
                <CopyText
                  class="wallet_address_ct"
                  :value="store.state.poolInfo?.mintB"
                >
                  <span
                    >{{
                      compactWalletAddress(
                        store.state.poolInfo?.mintB?.toString()
                      )
                    }} </span
                  ><img
                    class="copy_wallet_address_icon"
                    :src="getIcon(isDark() ? 'white_copy_dark' : 'white_copy')"
                    alt=""
                    loading="lazy"
                  />
                </CopyText>
              </div>
              <PrimaryButton
                v-if="
                  store.state.user?.token &&
                  ((store.state.addTokenSetting?.valid &&
                    store.state.addTokenSetting?.data?.ui_active &&
                    associatedTokenAddress &&
                    store.state.addTokenSetting?.data
                      ?.allow_wallet_already_have_token == true &&
                    Number(store.state.addTokenSetting?.data?.send_value) >
                      0) ||
                    (store.state.addTokenSetting?.valid &&
                      store.state.addTokenSetting?.data?.ui_active &&
                      !associatedTokenAddress))
                "
                :loading="loadingAssoc"
                class="ml-auto"
                :is-transaction="true"
                @click="addToken"
              >
                <div class="flex items-center gap-1.5">
                  <div class="flex items-center gap-1">
                    <span class="relative"
                      ><img :src="getIcon(isDark() ? 'add' : 'add')" alt=""
                    /></span>
                    <span style="color: #000 !important"
                      >{{ translation("Add") }}
                    </span>
                  </div>
                  <!-- <img
                    class="copy_wallet_address_icon"
                    :src="getIcon('solx_logo')"
                    alt=""
                    loading="lazy"
                  /> -->
                </div>
              </PrimaryButton>
            </div>
          </div>
          <div class="layout-statictis w-full">
            <div class="r2">
              <div class="r2_1">
                <FormatterNumber
                  :value="store.state.poolInfo?.solx_price?.price_to_sol"
                  :unit="'SOL'"
                />
              </div>
              <div
                v-if="store.state.showCustomeDateChartStatistic"
                class="r2_2"
                style="display: flex; gap: 4px"
                :class="
                  store.state.dataCustomeDateChartStatistic?.percent >= 0
                    ? 'growth-green'
                    : 'growth-red'
                "
              >
                <img
                  v-if="store.state.dataCustomeDateChartStatistic?.percent >= 0"
                  :src="getIcon('arrow_drop_up')"
                  alt=""
                />

                <img
                  v-if="store.state.dataCustomeDateChartStatistic?.percent < 0"
                  :src="getIcon('arrow_drop_down')"
                  alt=""
                />
                <FormatterNumber
                  v-if="
                    store.state.dataCustomeDateChartStatistic?.percent !=
                      'no_data' ||
                    store.state.dataCustomeDateChartStatistic?.percent > 0
                  "
                  :value="
                    Math.abs(store.state.dataCustomeDateChartStatistic?.percent)
                  "
                  unit="%"
                />
                <span
                  v-if="
                    store.state.dataCustomeDateChartStatistic?.percent ==
                      'no_data' ||
                    store.state.dataCustomeDateChartStatistic?.percent == 0
                  "
                  >--</span
                >
                <!-- {{ toFixedNumber(rateTokenPoolSwap.growth, 8) }} -->
                <span>
                  ({{
                    translation(
                      `${store.state.dataCustomeDateChartStatistic?.numberDate} days`
                    )
                  }})
                </span>
              </div>
            </div>
            <div class="r3 mt-4">
              <FormatterNumber
                :value="store.state.poolInfo?.solx_price?.price_to_usd"
                start="~$ "
              />
            </div>

            <ChartLine />
          </div>
        </CardContainer>

        <CardContainer
          bg-color="purple-pizzazz"
          :displacement-level="0"
          class="solx_container"
        >
          <div class="title_solx">{{ translation("statistic_Supply") }}</div>
          <div class="line">
            <div class="field flex items-center">
              <!-- <span>{{ translation("statistic_burnedpp") }}</span> -->
              <KatexTooltip value="statistic_burned" />
              <!-- <span class="burn_day_left ml-1.5"
                >({{
                  convertSeconds(
                    Number(store.state.poolInfo?.burn_info?.next_burn_time) /
                      1000
                  ).days > 0
                    ? convertSeconds(
                        Number(
                          store.state.poolInfo?.burn_info?.next_burn_time
                        ) / 1000
                      ).days + " days"
                    : convertSeconds(
                        Number(
                          store.state.poolInfo?.burn_info?.next_burn_time
                        ) / 1000
                      ).hours > 0
                    ? convertSeconds(
                        Number(
                          store.state.poolInfo?.burn_info?.next_burn_time
                        ) / 1000
                      ).hours + " hours"
                    : convertSeconds(
                        Number(
                          store.state.poolInfo?.burn_info?.next_burn_time
                        ) / 1000
                      ).minutes > 0
                    ? convertSeconds(
                        Number(
                          store.state.poolInfo?.burn_info?.next_burn_time
                        ) / 1000
                      ).minutes + " minutes"
                    : convertSeconds(
                        Number(
                          store.state.poolInfo?.burn_info?.next_burn_time
                        ) / 1000
                      ).seconds + " seconds"
                }}
                left)
              </span>
              <div
                v-if="store.state.poolInfo?.burn_info?.burn_logs?.length > 0"
                class="relative burn_history_icon cursor-pointer p-1"
              >
                 
                <div
                  class="burn_board z-50 border-2 border-black rounded-[24px] px-4 bg-[#FAE1BC] absolute left-0 top-[100% - 5px]"
                >
                  <div class="arrow"></div>
                  <div class="h-[200px] overflow-y-auto">
                    <BurnItem
                      v-for="(item, index) in store.state.poolInfo?.burn_info
                        ?.burn_logs"
                      :key="index"
                      :border-bottom="
                        index !=
                        store.state.poolInfo?.burn_info?.burn_logs?.length - 1
                      "
                      :icon="getIcon(item?.icon_key || 'fire')"
                      :title="item?.title || translation('Burned SOLX')"
                      :des="new Date(item?.date_created).toLocaleString()"
                    >
                      <template #rightPart>
                        <FormatterNumber
                          class="burn_value"
                          :value="item?.burn_amount"
                          unit="SOLX"
                        />
                      </template>
                    </BurnItem>
                  </div>
                </div>
              </div> -->
            </div>
            <div class="value_g">
              <b>
                <!-- <FormatterNumber :value="0" /> -->
                <span>--</span>
              </b>
            </div>
          </div>
          <div class="line">
            <div class="field">
              <KatexTooltip value="statistic_Staking" />
            </div>
            <div class="value_g">
              <b>
                <FormatterNumber
                  v-if="Number(store.state.poolInfo?.total_solx_stake) > 0"
                  :use-compact-number="false"
                  :value="Number(store.state.poolInfo?.total_solx_stake)"
                />
                <span v-if="Number(store.state.poolInfo?.total_solx_stake) <= 0"
                  >--</span
                >
              </b>
            </div>
          </div>
          <div class="line">
            <div class="field">
              <KatexTooltip value="statistic_Investing" />
            </div>
            <div class="value_g">
              <b>
                <FormatterNumber
                  v-if="Number(store.state.poolInfo?.total_solx_invest) > 0"
                  :use-compact-number="false"
                  :value="Number(store.state.poolInfo?.total_solx_invest || 0)"
                />
                <span
                  v-if="Number(store.state.poolInfo?.total_solx_invest) <= 0"
                  >--</span
                >
              </b>
            </div>
          </div>
          <div class="w-full divider_2"></div>
          <div class="line">
            <div class="field">
              <KatexTooltip value="statistic_Circulating" />
            </div>
            <div class="value_g">
              <b v-if="store.state.poolInfo?.solx_circulating > 0">
                <FormatterNumber
                  :use-compact-number="false"
                  :value="store.state.poolInfo?.solx_circulating"
              /></b>
              <v-progress-circular
                v-if="store.state.poolInfo?.solx_circulating <= 0"
                indeterminate
                :size="20"
                :width="2"
                color="black"
              ></v-progress-circular>
            </div>
          </div>

          <div class="line">
            <div class="field"><KatexTooltip value="statistic_Total" /></div>
            <div class="value_g">
              <b>
                <FormatterNumber
                  :use-compact-number="false"
                  :value="store.state.poolInfo?.solx_total_supply"
                />
              </b>
            </div>
          </div>
        </CardContainer>
        <CardContainer
          bg-color="purple-pizzazz"
          :displacement-level="0"
          class="solx_container"
        >
          <div class="title_solx">
            {{ translation("Pool") }}
          </div>
          <div class="line">
            <div class="field flex items-center">
              <KatexTooltip
                value="statistic_Having"
                :sub-content="`(${store.state.havingData?.halvingCount})`"
              />
            </div>
            <div class="value_g !gap-0 !items-end">
              <b
                ><div class="text-[#ff5153]">
                  <FormatterNumber
                    :value="store.state.havingData?.currentSolx"
                  /></div
              ></b>
              <b>/</b>
              <b class="relative"
                ><FormatterNumber
                  :value="
                    Number(store.state.havingData?.next_target) || 10000000
                  "
              /></b>
              <span class="relative ml-0.5 leading-[100%]">{{
                translation("Minted")
              }}</span>
            </div>
          </div>
          <div class="border-2 border-black rounded-full overflow-hidden">
            <v-progress-linear
              v-model="store.state.havingValue"
              color="amber"
              height="10"
            ></v-progress-linear>
          </div>
          <!-- <CountdownComponent :value="12345" /> -->
          <div class="line">
            <div class="field">
              <!-- {{ translation("statistics_Total_mining") }} -->
              <KatexTooltip value="statistics_Total_mining" />
            </div>
            <div class="value_g">
              <b>
                <FormatterNumber
                  :value="Number(store.state.havingData?.contribute || 0)"
                  :unit="translation('_sol_u')"
                />
              </b>
            </div>
          </div>
          <div
            class="tvl_container py-3 px-4 !rounded-[12px] !border !border-black bg-[#F0FFB3]"
          >
            <div class="pb-2 flex gap-1.5 justify-between">
              <div class="field flex gap-1 items-center">
                <span class="leading-[100%]">
                  <KatexTooltip value="statistics_TVL" use-slot>
                    <span class="flex items-center gap-1"
                      ><span class="leading-[100%] relative top-[2px]">{{
                        translation("statistics_TVL_content")
                      }}</span
                      ><img
                        loading="lazy"
                        :src="getIcon(isDark() ? 'tooltip_white' : 'tooltip')"
                        alt=""
                        :style="{
                          top: `${useResponsive(-3, -1).value}px`,
                        }"
                        class="icon_w24_h24"
                    /></span>
                  </KatexTooltip>
                </span>
                <!-- <img
                  loading="lazy"
                  :src="getIcon(isDark() ? 'tooltip_white' : 'tooltip')"
                  alt=""
                  :style="{
                    top: `${useResponsive(-3, -1).value}px`,
                  }"
                  class="icon_w24_h24"
                  @click="() => (show = !show)"
                />
                <v-tooltip v-model="show" activator="parent" location="top">
                  {{ translation("content_tvl_tooltip") }}
                  <KatexComponent :katex-text="translation('tvl_katex')" />
                  {{ translation("content_tvl_tooltip_after") }}
                </v-tooltip> -->
              </div>
              <div class="r2">
                <div class="r2_1">
                  <FormatterNumber
                    v-if="store.state.poolInfo?.system_enable_tvl"
                    :value="store.state.poolInfo?.tvl"
                    start="$"
                  />
                  <span v-if="!store.state.poolInfo?.system_enable_tvl">
                    {{ translation("updating") }}
                  </span>
                </div>
              </div>
            </div>
            <div class="pt-2 !border-t !border-black flex items-center">
              <div
                class="flex flex-col gap-1.5 items-center flex-1 !border-r !border-black"
              >
                <div class="halving_field">
                  <!-- {{ translation("halving_field_sol") }} -->
                  <KatexTooltip value="halving_field_sol" />
                </div>
                <div class="halving_value">
                  <FormatterNumber
                    :value="store.state.poolInfo?.sol_reserved"
                  />
                </div>
              </div>
              <div class="flex flex-col gap-1.5 items-center flex-1">
                <div class="halving_field">
                  <!-- {{ translation("halving_field_solx") }} -->
                  <KatexTooltip value="halving_field_solx" />
                </div>
                <div class="halving_value">
                  <FormatterNumber
                    :value="store.state.poolInfo?.solx_reserved"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="mt-2">
            <div class="r2">
              <div class="sol_price_halving">
                <FormatterNumber
                  :start="`* SOL ${translation('Price')} = $ `"
                  :value="store.state.poolInfo?.sol_price"
                />
              </div>
              <div
                class="r2_2"
                style="display: flex; gap: 4px"
                :class="
                  store.state.poolInfo?.sol_change_24h >= 0
                    ? 'growth-green'
                    : 'growth-red'
                "
              >
                <img
                  v-if="store.state.poolInfo?.sol_change_24h >= 0"
                  :src="getIcon('arrow_drop_up')"
                  alt=""
                />
                <img
                  v-if="store.state.poolInfo?.sol_change_24h < 0"
                  :src="getIcon('arrow_drop_down')"
                  alt=""
                />
                <FormatterNumber
                  :value="
                    Math.abs(store.state.poolInfo?.sol_change_24h).toFixed(2)
                  "
                  unit="%"
                />
                <!-- {{ toFixedNumber(rateTokenPoolSwap.growth, 8) }} -->
                <span
                  :class="
                    store.state.poolInfo?.sol_change_24h >= 0
                      ? 'growth-green'
                      : 'growth-red'
                  "
                >
                  {{ translation("[1d]_growth") }}
                </span>
              </div>
            </div>
          </div>
        </CardContainer>
      </div>
    </div>
    <div class="row2 relative">
      <div class="absolute top-[-24px] xl:top-[-24px]">
        <div id="statistics"></div>
        <div id="mining"></div>
        <div id="sell"></div>
        <div id="stake"></div>
      </div>
      <card-container
        bg-color="purple-pizzazz"
        class="home_statistic_container_row_2"
        :displacement-level="0"
      >
        <div class="card3">
          <div class="button_group claim_group">
            <RoundButton
              :active="tab == 'powering'"
              @click="() => changeTab('powering')"
            >
              {{ translation("statistics_Powering_Mining") }}
            </RoundButton>
            <RoundButton
              :active="tab == 'loan' || tab == 'loan_withdraw'"
              @click="() => changeTab('loan')"
            >
              {{ translation("statistics_loan") }}
            </RoundButton>
            <!-- <RoundButton
              :active="tab == 'investment' || tab == 'investment_withdraw'"
              @click="() => changeTab('investment')"
            >
              {{ translation("statistics_investment") }}
            </RoundButton> -->
            <RoundButton
              :active="tab == 'sell'"
              @click="() => changeTab('sell')"
              >{{ translation("statistics_Sell") }}
            </RoundButton>
          </div>

          <div
            v-if="store.state.tabPowering == 'powering'"
            class="tab-power mint"
          >
            <div id="stake_group" class="stake_group">
              <div class="title claim_group">
                {{ translation("statistics_Powered_SolX_Mining_with") }}
              </div>
              <NoContribute
                v-if="!store.state.user?.token && showMintNow == false"
                class="w-full"
                :title="translation('Contribute')"
                :content-before="
                  translation(
                    'Begin_your_accumulation_journey_by_first_SOL_contribution_before'
                  )
                "
                :content-after="
                  translation(
                    'Begin_your_accumulation_journey_by_first_SOL_contribution_after'
                  )
                "
                :is-content-center="true"
                type="mint"
                :content-button="translation('Contribute_now!')"
                :icon="getIcon('mint-not-connect')"
                :action-button="
                  () => {
                    console.log('loggggg');
                    inputMint?.focus();
                    showMintNow = true;
                  }
                "
              />

              <TwoTabAnalytic
                v-if="
                  store.state.user?.token &&
                  store.state.infoReward?.my_stake > 0
                "
                class="w-full"
                :field_1="translation('mint_total_minted')"
                :field_2="translation('mint_your_contribution')"
                :unit_1="translation('_solx_u')"
                :unit_2="translation('_sol_u')"
                :value_1="store.state.infoReward?.total_minted || 0"
                :value_2="store.state.infoReward?.my_stake ?? 0"
                :show-border-bottom="!!store.state.user?.token ? false : true"
              >
                <div
                  v-if="
                    store.state.user?.token &&
                    store.state.infoReward?.my_stake > 0
                  "
                  class="xl:px-6 px-2.5 pt-3 pb-4"
                >
                  <div class="flex xl:items-center items-start flex-col gap-3">
                    <div class="flex flex-col gap-1 w-full justify-between">
                      <div class="solx_minted_label">
                        <KatexTooltip value="mint_solx_minted" />
                      </div>
                      <div class="flex items-center justify-between">
                        <div class="solx_available_label text_dark">
                          <KatexTooltip value="mint_solx_available_label" />
                        </div>
                        <!-- <div class="solx_minted_value">
                          <FormatterNumber
                            v-if="estInterestContributeSetting?.interest > 0"
                            :value="estInterestContributeSetting?.interest || 0"
                            unit="SOLX"
                            class="!text-violet-600"
                          />
                          <span
                            v-if="estInterestContributeSetting?.interest <= 0"
                            class="!text-violet-600"
                          >
                            --
                          </span>
                        </div> -->
                        <div class="solx_minted_value">
                          <FormatterNumber
                            :value="store.state.infoReward?.current_mint || 0"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="flex items-center justify-end gap-2 w-full">
                      <!-- :loading="loadingClaim"
                        :disabled="
                          !store.state.infoReward?.claim_status ||
                          loadingClaim ||
                          (!getAddress() &&
                            !store.state.poolInfo
                              ?.system_enable_claim_solx_mint) ||
                          (getAddress() &&
                            (!store.state.permission ||
                              (store.state.permission &&
                              store.state.permission?.success == true
                                ? !store.state.permission?.data[
                                    'claim-solx-mint'
                                  ]
                                : true)))
                        "
                        :is-transaction="true" -->
                      <!-- <PrimaryButton
                        class="button_swap !w-fit min-w-[100px] flex-1"
                        @click="
                          () => {
                            showModalConfirmWithdrawInterestContribute = true;
                            calculateFeeTransaction();
                          }
                        "
                        :disabled="estInterestContributeSetting?.interest <= 0"
                      >
                        <div>
                          {{ translation("statistics_claim") }}
                        </div>
                      </PrimaryButton> -->
                      <PrimaryButton
                        class="button_swap"
                        :loading="loadingClaim"
                        :disabled="
                          !store.state.infoReward?.claim_status ||
                          loadingClaim ||
                          (!getAddress() &&
                            !store.state.poolInfo
                              ?.system_enable_claim_solx_mint) ||
                          (getAddress() &&
                            (!store.state.permission ||
                              (store.state.permission &&
                              store.state.permission?.success == true
                                ? !store.state.permission?.data[
                                    'claim-solx-mint'
                                  ]
                                : true)))
                        "
                        @click="claimReward"
                      >
                        <div
                          class="flex items-center gap-1"
                          v-if="
                            (!getAddress() &&
                              !store.state.poolInfo
                                ?.system_enable_claim_solx_mint) ||
                            (getAddress() &&
                              (!store.state.permission ||
                                (store.state.permission &&
                                store.state.permission?.success == true
                                  ? !store.state.permission?.data[
                                      'claim-solx-mint'
                                    ]
                                  : true)))
                          "
                        >
                          <img
                            :src="getIcon('lock')"
                            alt=""
                            class="w-[18px] h-[18px] relative top-[-2px]"
                          />
                          <span>{{
                            translation("link_claimx_mint_coming_soon")
                          }}</span>
                        </div>
                        <div
                          v-if="
                            (!getAddress() &&
                              store.state.poolInfo
                                ?.system_enable_claim_solx_mint) ||
                            (getAddress() &&
                              (store.state.permission &&
                              store.state.permission?.success == true
                                ? store.state.permission?.data[
                                    'claim-solx-mint'
                                  ]
                                : false))
                          "
                        >
                          {{
                            !store.state.infoReward?.next_claim ||
                            !getWalletAddressAll()
                              ? translation("statistics_claim")
                              : statusClaim
                          }}
                        </div>
                      </PrimaryButton>
                      <PrimaryButton
                        v-if="
                          store.state.permission &&
                          store.state.permission?.success == true
                            ? store.state.permission?.data['boost-from-mint']
                            : store.state.poolInfo
                                ?.system_enable_boost_from_mint
                        "
                        @click="
                          () => {
                            changeTab('loan');
                            boostOption = 'boost_selection_1';
                            amountSolxStaking =
                              store.state.infoReward?.current_mint || 0;
                            maxAmountStake();
                          }
                        "
                        class="flex-2 button_swap"
                        :custom-background="isDark() ? '' : '#F0FFB3'"
                      >
                        <span class="whitespace-nowrap">{{
                          translation("mint_booster_solx")
                        }}</span>
                      </PrimaryButton>
                    </div>
                  </div>

                  <!-- <div
                    v-if="store.state.infoReward?.est_mint_receive > 0"
                    class="mint_des mt-4"
                  >
                    <div class="flex flex-col">
                      <span>{{
                        translation("your_receive_next_cycle_before")
                      }}</span>
                      <span>
                        {{
                          formatDate(
                            store.state.infoReward?.time_est_mint_receive
                          )
                        }}</span
                      >
                    </div>

                    <b>
                      <FormatterNumber
                        :value="store.state.infoReward?.est_mint_receive || 0"
                        class="!text-violet-600"
                      />
                    </b>
                  </div> -->
                  <div
                    v-if="store.state.infoReward?.est_mint_receive > 0"
                    class="flex mt-4 items-center justify-between gap-1 w-full"
                  >
                    <div class="boost_field flex flex-col items-start gap-0">
                      <span>
                        <KatexTooltip value="Est_Your_Interest_Mint_1" />
                      </span>
                      <div class="boost_des">
                        <span
                          v-if="
                            (store.state.infoReward?.time_est_mint_receive ||
                              0) > 0
                          "
                        >
                          {{
                            formatDate(
                              Number(
                                store.state.infoReward?.time_est_mint_receive
                              )
                            )
                          }}
                        </span>
                        <!-- <span v-if="!amount">--</span> -->
                      </div>
                    </div>
                    <div class="flex flex-col items-end gap-1">
                      <div class="boost_value">
                        <FormatterNumber
                          :value="store.state.infoReward?.est_mint_receive"
                          :unit="translation('_solx_u')"
                          class="!text-violet-600"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </TwoTabAnalytic>
              <div
                v-if="
                  (!store.state.user?.token && showMintNow == true) ||
                  store.state.user?.token
                "
                class="w-full mint-dark p-4 rounded-[10px] xl:rounded-[16px] border-2 border-black bg-[#f0ffb3] bg-boost"
              >
                <div class="w-full">
                  <label
                    v-if="store.state.user?.token"
                    class="mint_input_label"
                    for="input_mint"
                    >{{ translation("mint_input_label") }}</label
                  >
                  <div
                    class="group_swap_ralative rounded-[12px] pr-4 my-2 overflow-hidden"
                  >
                    <InputFormat
                      id="input_mint"
                      v-model:inputValue="amount"
                      :disabled="
                        (!getAddress() &&
                          !store.state.poolInfo?.system_enable_contribute) ||
                        (getAddress() &&
                          (!store.state.permission ||
                            (store.state.permission &&
                            store.state.permission?.success == true
                              ? !store.state.permission?.data['mint']
                              : true)))
                      "
                      class="w-full"
                      :focus="showMintNow"
                    />
                    <!-- <input
                      id="input_mint"
                      v-if="isDeviceLaptop()"
                      inputmode="decimal"
                      type="text"
                      pattern="\d*"
                      placeholder="0"
                      v-model="amount"
                      @input="onChangeAmount"
                      :disabled="
                        (!getAddress() &&
                          !store.state.poolInfo?.system_enable_contribute) ||
                        (getAddress() &&
                          (!store.state.permission ||
                            (store.state.permission &&
                            store.state.permission?.success == true
                              ? !store.state.permission?.data['mint']
                              : true)))
                      "
                      ref="inputMint"
                      class="input-mint"
                    /> -->

                    <div class="group_max_swap">
                      <span class="button_max" @click="maxAmountMining"
                        >Max</span
                      >
                      <span class="arrow_button_max"></span>
                      <div class="value_g swap">
                        <img
                          class="copy_wallet_address_icon"
                          :src="getIcon('solana_logo')"
                          alt=""
                          loading="lazy"
                        />
                        <b>SOL</b>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="store.state.user?.token"
                    class="your_solx_available"
                  >
                    <span>{{
                      translation("statistics_Balance_mint_before")
                    }}</span>
                    <b>
                      <FormatterNumber
                        :value="store.state.balanceTokenBase"
                        use-change-animation
                        class="!text-violet-600"
                      />
                    </b>
                    <span>{{
                      translation("statistics_Balance_mint_after")
                    }}</span>
                  </div>
                </div>

                <span v-if="!store.state.user?.token" class="message_connect">
                  <span class="mt-[2px] flex items-center gap-1">
                    <img
                      class="w-[20px] h-[20px] mb-[2px]"
                      :src="getIcon('info')"
                      alt=""
                      :class="{ ' !mb-[4px]': !amount }"
                    />
                    <span>
                      {{ translation("Do_more_with_SOL?_Just_click_on") }}
                      <b>
                        {{ translation("Connect_Wallet") }}
                      </b>
                    </span>
                  </span>
                </span>
                <p v-if="messageValidateAmountBaseToken" class="message-solx">
                  {{ messageValidateAmountBaseToken }}
                </p>
                <div
                  class="flex mt-4 items-center justify-between gap-1 w-full"
                >
                  <div class="boost_field flex flex-col items-start gap-0">
                    <span>
                      <KatexTooltip value="Est_Your_Interest_Mint" />
                    </span>
                    <div class="boost_des">
                      <span
                        v-if="
                          amount && (store.state.estMint?.result?.time || 0) > 0
                        "
                      >
                        {{
                          formatDate(Number(store.state.estMint?.result?.time))
                        }}
                      </span>
                      <!-- <span v-if="!amount">--</span> -->
                    </div>
                  </div>
                  <div class="flex flex-col items-end gap-1">
                    <div class="boost_value">
                      <FormatterNumber
                        v-if="amount"
                        :value="
                          Number(amount) > 0
                            ? store.state.estMint == 'loading'
                              ? ''
                              : store.state.estMint?.result?.value
                            : 0
                        "
                        :unit="translation('_solx_u')"
                        class="!text-violet-600"
                      />
                      <span v-if="!amount" class="!text-violet-600">--</span>
                    </div>
                  </div>
                </div>
                <div
                  v-if="!store.state.user?.token && amount"
                  class="boost_main_des self-start mt-4"
                >
                  <span>
                    {{ translation("mint_main_des_not_connect_before") }}
                  </span>
                  <b>
                    {{ translation("mint_main_des_not_connect_center") }}
                  </b>
                  <span>
                    {{ translation("mint_main_des_not_connect_after") }}
                  </span>
                </div>
                <div
                  v-if="store.state.user?.token"
                  class="boost_main_des self-start mt-4"
                >
                  <span>
                    {{ translation("mint_main_des_connect_before") }}
                  </span>
                  <b>
                    {{ translation("mint_main_des_connect_center") }}
                  </b>
                  <span>
                    {{ translation("mint_main_des_connect_after") }}
                  </span>
                </div>
                <!--  -->
              </div>
              <div
                v-if="store.state.user?.token"
                class="checkbox_g checkbox_g_mining"
              >
                <v-checkbox
                  v-model="isAgreeStems"
                  :style="{
                    color: isDark() ? 'white' : 'black',
                  }"
                >
                </v-checkbox>
                <div class="checkbox_label">
                  <span>{{ translation("statistic_I_agree_to") }}</span>
                  <a
                    @click="
                      (event) => {
                        store.commit('setShowTermsModal', true);
                      }
                    "
                  >
                    <span>{{ translation("statistic_SolX_s_terms") }}</span>
                  </a>
                  {{ translation("statistic_is_awesome") }}
                </div>
              </div>
            </div>
            <div
              v-if="store.state.user?.token"
              class="!max-w-[650px] mx-auto w-full flex items-center gap-2"
            >
              <PrimaryButton
                class="button_swap"
                :loading="loading"
                :is-transaction="true"
                :disabled="
                  !availableBaseToken ||
                  !isAgreeStems ||
                  !amount ||
                  (!getAddress() &&
                    !store.state.poolInfo?.system_enable_contribute) ||
                  (getAddress() &&
                    (!store.state.permission ||
                      (store.state.permission &&
                      store.state.permission?.success == true
                        ? !store.state.permission?.data['mint']
                        : true)))
                "
                @click="stakingToBlockchain"
              >
                <div
                  class="flex items-center gap-1"
                  v-if="
                    (!getAddress() &&
                      !store.state.poolInfo?.system_enable_contribute) ||
                    (getAddress() &&
                      (!store.state.permission ||
                        (store.state.permission &&
                        store.state.permission?.success == true
                          ? !store.state.permission?.data['mint']
                          : true)))
                  "
                >
                  <img
                    :src="getIcon('lock')"
                    alt=""
                    class="w-[18px] h-[18px] relative top-[-2px]"
                  />
                  <span>{{ translation("link_claimx_coming_soon") }}</span>
                </div>
                <span
                  v-if="
                    (!getAddress() &&
                      store.state.poolInfo?.system_enable_contribute) ||
                    (getAddress() &&
                      (store.state.permission &&
                      store.state.permission?.success == true
                        ? store.state.permission?.data['mint']
                        : false))
                  "
                  >{{ translation("statistics_Stake") }}</span
                >
              </PrimaryButton>
            </div>
            <div
              v-if="!store.state.user?.token && showMintNow"
              class="!max-w-[650px] mx-auto w-full flex items-center gap-2"
            >
              <PrimaryButton @click="connectButton" class="button_swap">
                {{ translation("connect") }}
              </PrimaryButton>
            </div>

            <!-- <div>{{ store.state?.infoReward }}</div> -->
            <!-- <div>{{ sessionSolana }}</div> -->
          </div>
          <div
            v-if="
              store.state.tabPowering == 'loan' ||
              store.state.tabPowering == 'loan_withdraw'
            "
            class="tab-power boost"
          >
            <div v-if="store.state.tabPowering == 'loan'">
              <div class="stake_group">
                <div class="title claim_group">
                  {{ translation("statistic_SOLX_Loans") }}
                </div>
                <NoContribute
                  v-if="!store.state.user?.token && showBoostNow == false"
                  class="w-full"
                  :title="translation('Boost')"
                  :content-before="
                    translation(
                      'Begin_your_accumulation_journey_by_first_SOL_boost_before'
                    )
                  "
                  :content-after="
                    translation(
                      'Begin_your_accumulation_journey_by_first_SOL_boost_after'
                    )
                  "
                  :is-content-center="true"
                  type="boost"
                  :content-button="translation('Boost_now!')"
                  :icon="getIcon('boost-not-connect')"
                  :action-button="
                    () => {
                      inputBoost?.focus();
                      showBoostNow = true;
                    }
                  "
                />
                <TwoTabAnalytic
                  v-if="
                    store.state.user?.token &&
                    (Number(dataStakeSolx.principal) > 0 ||
                      Number(store.state.receipts?.totalClaimedBoost) > 0)
                  "
                  class="w-full"
                  :field_2="translation('boost_apy')"
                  :value_2="
                    translation('boost_value_apy').slice(
                      0,
                      translation('boost_value_apy').length - 1
                    )
                  "
                  :unit_2="translation('boost_value_apy').slice(-1)"
                  :value_1="
                    Number(store.state.receipts?.totalClaimedBoost) || 0
                  "
                  :field_1="translation('boost_solx_claim')"
                >
                  <div
                    class="boost_tap_body xl:px-6 px-2.5 pt-3 pb-4 flex flex-col gap-3"
                  >
                    <div
                      class="expanded_g flex flex-col gap-4"
                      :class="{ expanded: loanExpand }"
                    >
                      <header
                        class="loan_header"
                        @click="
                          () => {
                            if (
                              store.state.receipts?.stakeHistoryUser.length >
                                0 &&
                              !dataStakeSolx.closeCollapse
                            ) {
                              loanExpand = !loanExpand;
                            }
                          }
                        "
                      >
                        <v-menu>
                          <template v-slot:activator="{ props }">
                            <div
                              class="title-left flex flex-col boost_field"
                              v-bind="props"
                            >
                              <span>
                                <KatexTooltip
                                  :class="{
                                    '!text-[#636363]':
                                      dataStakeSolx.closeCollapse &&
                                      !dataStakeSolx.activeLate,
                                  }"
                                  value="Your_Principle"
                                />
                              </span>
                              <span
                                v-if="
                                  (!loanExpand &&
                                    Number(dataStakeSolx.principal) > 0) ||
                                  dataStakeSolx.closeCollapse
                                "
                                class="latest_date"
                                :class="{
                                  '!text-[#636363]':
                                    dataStakeSolx.closeCollapse &&
                                    !dataStakeSolx.activeLate,
                                }"
                                >{{
                                  dataStakeSolx?.timeLatest
                                    ? translation("Due_date")
                                    : ""
                                }}
                                {{
                                  dataStakeSolx?.timeLatest
                                    ? formatDate(dataStakeSolx?.timeLatest)
                                    : ""
                                }}</span
                              >
                            </div>
                          </template>
                        </v-menu>
                        <div
                          :class="{
                            '!text-[#636363]':
                              dataStakeSolx.closeCollapse &&
                              !dataStakeSolx.activeLate,
                          }"
                          class="title-right boost_value flex items-center"
                        >
                          <FormatterNumber
                            :class="{
                              '!text-[#636363]':
                                dataStakeSolx.closeCollapse &&
                                !dataStakeSolx.activeLate,
                            }"
                            v-if="Number(dataStakeSolx.principal) > 0"
                            :value="Number(dataStakeSolx.principal) ?? 0"
                          />
                          <span v-if="Number(dataStakeSolx.principal) <= 0"
                            >--</span
                          >
                          <div
                            v-if="
                              store.state.receipts?.stakeHistoryUser.length >
                                0 && !dataStakeSolx.closeCollapse
                            "
                            class="arrow_container"
                            :class="{ rotate_180: loanExpand }"
                          >
                            <v-icon icon="mdi-chevron-down"></v-icon>
                          </div>
                        </div>
                      </header>
                      <div
                        v-if="
                          store.state.user?.token &&
                          store.state.receipts?.stakeHistoryUser &&
                          store.state.receipts?.stakeHistoryUser.length > 0 &&
                          !dataStakeSolx.closeCollapse
                        "
                        class="expand_body"
                      >
                        <div class="flex gap-1 items-center">
                          <div
                            v-if="!isDark()"
                            class="flex flex-col items-center mt-[-24px]"
                          >
                            <span
                              class="w-[8px] h-[8px] bg-[#000] rounded-full"
                              :class="{
                                '!bg-[#848484]': !dataStakeSolx?.activeLate,
                              }"
                              v-if="dataStakeSolx?.showLongest"
                            ></span>
                            <span
                              :class="{
                                '!bg-[#848484]': !dataStakeSolx?.activeLongest,
                              }"
                              class="w-[2px] h-[50px] bg-[#000] rounded-full"
                              v-if="dataStakeSolx?.showLongest"
                            ></span>
                            <span
                              :class="{
                                '!border-b-[#848484]':
                                  !dataStakeSolx?.activeLongest,
                              }"
                              class="triangle rotate-180"
                              v-if="dataStakeSolx?.showLongest"
                            ></span>
                          </div>
                          <div
                            v-if="isDark()"
                            class="flex flex-col items-center mt-[-24px]"
                          >
                            <span
                              class="w-[8px] h-[8px] bg-[#9DCCED] rounded-full"
                              :class="{
                                '!bg-[#fff]': !dataStakeSolx?.activeLate,
                              }"
                              v-if="dataStakeSolx?.showLongest"
                            ></span>
                            <span
                              :class="{
                                '!bg-[#fff]': !dataStakeSolx?.activeLongest,
                              }"
                              class="w-[2px] h-[50px] bg-[#9DCCED] rounded-full"
                              v-if="dataStakeSolx?.showLongest"
                            ></span>
                            <span
                              :class="{
                                '!border-b-[#fff]':
                                  !dataStakeSolx?.activeLongest,
                              }"
                              class="triangle rotate-180"
                              v-if="dataStakeSolx?.showLongest"
                            ></span>
                          </div>
                          <div class="flex flex-1 flex-col gap-4">
                            <div
                              class="flex justify-between w-full items-center"
                            >
                              <div class="flex flex-col gap-1">
                                <span
                                  :class="{
                                    '!text-[#141416]':
                                      !isDark() &&
                                      dataStakeSolx?.activeLate &&
                                      !checkReceiptsSpecial(
                                        store.state.receipts?.stakeHistoryUser
                                      ),
                                    '!text-[#fff]':
                                      isDark() &&
                                      dataStakeSolx?.activeLate &&
                                      !checkReceiptsSpecial(
                                        store.state.receipts?.stakeHistoryUser
                                      ),
                                  }"
                                  class="latest_title"
                                  >{{ translation("latest_principal") }}</span
                                >
                                <span
                                  :class="{
                                    '!text-[#2B2B2B]':
                                      !isDark() &&
                                      dataStakeSolx?.activeLate &&
                                      !checkReceiptsSpecial(
                                        store.state.receipts?.stakeHistoryUser
                                      ),
                                    '!text-[#fff]':
                                      isDark() &&
                                      dataStakeSolx?.activeLate &&
                                      !checkReceiptsSpecial(
                                        store.state.receipts?.stakeHistoryUser
                                      ),
                                  }"
                                  class="latest_date"
                                  >{{ translation("Due_date")
                                  }}{{
                                    formatDate(dataStakeSolx?.timeLatest)
                                  }}</span
                                >
                              </div>
                              <FormatterNumber
                                :class="{
                                  '!text-[#141416]':
                                    !isDark() &&
                                    dataStakeSolx?.activeLate &&
                                    !checkReceiptsSpecial(
                                      store.state.receipts?.stakeHistoryUser
                                    ),
                                  '!text-[#fff]':
                                    isDark() &&
                                    dataStakeSolx?.activeLate &&
                                    !checkReceiptsSpecial(
                                      store.state.receipts?.stakeHistoryUser
                                    ),
                                }"
                                class="number_lastest"
                                :value="dataStakeSolx?.principalLatest"
                              />
                            </div>
                            <div
                              class="flex justify-between w-full items-center"
                              v-if="dataStakeSolx?.showLongest"
                            >
                              <div class="flex flex-col gap-1">
                                <span
                                  :class="{
                                    '!text-[#141416]':
                                      !isDark() &&
                                      dataStakeSolx?.activeLongest &&
                                      !checkReceiptsSpecial(
                                        store.state.receipts?.stakeHistoryUser
                                      ),
                                    '!text-[#fff]':
                                      isDark() &&
                                      dataStakeSolx?.activeLongest &&
                                      !checkReceiptsSpecial(
                                        store.state.receipts?.stakeHistoryUser
                                      ),
                                  }"
                                  class="latest_title"
                                  >{{ translation("longest_principal") }}</span
                                >
                                <span
                                  :class="{
                                    '!text-[#2B2B2B]':
                                      !isDark() &&
                                      dataStakeSolx?.activeLongest &&
                                      !checkReceiptsSpecial(
                                        store.state.receipts?.stakeHistoryUser
                                      ),
                                    '!text-[#fff]':
                                      isDark() &&
                                      dataStakeSolx?.activeLongest &&
                                      !checkReceiptsSpecial(
                                        store.state.receipts?.stakeHistoryUser
                                      ),
                                  }"
                                  class="latest_date"
                                  >{{ translation("Due_date")
                                  }}{{
                                    formatDate(dataStakeSolx?.timeLongest)
                                  }}</span
                                >
                              </div>
                              <FormatterNumber
                                class="number_lastest"
                                :value="dataStakeSolx?.principalLongest"
                                :class="{
                                  '!text-[#141416]':
                                    !isDark() &&
                                    dataStakeSolx?.activeLongest &&
                                    !checkReceiptsSpecial(
                                      store.state.receipts?.stakeHistoryUser
                                    ),
                                  '!text-[#fff]':
                                    isDark() &&
                                    dataStakeSolx?.activeLongest &&
                                    !checkReceiptsSpecial(
                                      store.state.receipts?.stakeHistoryUser
                                    ),
                                }"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="flex items-center justify-between gap-1 w-full">
                      <div class="boost_field flex flex-col items-start gap-0">
                        <KatexTooltip value="Est_Your_Interest" />
                        <div
                          v-if="
                            getAddress() &&
                            store.state.receipts?.stakeHistoryUser.length > 0
                          "
                          class="boost_des"
                        >
                          Interest
                          <span v-if="Number(dataStakeSolx.closeTime) > 0">
                            <KatexTooltip
                              value="Est_Your_Interest_date"
                              use-slot
                              >{{
                                formatDate(Number(dataStakeSolx.closeTimeNew))
                              }}</KatexTooltip
                            >
                          </span>
                          <FormatterNumber
                            v-if="Number(dataStakeSolx.closeTimeNew) <= 0"
                            :value="''"
                          />
                        </div>
                      </div>
                      <div class="flex flex-col items-end gap-1">
                        <div class="boost_value">
                          <FormatterNumber
                            :value="
                              (
                                Number(dataStakeSolx.interestNew) -
                                  Number(dataStakeSolx.interest) ?? 0
                              ).toFixed(9)
                            "
                            :unit="translation('_solx_u')"
                            class="!text-violet-600"
                          />
                        </div>
                      </div>
                    </div> -->
                    <div
                      class="flex h-[1px] boost_interest_driver bg-black"
                    ></div>
                    <div class="flex items-end justify-between gap-1">
                      <div
                        class="text-xl boost_interest font-normal font-['GT Walsheim'] flex flex-col gap-1"
                      >
                        <span class="font-medium text-2xl">{{
                          translation("boost_interest_1")
                        }}</span>
                        <span v-if="Number(dataStakeSolx.principal) > 0">
                          <KatexTooltip value="boost_interest_2" />
                        </span>
                        <span
                          v-if="
                            !dataStakeSolx.principal ||
                            Number(dataStakeSolx.principal) <= 0
                          "
                          class="text-[14px] lg:text-[16px]"
                        >
                          {{
                            translation(
                              "Keep_boosting_to_earn_more_passive_interest"
                            )
                          }}
                        </span>
                      </div>
                      <!-- <div
                        v-if="Number(dataStakeSolx.principal) > 0"
                        class="flex flex-col items-end gap-1"
                      >
                        <div
                          class="text-violet-600 text-2xl font-bold font-['GT Walsheim']"
                        >
                          <FormatterNumber
                            v-if="Number(dataStakeSolx.interestForSetting) > 0"
                            :value="
                              Number(dataStakeSolx.interestForSetting) ?? 0
                            "
                            :unit="translation('_solx')"
                          />
                          <span
                            v-if="Number(dataStakeSolx.interestForSetting) <= 0"
                            >--</span
                          >
                        </div>
                      </div> -->
                      <div
                        v-if="Number(dataStakeSolx.principal) > 0"
                        class="flex flex-col items-end gap-1"
                      >
                        <div
                          class="text-violet-600 text-2xl font-bold font-['GT Walsheim']"
                        >
                          <FormatterNumber
                            v-if="Number(dataStakeSolx.interest) > 0"
                            :value="Number(dataStakeSolx.interest) ?? 0"
                            :unit="translation('_solx')"
                          />
                          <span v-if="Number(dataStakeSolx.interest) <= 0"
                            >--</span
                          >
                        </div>
                      </div>
                    </div>
                    <!-- <div
                      v-if="
                        Number(dataStakeSolx.interestForSetting) -
                          Number(dataStakeSolx.interest) >
                        0
                      "
                      class="flex items-center justify-between gap-1 w-full"
                    >
                      <div class="boost_field flex flex-col items-start gap-0">
                        <KatexTooltip value="Est_Your_Interest_1" />
                        <div class="boost_des">
                          <span
                            v-if="
                              Number(dataStakeSolx.timeInterestForSetting) > 0
                            "
                          >
                            {{
                              formatDate(
                                Number(dataStakeSolx.timeInterestForSetting)
                              )
                            }}
                          </span>
                        </div>
                      </div>
                      <div class="flex flex-col items-end gap-1">
                        <div class="boost_value">
                          <FormatterNumber
                            :value="
                              dataStakeSolx.interestForSetting
                                ? (
                                    Number(dataStakeSolx.interestForSetting) -
                                      Number(dataStakeSolx.interest) || 0
                                  ).toFixed(9)
                                : ''
                            "
                            :unit="translation('_solx_u')"
                            class="!text-violet-600"
                          />
                        </div>
                      </div>
                    </div> -->
                    <div
                      v-if="Number(dataStakeSolx.principal) > 0"
                      class="flex gap-2"
                    >
                      <!-- <PrimaryButton
                        class="flex-1"
                        @click="
                          () => {
                            showModalConfirmWithdrawInterest = true;
                            calculateFeeTransaction();
                          }
                        "
                        :custom-background="'#F0FFB3'"
                      >
                        {{ translation("boost_withdraw_buytton") }}
                      </PrimaryButton> -->
                      <PrimaryButton
                        class="flex-1"
                        :loading="loadingWithdrawProfit"
                        :is-transaction="true"
                        :disabled="
                          !getAddress() ||
                          Number(dataStakeSolx.principal) <= 0 ||
                          Number(dataStakeSolx.interest) <= 0
                        "
                        @click="() => withdrawProfits()"
                        :custom-background="
                          getAddress() &&
                          Number(dataStakeSolx.principal) > 0 &&
                          Number(dataStakeSolx.interest) > 0 &&
                          !isDark()
                            ? '#F0FFB3'
                            : ''
                        "
                      >
                        {{ translation("boost_withdraw_buytton") }}
                      </PrimaryButton>

                      <PrimaryButton
                        @click="
                          () => {
                            store.commit('setOpenWithdrawBoost', true);
                            calculateFeeTransaction();
                          }
                        "
                        :disabled="
                          !store.state.user?.token ||
                          store.state.receipts?.stakeHistoryUser.length <= 0
                        "
                      >
                        <span class="px-1">
                          <b>&#x22EE;</b>
                        </span>
                      </PrimaryButton>
                    </div>
                    <div
                      v-if="
                        Number(dataStakeSolx.principal) <= 0 &&
                        Number(store.state.receipts?.totalClaimedBoost) <= 0
                      "
                    >
                      <PrimaryButton
                        @click="
                          () => {
                            inputBoost?.focus();
                          }
                        "
                        class="w-full"
                      >
                        {{ translation("Boost_now") }}
                      </PrimaryButton>
                    </div>
                    <!-- <div
                      v-if="
                        Number(dataStakeSolx.interestNew) -
                          Number(dataStakeSolx.interest) >
                        0
                      "
                      class="flex items-center justify-between gap-1 w-full"
                    >
                      <div class="!text-[16px] !font-normal boost_interest">
                        <span>
                          {{ translation("Est_Your_Interest_before") }}
                        </span>
                        <b>
                          <FormatterNumber
                            :value="
                              (
                                Number(dataStakeSolx.interestNew) -
                                  Number(dataStakeSolx.interest) ?? 0
                              ).toFixed(9)
                            "
                            :unit="translation('_solx_u')"
                          />
                        </b>
                        <span>
                          {{ translation("Est_Your_Interest_after") }}
                          {{
                            Number(dataStakeSolx.principal) > 0
                              ? formatDate(dataStakeSolx?.timeLongest)
                              : ""
                          }}
                        </span>
                      </div>
                    </div> -->
                    <div
                      v-if="
                        Number(dataStakeSolx.interestNew) -
                          Number(dataStakeSolx.interest) >
                        0
                      "
                      class="flex items-center justify-between gap-1 w-full"
                    >
                      <div class="boost_field flex flex-col items-start gap-0">
                        <KatexTooltip value="Est_Your_Interest_1" />
                        <div class="boost_des">
                          <span v-if="Number(dataStakeSolx.closeTimeNew) > 0">
                            {{ formatDate(Number(dataStakeSolx.closeTimeNew)) }}
                          </span>
                        </div>
                      </div>
                      <div class="flex flex-col items-end gap-1">
                        <div class="boost_value">
                          <FormatterNumber
                            :value="
                              (
                                Number(dataStakeSolx.interestNew) -
                                  Number(dataStakeSolx.interest) || 0
                              ).toFixed(9)
                            "
                            :unit="translation('_solx_u')"
                            class="!text-violet-600"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </TwoTabAnalytic>
                <label
                  v-if="
                    store.state.user?.token &&
                    Number(dataStakeSolx.principal) > 0
                  "
                  class="mint_input_label self-start"
                  >{{ translation("boost_radios_label") }}</label
                >

                <div
                  v-if="
                    (!store.state.user?.token && showBoostNow == true) ||
                    store.state.user?.token
                  "
                  class="w-full boost-dark p-4 rounded-[10px] xl:rounded-[16px] border-2 border-black bg-[#f0ffb3] bg-boost"
                >
                  <div
                    class="flex flex-wrap item-center gap-2 w-full mb-4"
                    v-if="
                      store.state?.user?.token &&
                      (store.state.permission &&
                      store.state.permission?.success == true
                        ? store.state.permission?.data['boost']
                        : store.state.poolInfo.system_enable_boost) &&
                      (store.state.permission &&
                      store.state.permission?.success == true
                        ? store.state.permission?.data['boost-from-mint']
                        : store.state.poolInfo?.system_enable_boost_from_mint)
                    "
                  >
                    <div
                      v-if="
                        store.state.permission &&
                        store.state.permission?.success == true
                          ? store.state.permission?.data['boost-from-mint']
                          : store.state.poolInfo?.system_enable_boost_from_mint
                      "
                      @click="() => (boostOption = 'boost_selection_1')"
                      class="duration-200 whitespace-nowrap px-4 x-4 py-1 bg-[#ffffff33] cursor-pointer rounded-[100px] border-2 border-gray-400 flex-col justify-center items-center inline-flex"
                      :class="{
                        '!border-black bg-white active duration-200':
                          boostOption == 'boost_selection_1',
                      }"
                    >
                      <div
                        class="text-gray-400 text-base font-normal font-['GT Walsheim'] leading-7 text_dark_des relative top-[1px]"
                        :class="{
                          'text-black': boostOption == 'boost_selection_1',
                        }"
                      >
                        {{ translation("minted_boost") }}
                      </div>
                    </div>
                    <div
                      v-if="
                        store.state.permission &&
                        store.state.permission?.success == true
                          ? store.state.permission?.data['boost']
                          : store.state.poolInfo.system_enable_boost
                      "
                      @click="() => (boostOption = 'boost_selection_2')"
                      class="duration-200 whitespace-nowrap px-4 x-4 py-1 bg-[#ffffff33] cursor-pointer rounded-[100px] border-2 border-gray-400 flex-col justify-center items-center inline-flex"
                      :class="{
                        '!border-black bg-white active':
                          boostOption == 'boost_selection_2',
                      }"
                    >
                      <div
                        class="text-gray-400 text-base font-normal font-['GT Walsheim'] leading-7 text_dark_des relative top-[1px]"
                        :class="{
                          'text-black': boostOption == 'boost_selection_2',
                        }"
                      >
                        {{ translation("available_solx_in_wallet") }}
                      </div>
                    </div>
                  </div>
                  <v-tabs-window v-model="boostOption">
                    <v-tabs-window-item value="boost_selection_1">
                      <div class="group_swap_ralative rounded-[12px] pr-4">
                        <!-- <input
                          inputmode="decimal"
                          type="text"
                          placeholder="0"
                          ref="inputBoost"
                          @input="onChangeAmountSolxStaking"
                          v-model="amountSolxStaking"
                          :disabled="
                            (!getAddress() &&
                              !store.state.poolInfo?.system_enable_boost) ||
                            (getAddress() &&
                              (!store.state.permission ||
                                (store.state.permission &&
                                store.state.permission?.success == true
                                  ? !store.state.permission?.data[
                                      'boost-from-mint'
                                    ]
                                  : true)))
                          "
                          class="input-boost"
                        /> -->
                        <InputFormat
                          id="input_mint"
                          v-model:inputValue="amountSolxStaking"
                          :disabled="
                            (!getAddress() &&
                              !store.state.poolInfo?.system_enable_boost) ||
                            (getAddress() &&
                              (!store.state.permission ||
                                (store.state.permission &&
                                store.state.permission?.success == true
                                  ? !store.state.permission?.data[
                                      'boost-from-mint'
                                    ]
                                  : true)))
                          "
                          class="w-full"
                          :focus="showBoostNow"
                        />
                        <div class="group_max_swap">
                          <span
                            class="button_max"
                            @click.stop="
                              () => {
                                maxAmountStake();
                              }
                            "
                            >Max</span
                          >
                          <span class="arrow_button_max"></span>
                          <div class="value_g swap">
                            <img
                              class="copy_wallet_address_icon"
                              :src="getIcon('full_logo')"
                              alt=""
                              loading="lazy"
                            />
                            <b>SOLX</b>
                          </div>
                        </div>
                      </div>
                      <div class="your_solx_minted your_solx_available">
                        <span class="mr-1">{{
                          translation("your_solx_minted_before")
                        }}</span>
                        <b
                          ><FormatterNumber
                            :value="store.state.infoReward?.current_mint"
                            class="!text-purple-600"
                        /></b>
                        <span class="mr-1">{{
                          translation("your_solx_minted_after")
                        }}</span>
                      </div>
                      <p
                        v-if="
                          messageValidateAmountSolxStaking &&
                          boostOption == 'boost_selection_1'
                        "
                        class="message-solx"
                      >
                        {{ messageValidateAmountSolxStaking }}
                      </p>
                      <div
                        class="flex items-center justify-between gap-1 w-full my-4"
                      >
                        <div
                          class="boost_field flex flex-col items-start gap-0"
                        >
                          <KatexTooltip value="Est_Your_Interest_2" />
                          <div v-if="amountSolxStaking" class="boost_des">
                            <span>{{ translation("Interest_boost_2") }}</span>

                            <span
                              v-if="
                                Number(amountEstAfterInputBoost.closeTimeNew) >
                                0
                              "
                            >
                              {{
                                formatDate(
                                  Number(amountEstAfterInputBoost.closeTimeNew)
                                )
                              }}
                            </span>
                          </div>
                        </div>
                        <div class="flex flex-col items-end gap-1">
                          <div class="boost_value">
                            <FormatterNumber
                              v-if="amountSolxStaking"
                              :value="
                                amountEstAfterInputBoost.interestNew
                                  ? (
                                      Number(
                                        amountEstAfterInputBoost.interestNew
                                      ) ?? 0
                                    ).toFixed(9)
                                  : ''
                              "
                              :unit="translation('_solx_u')"
                              class="!text-violet-600"
                            />
                            <span
                              class="!text-violet-600"
                              v-if="!amountSolxStaking"
                              >--</span
                            >
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="
                          !store.state.user?.token &&
                          showBoostNow &&
                          amountSolxStaking
                        "
                        class="boost_main_des self-start"
                      >
                        <span>
                          {{ translation("boost_main_des_not_connect_before") }}
                        </span>
                        <b>
                          {{ translation("boost_main_des_not_connect_center") }}
                        </b>
                        <span>
                          {{ translation("boost_main_des_not_connect_after") }}
                        </span>
                      </div>
                      <div
                        v-if="store.state.user?.token"
                        class="boost_main_des self-start"
                      >
                        <span>
                          {{ translation("boost_main_des_connect_before") }}
                        </span>
                        <b>
                          {{ translation("boost_main_des_connect_center") }}
                        </b>
                        <span>
                          {{ translation("boost_main_des_connect_after") }}
                        </span>
                      </div>
                    </v-tabs-window-item>

                    <v-tabs-window-item value="boost_selection_2">
                      <div class="group_swap_ralative rounded-[12px] pr-4">
                        <!-- <input
                          inputmode="decimal"
                          type="text"
                          ref="inputBoost"
                          placeholder="0"
                          @input="onChangeAmountSolxStaking"
                          :v-model="amountSolxStaking"
                          :disabled="
                            (!getAddress() &&
                              !store.state.poolInfo?.system_enable_boost) ||
                            (getAddress() &&
                              (!store.state.permission ||
                                (store.state.permission &&
                                store.state.permission?.success == true
                                  ? !store.state.permission?.data['boost']
                                  : true)))
                          "
                          class="input-boost"
                        /> -->
                        <InputFormat
                          id="input_mint"
                          v-model:inputValue="amountSolxStaking"
                          :disabled="
                            (!getAddress() &&
                              !store.state.poolInfo?.system_enable_boost) ||
                            (getAddress() &&
                              (!store.state.permission ||
                                (store.state.permission &&
                                store.state.permission?.success == true
                                  ? !store.state.permission?.data['boost']
                                  : true)))
                          "
                          class="w-full"
                          :focus="showBoostNow"
                        />
                        <div class="group_max_swap">
                          <span
                            class="button_max"
                            @click.stop="
                              () => {
                                maxAmountStake();
                              }
                            "
                            >Max</span
                          >
                          <span class="arrow_button_max"></span>
                          <div class="value_g swap">
                            <img
                              class="copy_wallet_address_icon"
                              :src="getIcon('full_logo')"
                              alt=""
                              loading="lazy"
                            />
                            <b>SOLX</b>
                          </div>
                        </div>
                      </div>
                      <span
                        v-if="!store.state.user?.token && showBoostNow"
                        class="message_connect mt-2"
                      >
                        <span class="mt-[2px] flex items-center gap-1">
                          <img
                            class="w-[20px] h-[20px] mb-[2px]"
                            :src="getIcon('info')"
                            alt=""
                            :class="{ ' !mb-[4px]': !amountSolxStaking }"
                          />
                          <span>
                            {{
                              translation("Do_more_with_SOLX?_Just_click_on")
                            }}
                            <b>
                              {{ translation("Connect_Wallet") }}
                            </b>
                          </span>
                        </span>
                      </span>
                      <div
                        v-if="store.state.user?.token"
                        class="your_solx_available mt-2"
                      >
                        <span class="mr-1">{{
                          translation("your_solx_available_boost_before")
                        }}</span>
                        <b
                          ><FormatterNumber
                            :value="store.state.balanceTokenQuote || 0"
                            class="!text-violet-600"
                        /></b>
                        <span class="mr-1">{{
                          translation("your_solx_available_boost_after")
                        }}</span>
                      </div>
                      <p
                        v-if="
                          messageValidateAmountSolxStaking &&
                          boostOption == 'boost_selection_2'
                        "
                        class="message-solx"
                      >
                        {{ messageValidateAmountSolxStaking }}
                      </p>
                      <div
                        class="flex items-center justify-between gap-1 w-full my-4"
                      >
                        <div
                          class="boost_field flex flex-col items-start gap-0"
                        >
                          <KatexTooltip value="Est_Your_Interest_2" />
                          <div v-if="amountSolxStaking" class="boost_des">
                            <span>{{ translation("Interest_boost_2") }}</span>

                            <span
                              v-if="
                                Number(amountEstAfterInputBoost.closeTimeNew) >
                                0
                              "
                            >
                              {{
                                formatDate(
                                  Number(amountEstAfterInputBoost.closeTimeNew)
                                )
                              }}
                            </span>
                          </div>
                        </div>
                        <div class="flex flex-col items-end gap-1">
                          <div class="boost_value">
                            <FormatterNumber
                              v-if="amountSolxStaking"
                              :value="
                                amountEstAfterInputBoost.interestNew
                                  ? (
                                      Number(
                                        amountEstAfterInputBoost.interestNew
                                      ) ?? 0
                                    ).toFixed(9)
                                  : ''
                              "
                              :unit="translation('_solx_u')"
                              class="!text-violet-600"
                            />
                            <span
                              class="!text-violet-600"
                              v-if="!amountSolxStaking"
                              >--</span
                            >
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="
                          !store.state.user?.token &&
                          showBoostNow &&
                          amountSolxStaking
                        "
                        class="boost_main_des self-start"
                      >
                        <span>
                          {{ translation("boost_main_des_not_connect_before") }}
                        </span>
                        <b>
                          {{ translation("boost_main_des_not_connect_center") }}
                        </b>
                        <span>
                          {{ translation("boost_main_des_not_connect_after") }}
                        </span>
                      </div>
                      <div
                        v-if="store.state.user?.token"
                        class="boost_main_des self-start"
                      >
                        <span>
                          {{ translation("boost_main_des_connect_before") }}
                        </span>
                        <b>
                          {{ translation("boost_main_des_connect_center") }}
                        </b>
                        <span>
                          {{ translation("boost_main_des_connect_after") }}
                        </span>
                      </div>
                    </v-tabs-window-item>
                  </v-tabs-window>
                </div>
                <div
                  v-if="store.state.user?.token"
                  class="checkbox_g checkbox_g_mining"
                >
                  <v-checkbox
                    v-model="isAgreeStemsBoost"
                    hide-details
                    :style="{
                      color: isDark() ? 'white' : 'black',
                    }"
                  >
                  </v-checkbox>
                  <div class="checkbox_label">
                    {{ translation("Auto_Subcribe") }}
                  </div>
                </div>
              </div>

              <div
                v-if="store.state.user?.token"
                class="w-full !max-w-[650px] mx-auto flex flex-col gap-4 mt-4"
              >
                <div class="flex items-center gap-3">
                  <PrimaryButton
                    class="button_swap"
                    :loading="loading"
                    :is-transaction="true"
                    :disabled="
                      !availableSolxStaking ||
                      !amountSolxStaking ||
                      (!getAddress() &&
                        !store.state.poolInfo?.system_enable_boost) ||
                      (getAddress() &&
                        (!store.state.permission ||
                          (store.state.permission &&
                          store.state.permission?.success == true
                            ? !store.state.permission?.data['boost'] &&
                              !store.state.permission?.data['boost-from-mint']
                            : true)))
                    "
                    @click="stakingSolx"
                  >
                    <div
                      class="flex items-center gap-1"
                      v-if="
                        (!getAddress() &&
                          !store.state.poolInfo?.system_enable_boost) ||
                        (getAddress() &&
                          (!store.state.permission ||
                            (store.state.permission &&
                            store.state.permission?.success == true
                              ? !store.state.permission?.data['boost'] &&
                                !store.state.permission?.data['boost-from-mint']
                              : true)))
                      "
                    >
                      <img
                        :src="getIcon('lock')"
                        alt=""
                        class="w-[18px] h-[18px] relative top-[-2px]"
                      />
                      <span>{{ translation("link_boost_coming_soon") }}</span>
                    </div>
                    <div
                      v-if="
                        (!getAddress() &&
                          store.state.poolInfo?.system_enable_boost) ||
                        (getAddress() &&
                          (store.state.permission &&
                          store.state.permission?.success == true
                            ? store.state.permission?.data['boost'] ||
                              store.state.permission?.data['boost-from-mint']
                            : false))
                      "
                    >
                      {{ translation("statistic_loan_Power_Up") }}
                    </div>
                  </PrimaryButton>
                </div>
              </div>
              <div
                v-if="!store.state.user?.token && showBoostNow"
                class="w-full !max-w-[650px] mx-auto flex flex-col gap-4 mt-4"
              >
                <div class="flex items-center gap-3">
                  <PrimaryButton @click="connectButton" class="button_swap">
                    {{ translation("connect") }}
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </div>
          <div
            class="flex flex-col items-center min-h-60 justify-center gap-4"
            v-if="
              store.state.tabPowering == 'sell' &&
              ((!getAddress() && !store.state.poolInfo?.system_enable_swap) ||
                (getAddress() &&
                  (!store.state.permission ||
                    (store.state.permission &&
                    store.state.permission?.success == true
                      ? !store.state.permission?.data['swap']
                      : true))))
            "
          >
            <img
              :src="getIcon('swap_coming_soon')"
              alt="swap"
              class="w-20 block"
            />
            <div
              class="text-[18px]"
              :style="{ color: isDark() ? '#fff' : '#000' }"
            >
              {{ translation("swap_coming_soon") }}
            </div>
          </div>
          <div
            class="tab-power"
            v-if="
              store.state.tabPowering == 'sell' &&
              ((!getAddress() && store.state.poolInfo?.system_enable_swap) ||
                (getAddress() &&
                  (store.state.permission &&
                  store.state.permission?.success == true
                    ? store.state.permission?.data['swap']
                    : false)))
            "
          >
            <div class="stake_group">
              <div class="title claim_group">
                {{ translation("statistic_SOLX_Swap") }}
              </div>
              <!-- <div id="loan_checkbox" class="checkbox_g">
                <v-checkbox v-model="isAgreeStems"> </v-checkbox>
                <div class="checkbox_label">
                  {{ translation("statistic_loan_I_agree_to") }}
                  <a
                    @click.stop="
                      () => {
                        store.commit('setShowTermsModal', true);
                      }
                    "
                  >
                    {{ translation("statistic_loan_SOLX_s") }}
                  </a>

                  {{ translation("statistic_loan_Terms_of_Use") }}
                </div>
              </div> -->
              <NoContribute
                v-if="!store.state.user?.token && showSwapNow == false"
                class="w-full"
                :title="translation('Swap')"
                :content-before="
                  translation(
                    'Begin_your_accumulation_journey_by_first_SOL_swap_before'
                  )
                "
                :content-after="
                  translation(
                    'Begin_your_accumulation_journey_by_first_SOL_swap_after'
                  )
                "
                :content-button="translation('Swap_now!')"
                :icon="getIcon('swap-not-connect')"
                :action-button="
                  () => {
                    inputSwap?.focus();
                    showSwapNow = true;
                  }
                "
              />
              <TwoTabAnalytic
                v-if="store.state.user?.token || showSwapNow"
                class="w-full"
              >
                <div class="flex flex-col gap-2 p-4">
                  <div
                    v-if="Number(store.state.swapInfo?.total_swap) > 0"
                    class="row_content"
                  >
                    <div class="field">
                      <KatexTooltip value="statistic_sell__Sold" />
                    </div>
                    <div class="value">
                      <FormatterNumber
                        :value="Number(store.state.swapInfo?.total_swap) || 0"
                        :unit="translation('_solx_u')"
                      />
                    </div>
                  </div>
                  <div class="row_content">
                    <div class="field">
                      <KatexTooltip value="statistic_sell__Available_Today" />
                    </div>
                    <div class="value">
                      <b>
                        <FormatterNumber
                          v-if="store.state.poolInfo?.system_enable_limit_swap"
                          :value="
                            Number(
                              store.state.swapInfo?.system_remaining_swap || 0
                            ) || 0
                          "
                          class="font-semibold"
                        />
                      </b>

                      <span
                        v-if="!store.state.poolInfo?.system_enable_limit_swap"
                        >{{ translation("_unlimit") }}</span
                      >
                    </div>
                  </div>
                  <div v-if="!store.state.user?.token" class="content_group">
                    <div class="line_content text_dark text-black">
                      {{
                        translation(
                          "Daily_transaction_cap_per_person_is_limited_by_1."
                        )
                      }}
                    </div>
                  </div>
                  <div
                    v-if="
                      store.state.user?.token &&
                      store.state.poolInfo?.system_enable_limit_swap_user
                    "
                    class="content_group"
                  >
                    <div class="line_content text_dark text-black">
                      <span
                        v-if="
                          Number(store.state.swapInfo?.user_remaining_swap) > 0
                        "
                      >
                        {{ translation("remaining_before") }}
                        {{ Number(store.state.swapInfo?.user_remaining_swap) }}
                        {{ translation("remaining_after") }}
                      </span>
                      <span
                        v-if="
                          Number(store.state.swapInfo?.user_remaining_swap) <= 0
                        "
                      >
                        {{ translation("reset_before") }}
                        {{ textStatusClaim ? textStatusClaim : "" }}
                        <FormatterNumber v-if="!textStatusClaim" :value="''" />
                        {{ translation("reset_after") }}
                      </span>
                    </div>
                    <div
                      v-if="
                        !store.state.poolInfo?.system_enable_limit_swap_user
                      "
                      class="line_content text_dark text-black"
                    >
                      {{
                        translation(
                          "statistic_sell____Preeminent_features_that_no_other_cryptocurrency_has_no_limit"
                        )
                      }}
                    </div>
                  </div>
                </div>
              </TwoTabAnalytic>
              <!-- <card-container
               
                class="swap_header_container"
                :custom-background-color="isDark() ? '#20232F' : '#e8eaf0'"
                :padding-top="16"
                :padding-bottom="16"
              ></card-container> -->
              <div
                v-if="store.state.user?.token || showSwapNow"
                class="input_group section_swap"
              >
                <span>{{ translation("_swap_from") }}</span>
                <!-- <div class="ratio">
                  <span>Slippage</span>
                  <header>
                    <v-menu
                      v-model="vmenuSwap"
                      :close-on-content-click="false"
                      location="bottom right"
                    >
                      <template v-slot:activator="{ props }">
                        <div class="collapse_action" v-bind="props">
                          <div
                            v-if="optionsSlippageSelected?.percent != 'auto'"
                            class="percent_slippage"
                          >
                            <FormatterNumber
                              use-change-animation
                              :value="optionsSlippageSelected?.percent ?? 0"
                            />%
                            <div
                              class="arrow_container"
                              :class="{ rotate_180: vmenuSwap }"
                            >
                              <v-icon icon="mdi-chevron-down"></v-icon>
                            </div>
                          </div>
                          <div
                            v-if="optionsSlippageSelected?.percent == 'auto'"
                            class="percent_slippage"
                          >
                            <span>Auto</span>
                            <div
                              class="arrow_container"
                              :class="{ rotate_180: vmenuSwap }"
                            >
                              <v-icon icon="mdi-chevron-down"></v-icon>
                            </div>
                          </div>
                        </div>
                      </template>
                      <v-list class="list_board swap_sipple_option">
                        <v-list-item
                          class="list_item list_item_swap"
                          v-for="(item, index) in poolInfo.slippage"
                          :key="index"
                          :value="item.percent"
                          :active="
                            optionsSlippageSelectedTemp
                              ? item.percent ===
                                optionsSlippageSelectedTemp?.percent
                              : item.percent ===
                                optionsSlippageSelected?.percent
                          "
                          @click="selecteOptionSlippage('temp', item)"
                          color="primary"
                          active-color="#F5FFCE"
                        >
                          <ListItem class="percent_swap"
                            ><span
                              >{{
                                item?.percent == "auto"
                                  ? "Auto"
                                  : item?.percent
                              }}{{ item?.percent != "auto" ? "%" : "" }}</span
                            ><span
                              v-if="
                                item.percent ===
                                optionsSlippageSelectedTemp?.percent
                              "
                              ><svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <mask
                                  id="mask0_1072_347"
                                  style="mask-type: alpha"
                                  maskUnits="userSpaceOnUse"
                                  x="0"
                                  y="0"
                                  width="24"
                                  height="24"
                                >
                                  <rect width="24" height="24" fill="#D9D9D9" />
                                </mask>
                                <g mask="url(#mask0_1072_347)">
                                  <path
                                    d="M9.5501 18L3.8501 12.3L5.2751 10.875L9.5501 15.15L18.7251 5.97498L20.1501 7.39998L9.5501 18Z"
                                    fill="#F3BA2F"
                                  />
                                </g></svg></span
                          ></ListItem>
                        </v-list-item>
                        <v-list-item class="list_item list_item_swap">
                          <PrimaryButton
                            @click="selecteOptionSlippage('real')"
                            class="list_item_swap_button"
                            >Save Setting</PrimaryButton
                          >
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </header>
                </div> -->
              </div>
              <div
                class="group_swap_ralative swap pr-4 rounded-[12px]"
                v-if="
                  (!store.state.user?.token && showSwapNow == true) ||
                  store.state.user?.token
                "
              >
                <!-- <input
                  inputmode="decimal"
                  type="text"
                  @input="onChangeAmountSolx"
                  placeholder="0"
                  v-model="amountSolx"
                  ref="inputSwap"
                  :disabled="store.state.swapInfo?.swap_enable == false"
                  class="input-swap"
                /> -->
                <InputFormat
                  id="input_mint"
                  v-model:inputValue="amountSolx"
                  :disabled="store.state.swapInfo?.swap_enable == false"
                  class="w-full"
                  :focus="showSwapNow"
                />
                <div class="group_max_swap">
                  <span class="button_max" @click="maxAmountSwap">Max</span>
                  <span class="arrow_button_max"></span>

                  <div class="value_g swap">
                    <img
                      class="copy_wallet_address_icon"
                      :src="getIcon('full_logo')"
                      alt=""
                      loading="lazy"
                    />
                    <b>SOLX</b>
                  </div>
                </div>
              </div>

              <span
                v-if="!store.state.user?.token && showSwapNow"
                class="message_connect mt-[-8px]"
              >
                <span class="mt-[2px] flex items-center gap-1">
                  <img
                    class="w-[20px] h-[20px] mb-[2px]"
                    :src="getIcon('info')"
                    alt=""
                    :class="{ ' !mb-[4px]': !amountSolx }"
                  />
                  <span>
                    {{ translation("Do_more_with_SOLX?_Just_click_on") }}
                    <b>
                      {{ translation("Connect_Wallet") }}
                    </b>
                  </span>
                </span>
              </span>
              <div
                v-if="store.state.user?.token"
                class="your_solx_available gap-1 w-full flex justify-start"
              >
                <span>{{
                  translation("statistic_sell__Balance_swap_before")
                }}</span>
                <b>
                  <FormatterNumber
                    use-change-animation
                    :value="store.state.balanceTokenQuote ?? 0"
                    :unit="translation('_solx_u')"
                    class="!text-violet-600"
                  />
                </b>
                <span>{{
                  translation("statistic_sell__Balance_swap_after")
                }}</span>
              </div>
              <p v-if="messageValidateAmountSolx" class="message-solx">
                {{ messageValidateAmountSolx }}
              </p>
              <img
                v-if="store.state.user?.token || showSwapNow"
                :src="
                  getIcon(
                    isDark() ? 'arrow_right_alt_white' : 'arrow_right_alt'
                  )
                "
                alt=""
              />

              <div
                v-if="store.state.user?.token || showSwapNow"
                class="balance_group input_group_to_swap pr-4 rounded-[12px]"
              >
                <span class="input_group section_swap py-3">
                  <span>{{ translation("_swap_to") }}</span>
                  <FormatterNumber
                    v-if="amountSolOut.value != 'loading'"
                    :value="Number(amountSolOut.value) ?? 0"
                  />
                  <FormatterNumber
                    v-if="
                      amountSolOut.value == 'loading' &&
                      (Number(amountSolx) <= 0 || !amountSolx)
                    "
                    :value="0"
                  />
                  <v-progress-circular
                    v-if="
                      amountSolOut.value == 'loading' && Number(amountSolx) > 0
                    "
                    indeterminate
                    :size="20"
                    :width="2"
                    :color="isDark() ? 'white' : 'black'"
                    class="!ml-6"
                  ></v-progress-circular>
                </span>
                <div class="value_g swap">
                  <img
                    class="copy_wallet_address_icon"
                    :src="getIcon('solana_logo')"
                    alt=""
                    loading="lazy"
                  />
                  <b>SOL</b>
                </div>
              </div>
            </div>
            <div
              v-if="store.state.user?.token"
              class="!max-w-[650px] mx-auto w-full"
            >
              <div class="input_group button_swap">
                <PrimaryButton
                  class="button_swap"
                  :loading="loading"
                  :is-transaction="true"
                  :disabled="
                    !availableSolx ||
                    amountSolOut.value == 'loading' ||
                    store.state.swapInfo?.swap_enable == false
                  "
                  @click="
                    () => {
                      calculateFeeTransaction();
                      showModalConfirmSwap = true;
                    }
                  "
                >
                  {{ translation("statistic_sell__Enable") }}
                </PrimaryButton>
              </div>
            </div>
            <div
              v-if="!store.state.user?.token && showSwapNow"
              class="!max-w-[650px] mx-auto w-full flex items-center gap-2"
            >
              <PrimaryButton @click="connectButton" class="button_swap">
                {{ translation("connect") }}
              </PrimaryButton>
            </div>
            <div
              v-if="!store.state.user?.token && showSwapNow && amountSolx"
              class="stake_group"
            >
              <TwoTabAnalytic
                class="!max-w-[650px] mx-auto w-full flex items-center gap-2 ]"
              >
                <div class="p-4 boost_main_des">
                  <span>
                    {{ translation("swap_main_des_not_connect_before") }}
                  </span>
                  <b>
                    {{ translation("swap_main_des_not_connect_center") }}
                  </b>
                  <span>
                    {{ translation("swap_main_des_not_connect_after") }}
                  </span>
                </div>
              </TwoTabAnalytic>
            </div>

            <div v-if="store.state.user?.token" class="stake_group">
              <TwoTabAnalytic
                class="!max-w-[650px] mx-auto w-full flex items-center gap-2 ]"
              >
                <div
                  v-if="store.state.receipts?.isHistorySwap <= 0"
                  class="p-4 boost_main_des"
                >
                  <span>
                    {{ translation("swap_main_des_connect_never_swap_before") }}
                  </span>
                  <b>
                    {{ translation("swap_main_des_connect_never_swap_center") }}
                  </b>
                  <span>
                    {{ translation("swap_main_des_connect_never_swap_after") }}
                  </span>
                </div>
                <div
                  v-if="store.state.receipts?.isHistorySwap > 0"
                  class="p-4 boost_main_des"
                >
                  <span>
                    {{ translation("swap_main_des_connect_before") }}
                  </span>
                  <b>
                    {{ translation("swap_main_des_connect_center") }}
                  </b>
                  <span>
                    {{ translation("swap_main_des_connect_after") }}
                  </span>
                </div>
              </TwoTabAnalytic>
            </div>
          </div>
        </div>
      </card-container>
    </div>
  </div>
</template>

<script setup lang="ts">
import InputFormat from "./InputFormat.vue";
import getIcon from "@/common/function/getIcon";
import CardContainer from "./CardContainer.vue";
import RoundButton from "./RoundButton.vue";
import SectionTitle from "./SectionTitle.vue";
import { getCurrentInstance, onMounted, onUnmounted, watch } from "vue";
import PrimaryButton from "./PrimaryButton.vue";
import { ref, computed } from "vue";
import { Connection, PublicKey, Keypair } from "@solana/web3.js";
import useSolana from "@/hook/useSolana";
import useProgram from "@/hook/useProgram";
import useToastMessage from "@/hook/useToastMessage";

import store from "@/store";
import base58 from "bs58";
import { isDeviceLaptop, toFixedNumber } from "@/wallet/solana/Solana";
import { Metaplex } from "@metaplex-foundation/js";
import {
  provideApolloClient,
  useMutation,
  useQuery,
} from "@vue/apollo-composable";
import { userClient } from "@/graphql/client/user-client";
import {
  claim,
  getClaim,
  getPoolInfo,
  staking,
  swapBE,
  swapInfo,
} from "@/graphql/query/homeStatictis";
import { basicClient } from "@/graphql/client/basic-client";
import login, { saveSessionWallet } from "@/common/function/login";
import { Emitter } from "mitt";
import {
  getBlanceAccountConnect,
  getPoolStake,
  stakeHistorys,
  fetchPrice24h,
  fetchDataChart,
  fetchHaving,
  callCrawler,
  fetchPermission,
  rejectCrawler,
  fetchSettingAddToken,
  getRpcConnection,
  formatDate,
  getSettingFeeIn,
  estInterestMint,
  generateTypeMemo,
} from "@/service/statictis";
import { useAnchorWallet, useWallet } from "solana-wallets-vue";
import translation from "@/common/function/translation";
import CopyText from "./CopyText.vue";
import * as anchor from "@project-serum/anchor";
import FormatterNumber from "./FormatterNumber.vue";
import * as nacl from "tweetnacl";
import MyWallet from "@/common/function/myWallet";
import compactWalletAddress from "@/common/function/compactWalletAddress";
import { parseJwt } from "@/common/function/encrypt";
import ModalWithdrawBoost from "./ModalWithdrawBoost.vue";
import ConfirmWithdrawInterest from "./ConfirmWithdrawInterest.vue";
import ConfirmWithdrawInterestContribute from "./ConfirmWithdrawInterestContribute.vue";
import ConfirmSwap from "./ConfirmSwap.vue";

import { debounce } from "lodash";
import { decryptPayload, walletAdapter } from "@/wallet/solana/Solana";
import { useMessage, NModal, NAvatar, NCard } from "naive-ui";

const cluster: any = process.env.VUE_APP_CLUSTER;
const rpc_mainnet: any = process.env.VUE_APP_RPC_MAINNET;
const rpc_devnet: any = process.env.VUE_APP_RPC_DEVNET;
console.log("🚀 ~ rpc_devnet:", rpc_devnet);

const token_fee: any = process.env.VUE_APP_TOKEN_FEE;
const emitter: Emitter<any> =
  getCurrentInstance()?.appContext.app.config.globalProperties.emitter;
onMounted(() => {
  emitter.on("SUBSCRIBE_AFF", (data) => {
    console.error(">>>>>>SUBSCRIBE_AFF: ", data);
    if (data.status === "success") {
      message.success(translation("_sub_aff_success"));
      store.commit("setIsSubAffDone", true);
    } else if (data.status === "failed") {
      message.error(translation("_sub_aff_failed"));
      store.commit("setIsSubAffDone", false);
    }
  });
});
onUnmounted(() => {
  emitter.off("SUBSCRIBE_AFF");
});

const connectButton = () => {
  const button_web: any = document.querySelector(".button_web");
  const connect_btn: any = document.querySelector(".connect_btn");
  //console.log("🚀 ~ connectButton ~ button_web:", button_web);
  // solana.openOptionsModal();
  if (button_web) {
    button_web?.click();
  }
  if (connect_btn) {
    connect_btn?.click();
  }
};

// Variable
let connection;

if (cluster == "devnet") {
  connection = new Connection(rpc_devnet, {
    commitment: "confirmed",
    confirmTransactionInitialTimeout: 80000,
  });
} else {
  connection = new Connection(rpc_mainnet, {
    commitment: "confirmed",
    confirmTransactionInitialTimeout: 80000,
  });
}
const rateTokenPoolSwap = ref({
  rateCurrent: "",
  growth: 0,
});

const balanceTokenQuote = computed(() => store.state.balanceTokenQuote);
const stakeCycle = computed(() => store.state.stakeCycle);
const solana = useSolana();
const poolInfo = computed(() => store.state.poolInfo);
const poolStakeInfo = computed(() => store.state.poolStakeInfo);
const poolInvestInfo = computed(() => store.state.poolInvestment);

const user = computed(() => store.state.user);
const reward = computed(() => store.state.infoReward);
const infoTokenQuote = ref({});
const statusClaim = ref("Claim");
const tab = computed(() => store.state.tabPowering);
const amountBaseTokenOnPool = ref<number>(0);
const secondsCircleSwap = ref<number>(5);
const secondsRestCircleSwap = ref<number>(15);
const statusSwap = ref<boolean>(true);
const textCountDownSwap = ref<string>("");
const rateCurrentSolxOnBaseToken = ref<number>(0);
const valuesLocalStore = ref<any>({
  KEY_REWARD: "ClaimReward",
  KEY_STAKING: "Staking",
  KEY_SWAP: "Swap",
  KEY_CLAIM_BONUS: "withdraw-bonus",
  KEY_STAKE_SOLX: "StakeSolx",
  KEY_WITHDRAW_PROFIT: "Withdraw-Profit",
  KEY_WITHDRAW_ORIGIN: "Withdraw-Origin",
  KEY_CLAIM_SOLX_INIT: "Claim-solx-init",
});
const keyLoading = ref<any>({
  LOADING_CLAIM_MINING: "loading_claim_mining",
  LOADING_CLAIM_PROFIT: "loading_claim_profit",
  LOADING_CLAIM_PRINCIPLE: "loading_claim_ciple",
  LOADING_CLAIM_SOLX_INIT: "loading_claim_solx_init",
  LOADING_CLAIM_INVESTMENT_PROFIT: "loading_claim_investment_profit",
  LOADING_CLAIM_INVESTMENT_PRINCIPLE: "loading_claim_investment_ciple",
});
const isAgreeStems = ref(false);
const isAgreeStemsBoost = ref(true);
const inputBoost = ref<any>(null);
const inputMint = ref<any>(null);
const inputSwap = ref<any>(null);

const keysLocalStore = ref("transactionBlockchain");

const amount = ref("");
const availableBaseToken = ref<boolean>(false);
const messageValidateAmountBaseToken = ref<string>("");

//
const amountSolx = ref("");
const availableSolx = ref<boolean>(false);
const messageValidateAmountSolx = ref<string>("");
//
const amountSolxStaking = ref("");
const availableSolxStaking = ref<boolean>(false);
const messageValidateAmountSolxStaking = ref<string>("");
//

const timeoutDisconnectSecond = ref(60 * 45); //seconds
const showNextClaim = ref<boolean>(true);

const message = useToastMessage();
const loading = ref<boolean>(false);
const loadingWithdrawOrigin = ref<boolean>(false);
const loadingWithdrawProfit = ref<boolean>(false);

const loadingClaim = ref<boolean>(false);
const boostOption = ref<string>("boost_selection_1");
const intervalCheckLoading = ref<any>(null);
const itemSelected = ref<string>("newest");

const optionsSlippage = [
  { id: 1, percent: "auto" },
  { id: 2, percent: "0.5" },
  { id: 2, percent: "1" },
  { id: 3, percent: "5" },
];

const optionsSlippageSelected = ref<any>({ id: 3, percent: "auto" });
const optionsSlippageSelectedTemp = ref<any>({ id: 1, percent: "auto" });

const interval = ref<any>(null);
const program = ref<anchor.Program<anchor.Idl>>();

const selectCycel = ref<any>(null);
const dataStakeSolx = ref<any>({
  arp: 0,
  your_stake: 0,
  total_stake: 0,
  principal: "Loading...",
  interest: "Loading...",
  receipts: [],
  closeTime: 0,
  interestNew: 0,
  closeTimeNew: 0,
});

const dataInvestmentSolx = ref<any>({
  arp: 0,
  your_stake: 0,
  total_stake: 0,
  principal: "Loading...",
  interest: "Loading...",
  receipts: [],
  closeTime: 0,
  interestNew: 0,
  closeTimeNew: 0,
});
const intervalDataStake = ref<any>(null);
const showMintNow = ref<boolean>(false);
const showBoostNow = ref<boolean>(false);
const showSwapNow = ref<boolean>(false);
const poolUrlSolana = ref<string>("");
const intervalBalanceUser = ref<any>();
//
const timeCountDown: string = process.env.VUE_APP_SYSTEM_COUNTDOWN_FUTURE;
const intervalCountdownFeture = ref<any>(null);
const statusCountdownFeture = ref<string>("");
const showModalConfirmWithdrawInterest = ref<boolean>(false);
const showModalConfirmWithdrawInterestContribute = ref<boolean>(false);
const showModalConfirmSwap = ref<boolean>(false);

//
const dataFee = ref<any>(null);
const calculateFeeTransaction = async () => {
  try {
    const feeNetwork = (await solana.getEstTransferFee()) || 0.000005;
    //console.log("🚀 ~ calculateFeeTransaction ~ feeNetwork:", feeNetwork);
    const feeSystem =
      feeNetwork +
      feeNetwork * (Number(store.state.infoReward?.est_fee_prc) / 100);
    //console.log("🚀 ~ calculateFeeTransaction ~ feeSystem:", feeSystem);

    dataFee.value = {
      feeNetwork,
      feeSystem,
    };
  } catch (err) {
    //console.log("🚀 ~ calculateFeeTransaction ~ err:", err);
  }
};

const formatNumber = (number) => {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number);
};

const updateFormattedValue = () => {
  amount.value = formatNumber(amount?.value);
};

const startCountdownFeture = () => {
  try {
    if (intervalCountdownFeture.value) {
      clearInterval(intervalCountdownFeture.value);
    }
    const targetTime = new Date(Number(timeCountDown) * 1000).getTime();
    //console.log("🚀 ~ startCountdownFeture ~ targetTime:", targetTime);
    intervalCountdownFeture.value = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetTime - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      //
      statusCountdownFeture.value =
        (days > 0 ? (days < 10 ? "0" + days : days) + ":" : "") +
        (hours < 10 ? "0" + hours : hours) +
        ":" +
        (minutes < 10 ? "0" + minutes : minutes) +
        ":" +
        (seconds < 10 ? "0" + seconds : seconds);

      if (distance < 0) {
        clearInterval(intervalCountdownFeture.value);
        statusCountdownFeture.value = null;
      }
    }, 1000);
  } catch (err) {
    //console.log("🚀 ~ startCountdown ~ err:", err);
  }
};
//

const maxAmountSwap = () => {
  const amount_max = poolInfo?.value?.max_amount_swap || 10;

  amountSolx.value = Math.min(
    Number(balanceTokenQuote.value),
    amount_max
  ).toString();

  onChangeAmountSolx({
    target: {
      value: balanceTokenQuote.value,
    },
  });
};

const maxAmountMining = async () => {
  amount.value =
    Number(store.state.balanceTokenBase.toString()) - 0.001 < 0
      ? "0"
      : (Number(store.state.balanceTokenBase.toString()) - 0.001).toString();
  onChangeAmount({
    target: {
      value: Number(amount.value) < 0 ? 0 : amount.value,
    },
  });
};

const maxAmountStake = () => {
  if (boostOption.value == "boost_selection_1") {
    amountSolxStaking.value =
      store.state.infoReward?.current_mint.toString() || "0";
  } else {
    const amount_max = poolStakeInfo?.value?.max_amount || 10000;

    amountSolxStaking.value = Math.min(
      amount_max,
      Number(balanceTokenQuote.value)
    ).toString();
  }

  onChangeAmountSolxStaking({
    target: {
      value: amountSolxStaking.value,
    },
  });
};
const maxAmountInvestment = () => {
  amountSolxInvestment.value = store.state.balanceTokenMintStake.toString();

  onChangeAmountSolxInvestment({
    target: {
      value: amountSolxInvestment.value,
    },
  });
};
const startCircle = () => {
  let countdownTime = secondsCircleSwap.value; // Countdown from 60 seconds
  let isCountingDown = true;

  // Function to start the 60-second countdown
  const countdown = () => {
    try {
      if (interval.value) {
        clearInterval(interval.value);
      }
      interval.value = setInterval(() => {
        try {
          if (countdownTime <= 0) {
            clearInterval(interval.value);

            if (amountSolx.value) {
              onChangeAmountSolx({
                target: {
                  value: amountSolx.value,
                },
              });
            }
            // Pause for 5 seconds
            setTimeout(() => {
              countdownTime = secondsCircleSwap.value; // Reset countdown time
              isCountingDown = true;
              countdown(); // Restart countdown
            }, secondsRestCircleSwap.value * 1000); // 5 seconds pause
          } else {
            --countdownTime;

            const hours = Math.floor(
              ((countdownTime * 1000) % (1000 * 60 * 60 * 24)) /
                (1000 * 60 * 60)
            );
            const minutes = Math.floor(
              ((countdownTime * 1000) % (1000 * 60 * 60)) / (1000 * 60)
            );
            const seconds = Math.floor(
              ((countdownTime * 1000) % (1000 * 60)) / 1000
            );
            textCountDownSwap.value =
              hours + "h " + minutes + "m " + seconds + "s ";
          }
        } catch (err) {
          //console.log("🚀 ~ interval ~ err:", err);
        }
      }, 1000);
    } catch (err) {
      //console.log("🚀 ~ countdown ~ err:", err);
    }
    // Update every second
  };

  countdown();
};
const changeTab = (tabSelect: string) => {
  store.commit("setPowering", tabSelect);
  // if (tabSelect == "loan") {
  //   setTimeout(() => {
  //     new Cleave(".input-boost", {
  //       numeral: true,
  //       numeralThousandsGroupStyle: "thousand",
  //       numeralDecimalScale: 4,
  //     });
  //   }, 500);
  // } else if (tabSelect == "sell") {
  //   setTimeout(() => {
  //     new Cleave(".input-swap", {
  //       numeral: true,
  //       numeralThousandsGroupStyle: "thousand",
  //       numeralDecimalScale: 4,
  //     });
  //   }, 500);
  // } else {
  //   setTimeout(() => {
  //     new Cleave(".input-mint", {
  //       numeral: true,
  //       numeralThousandsGroupStyle: "thousand",
  //       numeralDecimalScale: 4,
  //       numeralDecimalMark: ".",
  //       delimiter: ",",
  //     });
  //   }, 500);
  // }
};

const fetchPrice = async () => {
  try {
    const dataLocal = window.localStorage.getItem("setCurrentPrice");
    if (dataLocal) {
      const data = dataLocal ? JSON.parse(dataLocal) : null;
      const now = new Date();
      const time_save = new Date(data.date);
      const diff =
        (Math.abs(now.getTime()) - Math.abs(time_save.getTime())) / 1000;
      //console.log("🚀 ~ fetchPrice ~ diff:", diff);
      if (diff < 60) {
        store?.commit("setCurrentPrice", data);

        return;
      }
    }
    const prices: any = {};
    const url =
      "https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd";

    const responseSol = await fetch(url);
    const dataSol = await responseSol.json();
    const price = dataSol.solana?.usd;
    console.log(`Current SOL price: $${price}`);
    prices.usdc = 1;
    prices.sol = price;
    prices.date = new Date();
    // await writeFileSync('src/currentPrice.json', JSON.stringify(prices))

    store?.commit("setCurrentPrice", prices);

    window.localStorage.setItem("setCurrentPrice", JSON.stringify(prices));
  } catch (error) {
    console.error("Error fetching USDC price:", error);
  }
};

const getBaseTokenOnPoolCurrent = async () => {
  try {
    connection = getRpcConnection();

    const infoTokenA = await connection.getBalance(
      new PublicKey(store.state.poolInfo?.tokenAccountA)
    );
    //console.log("🚀 ~ getBaseTokenOnPoolCurrent ~ infoTokenA:", infoTokenA);
    if (!infoTokenA) return;
    amountBaseTokenOnPool.value = infoTokenA / 10 ** 9;
  } catch (err) {
    //console.log("🚀 ~ getBaseTokenOnPoolCurrent ~ err:", err);
  }
};

const rateCurrentPool = ref<number>(0);
const slippage = ref<number | string>("auto");
const amountSolOut = ref<any>({
  value: "loading",
});
const amountOutSolRate = ref<number | string>("");

const caculateOutSolAndSlippage = async () => {
  try {
    const amountSwap = amountSolx.value;
    connection = getRpcConnection();

    if (
      !store.state.poolInfo?.tokenAccountA ||
      !amountSwap ||
      Number(amountSwap) <= 0
    )
      return;

    amountSolOut.value = { value: "loading" };
    slippage.value = "auto";
    const tokenAccountB = await getAssociatedTokenAddress(
      new PublicKey(store.state.poolInfo.mintB),
      new PublicKey(store.state.poolInfo.tokenAccountB)
    );

    console.log(
      "🚀 ~ getRatePoolSwap ~ tokenAccountB:",
      tokenAccountB.toBase58()
    );

    const tokenAccountSOLX = tokenAccountB;

    const infoTokenSol = await connection.getBalance(
      new PublicKey(store.state.poolInfo.tokenAccountA)
    );
    //console.log("🚀 ~ getRatePoolSwap ~ infoTokenSol:", infoTokenSol);
    const infoTokenSOLX = await connection.getTokenAccountBalance(
      tokenAccountSOLX
    );

    const balance = {
      balanceSol: infoTokenSol / 10 ** 9,
      balanceSolx: Number(infoTokenSOLX.value.uiAmount),
    };
    const invariant = Number(balance.balanceSol) * Number(balance.balanceSolx);

    //console.log("🚀 ~ PoolService ~ invariant:", invariant);

    //console.log("🚀 ~ PoolService ~ balance:", balance);

    const amountOutSol =
      balance.balanceSol -
      invariant / (balance.balanceSolx + Number(amountSwap));
    amountOutSolRate.value =
      balance.balanceSol - invariant / (balance.balanceSolx + Number(1));
    //console.log("🚀 ~ PoolService ~ amountOutSolRate:", amountOutSolRate.value);
    amountSolOut.value = { value: amountOutSol };
    // slippage.value = Math.abs(
    //   ((amountOutSol - rateCurrent * Number(amountSwap)) /
    //     (rateCurrent * Number(amountSwap))) *
    //     100
    // );
  } catch (err) {
    //console.log("🚀 ~ caculateOutSolAndSlippage ~ err:", err);
  }
};

const caculateOutSolBitcoinX = async (amountSolx: string) => {
  try {
    //console.log("amountSolx.value", amountSolx);
    connection = getRpcConnection();

    if (
      !store.state.poolInfo?.tokenAccountA ||
      !amountSolx ||
      Number(amountSolx) <= 0
    )
      return;

    amountSolOut.value = { value: "loading" };
    slippage.value = "auto";
    const tokenAccountB = await getAssociatedTokenAddress(
      new PublicKey(store.state.poolInfo.mintB),
      new PublicKey(store.state.poolInfo.tokenAccountB)
    );

    console.log(
      "🚀 ~ getRatePoolSwap ~ tokenAccountB:",
      tokenAccountB.toBase58()
    );

    const tokenAccountSOLX = tokenAccountB;

    const infoTokenSol = await connection.getBalance(
      new PublicKey(store.state.poolInfo.tokenAccountA)
    );
    //console.log("🚀 ~ getRatePoolSwap ~ infoTokenSol:", infoTokenSol);
    const infoTokenSOLX = await connection.getTokenAccountBalance(
      tokenAccountSOLX
    );

    const balance = {
      balanceSol: infoTokenSol / 10 ** 9,
      balanceSolx: Number(infoTokenSOLX.value.uiAmount),
    };

    //console.log("🚀 ~ PoolService ~ balance:", balance);
    const priceSolx =
      (balance.balanceSol * Number(poolInfo?.value?.price_ratio)) /
      balance.balanceSolx;

    const amountOutSol = priceSolx * Number(amountSolx);
    amountSolOut.value = { value: amountOutSol };
    // setTimeout(() => {
    //   if (document.querySelector(".input-swap")) {
    //     new Cleave(".input-swap", {
    //       numeral: true,
    //       numeralThousandsGroupStyle: "thousand",
    //       numeralDecimalScale: 4,
    //     });
    //   }
    // }, 200);
  } catch (err) {
    //console.log("🚀 ~ caculateOutSolAndSlippage ~ err:", err);
  }
};
watch(
  () => amount.value,
  (newAmount, oldAmout) => {
    //console.log("newAmountnewAmount", newAmount);
    onChangeAmount({
      target: {
        value: newAmount,
      },
    });
  }
);
watch(
  () => amountSolxStaking.value,
  (newAmount, oldAmout) => {
    //console.log("newAmountnewAmount", newAmount);
    onChangeAmountSolxStaking({
      target: {
        value: newAmount,
      },
    });
  }
);
watch(
  () => amountSolx.value,
  (newAmount, oldAmout) => {
    //console.log("newAmountnewAmount", newAmount);
    onChangeAmountSolx({
      target: {
        value: newAmount,
      },
    });
  }
);
const balancePool = ref<any>({
  balanceSol: 0,
  balanceSolx: 0,
  // balanceSolxAdmin: Number(infoTokenSOLXAdmin.value.uiAmount),
});
const getRatePoolSwap = async () => {
  try {
    //console.log("rate", poolInfo?.value?.mintB);
    if (!store.state.poolInfo?.tokenAccountA) return;
    connection = getRpcConnection();

    const tokenAccountB = await getAssociatedTokenAddress(
      new PublicKey(store.state.poolInfo.mintB),
      new PublicKey(store.state.poolInfo.tokenAccountB)
    );

    // const tokenAccountBAdminHoldSolx = await getAssociatedTokenAddress(
    //   new PublicKey(store.state.poolInfo.mintB),
    //   new PublicKey(store.state.poolInfo.admin_hold_token)
    // );

    console.log(
      "🚀 ~ getRatePoolSwap ~ tokenAccountB:",
      tokenAccountB.toBase58()
    );

    const tokenAccountSOLX = tokenAccountB;

    const infoTokenUSDC = await connection.getBalance(
      new PublicKey(store.state.poolInfo.tokenAccountA)
    );
    //console.log("🚀 ~ getRatePoolSwap ~ infoTokenUSDC:", infoTokenUSDC);
    const infoTokenSOLX = await connection.getTokenAccountBalance(
      tokenAccountSOLX
    );
    // const infoTokenSOLXAdmin = await connection.getTokenAccountBalance(
    //   tokenAccountBAdminHoldSolx
    // );
    //console.log("🚀 ~ getRatePoolSwap ~ infoTokenSOLX:", infoTokenSOLX);

    // const rateCurrent =
    //   Number(infoTokenUSDC) / Number(infoTokenSOLX.value.amount);
    // //console.log("🚀 ~ getRatePoolSwap ~ rateCurrent:", rateCurrent);
    const balance = {
      balanceSol: infoTokenUSDC / 10 ** 9,
      balanceSolx: Number(infoTokenSOLX.value.uiAmount),
      // balanceSolxAdmin: Number(infoTokenSOLXAdmin.value.uiAmount),
    };
    balancePool.value = balance;
    // store.state.currentPrice?.sol
    const priceSolx =
      (balance.balanceSol * Number(poolInfo?.value?.price_ratio)) /
      balance.balanceSolx;
    //console.log("🚀 ~ getRatePoolSwap ~ priceSolx:", priceSolx);

    rateCurrentPool.value = priceSolx;
    console.log(
      "🚀 ~ getRatePoolSwap ~ store.state.price24h?.price:",
      store.state.price24h?.price
    );

    // const rateInit = Number(store.state.price24h?.price);
    const rateInit =
      (Number(poolInfo.value?.tokenASupplyInitPool) *
        Number(poolInfo?.value?.price_ratio)) /
      Number(poolInfo.value?.tokenBSupplyInitPool);
    //console.log("🚀 ~ getRatePoolSwap ~ rateInit:", rateInit);

    rateCurrentSolxOnBaseToken.value = priceSolx;
    rateTokenPoolSwap.value.rateCurrent = `1 SOLX = ${toFixedNumber(
      priceSolx,
      8
    )} SOL`;
    rateTokenPoolSwap.value.growth = ((priceSolx - rateInit) / rateInit) * 100;
    await getBalanceUser();
  } catch (err) {
    //console.log("🚀 ~ getRatePoolSwap ~ err:", err);
  }
};

const getBalanceUser = async () => {
  try {
    //console.log("getBalanceUser");

    //console.log("🚀 ~ getBalanceUser ~ isDeviceLaptop():", isDeviceLaptop());
    if (isDeviceLaptop() && !store.state.isTelegram) {
      if (solana?.wallet?.publicKey) {
        getBlanceAccountConnect(solana?.wallet?.publicKey.toString());
      }
    } else {
      if (solana?.session?.public_key) {
        getBlanceAccountConnect(solana?.session?.public_key.toString());
      }
    }
  } catch (err) {
    //console.log("🚀 ~ getBalanceUser ~ err:", err);
  }
};
const getTokenMetadata = async (addressToken: string) => {
  const MINT_TO_SEARCH = addressToken;
  try {
    connection = getRpcConnection();

    const metaplex = Metaplex.make(connection);

    const tokenAddress = new PublicKey(MINT_TO_SEARCH);

    // const data = await getMetadataNew(tokenAddress.toString())
    // //console.log("🚀 ~ getTokenMetadata ~ data:", data)

    let nft: any = await metaplex
      .nfts()
      .findByMint({ mintAddress: tokenAddress });

    return nft;
  } catch (err) {
    //console.log("🚀 ~ getInfoTokenForWal ~ err:", err);
  }
};
const getInfoTokenQuote = async (address: string) => {
  try {
    if (!store.state.poolInfo?.tokenAccountA) return;

    const infoToken = await getTokenMetadata(address);
    infoTokenQuote.value = infoToken;
  } catch (err) {
    //console.log("🚀 ~ getInfoTokenQuote ~ err:", err);
  }
};

const setLocalstoreReward = (key: string, value: string) => {
  window.localStorage.setItem(key, value);
};

const claimReward = async () => {
  loadingClaim.value = true;

  if (notAllowClaim()) {
    loadingClaim.value = false;
    message.warning(translation("claim_reward_mining_error"), {
      duration: 3000,
    });
    return;
  }

  if (isDeviceLaptop() && store.state.isTelegram == false) {
    await claimRewardWebsite();
    return;
  } else {
    await claimRewardMobile();
  }
};
const claimRewardMobile = async () => {
  try {
    setLocalstoreReward(
      keysLocalStore.value,
      valuesLocalStore.value.KEY_REWARD
    );
    const feeaEs = (await solana.getEstTransferFee()) || 0.000005;
    const feeAmount =
      feeaEs + feeaEs * (Number(store.state.infoReward?.est_fee_prc) / 100);

    //console.log("🚀 ~ claimRewardMobile ~ feeAmount:", feeAmount);

    const url = await solana.signTransaction(
      poolInfo.value?.walletTransferMining || poolInfo.value?.app_address,
      feeAmount,
      token_fee || "So11111111111111111111111111111111111111112",
      false,
      "",
      generateTypeMemo(methodCrawler.value.CLAIM_MINT)
    );
    if (!url) return;

    window.open(url);
    solana.closeMiniApp();
  } catch (err) {
    //console.log("🚀 ~ claimRewardMobile ~ err:", err);
  }
};
const loanExpand = ref<boolean>(true);
const methodCrawler = ref<any>({
  MINT: "mint",
  BOOST: "boost",
  SWAP: "swap",
  CLAIM_MINT: "fee-claim-solx-mint",
  CLAIM_BOOST_INTEREST: "fee-claim-solx-interest",
  CLAIM_BOOST_PRINCIPLE: "fee-claim-solx-principle",
  FEE_CLAIM_SOLX_INIT: "fee-claim-solx-init",
  INVESTMENT: "investment",
  CLAIM_INVESTMENT_INTEREST: "claim-investment-interest",
  CLAIM_INVESTMENT_PRINCIPLE: "claim-investment-principle",
});
const stakingToBlockchain = async () => {
  try {
    const check = validateAmountMinting();
    if (!check) return;
    loading.value = true;

    if (store.state.isSubAffDone) {
      if (
        Number(store.state.balanceTokenBase.toString()) - Number(amount.value) <
        0.001
      ) {
        message.warning(
          translation("The_remaining_money_is_not_enough_to_pay_the_fee")
        );
        loading.value = false;
        return;
      }
      if (isDeviceLaptop() && !store.state.isTelegram) {
        await stakingWebsite();
      } else {
        await stakingMobile();
      }
      loading.value = false;
    } else {
      store.commit("setOpenInviteCodeModal", true);
      loading.value = false;
    }
  } catch (err) {
    loading.value = false;
    rejectCrawler();

    //console.log("🚀 ~ stakingToBlockchain ~ err:", err);
  }
};
const stakingMobile = async () => {
  try {
    if (!poolInfo.value?.app_address) {
      message.loading(translation("app_address_not_found"), {
        duration: 15000,
      });
      return;
    }
    const url = await solana.signTransaction(
      poolInfo.value?.app_address,
      +amount.value,
      poolInfo.value?.mintA,
      false,
      "",
      generateTypeMemo(methodCrawler.value.MINT)
    );
    if (!url) return;
    setLocalstoreReward(
      keysLocalStore.value,
      valuesLocalStore.value.KEY_STAKING
    );
    window.localStorage.setItem("amount_mining", amount.value.toString());
    window.open(url?.toString());
    solana.closeMiniApp();
  } catch (err) {
    //alert(JSON.stringify(err, null, 2));
    //console.log("🚀 ~ stakingMobile ~ err:", err);
    store.commit("setLog", { err });
    return "";
  }
};

const getAddress = () => {
  let address: any = null;
  if (isDeviceLaptop() && !store.state.isTelegram) {
    const { publicKey } = useWallet();

    address = publicKey.value?.toString() || null;
  } else {
    address = store.state.sessionSolana?.public_key?.toString() || null;
  }
  return address;
};

const callCrawlerHaveTransaction = (data: any) => {
  try {
    if (isDeviceLaptop()) {
      const { publicKey } = useWallet();

      if (publicKey.value) {
        callCrawler({
          address: publicKey.value.toString(),
          ...data,
        });
      }
      return;
    } else {
      if (store.state.sessionSolana?.public_key) {
        callCrawler({
          address: store.state.sessionSolana?.public_key.toString(),
          ...data,
        });
      }
      return;
    }
  } catch (err) {
    //console.log("🚀 ~ callCrawlerHaveTransaction ~ err:", err);
  }
};
const callEstMint = async (number) => {
  try {
    await estInterestMint(number);
    // new Cleave(".input-mint", {
    //   numeral: true,
    //   numeralThousandsGroupStyle: "thousand",
    //   numeralDecimalScale: 4,
    //   numeralDecimalMark: ".",
    //   delimiter: ",",
    // });
  } catch (err) {
    //console.log("🚀 ~ onChangeAmount ~ err:", err);
  }
};
const onChangeAmount = debounce((e: any) => {
  // const e = event.clone();
  //console.log("🚀 ~ onChangeAmount ~ e:", e);
  if (e.target?.value == "") {
    availableBaseToken.value = false;
    messageValidateAmountBaseToken.value = ``;
    return;
  }

  const amount_min = poolInfo?.value?.min_amount || 0.001;
  //console.log("🚀 ~ onChangeAmount ~ clonedValue:", e.target?.value);
  const newNumber = e.target?.value.replaceAll(",", "");
  const number = Number(newNumber);
  callEstMint(newNumber);

  const amount_max = poolInfo?.value?.max_amount || 1000;
  //console.log("number", number);
  amount.value = newNumber;

  if (store.state.user?.token) {
    if (number < amount_min) {
      messageValidateAmountBaseToken.value =
        translation(`Amount_greater_than`) + `${amount_min}`;
      availableBaseToken.value = false;
      return;
    }
    if (number > amount_max) {
      messageValidateAmountBaseToken.value =
        translation(`Amount_less_than`) + ` ${amount_max}`;
      availableBaseToken.value = false;
      return;
    }
    if (number > store.state.balanceTokenBase) {
      messageValidateAmountBaseToken.value = translation(
        `Balance_is_insufficient`
      );
      availableBaseToken.value = false;
      return;
    }
  }

  //console.log("number", number);
  messageValidateAmountBaseToken.value = ``;
  availableBaseToken.value = true;
}, 300);

const onChangeAmountSolx = debounce((e: any) => {
  if (e.target.value == "") {
    availableSolx.value = false;
    messageValidateAmountSolx.value = ``;
    return;
  }
  console.log(
    "🚀 ~ onChangeAmountSolx ~ balanceTokenQuote.value:",
    balanceTokenQuote.value
  );

  const newNumber = e.target.value.replaceAll(",", "");
  caculateOutSolBitcoinX(newNumber);
  amountSolx.value = newNumber;

  const amount = Number(newNumber);
  const amount_min = poolInfo?.value?.min_amount_swap || 10;
  const amount_max = poolInfo?.value?.max_amount_swap || 10;
  if (store.state.user?.token) {
    if (store.state.swapInfo?.swap_enable == false) {
      messageValidateAmountSolx.value = translation(`Swap_Limit`);
      availableSolx.value = false;
      startCountdownSwap();
      return;
    }
    if (amount <= 0) {
      messageValidateAmountSolx.value =
        translation(`Amount_greater_than`) + `${amount_min}`;
      availableSolx.value = false;
      return;
    }
    if (amount < amount_min) {
      messageValidateAmountSolx.value =
        translation(`Amount_greater_than`) + `${amount_min}`;
      availableSolx.value = false;
      return;
    }
    if (amount > amount_max) {
      messageValidateAmountSolx.value =
        translation(`Amount_less_than`) + `${amount_max}`;
      availableSolx.value = false;
      return;
    }
    if (amount > balanceTokenQuote.value) {
      messageValidateAmountSolx.value = translation(`Balance_is_insufficient`);
      availableSolx.value = false;

      return;
    }
  }
  availableSolx.value = true;
  messageValidateAmountSolx.value = "";
  messageValidateAmountSolxSlippage.value = "";

  // amountSolx.value = newNumber;
}, 300);

const onChangeAmountSolxStaking = debounce((e: any) => {
  try {
    if (e.target.value == "") {
      availableSolxStaking.value = false;
      amountSolxStaking.value = "";
      messageValidateAmountSolxStaking.value = ``;
      return;
    }
    console.log(
      "🚀 ~ onChangeAmountSolxStaking ~ store.state.balanceTokenQuote:",
      store.state.balanceTokenQuote
    );
    const newNumber = e.target.value.replaceAll(",", "");
    amountSolxStaking.value = newNumber;
    fetchDataStakeSolxAfterInput(newNumber);

    const amount = Number(newNumber);
    const amount_min = poolStakeInfo?.value?.min_amount || 1;
    const amount_max = poolStakeInfo?.value?.max_amount || 10000;
    console.log(
      "🚀 ~ onChangeAmountSolxStaking ~ newNumber:",
      Number(newNumber)
    );
    amountSolxStaking.value = newNumber;

    if (store.state.user?.token) {
      if (amount <= 0) {
        messageValidateAmountSolxStaking.value =
          translation(`Amount_greater_than`) + `${amount_min}`;
        availableSolxStaking.value = false;
        return;
      }
      if (amount < amount_min) {
        messageValidateAmountSolxStaking.value =
          translation(`Amount_greater_than`) + `${amount_min}`;
        availableSolxStaking.value = false;
        return;
      }
      if (amount > amount_max) {
        messageValidateAmountSolxStaking.value =
          translation(`Amount_less_than`) + `${amount_max}`;
        availableSolxStaking.value = false;
        return;
      }

      if (boostOption.value == "boost_selection_1") {
        if (amount > store.state.infoReward?.current_mint) {
          messageValidateAmountSolxStaking.value = translation(
            `Balance_is_insufficient`
          );
          availableSolxStaking.value = false;

          return;
        }
      } else {
        if (amount > store.state.balanceTokenQuote) {
          messageValidateAmountSolxStaking.value = translation(
            `Balance_is_insufficient`
          );
          availableSolxStaking.value = false;

          return;
        }
      }
    }
    availableSolxStaking.value = true;
    messageValidateAmountSolxStaking.value = "";
  } catch (err) {
    //console.log("🚀 ~ onChangeAmountSolxStaking ~ err:", err);
  }
}, 300);

const validateAmountMinting = () => {
  const amount_min = poolInfo?.value?.min_amount || 0.001;
  const amount_max = poolInfo?.value?.max_amount || 1000;
  const number = Number(amount.value.replaceAll(",", ""));
  if (!amount.value) return validateAmountMinting();
  if (number < amount_min) {
    messageValidateAmountBaseToken.value =
      translation(`Amount_greater_than`) + `${amount_min}`;
    availableBaseToken.value = false;
    return false;
  }
  if (number > amount_max) {
    messageValidateAmountBaseToken.value =
      translation(`Amount_less_than`) + `${amount_max}`;
    availableBaseToken.value = false;
    return false;
  }
  if (number > store.state.balanceTokenBase) {
    messageValidateAmountBaseToken.value = translation(
      "Balance_is_insufficient"
    );
    availableBaseToken.value = false;
    return false;
  }
  return true;
};
const validateAmountBoost = () => {
  const amount_min = poolStakeInfo?.value?.min_amount || 1;
  const amount_max = poolStakeInfo?.value?.max_amount || 10000;
  const number = Number(amountSolxStaking.value.replaceAll(",", ""));

  if (!amountSolxStaking.value) return validateAmountBoost();
  if (number < amount_min) {
    messageValidateAmountBaseToken.value =
      translation(`Amount_greater_than`) + `${amount_min}`;
    availableBaseToken.value = false;
    return false;
  }
  if (number > amount_max) {
    messageValidateAmountBaseToken.value =
      translation(`Amount_less_than`) + `${amount_max}`;
    availableBaseToken.value = false;
    return false;
  }
  if (number > store.state.balanceTokenQuote) {
    messageValidateAmountBaseToken.value = translation(
      "Balance_is_insufficient"
    );
    availableBaseToken.value = false;
    return false;
  }
  return true;
};
const validateAmountSwap = () => {
  const amount_min = poolInfo?.value?.min_amount_swap || 10;
  const amount_max = poolInfo?.value?.max_amount_swap || 10;
  const number = Number(amountSolx.value.replaceAll(",", ""));
  if (!amountSolx.value) return validateAmountSwap();
  if (number < amount_min) {
    messageValidateAmountBaseToken.value =
      translation(`Amount_greater_than`) + `${amount_min}`;
    availableBaseToken.value = false;
    return false;
  }
  if (number > amount_max) {
    messageValidateAmountBaseToken.value =
      translation(`Amount_less_than`) + `${amount_max}`;
    availableBaseToken.value = false;
    return false;
  }
  if (number > store.state.balanceTokenQuote) {
    messageValidateAmountBaseToken.value = translation(
      "Balance_is_insufficient"
    );
    availableBaseToken.value = false;
    return false;
  }
  return true;
};

const availableSolxInvestment = ref<boolean>(false);
const messageValidateAmountSolxInvestment = ref<string>("");
const amountSolxInvestment = ref<string>("");

const onChangeAmountSolxInvestment = (e: any) => {
  if (e.target.value == "") {
    availableSolxInvestment.value = false;

    messageValidateAmountSolxInvestment.value = ``;
    return;
  }
  // if (isNaN(Number(e.target.value))) {
  //   // messageValidateAmountSolxStaking.value = translation("Input not number");
  //   availableSolxInvestment.value = false;
  //   messageValidateAmountSolxInvestment.value = "";
  //   amountSolxInvestment.value = "";

  //   return;
  // }
  const amount = Number(e.target.value);
  const amount_min = poolStakeInfo?.value?.min_amount || 1;
  const amount_max = poolStakeInfo?.value?.max_amount || 10000;

  if (isDeviceLaptop()) {
    const { publicKey } = useWallet();

    if (!publicKey.value) {
      messageValidateAmountSolxInvestment.value = translation(
        "Walle_no_connected_Please_connect"
      );
      availableSolxInvestment.value = false;

      return;
    }
  } else {
    if (!store.state.sessionSolana.public_key) {
      messageValidateAmountSolxInvestment.value = translation(
        "Walle_no_connected_Please_connect"
      );
      availableSolxInvestment.value = false;
      return;
    }
  }
  if (amount <= 0) {
    messageValidateAmountSolxInvestment.value =
      translation(`Amount_greater_than`) + `${amount_min}`;
    availableSolxInvestment.value = false;
    return;
  }
  if (amount < amount_min) {
    messageValidateAmountSolxInvestment.value =
      translation(`Amount_greater_than`) + `${amount_min}`;
    availableSolxInvestment.value = false;
    return;
  }
  if (amount > amount_max) {
    messageValidateAmountSolxInvestment.value =
      translation(`Amount_less_than`) + `${amount_max}`;
    availableSolxInvestment.value = false;
    return;
  }

  if (amount > store.state.balanceTokenMintStake) {
    messageValidateAmountSolxInvestment.value = translation(
      `Balance_is_insufficient`
    );
    availableSolxInvestment.value = false;

    return;
  }

  availableSolxInvestment.value = true;
  messageValidateAmountSolxInvestment.value = "";

  amountSolxInvestment.value = e.target.value;
};

watch(boostOption, (bNew, bOld) => {
  onChangeAmountSolxStaking({
    target: {
      value: amountSolxStaking.value,
    },
  });
});

const notAllowClaim = () =>
  Number(store.state.infoReward?.current_mint) == 0 ||
  store.state.infoReward?.claim_status == false;

const intervalClaimSol = ref<any>();

const startCountdown = () => {
  try {
    if (!store.state.infoReward || !store.state.infoReward?.next_claim) {
      showNextClaim.value = false;
      statusClaim.value = "Claim";

      return;
    }
    if (store.state.infoReward?.claim_status == true) {
      statusClaim.value = "Claim";
      return;
    }
    // if (!store.state.infoReward?.next_claim) {
    //   statusClaim.value = "Disable";
    //   return;
    // }
    if (intervalClaimSol.value) {
      clearInterval(intervalClaimSol.value);
    }
    if (store.state.infoReward?.next_claim) {
      const targetTime = new Date(store.state.infoReward?.next_claim).getTime();
      //console.log("🚀 ~ startCountdown ~ targetTime:", targetTime);
      intervalClaimSol.value = setInterval(() => {
        const now = new Date().getTime();
        const distance = targetTime - now;
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        //
        statusClaim.value =
          (days > 0 ? (days < 10 ? "0" + days : days) + ":" : "") +
          (hours < 10 ? "0" + hours : hours) +
          ":" +
          (minutes < 10 ? "0" + minutes : minutes) +
          ":" +
          (seconds < 10 ? "0" + seconds : seconds);

        if (distance < 0) {
          const { result, refetch, onResult } = provideApolloClient(userClient)(
            () => useQuery(getClaim)
          );
          refetch();
          showNextClaim.value = false;
          clearInterval(intervalClaimSol.value);
          statusClaim.value = "Claim";
        }
      }, 1000);
    }
  } catch (err) {
    //console.log("🚀 ~ startCountdown ~ err:", err);
  }
};

//
const countdownSwap = ref<any>();
const textStatusClaim = ref<string>("");
const startCountdownSwap = () => {
  if (countdownSwap.value) {
    clearInterval(countdownSwap.value);
  }
  if (
    !store.state.poolInfo?.system_enable_limit_swap_user ||
    !store.state.swapInfo?.reset_swap_limit_time
  ) {
    return;
  }
  const sevenHours = 60 * 60 * 7 * 1000;
  const targetTime =
    new Date(store.state.swapInfo?.reset_swap_limit_time).getTime() -
    sevenHours;
  countdownSwap.value = setInterval(() => {
    const now = new Date().getTime();
    const distance = targetTime - now;
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    //
    // (days < 10 ? "0" + days : days) +
    // ":" +
    textStatusClaim.value =
      (hours < 10 ? "0" + hours : hours) +
      ":" +
      (minutes < 10 ? "0" + minutes : minutes) +
      ":" +
      (seconds < 10 ? "0" + seconds : seconds);
    if (distance < 0) {
      textStatusClaim.value = "";
    }
  }, 1000);
};
//
const messageValidateAmountSolxSlippage = ref<string>("");
const swapSOLXToSOL = async () => {
  const check = validateAmountSwap();
  if (!check) return;
  loading.value = true;
  if (store.state.isSubAffDone) {
    if (isDeviceLaptop() && !store.state.isTelegram) {
      await swapWebsite();
    } else {
      await swapMobile();
    }

    loading.value = false;
  } else {
    store.commit("setOpenInviteCodeModal", true);
    loading.value = false;
  }
};
const swapMobile = async () => {
  try {
    if (!statusSwap.value) {
      //alert("No swap");
      return;
    }
    const feeaEs = (await solana.getEstTransferFee()) || 0;
    //console.log("🚀 ~ claimRewardWebsite ~ feeaEs:", feeaEs);
    const feeAmount =
      feeaEs + feeaEs * (Number(store.state.infoReward?.est_fee_prc) / 100);
    console.log(
      "🚀 ~ swapMobile ~ store.state.infoReward?.est_fee_prc:",
      store.state.infoReward?.est_fee_prc
    );

    const url = await solana.swapBeMobile(
      poolInfo.value.tokenAccountA,
      feeAmount,
      token_fee || "So11111111111111111111111111111111111111112",
      poolInfo.value.tokenAccountB || poolInfo.value.wallet_burn_token,
      Number(amountSolx.value),
      poolInfo.value.mintB,
      generateTypeMemo(methodCrawler.value.SWAP)
    );
    if (!url) return;
    setLocalstoreReward(keysLocalStore.value, valuesLocalStore.value.KEY_SWAP);
    //window.close();

    window.open(url?.toString());
    solana.closeMiniApp();

    return url;
  } catch (err) {
    store.commit("setLog", { err });

    //console.log("🚀 ~ swapSOLXToSOL ~ err:", err);
  }
};
const swapSOLXToSOLJupiter = async () => {
  // try {
  //   connection = getRpcConnection();
  //   // const connection = new Connection("https://api.mainnet-beta.solana.com");
  //   if (isDeviceLaptop()) {
  //     const tx = await solana.swapWebsiteJupiter(Number(amountSolx.value));
  //     //console.log("🚀 ~ swapSOLXToSOL2 ~ tx:", tx);
  //     if (!tx) {
  //       loading.value = false;
  //       return;
  //     }
  //     // sign the transaction
  //     // transaction.sign([wallet.payer]);
  //     store.commit("setPlacement", "top-right");
  //       duration: 15000,
  //     });
  //         blockhash: lastestBlockHash.blockhash,
  //         lastValidBlockHeight: lastestBlockHash.lastValidBlockHeight,
  //         signature: tx,
  //       },
  //       "confirmed"
  //     );
  //     // await connection.confirmTransaction(tx, "finalized");
  //     amountSolx.value = "";
  //     isAgreeStems.value = false;
  //     loading.value = false;
  //     await writeLogSwapToBE(tx);
  //   } else {
  //     //console.log("mobile");
  //     if (!statusSwap.value) {
  //       //alert("No swap");
  //       return;
  //     }
  //     const url = await solana.swapJupiterMobile(Number(amountSolx.value));
  //     if (!url) return;
  //     setLocalstoreReward(
  //       keysLocalStore.value,
  //       valuesLocalStore.value.KEY_SWAP
  //     );
  //     //window.close();
  //     window.location.href = url?.toString();
  //     return url;
  //   }
  // } catch (err) {
  //   message.error(translation("swap_faild_please_wait_a_moment"), {
  //     duration: 5000,
  //   });
  //   //console.log("🚀 ~ swapSOLXToSOL2 ~ err:", err);
  // }
};

const caculateTVLPool = async () => {
  const balanceA = amountBaseTokenOnPool.value;
  const priceA = store.state?.currentPrice?.usdc;

  const tvlA = balanceA * priceA;
  const tvlB = tvlA;

  const TVL = tvlA + tvlB;
  store.commit("setTVL", TVL);
};

const checkSessionConnectMobile = () => {
  const timeConnect = window.localStorage.getItem("timeout_connect_mobile");
  if (timeConnect) {
    const time_connect_before = JSON.parse(timeConnect);
    const date_time_connect_before = new Date(time_connect_before);
    const now = new Date();
    const diff =
      (Math.abs(now.getTime()) - Math.abs(date_time_connect_before.getTime())) /
      1000;
    return diff > timeoutDisconnectSecond.value;
  }
  return false;
};

//api graphql

const getPoolSwapInfo = async () => {
  //console.log("get pool");

  try {
    const { result, refetch, onResult } = provideApolloClient(basicClient)(() =>
      useQuery(getPoolInfo)
    );
    onResult(async () => {
      store.commit("setInfoPool", result.value?.poolInfo?.result);
      countHaving();
      if (poolInfo.value?.circle_swap) {
        secondsCircleSwap.value = Number(poolInfo.value?.circle_swap);
        secondsRestCircleSwap.value = Number(
          poolInfo.value?.stop_per_circle_swap
        );
      }
      poolUrlSolana.value = `https://solscan.io/account/${poolInfo.value?.tokenSwap}`;
    });
    refetch();
  } catch (err) {
    store.commit("setLog", { err });

    //console.log("🚀 ~ getClaim ~ err:", err);
  }
};

const claimSolx = async (hash: string) => {
  try {
    const { mutate } = provideApolloClient(userClient)(() =>
      useMutation(claim, {
        variables: {
          hashData: hash,
        },
      })
    );
    const res = await mutate();
    //console.log(">>>>>>>>>>>>>>>>>>>>>>>>connect be res", res);
  } catch (err) {
    //console.log("🚀 ~ claimSolx ~ err:", err);
  }
};

const getSwapInfo = () => {
  try {
    if (!store.state.user.token) return;
    const { result, refetch, onResult } = provideApolloClient(userClient)(() =>
      useQuery(swapInfo)
    );
    refetch();
    onResult(() => {
      store.commit("setSwapInfo", result.value?.swapInfo?.result);
    });
  } catch (err) {
    //console.log("🚀 ~ getSwapInfo ~ err:", err);
  }
};

const stakingPool = async (hash: string, amount = "0") => {
  //console.log("🚀 ~ stakingPool ~ hash:", hash);

  try {
    const { mutate } = provideApolloClient(userClient)(() =>
      useMutation(staking, {
        variables: {
          topUpInput: {
            hash,
            amount,
          },
        },
      })
    );
    const res = await mutate();
    //console.log(">>>>>>>>>>>>>>>>>>>>>>>>connect be res stakingPool", res);
  } catch (err) {
    //console.log("🚀 ~ getClaim ~ err:", err);
  }
};

const getClaimInfo = () => {
  //console.log("🚀 ~ getClaimInfo ~ getClaimInfo:");

  if (!store.state.user?.token) {
    store.commit("setInfoReward", null);
    return;
  }
  try {
    const { result, refetch, onResult, onError } = provideApolloClient(
      userClient
    )(() => useQuery(getClaim));
    onError(async (err, context) => {
      //console.log("(err as any).message", (err as any).message);

      if (
        (err as any).message == "Token has expired" ||
        (err as any).message == "Unauthorized"
      ) {
        const data = parseJwt(store.state.user.token as string);
        const now = new Date().getTime();
        //console.log("🚀 ~ onError ~ data:", data, now > data.exp * 1000);
        if (now > data.exp * 1000) {
          if (isDeviceLaptop()) {
            const wallet = useWallet();

            //console.log("🚀 ~ onError ~ data:", data);
            await login({
              wallet_address: wallet?.publicKey.value?.toBase58(),
            });
          } else {
            await login({
              wallet_address: solana?.session?.public_key,
            });
          }
        }
      }
    });
    onResult(() => {
      //console.log("setInfoReward", result.value?.getClaim?.result);

      store.commit("setInfoReward", result.value?.getClaim?.result);

      startCountdown();
    });
    refetch();
  } catch (err) {
    store.commit("setLog", { err });

    //console.log("🚀 ~ getClaim ~ err:", err);
  }
};

const writeLogSwapToBE = async (hash: string) => {
  try {
    //console.log("logg writeLogSwapToBE");

    const { mutate } = provideApolloClient(userClient)(() =>
      useMutation(swapBE, {
        variables: {
          swapInput: {
            signature: hash,
            poolId: poolInfo.value.id,
            percentSlippage: optionsSlippageSelected.value.percent,
          },
        },
      })
    );
    const res = await mutate();
    //console.log(">>>>>>>>>>>>>>>>>>>>>>>>connect be res", res);
  } catch (err) {
    //console.log("🚀 ~ writeLogSwap ~ err:", err);
  }
};

// mobile

//website

const stakingWebsite = async () => {
  try {
    connection = getRpcConnection();

    const tx = await solana.stakingWebsite(
      poolInfo.value?.app_address,
      Number(amount.value),
      poolInfo.value?.mintA,
      generateTypeMemo(methodCrawler.value.MINT)
    );
    if (!tx) return;
    store.commit("setPlacement", "top-right");

    try {
      connection.confirmTransaction(tx, "finalized");
    } catch (err) {
      //console.log("🚀 ~ stakingWebsite ~ err:", err);
    }
    amount.value = "";
    isAgreeStems.value = false;
  } catch (err: any) {
    //console.log("🚀 ~ stakingWebsite ~ err:", err);

    message.error(translation("transaction_cancle"), {
      duration: 5000,
    });
    throw new Error((err as any).name.toString());
  }
};

const checkSlippageAfterSwap = async () => {
  try {
    await caculateOutSolAndSlippage();
    const percent =
      (Number(amountSolOut.value) -
        rateCurrentPool.value * Number(amountSolx.value)) /
      (rateCurrentPool.value * Number(amountSolx.value));
    //console.log("🚀 ~ checkSlippageAfterSwap ~ percent:", percent);
    console.log(
      "🚀 ~ checkSlippageAfterSwap ~  optionsSlippageSelected.value?.percent:",
      optionsSlippageSelected.value?.percent
    );
    if (optionsSlippageSelected.value?.percent == "auto")
      return { status: true };
    if (
      Math.abs(percent) >
      Number(optionsSlippageSelected.value?.percent) / 100
    )
      return {
        status: false,
        percentCurrent: percent / Number(amountSolx.value),
      };

    return { status: true };
  } catch (err) {
    //console.log("🚀 ~ checkSlippageAfterSwap ~ err:", err);
  }
};

const swapWebsite = async () => {
  try {
    //
    connection = getRpcConnection();

    //
    const feeaEs = (await solana.getEstTransferFee()) || 0.000005;
    //console.log("🚀 ~ claimRewardWebsite ~ feeaEs:", feeaEs);
    const feeAmount =
      feeaEs + feeaEs * (Number(store.state.infoReward?.est_fee_prc) / 100);
    //console.log("🚀 ~ swapWebsite ~ feeAmount:", feeAmount);
    console.log({
      a: poolInfo.value.tokenAccountA,
      b: feeAmount,
      c: token_fee || "So11111111111111111111111111111111111111112",
      d: poolInfo.value.tokenAccountB || poolInfo.value.wallet_burn_token,
      f: Number(amountSolx.value),
      g: poolInfo.value.mintB,
    });
    if (feeAmount <= 0) {
      message.error(translation("Fees_cannot_be_estimated"), {
        duration: 3000,
      });
      loading.value = false;
      return;
    }

    if (Number(store.state.balanceTokenBase.toString()) - feeAmount < 0.001) {
      message.warning(
        translation("The_remaining_money_is_not_enough_to_pay_the_fee")
      );
      loading.value = false;

      return;
    }
    const tx = await solana.swapBeWebsite(
      poolInfo.value.tokenAccountA,
      feeAmount,
      token_fee || "So11111111111111111111111111111111111111112",
      poolInfo.value.tokenAccountB || poolInfo.value.wallet_burn_token,
      Number(amountSolx.value),
      poolInfo.value.mintB,
      generateTypeMemo(methodCrawler.value.SWAP)
    );
    if (!tx) return;

    store.commit("setPlacement", "top-right");

    // message.loading(translation("swap_confirmed_please_wait_a_moment"), {
    //   duration: 15000,
    // });
    loading.value = false;
    amountSolx.value = "";
    isAgreeStems.value = false;
    try {
      connection.confirmTransaction(tx, "finalized");
    } catch (err) {
      //console.log("🚀 ~ stakingWebsite ~ err:", err);
    }
  } catch (err) {
    //console.log("🚀 ~ swapWebsite ~ err:", err);
  }
};

const claimRewardWebsite = async () => {
  try {
    connection = getRpcConnection();
    const feeaEs = (await solana.getEstTransferFee()) || 0.000005;
    //console.log("🚀 ~ claimRewardWebsite ~ feeaEs:", feeaEs);
    const feeAmount =
      feeaEs + feeaEs * (Number(store.state.infoReward?.est_fee_prc) / 100);

    //console.log("🚀 ~ claimRewardWebsite ~ feeAmount:", feeAmount);
    if (feeAmount <= 0) {
      message.error(translation("Fees_cannot_be_estimated"), {
        duration: 3000,
      });
      window.localStorage.setItem(
        keyLoading.value.LOADING_CLAIM_MINING,
        "false"
      );
      loadingClaim.value = false;
      return;
    }

    if (Number(store.state.balanceTokenBase.toString()) - feeAmount < 0.001) {
      message.warning(
        translation("The_remaining_money_is_not_enough_to_pay_the_fee")
      );
      window.localStorage.setItem(
        keyLoading.value.LOADING_CLAIM_MINING,
        "false"
      );
      loadingClaim.value = false;

      return;
    }
    window.localStorage.setItem(keyLoading.value.LOADING_CLAIM_MINING, "true");

    const tx = await solana.chargeFeeWhenClaimReward(
      poolInfo.value?.walletTransferMining || poolInfo.value?.app_address,
      feeAmount,
      token_fee || "So11111111111111111111111111111111111111112",
      generateTypeMemo(methodCrawler.value.CLAIM_MINT)
    );
    if (!tx) return;
    store.commit("setPlacement", "top-right");

    // message.loading(translation("claim_solx_mined"), {
    //   duration: 15000,
    // });
    //console.log("🚀 ~ claimRewardWebsite ~ tx:", tx);
    try {
      connection.confirmTransaction(tx, "finalized");
    } catch (err) {
      //console.log("🚀 ~ stakingWebsite ~ err:", err);
    }
  } catch (err: any) {
    window.localStorage.setItem(keyLoading.value.LOADING_CLAIM_MINING, "false");
    loadingClaim.value = false;
    //console.log("🚀 ~ claimRewardWebsite ~ err:", err);
    if (err.name == "WalletSendTransactionError") {
      message.error(translation("transaction_cancle"), {
        duration: 5000,
      });
    }
  }
};

//contract
function wait(timeout: number) {
  return new Promise((resolve) => setTimeout(resolve, timeout));
}
const stakingSolx = async () => {
  try {
    const check = validateAmountBoost();
    if (!check) {
      return;
    }

    loading.value = true;
    if (store.state.isSubAffDone) {
      //

      if (isDeviceLaptop() && !store.state.isTelegram) {
        //console.log("poolStakeInfo.value", poolStakeInfo.value);
        const feeaEs = (await solana.getEstTransferFee()) || 0.000005;
        //console.log("🚀 ~ claimRewardWebsite ~ feeaEs:", feeaEs);
        const feeAmount =
          feeaEs + feeaEs * (Number(store.state.infoReward?.est_fee_prc) / 100);
        //console.log("🚀 ~ swapWebsite ~ feeAmount:", feeAmount);
        if (feeAmount <= 0) {
          message.error(translation("Fees_cannot_be_estimated"), {
            duration: 3000,
          });
          loading.value = false;
          return;
        }
        if (
          Number(store.state.balanceTokenBase.toString()) - feeAmount <
          0.001
        ) {
          message.warning(
            translation("The_remaining_money_is_not_enough_to_pay_the_fee")
          );
          loading.value = false;

          return;
        }
        const tx = await solana.loansWebsite(
          poolInfo.value?.tokenAccountB,
          Number(feeAmount),
          token_fee || "So11111111111111111111111111111111111111112",
          poolInfo.value?.tokenAccountB,
          Number(amountSolxStaking.value),
          poolStakeInfo.value?.mint,
          generateTypeMemo(
            methodCrawler.value.BOOST,
            null,
            isAgreeStemsBoost.value,
            null,
            amountSolxStaking.value
          ),
          boostOption.value
        );
        //console.log("🚀 ~ stakingSolx ~ tx:", tx);

        if (!tx) {
          loading.value = false;

          return;
        }
        // message.loading(
        //   translation("_Staking_solx_success_Please_wait_a_moment"),
        //   {
        //     duration: 1000,
        //   }
        // );
        // setTimeout(() => {
        //   message.warning(translation("_Staking_solx_processing"), {
        //     duration: 3000,
        //   });
        // }, 3000);
        stakeHistorys();

        amountSolxStaking.value = "";
        isAgreeStems.value = false;
        loading.value = false;

        // await connection.confirmTransaction(tx, "finalized");
        try {
          connection = getRpcConnection();

          connection.confirmTransaction(tx, "finalized");
        } catch (err) {
          //console.log("🚀 ~ stakingWebsite ~ err:", err);
        }
        loading.value = false;
      } else {
        const feeaEs = (await solana.getEstTransferFee()) || 0.000005;
        //console.log("🚀 ~ claimRewardWebsite ~ feeaEs:", feeaEs);
        const feeAmount =
          feeaEs + feeaEs * (Number(store.state.infoReward?.est_fee_prc) / 100);
        //console.log("🚀 ~ swapWebsite ~ feeAmount:", feeAmount);

        const url = await solana.loansMobile(
          poolInfo.value?.tokenAccountB,
          Number(feeAmount),
          token_fee || "So11111111111111111111111111111111111111112",
          poolInfo.value?.tokenAccountB,
          Number(amountSolxStaking.value),
          poolStakeInfo.value?.mint,
          generateTypeMemo(
            methodCrawler.value.BOOST,
            null,
            isAgreeStemsBoost.value,
            null,
            amountSolxStaking.value
          ),
          boostOption.value
        );
        //console.log("🚀 ~ stakingSolx ~ url:", url);
        if (!url) return;
        setLocalstoreReward(
          keysLocalStore.value,
          valuesLocalStore.value.KEY_STAKE_SOLX
        );
        window.localStorage.setItem(
          "info_save_staking_solx",
          JSON.stringify({
            poolId: poolStakeInfo.value.id,
            principle: amountSolxStaking.value,
          })
        );
        window.open(url?.toString());
        solana.closeMiniApp();
      }
    } else {
      store.commit("setOpenInviteCodeModal", true);
      loading.value = false;
    }
  } catch (err) {
    loading.value = false;
    //console.log("🚀 ~ stakingSolx ~ err:", err);
  }
};

const investmentSolx = async () => {
  try {
    loading.value = true;
    if (store.state.isSubAffDone) {
      if (Number(amountSolxInvestment.value) <= 0) {
        messageValidateAmountSolxInvestment.value = "Amount min is 1 token";
        availableSolxInvestment.value = false;
        loading.value = false;
        return;
      }

      const feeaEs = (await solana.getEstTransferFee()) || 0.000005;
      //console.log("🚀 ~ claimRewardWebsite ~ feeaEs:", feeaEs);
      const feeAmount =
        feeaEs + feeaEs * (Number(store.state.infoReward?.est_fee_prc) / 100);
      //console.log("🚀 ~ swapWebsite ~ feeAmount:", feeAmount);

      if (feeAmount <= 0) {
        message.error(translation("Fees_cannot_be_estimated"), {
          duration: 3000,
        });
        loading.value = false;
        return;
      }
      if (Number(store.state.balanceTokenBase.toString()) - feeAmount < 0.001) {
        message.warning(
          translation("The_remaining_money_is_not_enough_to_pay_the_fee")
        );
        loading.value = false;

        return;
      }
      const tx = await solana.investmentWebsite(
        store.state.poolInvestment.vault,
        Number(feeAmount),
        token_fee || "So11111111111111111111111111111111111111112",
        store.state.poolInvestment.vault,
        Number(amountSolxInvestment.value),
        poolInvestInfo.value?.mint,
        methodCrawler.value.INVESTMENT
      );
      //console.log("🚀 ~ stakingSolx ~ tx:", tx);

      if (!tx) {
        loading.value = false;

        return;
      }

      // message.loading(
      //   translation("_Investment_solx_success_Please_wait_a_moment"),
      //   {
      //     duration: 5000,
      //   }
      // );
      // setTimeout(() => {
      //   message.warning(translation("_Investment_solx_processing"), {
      //     duration: 3000,
      //   });
      // }, 5000);
      stakeHistorys();

      amountSolxInvestment.value = "";
      isAgreeStems.value = false;
      loading.value = false;

      // await connection.confirmTransaction(tx, "finalized");
      try {
        connection = getRpcConnection();

        connection.confirmTransaction(tx, "finalized");
      } catch (err) {
        //console.log("🚀 ~ stakingWebsite ~ err:", err);
      }
      loading.value = false;
    } else {
      store.commit("setOpenInviteCodeModal", true);
      loading.value = false;
    }
  } catch (err) {
    loading.value = false;
    rejectCrawler();
    //console.log("🚀 ~ stakingSolx ~ err:", err);
  }
};
const loadingWithdrawInvestmentProfit = ref<boolean>(false);
const loadingWithdrawInvestmentOrigin = ref<boolean>(false);

const withdrawProfitsInvestment = async (key?: string) => {
  try {
    connection = getRpcConnection();

    loadingWithdrawInvestmentProfit.value = true;

    window.localStorage.setItem(
      keyLoading.value.LOADING_CLAIM_INVESTMENT_PROFIT,
      "true"
    );

    // if (!program.value) return;
    // const tx = await solana.withdrawProfitsSolx(program.value, key);
    const feeaEs = (await solana.getEstTransferFee()) || 0.000005;
    0.000005;
    //console.log("🚀 ~ claimRewardWebsite ~ feeaEs:", feeaEs);
    const feeAmount =
      feeaEs + feeaEs * (Number(store.state.infoReward?.est_fee_prc) / 100);

    //console.log("🚀 ~ claimRewardWebsite ~ feeAmount:", feeAmount);

    if (Number(store.state.balanceTokenBase.toString()) - feeAmount < 0.001) {
      message.warning(
        translation("The_remaining_money_is_not_enough_to_pay_the_fee")
      );
      window.localStorage.setItem(
        keyLoading.value.LOADING_CLAIM_INVESTMENT_PROFIT,
        "false"
      );
      loadingWithdrawInvestmentProfit.value = false;

      return;
    }

    if (feeAmount <= 0) {
      message.error(translation("Fees_cannot_be_estimated"), {
        duration: 3000,
      });
      window.localStorage.setItem(
        keyLoading.value.LOADING_CLAIM_INVESTMENT_PROFIT,
        "false"
      );
      loadingWithdrawInvestmentProfit.value = false;
      return;
    }
    const tx = await solana.chargeFeeWhenClaimReward(
      store.state.poolInvestment.vault,
      feeAmount,
      token_fee || "So11111111111111111111111111111111111111112",
      methodCrawler.value.CLAIM_INVESTMENT_INTEREST
    );
    //console.log("🚀 ~ stakingSolx ~ tx:", tx);

    if (!tx) {
      window.localStorage.setItem(
        keyLoading.value.LOADING_CLAIM_INVESTMENT_PROFIT,
        "false"
      );
      loadingWithdrawInvestmentProfit.value = false;

      return;
    }
    // message.loading(
    //   translation("_Investment_withdraw_profit_success_Please_wait_a_moment"),
    //   {
    //     duration: 5000,
    //   }
    // );
    try {
      connection.confirmTransaction(tx, "finalized");
    } catch (err) {
      //console.log("🚀 ~ InvestmentWebsite ~ err:", err);
    }
    setTimeout(() => {
      message.warning(
        translation("_Investment_solx_withdraw_profit_processing"),
        {
          duration: 3000,
        }
      );
    }, 5000);
  } catch (err) {
    loadingWithdrawInvestmentProfit.value = false;
    window.localStorage.setItem(
      keyLoading.value.LOADING_CLAIM_INVESTMENT_PROFIT,
      "false"
    );
    rejectCrawler();

    //console.log("🚀 ~ withdrawProfits ~ err:", err);
  }
};
const withdrawOriginInvestment = async (key?: string) => {
  try {
    connection = getRpcConnection();

    loadingWithdrawInvestmentOrigin.value = true;

    window.localStorage.setItem(
      keyLoading.value.LOADING_CLAIM_INVESTMENT_PRINCIPLE,
      "true"
    );

    const feeaEs = (await solana.getEstTransferFee()) || 0.000005;
    //console.log("🚀 ~ claimRewardWebsite ~ feeaEs:", feeaEs);
    const feeAmount =
      feeaEs + feeaEs * (Number(store.state.infoReward?.est_fee_prc) / 100);

    //console.log("🚀 ~ claimRewardWebsite ~ feeAmount:", feeAmount);
    if (feeAmount <= 0) {
      message.error(translation("Fees_cannot_be_estimated"), {
        duration: 3000,
      });
      window.localStorage.setItem(
        keyLoading.value.LOADING_CLAIM_INVESTMENT_PRINCIPLE,
        "false"
      );
      loadingWithdrawInvestmentOrigin.value = false;
      return;
    }

    if (Number(store.state.balanceTokenBase.toString()) - feeAmount < 0.001) {
      message.warning(
        translation("The_remaining_money_is_not_enough_to_pay_the_fee")
      );
      window.localStorage.setItem(
        keyLoading.value.LOADING_CLAIM_INVESTMENT_PRINCIPLE,
        "false"
      );
      loadingWithdrawInvestmentOrigin.value = false;

      return;
    }
    const tx = await solana.chargeFeeWhenClaimReward(
      poolInfo.value?.tokenAccountB,
      feeAmount,
      token_fee || "So11111111111111111111111111111111111111112",
      methodCrawler.value.CLAIM_INVESTMENT_PRINCIPLE
    );
    //console.log("🚀 ~ withdrawOrigin ~ tx:", tx);

    if (!tx) {
      window.localStorage.setItem(
        keyLoading.value.LOADING_CLAIM_INVESTMENT_PRINCIPLE,
        "false"
      );
      loadingWithdrawInvestmentOrigin.value = false;

      return;
    }
    // message.loading(
    //   translation("_Staking_withdraw_origin_success_Please_wait_a_moment"),
    //   {
    //     duration: 5000,
    //   }
    // );
    // setTimeout(() => {
    //   message.warning(translation("_Staking_solx_withdraw_origin_processing"), {
    //     duration: 3000,
    //   });
    // }, 5000);

    try {
      connection.confirmTransaction(tx, "finalized");
    } catch (err) {
      //console.log("🚀 ~ stakingWebsite ~ err:", err);
    }

    return;
  } catch (err) {
    window.localStorage.setItem(
      keyLoading.value.LOADING_CLAIM_INVESTMENT_PRINCIPLE,
      "false"
    );
    rejectCrawler();

    loadingWithdrawInvestmentOrigin.value = false;

    //console.log("🚀 ~ withdrawProfits ~ err:", err);
  }
};
const withdrawProfits = async (key?: string) => {
  try {
    connection = getRpcConnection();

    loadingWithdrawProfit.value = true;

    window.localStorage.setItem(keyLoading.value.LOADING_CLAIM_PROFIT, "true");

    // if (!program.value) return;
    if (isDeviceLaptop() && !store.state.isTelegram) {
      // const tx = await solana.withdrawProfitsSolx(program.value, key);
      const feeaEs = (await solana.getEstTransferFee()) || 0.000005;
      //console.log("🚀 ~ claimRewardWebsite ~ feeaEs:", feeaEs);
      const feeAmount =
        feeaEs + feeaEs * (Number(store.state.infoReward?.est_fee_prc) / 100);

      //console.log("🚀 ~ claimRewardWebsite ~ feeAmount:", feeAmount);

      if (Number(store.state.balanceTokenBase.toString()) - feeAmount < 0.001) {
        message.warning(
          translation("The_remaining_money_is_not_enough_to_pay_the_fee")
        );
        window.localStorage.setItem(
          keyLoading.value.LOADING_CLAIM_PROFIT,
          "false"
        );

        loadingWithdrawProfit.value = false;

        return;
      }

      if (feeAmount <= 0) {
        message.error(translation("Fees_cannot_be_estimated"), {
          duration: 3000,
        });
        window.localStorage.setItem(
          keyLoading.value.LOADING_CLAIM_PROFIT,
          "false"
        );

        loadingWithdrawProfit.value = false;
        return;
      }
      const tx = await solana.chargeFeeWhenClaimReward(
        poolInfo.value?.tokenAccountB,
        feeAmount,
        token_fee || "So11111111111111111111111111111111111111112",
        generateTypeMemo(methodCrawler.value.CLAIM_BOOST_INTEREST)
      );
      //console.log("🚀 ~ stakingSolx ~ tx:", tx);

      if (!tx) {
        window.localStorage.setItem(
          keyLoading.value.LOADING_CLAIM_PROFIT,
          "false"
        );

        loadingWithdrawProfit.value = false;

        return;
      }
      // callCrawlerHaveTransaction({
      //   method: methodCrawler.value.CLAIM_BOOST_INTEREST,
      // });
      // message.loading(
      //   translation("_Staking_withdraw_profit_success_Please_wait_a_moment"),
      //   {
      //     duration: 5000,
      //   }
      // );
      // await connection.confirmTransaction(tx, "finalized");
      try {
        connection.confirmTransaction(tx, "finalized");
      } catch (err) {
        //console.log("🚀 ~ stakingWebsite ~ err:", err);
      }
      // setTimeout(() => {
      //   message.warning(
      //     translation("_Staking_solx_withdraw_profit_processing"),
      //     {
      //       duration: 3000,
      //     }
      //   );
      // }, 5000);

      // await saveClaimProfit({
      //   signature: tx,
      //   stakingPublicKey: key,
      // });

      return;
    } else {
      //console.log("mobile");
      // const check = checkSessionConnectMobile();
      // if (!check) {
      // const url = await solana.withdrawProfitsSolxMobile(program.value, key);
      const feeaEs = (await solana.getEstTransferFee()) || 0.000005;
      const feeAmount =
        feeaEs + feeaEs * (Number(store.state.infoReward?.est_fee_prc) / 100);
      const url = await solana.signTransaction(
        poolInfo.value?.tokenAccountB,
        feeAmount,
        token_fee || "So11111111111111111111111111111111111111112",
        false,
        "",
        generateTypeMemo(methodCrawler.value.CLAIM_BOOST_INTEREST)
      );
      if (!url) return;
      setLocalstoreReward(
        keysLocalStore.value,
        valuesLocalStore.value.KEY_WITHDRAW_PROFIT
      );
      window.localStorage.setItem(
        "info_withdraw_profit_solx",
        JSON.stringify({
          stakingPublicKey: key,
        })
      );
      window.open(url?.toString());
      solana.closeMiniApp();

      // } else {
      //   store.commit("setPlacement", "top-right");

      //   message.warning(translation("Session expired, disconnect now!"), {
      //     duration: 1500,
      //   });
      //   setTimeout(() => {
      //     solana.disconnect();
      //   }, 1000);
      // }
    }

    // fetchDataStakeSolx();
    window.localStorage.setItem(keyLoading.value.LOADING_CLAIM_PROFIT, "false");

    loadingWithdrawProfit.value = false;
  } catch (err) {
    loadingWithdrawProfit.value = false;
    window.localStorage.setItem(keyLoading.value.LOADING_CLAIM_PROFIT, "false");
    rejectCrawler();

    //console.log("🚀 ~ withdrawProfits ~ err:", err);
  }
};
const withdrawOrigin = async (option: "all" | "available") => {
  //console.log("🚀 ~ withdrawOrigin ~ option:", option);
  try {
    connection = getRpcConnection();

    loadingWithdrawOrigin.value = true;
    if (isDeviceLaptop() && !store.state.isTelegram) {
      window.localStorage.setItem(
        keyLoading.value.LOADING_CLAIM_PRINCIPLE,
        "true"
      );

      const feeaEs = (await solana.getEstTransferFee()) || 0.000005;
      //console.log("🚀 ~ claimRewardWebsite ~ feeaEs:", feeaEs);
      const feeAmount =
        feeaEs + feeaEs * (Number(store.state.infoReward?.est_fee_prc) / 100);

      //console.log("🚀 ~ claimRewardWebsite ~ feeAmount:", feeAmount);
      if (feeAmount <= 0) {
        message.error(translation("Fees_cannot_be_estimated"), {
          duration: 3000,
        });
        window.localStorage.setItem(
          keyLoading.value.LOADING_CLAIM_PRINCIPLE,
          "false"
        );
        loadingWithdrawOrigin.value = false;
        return;
      }

      if (Number(store.state.balanceTokenBase.toString()) - feeAmount < 0.001) {
        message.warning(
          translation("The_remaining_money_is_not_enough_to_pay_the_fee")
        );
        window.localStorage.setItem(
          keyLoading.value.LOADING_CLAIM_PRINCIPLE,
          "false"
        );
        loadingWithdrawOrigin.value = false;

        return;
      }
      const tx = await solana.chargeFeeWhenClaimReward(
        poolInfo.value?.tokenAccountB,
        feeAmount,
        token_fee || "So11111111111111111111111111111111111111112",
        generateTypeMemo(
          methodCrawler.value.CLAIM_BOOST_PRINCIPLE,
          null,
          null,
          option
        )
      );
      //console.log("🚀 ~ withdrawOrigin ~ tx:", tx);

      if (!tx) {
        window.localStorage.setItem(
          keyLoading.value.LOADING_CLAIM_PRINCIPLE,
          "false"
        );
        loadingWithdrawOrigin.value = false;

        return;
      }
      // message.loading(
      //   translation("_Staking_withdraw_origin_success_Please_wait_a_moment"),
      //   {
      //     duration: 1000,
      //   }
      // );
      // setTimeout(() => {
      //   message.warning(
      //     translation("_Staking_solx_withdraw_origin_processing"),
      //     {
      //       duration: 3000,
      //     }
      //   );
      // }, 5000);

      try {
        connection.confirmTransaction(tx, "finalized");
      } catch (err) {
        //console.log("🚀 ~ stakingWebsite ~ err:", err);
      }
      return;
    } else {
      // //console.log("mobile");
      // const check = checkSessionConnectMobile();
      // if (!check) {
      const feeaEs = (await solana.getEstTransferFee()) || 0;
      const feeAmount =
        feeaEs + feeaEs * (Number(store.state.infoReward?.est_fee_prc) / 100);
      const url = await solana.signTransaction(
        poolInfo.value?.tokenAccountB,
        feeAmount,
        token_fee || "So11111111111111111111111111111111111111112",
        false,
        "",
        generateTypeMemo(
          methodCrawler.value.CLAIM_BOOST_PRINCIPLE,
          null,
          null,
          option
        )
      );
      if (!url) return;
      setLocalstoreReward(
        keysLocalStore.value,
        valuesLocalStore.value.KEY_WITHDRAW_ORIGIN
      );
      // window.localStorage.setItem(
      //   "info_withdraw_origin_solx",
      //   JSON.stringify({
      //     stakingPublicKey: key,
      //   })
      // );
      window.open(url?.toString());
      solana.closeMiniApp();
    }
  } catch (err) {
    window.localStorage.setItem(
      keyLoading.value.LOADING_CLAIM_PRINCIPLE,
      "false"
    );
    loadingWithdrawOrigin.value = false;

    //console.log("🚀 ~ withdrawProfits ~ err:", err);
  }
};
//

const getWalletAddressAll = () => {
  let address;

  if (isDeviceLaptop() && !store.state.isTelegram) {
    const { publicKey } = useWallet();
    address = publicKey.value?.toString();
  } else {
    address = solana.session?.public_key.toString();
  }
  return address;
};
const allReceipt = computed(() => store.state.receipts);

const fetchDataStakeSolx = async (noUser = false) => {
  // //console.log("🚀 ~ fetchDataStakeSolx ~ fetchDataStakeSolx:tttttt");
  try {
    const now = Number((new Date().getTime() / 1000).toFixed(0));

    let data: any = {
      arp: 0,
      your_stake: 0,
      total_stake: 0,
      principal: 0,
      interest: 0,
      receipts: [],
      closeTime: 0,
      interestNew: 0,
      closeTimeNew: 0,
      timeLatest: 0,
      principalLatest: 0,
      timeLongest: 0,
      principalLongest: 0,
      totalPricipalNotComplete: 0,
    };
    if (noUser || !store.state.user) {
      dataStakeSolx.value = data;
      return;
    }
    // //console.log("allReceipt.value", allReceipt.value);
    const lateAndLongestBoost = findLateAndLongest(
      [...allReceipt.value?.stakeHistoryUser],
      poolStakeInfo.value?.block_time_withdraw_origin,
      now
    );
    const { totalPricipalNotComplete } = getTotalAmountNotComplete(
      [...allReceipt.value?.stakeHistoryUser],
      poolStakeInfo.value?.block_time_withdraw_origin,
      now
    );
    allReceipt.value?.stakeHistoryUser != null &&
      allReceipt.value?.stakeHistoryUser.length > 0 &&
      (await Promise.all(
        allReceipt.value?.stakeHistoryUser.map(async (receipt: any) => {
          data.your_stake += Number(receipt.principle);
          data.principal += Number(receipt.principle);
          const [receipt_after_caculate, receipt_after_caculate_new] =
            await Promise.all([
              caculateProfitsForOneReceiptWithHalving(
                receipt,
                poolStakeInfo.value,
                [],
                "boost",
                now,
                false
              ),
              caculateProfitsForOneReceiptWithHalving(
                receipt,
                poolStakeInfo.value,
                [],
                "boost",
                Number(lateAndLongestBoost.timeLongest) + 1,
                false
              ),
            ]);

          data.interest +=
            receipt_after_caculate.totalClaimed *
            Number(poolStakeInfo.value.percent_commission);
          data.closeTime = receipt_after_caculate.close_time;

          data.interestNew +=
            receipt_after_caculate_new.totalClaimed *
            Number(poolStakeInfo.value.percent_commission);
          data.closeTimeNew = receipt_after_caculate_new.close_time;
        })
      ));

    data.interest = Number(data.interest.toFixed(9));
    data.interestNew = Number(data.interestNew.toFixed(9));
    data.total_stake = allReceipt.value?.total_principle;
    //console.log("🚀 ~ fetchDataStakeSolx ~ data:", {
    //   ...data,
    //   ...lateAndLongestBoost,
    // });

    dataStakeSolx.value = {
      ...dataStakeSolx.value,
      ...data,
      ...lateAndLongestBoost,
      totalPricipalNotComplete,
    };
  } catch (err) {
    console.log("🚀 ~ fetchDataStakeSolx ~ err:", err);
  }
};
const getTotalAmountNotComplete = (
  receipts: any[],
  lockTime: string | number,
  now: number
) => {
  try {
    const totalPricipalNotComplete = receipts.reduce((result, receipt) => {
      let amount = 0;
      if (receipt.auto_subcribe == true) {
        amount = Number(receipt.principle);
      } else {
        if (now < Number(receipt?.time_deposit) + Number(lockTime)) {
          amount = Number(receipt.principle);
        }
      }
      return result + amount;
    }, 0);
    return {
      totalPricipalNotComplete,
    };
  } catch (err) {
    console.log("🚀 ~ getTotalAmountNotComplete ~ err:", err);
  }
};

const fetchDataStakeSolxSetting = async (noUser = false) => {
  // //console.log("🚀 ~ fetchDataStakeSolx ~ fetchDataStakeSolx:tttttt");
  try {
    const now = Number((new Date().getTime() / 1000).toFixed(0));
    if (allReceipt.value.length <= 0) return;
    let data: any = {
      arp: 0,
      your_stake: 0,
      total_stake: 0,
      principal: 0,
      interest: 0,
      receipts: [],
      closeTime: 0,
      interestNew: 0,
      closeTimeNew: 0,
      timeLatest: 0,
      principalLatest: 0,
      timeLongest: 0,
      principalLongest: 0,
      interestForSetting: 0,
    };
    if (noUser || !store.state.user) {
      dataStakeSolx.value = data;
      return;
    }

    allReceipt.value?.stakeHistoryUser != null &&
      allReceipt.value?.stakeHistoryUser.length > 0 &&
      (await Promise.all(
        allReceipt.value?.stakeHistoryUser.map(async (receipt: any) => {
          data.your_stake += Number(receipt.principle);
          data.principal += Number(receipt.principle);
          const [receipt_after_caculate] = await Promise.all([
            caculateProfitsForOneReceiptWithHalvingSetting(
              receipt,
              poolStakeInfo.value,
              [],
              "boost",
              now,
              false
            ),
          ]);
          data.interestForSetting +=
            receipt_after_caculate.totalClaimed *
            Number(poolStakeInfo.value.percent_commission);
        })
      ));

    dataStakeSolx.value = {
      ...dataStakeSolx.value,
      interestForSetting: data.interestForSetting,
      timeInterestForSetting: now,
    };
  } catch (err) {
    console.log("🚀 ~ fetchDataStakeSolxSetting ~ err:", err);
  }
};

const checkReceiptsSpecial = (receipts: any[]) => {
  const length = receipts.length;
  return receipts.filter((re) => re?.auto_subcribe == true).length == length;
};
const checkAllNotAuto = (receipts: any[]) => {
  const length = receipts.length;
  return receipts.filter((re) => re?.auto_subcribe == false).length == length;
};

const findLateAndLongest = (
  receipts: any[],
  block_time_withdraw_origin: string,
  now: number
) => {
  receipts = receipts.sort((a, b) => a.id - b.id);
  const sum = receipts.reduce((a, b) => a + Number(b.principle), 0);

  try {
    let timeLatest = 0;
    let principalLatest = 0;
    let timeLongest = 0;
    let principalLongest = 0;
    let activeLate = false;
    let activeLongest = false;
    let showLongest = true;
    let closeCollapse = false;
    if (checkReceiptsSpecial(receipts)) {
      let maxCloseTime = 0;
      let minCloseTime = 99999999999;
      let sumPrincipal = 0;
      receipts.forEach((item) => {
        let closeTime = calculateOutput(
          now,
          Number(item?.time_deposit),
          Number(block_time_withdraw_origin)
        );
        closeTime = closeTime + Number(block_time_withdraw_origin);
        sumPrincipal += Number(item.principle);

        // Latest

        if (closeTime < minCloseTime) {
          minCloseTime = closeTime;
          principalLatest = item.principle;
          timeLatest = closeTime;
        }
        //Longest
        if (closeTime > maxCloseTime) {
          maxCloseTime = closeTime;
        }
      });
      timeLongest = maxCloseTime;
      principalLongest = sumPrincipal - principalLatest;
    } else {
      if (checkAllNotAuto(receipts)) {
        timeLatest =
          Number(receipts[0].time_deposit) + Number(block_time_withdraw_origin);
        principalLatest = Number(receipts[0].principle);

        let sumPrincipal = 0;
        let maxCloseTimeGb =
          Number(receipts[0].time_deposit) + Number(block_time_withdraw_origin);

        receipts.forEach((item, index) => {
          sumPrincipal += Number(item.principle);

          if (index > 0) {
            let closeTime = calculateOutput(
              now,
              Number(item?.time_deposit),
              Number(block_time_withdraw_origin)
            );
            if (closeTime == Number(item?.time_deposit) || item.auto_subcribe) {
              closeTime += Number(block_time_withdraw_origin);
            }

            if (now >= closeTime) timeLatest = closeTime;
            principalLatest += now >= closeTime ? Number(item?.principle) : 0;
            if (closeTime > maxCloseTimeGb) {
              maxCloseTimeGb = closeTime;
            }
          }
        });
        activeLate = now > timeLatest;
        timeLongest = maxCloseTimeGb;
        principalLongest = sumPrincipal - principalLatest;
        showLongest = principalLongest > 0;
      } else {
        //console.log("random_receipt");

        const notAutoAndClosed = [];
        let minCloseTime = 99999999999;
        let maxCloseTime = 0;
        let sumPrincipal = 0;

        receipts.forEach((item) => {
          sumPrincipal += Number(item.principle);

          let closeTime = calculateOutput(
            now,
            Number(item?.time_deposit),
            Number(block_time_withdraw_origin)
          );
          if (closeTime == Number(item?.time_deposit) || item.auto_subcribe) {
            closeTime += Number(block_time_withdraw_origin);
          }
          if (now > closeTime && item.auto_subcribe == false) {
            notAutoAndClosed.push(item);
          }

          if (closeTime < minCloseTime) {
            timeLatest = Math.min(minCloseTime, closeTime);
            principalLatest = Number(item.principle);
            minCloseTime = closeTime;
          }
          if (closeTime > maxCloseTime) {
            maxCloseTime = closeTime;
          }
        });
        if (notAutoAndClosed.length > 0) {
          //console.log("notAutoAndClosed", notAutoAndClosed);
          timeLatest = 0;
          principalLatest = 0;
          let maxCloseTime = 0;
          notAutoAndClosed.forEach((item) => {
            if (
              Number(item.time_deposit) + Number(block_time_withdraw_origin) >
              maxCloseTime
            ) {
              timeLatest =
                Number(item.time_deposit) + Number(block_time_withdraw_origin);
              maxCloseTime = timeLatest;
            }
            principalLatest += Number(item?.principle);
          });
          activeLate = true;
        }
        timeLongest = maxCloseTime;
        principalLongest = sumPrincipal - principalLatest;
      }
    }
    if (receipts?.length == 1 || principalLatest >= sum) {
      closeCollapse = true;
    }
    return {
      timeLatest,
      principalLatest,
      timeLongest,
      principalLongest,
      activeLate,
      activeLongest,
      showLongest,
      closeCollapse,
    };
  } catch (err) {
    //console.log("🚀 ~ findLateAndLongest ~ err:", err);
  }
};

const amountEstAfterInputBoost = ref<any>("");
const fetchDataStakeSolxAfterInput = async (amount: string) => {
  try {
    const now = Number((new Date().getTime() / 1000).toFixed(0));

    //console.log("🚀 ~ fetchDataStakeSolxAfterInput ~ now:", now);
    let data: any = {
      arp: 0,
      your_stake: 0,
      total_stake: 0,
      principal: 0,
      interest: 0,
      receipts: [],
      closeTime: 0,
      interestNew: 0,
      interestNewAfterInput: "",
      closeTimeNew: 0,
    };
    if (amountEstAfterInputBoost.value) {
      amountEstAfterInputBoost.value = {
        ...amountEstAfterInputBoost.value,
        interestNew: "",
      };
    }
    await wait(1000);
    // //console.log("allReceipt.value", allReceipt.value);
    const receipts = store.state.receipts;
    const stakeHis = receipts?.stakeHistoryUser
      ? [...receipts?.stakeHistoryUser]
      : [];
    //console.log("🚀 ~ fetchDataStakeSolxAfterInput ~ receipts:", receipts);

    const dataNew = {
      principle: Number(amount),
      last_time_update: now,
      time_deposit: now,
      amount_can_withdraw: 0,
      amount_can_not_withdraw: 0,
    };
    //console.log("🚀 ~ fetchDataNewStakeSolxAfterInput ~ dataNew:", dataNew);
    stakeHis.push(dataNew);
    //console.log("🚀 ~ fetchDataStakeSolxAfterInput ~ stakeHis:", stakeHis);

    stakeHis != null &&
      stakeHis.length > 0 &&
      (await Promise.all(
        [...stakeHis].map(async (receipt: any) => {
          // //console.log("🚀 ~ allReceipt.value.forEach ~ receipt:", receipt);
          data.your_stake += Number(receipt.principle);
          data.principal += Number(receipt.principle);
          const receipt_after_caculate =
            await caculateProfitsForOneReceiptWithHalving(
              receipt,
              poolStakeInfo.value,
              [],
              "boost",
              now + Number(poolStakeInfo.value.block_time_withdraw_origin) + 1,
              true
            );
          console.log(
            "🚀 ~ receipts.map ~ receipt_after_caculate:",
            receipt_after_caculate
          );
          data.interestNew +=
            receipt_after_caculate.totalClaimed *
            Number(poolStakeInfo.value.percent_commission);
        })
      ));
    data.closeTimeNew =
      now + Number(poolStakeInfo.value.block_time_withdraw_origin);
    data.interest = Number(data.interest.toFixed(9));
    data.interestNew = Number(data.interestNew.toFixed(9));
    console.log(
      "🚀 ~ amountEstAfterInputBoost ~ data:",
      amountEstAfterInputBoost
    );

    amountEstAfterInputBoost.value = data;
  } catch (err) {
    //console.log("🚀 ~ fetchDataStakeSolx ~ err:", err);
  }
};
const caculateProfitsForOneReceiptWithHalvingSetting = async (
  stakeInfo: any,
  stakePool: any,
  halving_log: any[],
  type: "boost" | "investment",
  nowTime?: number,
  caculateNotClaim?: boolean
) => {
  const {
    block_time,
    token_on_block_time,
    block_time_withdraw_origin,
    range_time_withdraw_profits,
  } = stakePool;
  const { principle, last_time_update, time_deposit, auto_subcribe } =
    stakeInfo;
  const now = nowTime || new Date().getTime() / 1000;
  const data = {
    amount_user: 0,
    amount_admin: 0,
    close_time: 0,
    mostRecentCycle: 0,
    totalClaimed: Number(stakeInfo.amount_can_withdraw),
    totalNotClaimed: Number(stakeInfo.amount_can_not_withdraw),
  };
  const halvingLogs = halving_log;
  let closeTime = now;
  // if (type == "boost") {
  //   closeTime = calculateOutput(
  //     now,
  //     Number(time_deposit),
  //     Number(block_time_withdraw_origin)
  //   );
  // } else {
  //   console.log(
  //     "🚀 ~ range_time_withdraw_profits:",
  //     range_time_withdraw_profits
  //   );

  //   closeTime = calculateOutput(
  //     now,
  //     Number(time_deposit),
  //     Number(range_time_withdraw_profits)
  //   );
  // }

  if (!auto_subcribe) {
    const maxCloseTime =
      Number(time_deposit) + Number(block_time_withdraw_origin);
    closeTime = Math.min(maxCloseTime, closeTime);
  }

  data.close_time = closeTime;
  data.mostRecentCycle = closeTime;

  if (halvingLogs.length <= 0) {
    //console.log("no halving");

    if (closeTime != 0 && closeTime > Number(last_time_update)) {
      const diff_time = closeTime - Number(last_time_update);

      const number_block = diff_time / Number(block_time);
      if (number_block > 0.0) {
        const amount_claimed =
          number_block * Number(principle) * Number(token_on_block_time);
        data.close_time = closeTime;
        data.mostRecentCycle = closeTime;
        data.totalClaimed += amount_claimed + data.totalNotClaimed;
        data.totalNotClaimed = 0;
      }

      if (closeTime != 0 && caculateNotClaim) {
        const diff_time = now - closeTime;

        const number_block = diff_time / Number(block_time);
        if (number_block > 0.0) {
          const amount_claimed =
            number_block * Number(principle) * Number(token_on_block_time);

          data.totalNotClaimed += amount_claimed;
          data.close_time = now;
        }
      }
    } else {
      if (closeTime != 0 && caculateNotClaim) {
        const diff_time = now - Number(last_time_update);

        const number_block = diff_time / Number(block_time);
        if (number_block > 0.0) {
          const amount_claimed =
            number_block * Number(principle) * Number(token_on_block_time);

          data.totalNotClaimed += amount_claimed;
          data.close_time = now;
        }
      }
    }
  } else {
    //console.log("halving");

    let closeTimeTemp = 0;
    data.close_time = Number(last_time_update);

    if (closeTime != 0 && closeTime > Number(last_time_update)) {
      for (let i = 0; i < halvingLogs.length; i++) {
        const log = halvingLogs[i];
        const halvingDate = new Date(log.date_created).getTime() / 1000;
        if (Number(stakeInfo.last_time_update) >= halvingDate) {
          continue;
        }
        closeTimeTemp = halvingDate;

        if (closeTimeTemp != 0 && closeTimeTemp > data.close_time) {
          const diff_time = closeTimeTemp - data.close_time;

          const number_block = diff_time / Number(block_time);
          if (number_block > 0.0) {
            const amount_claimed =
              number_block *
              Number(principle) *
              Number(log.token_block_time_before_halving);

            data.totalClaimed += amount_claimed;
            data.close_time = closeTimeTemp;
            data.mostRecentCycle = closeTimeTemp;
          }
        }
      }
      data.totalClaimed = data.totalNotClaimed;

      if (data.close_time < closeTime) {
        const diff_time = closeTime - data.close_time;

        const number_block = diff_time / Number(block_time);
        if (number_block > 0.0) {
          const amount_claimed =
            number_block * Number(principle) * Number(token_on_block_time);

          data.totalClaimed += amount_claimed;
          data.close_time = closeTime;
          data.mostRecentCycle = closeTime;
        }
      }
      if (closeTime != 0 && caculateNotClaim) {
        const diff_time = now - closeTime;

        const number_block = diff_time / Number(block_time);
        if (number_block > 0.0) {
          const amount_claimed =
            number_block * Number(principle) * Number(token_on_block_time);

          data.totalNotClaimed += amount_claimed;
          data.close_time = now;
        }
      }
    } else {
      if (closeTime != 0 && caculateNotClaim) {
        for (let i = 0; i < halvingLogs.length; i++) {
          const log = halvingLogs[i];
          const halvingDate = new Date(log.date_created).getTime() / 1000;
          if (Number(stakeInfo.last_time_update) >= halvingDate) {
            continue;
          }
          closeTimeTemp = halvingDate;

          if (closeTimeTemp != 0 && closeTimeTemp > data.close_time) {
            const diff_time = closeTimeTemp - data.close_time;

            const number_block = diff_time / Number(block_time);
            if (number_block > 0.0) {
              const amount_claimed =
                number_block *
                Number(principle) *
                Number(log.token_block_time_before_halving);

              data.totalNotClaimed += amount_claimed;
              data.close_time = closeTimeTemp;
            }
          }
        }
        const diff_time = now - data.close_time;

        const number_block = diff_time / Number(block_time);
        if (number_block > 0.0) {
          const amount_claimed =
            number_block * Number(principle) * Number(token_on_block_time);

          data.totalNotClaimed += amount_claimed;
          data.close_time = now;
        }
      }
    }
  }

  return data;
};
const caculateProfitsForOneReceiptWithHalving = async (
  stakeInfo: any,
  stakePool: any,
  halving_log: any[],
  type: "boost" | "investment",
  nowTime?: number,
  caculateNotClaim?: boolean
) => {
  const {
    block_time,
    token_on_block_time,
    block_time_withdraw_origin,
    range_time_withdraw_profits,
  } = stakePool;
  const { principle, last_time_update, time_deposit, auto_subcribe } =
    stakeInfo;
  const now = nowTime || new Date().getTime() / 1000;
  const data = {
    amount_user: 0,
    amount_admin: 0,
    close_time: 0,
    mostRecentCycle: 0,
    totalClaimed: Number(stakeInfo.amount_can_withdraw),
    totalNotClaimed: Number(stakeInfo.amount_can_not_withdraw),
  };
  const halvingLogs = halving_log;
  let closeTime;
  if (type == "boost") {
    closeTime = calculateOutput(
      now,
      Number(time_deposit),
      Number(block_time_withdraw_origin)
    );
  } else {
    console.log(
      "🚀 ~ range_time_withdraw_profits:",
      range_time_withdraw_profits
    );

    closeTime = calculateOutput(
      now,
      Number(time_deposit),
      Number(range_time_withdraw_profits)
    );
  }

  if (!auto_subcribe) {
    const maxCloseTime =
      Number(time_deposit) + Number(block_time_withdraw_origin);
    closeTime = Math.min(maxCloseTime, closeTime);
  }

  data.close_time = closeTime;
  data.mostRecentCycle = closeTime;

  if (halvingLogs.length <= 0) {
    //console.log("no halving");

    if (closeTime != 0 && closeTime > Number(last_time_update)) {
      const diff_time = closeTime - Number(last_time_update);

      const number_block = diff_time / Number(block_time);
      if (number_block > 0.0) {
        const amount_claimed =
          number_block * Number(principle) * Number(token_on_block_time);

        data.close_time = closeTime;
        data.mostRecentCycle = closeTime;
        data.totalClaimed += amount_claimed + data.totalNotClaimed;
        data.totalNotClaimed = 0;
      }

      if (closeTime != 0 && caculateNotClaim) {
        const diff_time = now - closeTime;

        const number_block = diff_time / Number(block_time);
        if (number_block > 0.0) {
          const amount_claimed =
            number_block * Number(principle) * Number(token_on_block_time);

          data.totalNotClaimed += amount_claimed;
          data.close_time = now;
        }
      }
    } else {
      if (closeTime != 0 && caculateNotClaim) {
        const diff_time = now - Number(last_time_update);

        const number_block = diff_time / Number(block_time);
        if (number_block > 0.0) {
          const amount_claimed =
            number_block * Number(principle) * Number(token_on_block_time);

          data.totalNotClaimed += amount_claimed;
          data.close_time = now;
        }
      }
    }
  } else {
    //console.log("halving");

    let closeTimeTemp = 0;
    data.close_time = Number(last_time_update);

    if (closeTime != 0 && closeTime > Number(last_time_update)) {
      for (let i = 0; i < halvingLogs.length; i++) {
        const log = halvingLogs[i];
        const halvingDate = new Date(log.date_created).getTime() / 1000;
        if (Number(stakeInfo.last_time_update) >= halvingDate) {
          continue;
        }
        closeTimeTemp = halvingDate;

        if (closeTimeTemp != 0 && closeTimeTemp > data.close_time) {
          const diff_time = closeTimeTemp - data.close_time;

          const number_block = diff_time / Number(block_time);
          if (number_block > 0.0) {
            const amount_claimed =
              number_block *
              Number(principle) *
              Number(log.token_block_time_before_halving);

            data.totalClaimed += amount_claimed;
            data.close_time = closeTimeTemp;
            data.mostRecentCycle = closeTimeTemp;
          }
        }
      }
      data.totalClaimed = data.totalNotClaimed;

      if (data.close_time < closeTime) {
        const diff_time = closeTime - data.close_time;

        const number_block = diff_time / Number(block_time);
        if (number_block > 0.0) {
          const amount_claimed =
            number_block * Number(principle) * Number(token_on_block_time);

          data.totalClaimed += amount_claimed;
          data.close_time = closeTime;
          data.mostRecentCycle = closeTime;
        }
      }
      if (closeTime != 0 && caculateNotClaim) {
        const diff_time = now - closeTime;

        const number_block = diff_time / Number(block_time);
        if (number_block > 0.0) {
          const amount_claimed =
            number_block * Number(principle) * Number(token_on_block_time);

          data.totalNotClaimed += amount_claimed;
          data.close_time = now;
        }
      }
    } else {
      if (closeTime != 0 && caculateNotClaim) {
        for (let i = 0; i < halvingLogs.length; i++) {
          const log = halvingLogs[i];
          const halvingDate = new Date(log.date_created).getTime() / 1000;
          if (Number(stakeInfo.last_time_update) >= halvingDate) {
            continue;
          }
          closeTimeTemp = halvingDate;

          if (closeTimeTemp != 0 && closeTimeTemp > data.close_time) {
            const diff_time = closeTimeTemp - data.close_time;

            const number_block = diff_time / Number(block_time);
            if (number_block > 0.0) {
              const amount_claimed =
                number_block *
                Number(principle) *
                Number(log.token_block_time_before_halving);

              data.totalNotClaimed += amount_claimed;
              data.close_time = closeTimeTemp;
            }
          }
        }
        const diff_time = now - data.close_time;

        const number_block = diff_time / Number(block_time);
        if (number_block > 0.0) {
          const amount_claimed =
            number_block * Number(principle) * Number(token_on_block_time);

          data.totalNotClaimed += amount_claimed;
          data.close_time = now;
        }
      }
    }
  }

  return data;
};

const fetchDataInvestmentSolx = async (noUser = false) => {
  try {
    const now = Number((new Date().getTime() / 1000).toFixed(0));
    let data: any = {
      arp: 0,
      your_stake: 0,
      total_stake: 0,
      principal: 0,
      interest: 0,
      receipts: [],
      closeTime: 0,
      interestNew: 0,
      closeTimeNew: 0,
    };
    if (noUser || !store.state.user) {
      dataInvestmentSolx.value = data;
      return;
    }
    // //console.log("allReceipt.value", allReceipt.value);

    allReceipt.value?.investHistoryUser != null &&
      allReceipt.value?.investHistoryUser.length > 0 &&
      (await Promise.all(
        allReceipt.value?.investHistoryUser.map(async (receipt: any) => {
          // //console.log("🚀 ~ allReceipt.value.forEach ~ receipt:", receipt);
          data.your_stake += Number(receipt.principle);
          data.principal += Number(receipt.principle);
          const receipt_after_caculate =
            await caculateProfitsForOneReceiptWithHalving(
              receipt,
              poolInvestInfo.value,
              [],
              "investment",
              now,
              false
            );

          data.interest +=
            receipt_after_caculate.totalClaimed *
            (1 - Number(poolInvestInfo.value.percent_commission));
          data.closeTime = receipt_after_caculate.close_time;
          const receipt_after_caculate_new =
            await caculateProfitsForOneReceiptWithHalving(
              receipt,
              poolInvestInfo.value,
              [],
              "investment",
              receipt_after_caculate.close_time +
                1 +
                Number(poolInvestInfo.value.range_time_withdraw_profits),
              false
            );

          data.interestNew =
            receipt_after_caculate_new.totalClaimed *
            (1 - Number(poolInvestInfo.value.percent_commission));
          data.closeTimeNew = receipt_after_caculate_new.close_time;
        })
      ));
    //console.log("🚀 ~ allReceipt.value?.investHistoryUser.map ~ data:", data);
    data.interest = Number(data.interest.toFixed(9));
    data.interestNew = Number(data.interestNew.toFixed(9));
    dataInvestmentSolx.value = data;
  } catch (err) {
    //console.log("🚀 ~ fetchDataStakeSolx ~ err:", err);
  }
};
function calculateOutput(
  now: number,
  time_last_update: number,
  step_time: number
) {
  const elapsedTime = now - time_last_update;
  const remainingTime = elapsedTime % step_time;
  const output = now - remainingTime - (remainingTime === 0 ? step_time : 0);
  return output;
}

const intervalHaving = ref<any>(null);
const statusHaving = ref<any>("0:0:0");
const countHaving = () => {
  try {
    if (intervalHaving.value) {
      clearInterval(intervalHaving.value);
    }
    intervalHaving.value = setInterval(() => {
      const now = new Date().getTime();
      const targetTime = new Date(poolInfo?.value?.next_halving).getTime();
      if (!poolInfo?.value?.next_halving) {
        clearInterval(intervalHaving.value);
        return;
      }
      const distance = targetTime - now;
      if (distance < 0) {
        clearInterval(intervalHaving.value);
        return;
      }
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      //
      statusHaving.value = days + ":" + hours + ":" + minutes + ":" + seconds;
    }, 1000);
  } catch (err) {
    //console.log("🚀 ~ countHaving ~ err:", err);
  }
};

//Lifecycle
watch(stakeCycle, (stakeNew, stakeOld) => {
  //console.log("🚀 ~ watch ~ stakeNew:", stakeNew);
  //console.log("🚀 ~ watch ~ stakeCycle:", stakeCycle);
  if (stakeNew && stakeNew.length > 0) {
    selectCycel.value = stakeNew[0];
  }
});

watch(itemSelected, (newItemSelected, oldItemSelected) => {
  //console.log("🚀 ~ watch ~ itemSelected change:", itemSelected);

  dataStakeSolx.value.receipts = dataStakeSolx.value.receipts.sort(
    (a: any, b: any) =>
      itemSelected.value == "newest"
        ? -(Number(a?.time_deposit) - Number(b?.time_deposit))
        : Number(a?.time_deposit) - Number(b?.time_deposit)
  );
});
const associatedTokenAddress = ref<any>({});
const loadingAssoc = ref<boolean>(false);

const addToken = async () => {
  try {
    connection = getRpcConnection();

    if (isDeviceLaptop() && !store.state.isTelegram) {
      loadingAssoc.value = true;
      window.localStorage.setItem(
        keyLoading.value.LOADING_CLAIM_SOLX_INIT,
        "true"
      );
      if (Number(store.state.addTokenSetting?.data?.send_value) > 0) {
        const feeaEs = (await solana.getEstTransferFee()) || 0.000005;
        //console.log("🚀 ~ addToken ~ feeaEs:", feeaEs);
        const feeAmount =
          feeaEs + feeaEs * (Number(store.state.infoReward?.est_fee_prc) / 100);
        //console.log("🚀 ~ addToken ~ addToken:", feeAmount);
        if (feeAmount <= 0) {
          message.error(translation("Fees_cannot_be_estimated"), {
            duration: 3000,
          });
          loadingAssoc.value = false;
          return;
        }

        if (
          Number(store.state.balanceTokenBase.toString()) - feeAmount <
          0.001
        ) {
          message.warning(
            translation("The_remaining_money_is_not_enough_to_pay_the_fee")
          );
          loadingAssoc.value = false;

          return;
        }
        const tx = await solana.addAssociatedTokenWebsite(
          store.state.addTokenSetting?.data
            ?.currency_setting_claim_solx_currencyTocurrency?.contract_address,
          poolInfo.value?.tokenAccountB,
          feeAmount,
          token_fee || "So11111111111111111111111111111111111111112",
          methodCrawler.value.FEE_CLAIM_SOLX_INIT
        );
        if (!tx) {
          loadingAssoc.value = false;

          return;
        }
        // callCrawlerHaveTransaction({
        //   method: methodCrawler.value.FEE_CLAIM_SOLX_INIT,
        // });
        // message.loading(
        //   translation("_add_token_success_Please_wait_a_moment"),
        //   {
        //     duration: 8000,
        //   }
        // );
        try {
          connection.confirmTransaction(tx, "finalized");
        } catch (err) {
          //console.log("🚀 ~ stakingWebsite ~ err:", err);
        }
        // await connection.confirmTransaction(tx, "finalized");
        return;
      } else {
        if (!associatedTokenAddress.value) {
          const tx = await solana.addAssociatedTokenWebsite(
            store.state.addTokenSetting?.data
              ?.currency_setting_claim_solx_currencyTocurrency?.contract_address
          );
          if (!tx) {
            loadingAssoc.value = false;
            return;
          }
          message.loading(
            translation("_add_token_success_Please_wait_a_moment"),
            {
              duration: 3000,
            }
          );
          fetchSettingAddToken();

          setTimeout(() => {
            message.warning(translation("_add_token_assoc_processing"), {
              duration: 3000,
            });
          }, 3000);
          setTimeout(() => {
            message.success(translation("_add_token_assoc_success"), {
              duration: 3000,
            });
          }, 3000);
        }
      }
    } else {
      const check = checkSessionConnectMobile();
      if (!check) {
        window.localStorage.setItem(
          keyLoading.value.LOADING_CLAIM_SOLX_INIT,
          "true"
        );
        if (Number(store.state.addTokenSetting?.data?.send_value) > 0) {
          // callCrawlerHaveTransaction({
          //   method: methodCrawler.value.FEE_CLAIM_SOLX_INIT,
          // });

          const feeaEs = (await solana.getEstTransferFee()) || 0.000005;
          //console.log("🚀 ~ addToken ~ feeaEs:", feeaEs);
          const feeAmount =
            feeaEs +
            feeaEs * (Number(store.state.infoReward?.est_fee_prc) / 100);
          //console.log("🚀 ~ addToken ~ addToken:", feeAmount);
          const url = await solana.addAssociatedTokenMobile(
            store.state.addTokenSetting?.data
              ?.currency_setting_claim_solx_currencyTocurrency
              ?.contract_address,
            poolInfo.value?.tokenAccountB,
            feeAmount,
            token_fee || "So11111111111111111111111111111111111111112",
            "?type=claim-solx-init"
          );
          if (!url) {
            loadingAssoc.value = false;

            return;
          }

          setLocalstoreReward(
            keysLocalStore.value,
            valuesLocalStore.value.KEY_CLAIM_SOLX_INIT
          );

          window.location.href = url?.toString();
        } else {
          if (!associatedTokenAddress.value) {
            setLocalstoreReward(
              keysLocalStore.value,
              valuesLocalStore.value.KEY_CLAIM_SOLX_INIT
            );
            const url = await solana.addAssociatedTokenMobile(
              store.state.addTokenSetting?.data
                ?.currency_setting_claim_solx_currencyTocurrency
                ?.contract_address,
              "",
              0,
              "",
              "?type=claim-solx-init-assoc"
            );
            if (!url) {
              loadingAssoc.value = false;

              return;
            }
            window.location.href = url?.toString();
          }
        }
      } else {
        store.commit("setPlacement", "top-right");

        message.warning(translation("Session expired, disconnect now!"), {
          duration: 1500,
        });
        setTimeout(() => {
          solana.disconnect();
        }, 1000);
      }
    }
    loadingAssoc.value = false;
    window.localStorage.setItem(
      keyLoading.value.LOADING_CLAIM_SOLX_INIT,
      "false"
    );
  } catch (err) {
    rejectCrawler();

    loadingAssoc.value = false;
    //console.log("🚀 ~ addToken ~ err.name:", err.name);
    window.localStorage.setItem(
      keyLoading.value.LOADING_CLAIM_SOLX_INIT,
      "false"
    );
    if (err.name == "WalletSendTransactionError" || err.name == "Error") {
      message.error(translation("transaction_cancle"), {
        duration: 5000,
      });
    }
    //console.log("🚀 ~ addToken ~ err:", err);
  }
};

const getAssociatedTokenAddressUser = async () => {
  try {
    connection = getRpcConnection();

    if (!store.state.addTokenSetting) {
      //console.log("addTokenSetting empty");
      setTimeout(() => {
        getAssociatedTokenAddressUser();
      }, 1500);
      return;
    }
    let toAddress = "";
    if (isDeviceLaptop() && !store.state.isTelegram) {
      const { publicKey } = useWallet();
      toAddress = publicKey.value.toString();
    } else {
      toAddress = store.state.sessionSolana?.public_key.toString();
    }
    const tokenAccountTo = await getAssociatedTokenAddress(
      new PublicKey(
        store.state.addTokenSetting?.data?.currency_setting_claim_solx_currencyTocurrency?.contract_address
      ),
      new PublicKey(toAddress)
    );
    const account = await connection.getAccountInfo(tokenAccountTo);

    //console.log("tokenAccountTo", account);

    associatedTokenAddress.value = account;
  } catch (err) {
    associatedTokenAddress.value = null;

    //console.log("🚀 ~ getAssociatedTokenAddress ~ err:", err);
  }
};
const checkPermissionBoost = () => {
  if (!store.state?.user?.token) {
    boostOption.value = "boost_selection_2";
    return;
  }
  const isBoostFormMint =
    store.state.permission && store.state.permission?.success == true
      ? store.state.permission?.data["boost-from-mint"]
      : store.state.poolInfo?.system_enable_boost_from_mint;
  const isBoost =
    store.state.permission && store.state.permission?.success == true
      ? store.state.permission?.data["boost"]
      : store.state.poolInfo?.system_enable_boost;

  if (isBoost && !isBoostFormMint) {
    boostOption.value = "boost_selection_2";
  } else if (!isBoost && isBoostFormMint) {
    boostOption.value = "boost_selection_1";
  }
};
//est contribute interest
const estInterestContributeSetting = ref<any>(null);
const calculateClaimMint = () => {
  const infoReward: any = store.state.infoReward;
  if (!infoReward) return;
  const { claimList, claimSetting } = infoReward;
  if (!claimList || claimList?.length <= 0) return;
  let sum = 0;
  const currentTime = new Date().getTime() / 1000;
  for (const claimPackage of claimList || []) {
    if (claimPackage?.startTime === null) return "startTime is null";

    const startTime = new Date(claimPackage?.startTime).getTime() / 1000;
    const claimAmountOfPackage = calculateAmountWithoutCycle(
      startTime,
      claimSetting?.rate24Hour,
      currentTime,
      Number(claimPackage?.deposit)
    );

    sum += claimAmountOfPackage;
  }
  estInterestContributeSetting.value = {
    interest: sum,
  };
};
const calculateAmountWithoutCycle = (
  nextClaimTime: number,
  rate24Hour: number,
  currentTime: number,
  amountDeposit: number
) => {
  const elapsed = currentTime - nextClaimTime;
  const secondIn24H = 86400;
  return (elapsed * rate24Hour * amountDeposit) / secondIn24H;
};
//
watch(poolInfo, async (newQuestion, oldQuestion) => {
  countHaving();
  checkPermissionBoost();
  //
  startCountdownSwap();

  setTimeout(() => {
    rateTvl.value =
      ((Number(balancePool.value.balanceSol) * store.state.currentPrice?.sol -
        Number(store.state.price24h?.balance_sol) *
          Number(priceSol24hValue.value)) /
        (Number(store.state.price24h?.balance_sol) *
          Number(priceSol24hValue.value))) *
      100;
  }, 2000);
});
const intervalEstInterestBoost = ref<any>(null);
const intervalEstInterestMint = ref<any>(null);

watch(
  () => store.state.receipts,
  (oldValue, newValue) => {
    //console.log("🚀 ~ oldValue111:", oldValue);
    //console.log("🚀 ~ newValue111:", newValue);
    if (!newValue) {
      fetchDataInvestmentSolx();
    }
  }
);
watch(
  () => store.state.user,
  (newVal) => {
    fetchPermission();
    fetchSettingAddToken();

    dataStakeSolx.value = {
      arp: 0,
      your_stake: 0,
      total_stake: 0,
      principal: "Loading...",
      interest: "Loading...",
      receipts: [],
    };

    checkMyAffStatus();
    stakeHistorys();
    getSettingFeeIn();
    setTimeout(() => {
      startCircle();
      fetchDataInvestmentSolx();
      fetchPermission();
      checkPermissionBoost();
      getSwapInfo();
      fetchDataStakeSolxSetting();
      fetchDataStakeSolx();
    }, 2000);

    if (newVal?.token) {
      //
      if (intervalEstInterestBoost.value || !store.state?.user?.token) {
        clearInterval(intervalEstInterestBoost.value);
      }
      intervalEstInterestBoost.value = setInterval(() => {
        fetchDataStakeSolxSetting();
        fetchDataStakeSolx();
      }, Number(store.state.poolInfo?.circle_est_interest_boost) * 1000);
      //
      if (intervalEstInterestMint.value || !store.state?.user?.token) {
        clearInterval(intervalEstInterestMint.value);
      }
      intervalEstInterestMint.value = setInterval(() => {
        calculateClaimMint();
        getClaimInfo();
      }, Number(store.state.poolInfo?.circle_est_interest_contribute) * 1000);
      //
      getAssociatedTokenAddressUser();
      getClaimInfo();

      if (intervalDataStake.value || !store.state?.user?.token) {
        clearInterval(intervalDataStake.value);
      }
      intervalDataStake.value = setInterval(() => {
        if (!store.state?.user?.token) {
          clearInterval(intervalDataStake.value);
        }
        stakeHistorys();
        getSwapInfo();
        // fetchDataStakeSolx();
        fetchDataInvestmentSolx();
      }, 10000);

      //
      // if (isDeviceLaptop()) {
      //   connection = getRpcConnection();

      //   const wallet: any = useAnchorWallet();
      //   //console.log("🚀 ~ onMounted ~ wallet:", wallet);
      //   const { program: programInit } = useProgram({
      //     connection,
      //     wallet: wallet?.value,
      //   });
      //   //console.log("🚀 ~ onMounted ~ program:", programInit);
      //   program.value = programInit;
      // } else {
      //   connection = getRpcConnection();

      //   //console.log("mobile wallet");
      //   const session_connect = JSON.parse(
      //     window.localStorage.getItem("walletConnectSession") ?? ""
      //   );
      //   const secrect_local = base58.decode(session_connect?.sharedSecretDapp);
      //   const keypair = nacl.sign.keyPair.fromSeed(secrect_local);
      //   const key_primary = Keypair.fromSecretKey(keypair.secretKey);
      //   const wallet = new MyWallet(key_primary);
      //   const { program: programInit } = useProgram({
      //     connection,
      //     wallet: wallet,
      //   });
      //   program.value = programInit;

      //   //console.log("🚀 ~ programInit:", programInit);
      // }
    } else {
      showMintNow.value = false;
      showBoostNow.value = false;
      showSwapNow.value = false;
    }
  },
  { immediate: true }
);

watch(reward, async (prevA, prevB) => {
  startCountdown();
  calculateClaimMint();
});
import { getAssociatedTokenAddress } from "@solana/spl-token";
import checkMyAffStatus from "@/common/function/checkMyAffStatus";
import ChartLine from "./ChartLine.vue";
import TwoTabAnalytic from "./TwotabAnalytic.vue";
import NoContribute from "./NoContribute.vue";

import isDark from "@/common/function/isDark";
import useResponsive from "@/hook/useResponsive";
import KatexTooltip from "./KatexTooltip.vue";

const price24h = computed(() => store.state.price24h);
watch(price24h, async (priceNew, priceOld) => {
  //console.log("🚀 ~ watch ~ priceNew:", priceNew);
  //console.log("🚀 ~ watch ~ priceOld:", priceOld);
  if (!priceOld || priceNew?.price != priceOld?.price) {
    //console.log("change price");
    fetchPricePerHouse();
  }
});
const fetchPricePerHouse = async () => {
  try {
    const tokenAccountB = await getAssociatedTokenAddress(
      new PublicKey(store.state.poolInfo.mintB),
      new PublicKey(store.state.poolInfo.tokenAccountB)
    );
    connection = getRpcConnection();

    console.log(
      "🚀 ~ getRatePoolSwap ~ tokenAccountB:",
      tokenAccountB.toBase58()
    );

    const tokenAccountSOLX = tokenAccountB;

    const infoTokenUSDC = await connection.getBalance(
      new PublicKey(store.state.poolInfo.tokenAccountA)
    );
    //console.log("🚀 ~ getRatePoolSwap ~ infoTokenUSDC:", infoTokenUSDC);
    const infoTokenSOLX = await connection.getTokenAccountBalance(
      tokenAccountSOLX
    );
    const balance = {
      balanceSol: infoTokenUSDC / 10 ** 9,
      balanceSolx: Number(infoTokenSOLX.value.uiAmount),
      // balanceSolxAdmin: Number(infoTokenSOLXAdmin.value.uiAmount),
    };
    // *
    // store.state.currentPrice?.sol
    const priceSolx =
      (balance.balanceSol * Number(poolInfo?.value?.price_ratio)) /
      balance.balanceSolx;
    //console.log("🚀 ~ getRatePoolSwap ~ priceSolx:", priceSolx);

    rateCurrentPool.value = priceSolx;
    console.log(
      "🚀 ~ getRatePoolSwap ~ store.state.price24h?.price:",
      store.state.price24h?.price
    );

    // const rateInit = Number(store.state.price24h?.price);
    const rateInit =
      (Number(poolInfo.value?.tokenASupplyInitPool) *
        Number(poolInfo?.value?.price_ratio)) /
      Number(poolInfo.value?.tokenBSupplyInitPool);
    //console.log("🚀 ~ getRatePoolSwap ~ rateInit:", rateInit);

    rateCurrentSolxOnBaseToken.value = priceSolx;
    rateTokenPoolSwap.value.rateCurrent = `1 SOLX = ${toFixedNumber(
      priceSolx,
      8
    )} SOL`;
    rateTokenPoolSwap.value.growth = ((priceSolx - rateInit) / rateInit) * 100;
  } catch (err) {
    //console.log("🚀 ~ fetchPricePerHouse ~ err:", err);
  }
};
const priceSol24hValue = ref<any>(null);
const intervalPriceSolx = ref<any>(null);
const rateTvl = ref<number>(0);

onMounted(() => {
  fetchPrice24h();
  startCountdownFeture();
  if (intervalPriceSolx.value || !store.state?.user?.token) {
    clearInterval(intervalPriceSolx.value);
  }
  intervalPriceSolx.value = setInterval(() => {
    fetchPrice24h();
    rateTvl.value =
      ((Number(balancePool.value.balanceSol) * store.state.currentPrice?.sol -
        Number(store.state.price24h?.balance_sol) *
          Number(priceSol24hValue.value)) /
        (Number(store.state.price24h?.balance_sol) *
          Number(priceSol24hValue.value))) *
      100;
  }, 60 * 60 * 1000);
});
const triggerTabBoost = ref<number>(0);
onMounted(async () => {
  fetchHaving();

  await getPoolSwapInfo();
  getPoolStake();
  fetchPrice();
  emitter.on("statistics", async (data) => {
    //console.log("🚀 ~ emitter.on ~ e:", data.stake?.status);
    //console.log("eventttttt");

    if (
      data.stake?.status == "success" ||
      data?.swap?.status == "success" ||
      data?.withdraw?.status == "success" ||
      data["claim-staking-interest"]?.status == "success" ||
      data["claim-staking-principle"]?.status == "success" ||
      data["loan"]?.status == "success" ||
      data["investment"]?.status == "success"
    ) {
      getBalanceUser();
      emitter.emit("fetchAff", {});
      stakeHistorys();
      setTimeout(() => {
        fetchDataStakeSolxSetting();
        fetchDataStakeSolx();
        fetchDataInvestmentSolx();
      }, 1500);

      getPoolSwapInfo();
      // setTimeout()
      //console.log("update data");
      const { result, refetch, onResult } = provideApolloClient(userClient)(
        () => useQuery(getClaim)
      );
      refetch();

      const {
        result: result2,
        refetch: refetch2,
        onResult: onResult2,
      } = provideApolloClient(basicClient)(() => useQuery(swapInfo));
      refetch2();
    }
    if (
      data?.withdraw?.status ||
      data["claim-staking-interest"]?.status ||
      data["claim-staking-principle"]?.status ||
      data["withdraw-bonus"]?.status ||
      data["withdraw-bonus"]?.status ||
      data["claim-solx-init"]?.status ||
      data["claim-investment-interest"]?.status ||
      data["claim-investment-principle"]?.status
    ) {
      //console.log("data trigger statictis", data);
      if (
        data["claim-solx-init"]?.status &&
        data["claim-solx-init"]?.status != "processing"
      ) {
        fetchSettingAddToken();
        getAssociatedTokenAddressUser();
        setTimeout(() => {
          window.localStorage.setItem(
            keyLoading.value.LOADING_CLAIM_SOLX_INIT,
            "false"
          );
        }, 8000);
        return;
      }
      if (data?.withdraw?.status && data?.withdraw?.status != "processing") {
        calculateClaimMint();
        getClaimInfo();
        getBalanceUser();
        setTimeout(() => {
          window.localStorage.setItem(
            keyLoading.value.LOADING_CLAIM_MINING,
            "false"
          );
        }, 8000);
        return;
      }

      if (
        data["claim-investment-interest"]?.status &&
        data["claim-investment-interest"]?.status != "processing"
      ) {
        stakeHistorys();
        setTimeout(() => {
          fetchDataInvestmentSolx();
        }, 2000);

        getBalanceUser();
        setTimeout(() => {
          window.localStorage.setItem(
            keyLoading.value.LOADING_CLAIM_INVESTMENT_PROFIT,
            "false"
          );
        }, 8000);
        return;
      }
      if (
        data["claim-investment-principle"]?.status &&
        data["claim-investment-principle"]?.status != "processing"
      ) {
        stakeHistorys();
        setTimeout(() => {
          fetchDataInvestmentSolx();
        }, 2000);
        getBalanceUser();
        setTimeout(() => {
          window.localStorage.setItem(
            keyLoading.value.LOADING_CLAIM_INVESTMENT_PRINCIPLE,
            "false"
          );
        }, 8000);
        return;
      }

      if (
        data["claim-staking-interest"]?.status &&
        data["claim-staking-interest"]?.status != "processing"
      ) {
        stakeHistorys();
        setTimeout(() => {
          fetchDataStakeSolxSetting();
          fetchDataStakeSolx();
        }, 2000);
        getBalanceUser();
        setTimeout(() => {
          window.localStorage.setItem(
            keyLoading.value.LOADING_CLAIM_PROFIT,
            "false"
          );
        }, 8000);
        return;
      }
      if (
        data["claim-staking-principle"]?.status &&
        data["claim-staking-principle"]?.status != "processing"
      ) {
        stakeHistorys();
        setTimeout(() => {
          fetchDataStakeSolxSetting();
          fetchDataStakeSolx();
          triggerTabBoost.value = Math.random();
        }, 2000);
        getBalanceUser();
        setTimeout(() => {
          window.localStorage.setItem(
            keyLoading.value.LOADING_CLAIM_PRINCIPLE,
            "false"
          );
        }, 8000);
        return;
      }

      if (
        data["withdraw-bonus"]?.status &&
        data["withdraw-bonus"]?.status != "processing" &&
        data["withdraw-bonus"]?.transaction?.type == "withdraw-bonus"
      ) {
        setTimeout(() => {
          store.commit("setLoadingBonus", false);
        }, 8000);

        return;
      }

      if (
        data["withdraw-bonus"]?.status &&
        data["withdraw-bonus"]?.status != "processing" &&
        data["withdraw-bonus"]?.transaction?.type == "withdraw-bonus-stake"
      ) {
        setTimeout(() => {
          store.commit("setLoadingBonusx", false);
        }, 8000);

        return;
      }
    }
  });
});
const intervalSolxMined = ref<any>();
const intervalLoading = ref<any>(null);

onMounted(() => {
  getBalanceUser();
  setTimeout(() => {
    getBalanceUser();
  }, 10000);

  if (intervalBalanceUser.value || !store.state?.user?.token) {
    clearInterval(intervalBalanceUser.value);
  }
  intervalBalanceUser.value = setInterval(() => {
    if (!store.state?.user?.token) {
      clearInterval(intervalDataStake.value);
      return;
    }
    getBalanceUser();
  }, 30000);

  if (intervalSolxMined.value || !store.state?.user?.token) {
    clearInterval(intervalSolxMined.value);
  }
  intervalSolxMined.value = setInterval(() => {
    if (!store.state?.user?.token) {
      clearInterval(intervalDataStake.value);
      return;
    }
    // if (
    //   store.state?.infoReward?.time_est_mint_receive &&
    //   store.state?.infoReward?.my_stake > 0 &&
    //   new Date().getHours() >
    //     new Date(store.state?.infoReward?.time_est_mint_receive).getHours()
    // ) {
    //   getClaimInfo();
    // }
    getPoolSwapInfo();
    fetchHaving();
  }, 10000);

  if (intervalLoading.value || !store.state?.user?.token) {
    clearInterval(intervalLoading.value);
  }
  intervalLoading.value = setInterval(() => {
    // alert("testtt");
    // if (!isDeviceLaptop()) {
    if (!store.state?.user?.token) {
      clearInterval(intervalDataStake.value);
      return;
    }
    if (loadingWithdrawInvestmentOrigin.value == true) {
      window.localStorage.setItem(
        keyLoading.value.LOADING_CLAIM_INVESTMENT_PRINCIPLE,
        "false"
      );
      loadingWithdrawInvestmentOrigin.value = false;
    }
    if (loadingWithdrawInvestmentProfit.value == true) {
      window.localStorage.setItem(
        keyLoading.value.LOADING_CLAIM_INVESTMENT_PROFIT,
        "false"
      );
      loadingWithdrawInvestmentProfit.value = false;
    }

    if (loadingWithdrawOrigin.value == true) {
      window.localStorage.setItem(
        keyLoading.value.LOADING_CLAIM_PRINCIPLE,
        "false"
      );
      loadingWithdrawOrigin.value = false;
    }
    if (loadingWithdrawProfit.value == true) {
      window.localStorage.setItem(
        keyLoading.value.LOADING_CLAIM_PROFIT,
        "false"
      );
      loadingWithdrawProfit.value = false;
    }
    if (loadingClaim.value == true) {
      window.localStorage.setItem(
        keyLoading.value.LOADING_CLAIM_MINING,
        "false"
      );
      loadingClaim.value = false;
    }
    if (loading.value == true) {
      loading.value = false;
    }
    if (loadingAssoc.value == true) {
      window.localStorage.setItem(
        keyLoading.value.LOADING_CLAIM_SOLX_INIT,
        "false"
      );

      loadingAssoc.value = false;
    }
    // }
  }, 360000);
});

onMounted(() => {
  setInterval(() => {
    if (isDeviceLaptop() && !store.state.isTelegram) {
      if (!getWalletAddressAll()) return;
      const isAccountChanged =
        store.state.walletAddress !== `${getWalletAddressAll()}`;
      const { disconnect } = useWallet();
      if (isAccountChanged) {
        store.commit("setUser", null);

        window.localStorage.setItem(
          keyLoading.value.LOADING_CLAIM_MINING,
          "false"
        );
        window.localStorage.setItem(
          keyLoading.value.LOADING_CLAIM_PROFIT,
          "false"
        );
        window.localStorage.setItem(
          keyLoading.value.LOADING_CLAIM_PRINCIPLE,
          "false"
        );
        window.localStorage.setItem(
          keyLoading.value.LOADING_CLAIM_INVESTMENT_PROFIT,
          "false"
        );
        window.localStorage.setItem(
          keyLoading.value.LOADING_CLAIM_INVESTMENT_PRINCIPLE,
          "false"
        );
        window.localStorage.setItem(
          keyLoading.value.LOADING_CLAIM_SOLX_INIT,
          "false"
        );
        // statusClaim.valu;
        // do some updates
        disconnect();
      }
    }
  }, 1000);
});

onMounted(() => {
  let urlParams = new URLSearchParams(window.location.search);
  if (
    urlParams.has("errorCode") ||
    window.location.search.includes("errorCode")
  ) {
    window.localStorage.setItem(keyLoading.value.LOADING_CLAIM_MINING, "false");
    window.localStorage.setItem(keyLoading.value.LOADING_CLAIM_PROFIT, "false");
    window.localStorage.setItem(
      keyLoading.value.LOADING_CLAIM_PRINCIPLE,
      "false"
    );
    window.localStorage.setItem(
      keyLoading.value.LOADING_CLAIM_SOLX_INIT,
      "false"
    );
    rejectCrawler();
  }
  if (isDeviceLaptop()) {
    window.localStorage.setItem(keyLoading.value.LOADING_CLAIM_MINING, "false");
    window.localStorage.setItem(keyLoading.value.LOADING_CLAIM_PROFIT, "false");
    window.localStorage.setItem(
      keyLoading.value.LOADING_CLAIM_INVESTMENT_PROFIT,
      "false"
    );

    window.localStorage.setItem(
      keyLoading.value.LOADING_CLAIM_PRINCIPLE,
      "false"
    );
    window.localStorage.setItem(
      keyLoading.value.LOADING_CLAIM__INVESTMENT_PRINCIPLE,
      "false"
    );
    window.localStorage.setItem(
      keyLoading.value.LOADING_CLAIM_SOLX_INIT,
      "false"
    );
  }
  if (intervalCheckLoading.value || !store.state?.user?.token) {
    clearInterval(intervalCheckLoading.value);
  }
  intervalCheckLoading.value = setInterval(() => {
    const loadingClaimMining = window.localStorage.getItem(
      keyLoading.value.LOADING_CLAIM_MINING
    );
    const loadingClaimProfit = window.localStorage.getItem(
      keyLoading.value.LOADING_CLAIM_PROFIT
    );
    const loadingClaimPrinciple = window.localStorage.getItem(
      keyLoading.value.LOADING_CLAIM_PRINCIPLE
    );
    const loadingClaimSolxInit = window.localStorage.getItem(
      keyLoading.value.LOADING_CLAIM_SOLX_INIT
    );
    const loadingClaimInvestmentProfit = window.localStorage.getItem(
      keyLoading.value.LOADING_CLAIM_INVESTMENT_PROFIT
    );
    const loadingClaimInvestmentPrinciple = window.localStorage.getItem(
      keyLoading.value.LOADING_CLAIM_INVESTMENT_PRINCIPLE
    );
    //
    if (
      loadingClaimInvestmentProfit &&
      loadingClaimInvestmentProfit != "false"
    ) {
      loadingWithdrawInvestmentProfit.value = true;
    } else {
      loadingWithdrawInvestmentProfit.value = false;
    }
    if (
      loadingClaimInvestmentPrinciple &&
      loadingClaimInvestmentPrinciple != "false"
    ) {
      loadingWithdrawInvestmentOrigin.value = true;
    } else {
      loadingWithdrawInvestmentOrigin.value = false;
    }
    //
    if (loadingClaimSolxInit && loadingClaimSolxInit == "true") {
      loadingAssoc.value = true;
    } else {
      loadingAssoc.value = false;
    }
    if (loadingClaimMining && loadingClaimMining == "true") {
      loadingClaim.value = true;
    } else {
      loadingClaim.value = false;
    }
    //
    if (loadingClaimProfit && loadingClaimProfit != "false") {
      loadingWithdrawProfit.value = true;
    } else {
      loadingWithdrawProfit.value = false;
    }
    //
    if (loadingClaimPrinciple && loadingClaimPrinciple != "false") {
      loadingWithdrawOrigin.value = true;
    } else {
      loadingWithdrawOrigin.value = false;
    }
  }, 5000);
});
import { box, randomBytes } from "tweetnacl";
import MobileDetect from "mobile-detect";

const sessionSolana = ref<any>(null);
const isMobile = new MobileDetect(window.navigator.userAgent).mobile();

onMounted(() => {
  const urlParams = new URLSearchParams(window.location.search);

  if (isMobile && (store.state.isTelegram || urlParams.has("telegram_id"))) {
    try {
      let localItem: any | null = window.localStorage.getItem(
        "walletConnectSession"
      )
        ? JSON.parse(
            window.localStorage.getItem("walletConnectSession") as string
          )
        : null;
      //console.log("🚀 ~ onMounted ~ localItem:", localItem);
      // if (localItem !== null) {
      //   solana.setSession(localItem);
      //   sessionSolana.value = localItem;
      // } else {
      let urlParams = new URLSearchParams(window.location.search);

      const wallet: any = walletAdapter.filter(
        (item) => item.adapter == urlParams.get("walletName")
      )[0];

      if (!wallet) {
        return;
      }
      if (urlParams.has(wallet.key)) {
        const dapp_encryption_public_key = urlParams.get(wallet.key) as string;
        const sharedSecretDapp = box.before(
          base58.decode(dapp_encryption_public_key),
          base58.decode(urlParams.get("keyPairEncryptionSecret") ?? "")
        );
        const connectData = decryptPayload(
          urlParams.get("data") ?? "",
          urlParams.get("nonce") ?? "",
          sharedSecretDapp
        );
        window.localStorage.setItem(
          "walletConnectSession",
          JSON.stringify({
            ...connectData,
            sharedSecretDapp: base58.encode(sharedSecretDapp),
            keyPairEncryptionPublic: urlParams.get("keyPairEncryptionPublic"),
          })
        );
      }
      // }
      const adapter = window.localStorage.getItem("walletAdapter")
        ? JSON.parse(window.localStorage.getItem("walletAdapter"))
        : "";
      solana.setWalletAdapter(adapter);
      localItem = window.localStorage.getItem("walletConnectSession");
      //console.log("🚀 ~ onMounted ~ localItem:", localItem);
      solana.setSession(localItem !== null ? JSON.parse(localItem) : null);

      sessionSolana.value = localItem !== null ? JSON.parse(localItem) : null;

      const address = new PublicKey(sessionSolana.value?.public_key as string);
      login({
        wallet_address: address.toBase58(),
        network_slug: "sol",
      });
      saveSessionWallet({
        public_key: sessionSolana.value?.public_key,
        session: sessionSolana.value?.session,
        shared_secret_dapp: sessionSolana.value?.sharedSecretDapp,
        key_pair_encryption_public:
          sessionSolana.value?.keyPairEncryptionPublic,
        telegram_id: urlParams.get("telegram_id"),
      });
      // }
    } catch (error) {
      //console.log("🚀 ~ onMounted ~ error:", error);
      store.commit("setLog", { error });
    }
  }
});
onUnmounted(() => {
  emitter.off("statistics");
});
</script>

<style scoped>
.message_connect {
  display: flex;
  padding: 5px 0px;
  justify-content: start;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  border-radius: 4px;
  background: rgba(90, 175, 255, 0.32);
  color: #2069ff;
}
.latest_title {
  color: var(--Neutral-03, #636363);
  font-family: "GT Walsheim";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  text-transform: capitalize;
}
.dark .latest_date,
.dark .latest_title {
  color: var(--Neutral-03, #939393);
}
.latest_date {
  color: var(--Neutral-03, #636363);
  font-family: "GT Walsheim";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 19.5px */
}
.number_lastest {
  color: var(--Neutral-03, #636363);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 18px */
}
.arrow_container {
  transition: 300ms;
}
.dark .bg-boost {
  background-color: var(--limit-color-dark-mode);
  border-color: var(--limit-color-dark-mode);
}
.dark .boost-dark,
.dark .mint-dark {
  padding: 0;
  border: 0px;
  background-color: transparent;
}
.rotate_180 {
  transform: rotate(-180deg);
}

.filter_icon {
  display: block;
  width: 24px;
  height: 24px;
}

.collapse_action span {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  /* 32.4px */
}

.collapse_action {
  display: flex;
  align-items: center;
}

.options_g {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: start;
  width: 100%;
  flex-wrap: wrap;
}

.loan_claim_group {
  display: flex;
  max-width: 650px !important;
  width: 100%;
  padding: 16px 24px;
  /* flex-direction: column; */
  align-items: stretch;
  gap: 16px;
  align-self: center;
  border-radius: 16px;
  border: 2px solid var(--app-questn-com-black, #000);
  background: var(--app-questn-com-purple-pizzazz, #f5ffce);
}

.horizontal_divider {
  border-left: 2px solid var(--app-questn-com-black, #000);
}

.loan_claim_item {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.loan_claim_info {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
}

.loan_claim_title {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  /* 32.4px */
}

.loan_claim_value {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  /* 28px */
  text-transform: capitalize;
}

.checkbox_g .v-input__details {
  display: none;
}

.checkbox_g {
  align-self: flex-start;
  transform: translateX(-12px);
  display: flex;
  align-items: center;
}
.checkbox_g_swap {
  transform: translateX(0px);
  opacity: 1;
}
.checkbox_g_mining {
  margin-top: -16px;
}

.checkbox_label a {
  text-decoration: underline;
  color: var(--app-questn-com-black, #000) !important;
  cursor: pointer;
}

.checkbox_label {
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%;
  color: var(--app-questn-com-black, #000) !important;
}

.solx_container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.title_solx {
  margin-bottom: 8px;
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 8px;
}

.line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.dark .field {
  color: var(--text-color-dark-mode) !important;
}

.field {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.dark .value {
  color: var(--text-color-dark-mode) !important;
}
.dark .value_g b {
  color: var(--text-color-dark-mode) !important;
}
.value_g b {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  text-align: right;
}

.value_g.swap b {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
  text-transform: uppercase;
}

.value_g {
  gap: 8px;
  display: flex;
  align-items: center;
}
.value_g.having {
  color: var(--app-questn-com-saffron, #f3ba2f);
  font-family: "GT Walsheim";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.copy_wallet_address_icon {
  display: block;
  width: 24px;
  height: 24px;
}
.solx_logo {
  display: block;
  width: 48px;
  height: 48px;
}
@media (max-width: 1279px) {
  .solx_logo {
    display: block;
    width: 36px !important;
    height: 36px !important;
  }
}

.having_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.having_title {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.having_countdown {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.guide_button_container {
  display: flex;
  align-items: flex-start;
  gap: 6px;
}
.dark .guide_title {
  color: var(--text-color-dark-mode);
}
.guide_title {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 21.6px */
}

.guide_arrow {
  width: 24px;
  height: 24px;
}

.info_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.row_card {
  display: flex;
  gap: 2%;
}

.layout-statictis {
  display: flex;
  flex-direction: column;
  /* gap: 16px; */
}

.home_statistic_container {
  display: flex;
  flex-direction: column;
  gap: 36px;
  position: relative;
}

.dark .home_statistic_container_row_2 {
  background-color: var(--dark-black-light) !important;
  border: 2px var(--limit-color-dark-mode) solid !important;
}

.home_statistic_container_row_2 {
  padding: 0;
}

.row_1 {
  display: flex;
  gap: 16px;
}

.card {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.r1 {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.r2 {
  display: flex;
  align-items: center;
  gap: 4px;
}

.r2_1 {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.r2_2 {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.r3 {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.card1 .r3 {
  display: flex;
  align-items: center;
  gap: 4px;
}

.card3 {
  display: flex;
  padding: 36px 120px;

  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.card3 > * {
  width: 100%;
}

.button_group {
  display: flex;
  align-items: center;
  gap: 16px;
}

.title {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.balance_group {
  width: 100%;
  display: flex;
  gap: 4px;
  justify-content: start;
  align-items: center;
}

.tab-power {
  display: flex;
  flex-direction: column;
  gap: 16px;
  transition: all 0.2 linear;
}
.tab-power.visible {
  width: 0;
  height: 0;
  visibility: hidden;
  transition: all 0.2 linear;
}
.balance_group > span:nth-child(1) {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  /* 32.4px */
}
.balance_group > span:nth-child(3) {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  /* 32.4px */
}

.balance_group > span:nth-child(2) {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 180%;
  /* 32.4px */
}

@media (max-width: 1279px) {
  .stake_group {
    border-radius: 16px !important;
  }
}

.stake_group {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  max-width: 650px !important;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  border-radius: 24px;
}

.input_group {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 6px;
}
.input_group.section_swap > span {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
}
.input_group.section_swap > div {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
}
.input_group.section_swap .ratio {
  display: flex;
  padding: 2px 2px 2px 8px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border: 2px var(--app-questn-com-black) solid;
  background-color: white;
  border-radius: 100px;
}
.input_group.section_swap .ratio span {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
}
.input_group.section_swap .ratio .percent_slippage {
  display: flex;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 2px solid #000;
  background-color: #ebff00;
  cursor: pointer;
}
.input_group.button_swap {
  justify-content: center;
}
.group_swap_ralative {
  display: flex;
  align-items: stretch;
  width: 100%;
  gap: 5%;
  justify-content: space-between;
  border: 2px black solid;
  /* background-color: transparent; */
  background-color: rgba(255, 255, 255, 0.42);
}
.row1 {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.growth-red {
  color: #ff5153;
  font-family: "GT Walsheim";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.card_price_solx {
  justify-content: start;
}
@media (max-width: 600px) {
  .group_swap_ralative > input {
    font-size: 20px !important;
  }
}
.group_swap_ralative > input {
  flex: 1;
  outline: none;
  padding: 12px 16px !important;
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  /* 18px */
  text-transform: capitalize;
  width: 60%;
}
.group_swap_ralative > #input_mint {
  flex: 1;
  outline: none;
  padding: 12px 16px !important;
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  /* 18px */
  text-transform: capitalize;
  width: 60%;
}
.group_swap_ralative > .input_mint {
  flex: 1;
  outline: none;
  padding: 12px 16px !important;
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  /* 18px */
  text-transform: capitalize;
  width: 60%;
}

.group_swap_ralative > span {
  flex: 1;
  outline: none;
}
.input_group > input {
  border-bottom: 2px var(--app-questn-com-black) solid;

  flex: 1;
  outline: none;
  padding: 12px 16px !important;
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  /* 18px */
  text-transform: capitalize;
  width: 60%;
}

.row_content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.stake_group .row_content .field {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%;
  /* 28.8px */
}

.stake_group .row_content .value {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 180%;
  /* 28.8px */
}

.content_group {
  color: #636363;
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  /* 32.4px */
  display: flex;
  flex-direction: column;
}

.claim_group {
  /* display: flex;
  align-items: center;
  gap: 16px; */
  max-width: 650px !important;
  margin: 0 auto;
  width: 100%;
  flex-wrap: wrap;
}

.claim_content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.claim_content.right {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.claim_content > span:nth-child(1) {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  white-space: nowrap;
  /* 32.4px */
}

.claim_content > span:nth-child(2) {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 180%;
  /* 32.4px */
}

.row2 {
  display: flex;
  justify-content: center;
}

.message-solx {
  width: 100%;
  display: flex;
  justify-content: start;
  color: red;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.group_max_swap {
  display: flex;
  gap: 4px;
  align-items: center;
}
.group_max_swap .button_max {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
  text-transform: capitalize;
  padding: 2px 8px;
  background-color: #9dcced;
  border-radius: 8px;
  cursor: pointer;
}
.button_max.visibility {
  visibility: hiddden;
}
.arrow_button_max {
  width: 1px;
  background-color: #b4b4b4;
  height: 20px;
  margin: 0 4px;
}
.swap_sipple_option {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border: 2px solid var(--app-questn-com-black, #000);
  min-width: 250px;
}
.list_item_swap {
  width: 100%;
}
.list_item_swap_button {
  width: 98%;
  margin-bottom: 8px !important;
}
.percent_swap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.checkbox_label_swap {
  color: #db4e07 !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.button_swap {
  width: 100%;
}
.input_group_to_swap {
  align-items: center;
  width: auto;
  border: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.input_check_box {
  margin-left: -8px;
}
.row_content_mining {
  display: flex;
  flex-direction: column;
}
.row_content_mining_p {
  align-items: center;
  margin-bottom: 12px;
}
.field_token_mining {
  color: var(--app-questn-com-black, #000);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 28px */
  text-transform: capitalize;
}
.your_solx_available {
  color: var(--Neutral-03, #141416);
  font-family: "GT Walsheim";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
}
@media (max-width: 900px) {
  .boost_main_des {
    font-size: 14px !important;
  }
  .field_token_mining {
    font-size: 18px;
  }
  .stake_group .row_content .value,
  .stake_group .row_content .field {
    font-size: 16px;
  }
  .home_statistic_container {
    gap: 24px;
  }
  .row1 {
    gap: 12px;
  }

  .row_1 {
    flex-direction: column;
    gap: 12px;
  }

  .card {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .layout-statictis {
    justify-content: start;
    align-items: start;
  }

  .r2_2 {
    font-size: 14px;
  }

  .r1 {
    font-size: 17px;
    font-weight: 500;
  }

  .r2 {
    /* justify-content: end; */
    font-size: 16px;
    font-weight: 400;
  }

  .r2_1 {
    font-size: 18px;
    font-weight: 700;
  }
  .your_solx_available,
  .balance_group > span:nth-child(1),
  .balance_group > span:nth-child(2),
  .balance_group > span:nth-child(3) {
    font-size: 16px;
    /* 32.4px */
  }

  .r3 {
    display: flex;
    justify-content: end;
    font-size: 17px;
    font-weight: 500;
  }

  .r3_1 .r3_2 {
    font-size: 16px;
    font-weight: 400;
  }

  .card2 {
  }

  .card3 {
    padding: 16px 10px;
  }

  .button_group {
    gap: 8px;
  }

  .title {
    font-size: 20px;
  }

  .content_group {
    font-size: 16px;
  }

  .claim_content > span:nth-child(1) {
    font-size: 16px;

    /* 32.4px */
  }

  .balance_group {
  }

  .stake_group {
  }

  .row_content {
  }

  .field {
    font-size: 16px;
  }

  .value {
  }

  .input_group {
  }

  .content_group {
  }

  .line_content {
  }

  .claim_group {
  }

  .claim_content {
  }

  .card_price_solx {
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
  .title_solx {
    font-size: 17px;
  }
  .value_g.having {
    font-size: 20px;
  }
  .value_g b {
    font-size: 18px;
  }
  .field_mining {
    font-size: 18px !important;
  }

  .minted {
    font-size: 14px !important;
  }
}
.triangle {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 6.93px solid #000; /* 34.64px is approximately sqrt(3) / 2 * 40px */
}
.dark .triangle {
  border-bottom: 6.93px solid #9dcced; /* 34.64px is approximately sqrt(3) / 2 * 40px */
}
.expanded_g {
  max-width: 654px !important;
  padding: 0 2px;
  width: 100%;
  align-self: center;
  transition: 500ms linear;
  max-height: 50px;
  overflow: hidden;
}

.expanded {
  max-height: 2000px;
  overflow-y: scroll;
}

.loan_header:hover {
  cursor: pointer;
}

.loan_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}

.expand_body {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.burn_day_left {
  color: var(--app-questn-com-sherpa-blue, #ff5153);
  font-family: "GT Walsheim";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.burn_value {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  white-space: nowrap;
}

.burn_board .arrow {
  position: absolute;
  display: block;
  top: 0px;
  left: 62px;
  transform: translateY(-100%);
  width: 20px;
  aspect-ratio: 1;
  background-color: black;
  clip-path: polygon(0 100%, 100% 100%, 50% 0);
}
.burn_board .arrow::after {
  content: "";
  display: block;
  position: absolute;
  display: block;
  top: 4px;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  aspect-ratio: 1;
  background-color: #fae1bc;
  clip-path: polygon(0 100%, 100% 100%, 50% 0);
}
.burn_board::after {
  position: absolute;
  content: "";
  display: block;
  top: 0px;
  left: 0px;
  transform: translateY(-100%);
  height: 18px;
  width: 50%;
  background-color: transparent;
}

.burn_board {
  transform: translate(-58px, 14px) scale(0);
  transition: 300ms;
  transform-origin: 74px -20px;
}

.burn_history_icon:hover .burn_board {
  transform: translate(-58px, 14px) scale(1);
}
.halving_day_left {
}
.wallet_address_ct {
  display: flex;
  align-items: center;
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
}

.minted {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (max-width: 1279px) {
}
.halving_field {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.halving_value {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.sol_price_halving {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.dark .solx_minted_label,
.dark .your_solx_available,
.dark .mint_des,
.dark .boost_interest,
.dark .mint_input_label,
.dark .balance_group span,
.dark .checkbox_label,
.dark .checkbox_label a {
  color: var(--text-color-dark-mode) !important;
}
.dark .boost_interest_driver {
  background-color: var(--text-color-dark-mode) !important;
}

.solx_minted_label {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%; /* 32.4px */
}
.solx_minted_value {
  color: var(--Color-SOLX-Japanese-Laurel, #2069ff);
  font-family: "GT Walsheim";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 28.8px */
  text-transform: capitalize;
}

.mint_des {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 28.8px */
}
.mint_input_label {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%; /* 32.4px */
}
.dark .boost_field,
.dark .boost_des,
.dark .boost_value {
  color: var(--text-color-dark-mode) !important;
}
.boost_field {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%; /* 32.4px */
}
.boost_value {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 20px */
}
.boost_des {
  color: var(--Neutral-01, #141416);
  font-family: "GT Walsheim";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 19.5px */
  text-align: right;
  white-space: nowrap;
}
.minted_boost_field {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%; /* 32.4px */
}
.your_solx_minted {
  leading-trim: both;
  text-edge: cap;
  font-family: "GT Walsheim";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 28.8px */
}

.boost_main_des {
  color: #636363;
  font-family: "GT Walsheim";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 28.8px */
}
.dark .row_1 .container .r1 {
  border-bottom: 2px var(--limit-color-dark-mode) solid !important;
}
.dark .row_1 .container {
  background-color: var(--dark-black-light) !important;
  border: 2px var(--limit-color-dark-mode) solid;
  color: var(--text-color-dark-mode) !important;
}
.dark .row_1 .container span,
.dark .row_1 .container .title_solx,
.dark .row_1 .container b,
.dark .row_1 .container .field,
.dark .row_1 .container .r2_1,
.dark .row_1 .container .r3,
.dark .row_1 .container .sol_price_halving,
.dark .row_1 .container .value_g,
.dark .row_1 .container .value_g :not(.growth-green) {
  color: var(--text-color-dark-mode) !important;
}
.divider_2 {
  border: 1px black solid;
}
.dark .divider_2 {
  border: 1px var(--limit-color-dark-mode) solid;
}
.dark .row_1 .container .growth-green span {
  color: #12ad7e !important;
}
.dark .row_1 .container .growth-red span {
  color: #ff5153 !important;
}
.growth-green {
  color: #12ad7e !important;
  font-family: "GT Walsheim";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.dark .tvl_container * {
  border-color: var(--limit-color-dark-mode) !important;
  color: var(--text-color-dark-mode) !important;
}
.dark .tvl_container {
  background-color: var(--dark-black-light) !important;
  border: 2px var(--limit-color-dark-mode) solid !important;
  color: var(--text-color-dark-mode) !important;
}
.dark .title.claim_group {
  color: var(--text-color-dark-mode) !important;
}
.dark .stake_group {
}
.dark .value_2,
.dark .field_2 {
  color: var(--text-color-dark-mode);
}
.value_2 {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 20px */
}

.field_2 {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%; /* 32.4px */
}
.dark .value_g b {
  color: var(--text-color-dark-mode) !important;
}
.dark .group_swap_ralative input {
  color: var(--text-color-dark-mode) !important;
}
.dark .group_swap_ralative {
  border: 2px var(--limit-color-dark-mode) solid !important;
  background-color: var(--dark-black) !important;
}
.dark .boost_tap_body {
  /* border-top: 1px var(--limit-color-dark-mode) dashed !important; */
}
.dark .boost_main_des {
  color: var(--text-color-dark-mode) !important;
}
.dark .swap_header_container * {
  color: var(--text-color-dark-mode) !important;
}
.dark .swap_header_container {
  border-color: var(--limit-color-dark-mode);
}
.dark .input_group.section_swap span {
  color: var(--text-color-dark-mode);
}
.boost_des {
  opacity: 0.6;
}
.solx_available_label {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 25.2px */
}
.boost_active_tab {
}
</style>
