<template>
  <span class="katex_style" v-html="htmlKatex"></span>
</template>

<script setup lang="ts">
import { onMounted, ref, defineProps, watch } from "vue";
import katex from "katex";
const htmlKatex = ref("");
const props = defineProps<{
  katexText?: string;
}>();
watch(
  () => props.katexText,
  () => {
    htmlKatex.value = katex.renderToString(props.katexText);
  },
  {
    immediate: true,
  }
);
</script>

<style scoped>
.katex_style {
  font-size: 1em;
}
</style>
