export const encryptData = async (data: any) => {
  try {
    const publicKey = {
      key_ops: ["encrypt"],
      ext: true,
      kty: "RSA",
      n: process.env.VUE_APP_ENCRYPT_N,
      e: process.env.VUE_APP_ENCRYPT_E,
      alg: "RSA-OAEP-256",
    };
    if (window.crypto && window.crypto.subtle) {
      // Call importKey
      const keyEncrypt = await window.crypto.subtle.importKey(
        "jwk",
        publicKey,
        {
          name: "RSA-OAEP",
          hash: "SHA-256",
        },
        true,
        ["encrypt"]
      );
      const encrypted = await window.crypto.subtle.encrypt(
        {
          name: "RSA-OAEP",
        },
        keyEncrypt,
        new TextEncoder().encode(
          JSON.stringify({
            expiredAt: Date.now() + 15 * 1000,
            ...data,
          })
        )
      );

      return bufferToBase64(encrypted);
    } else {
      console.error("Web Crypto API is not supported in this browser. 11");
    }
  } catch (Err) {
    console.error("🚀 ~ encryptData ~ Err:", Err);
    alert("error encrypt" + JSON.stringify(Err.name, null, 2));
  }
};

const bufferToBase64 = (buffer: ArrayBuffer) => {
  const binary = new Uint8Array(buffer);
  let base64 = "";
  binary.forEach((byte) => {
    base64 += String.fromCharCode(byte);
  });
  return btoa(base64);
};

export const parseJwt = (token: string) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};
