import compactWalletAddress from "./compactWalletAddress";
import createKey from "./createKey";
import createKeyByNumber from "./createKeyByNumber";
import formatter from "./formatter";
import translation from "./translation";

const standardDataForAffTree = (member?: any, level = 26): any => {
  try {
    member.name = compactWalletAddress(member.wallet_address);
    member.value = level;
    member.ranking = isNaN(Number(member.ranking))
      ? member.ranking.rank
      : Number(member.ranking);
    member.rank_name = translation(
      createKey(
        createKeyByNumber(
          isNaN(Number(member.ranking))
            ? member.ranking.rank
            : Number(member.ranking),
          "_r"
        ),
        "_rank_name"
      )
    );
    member.sol = formatter(member.totalPurchase) ?? 0;
    member.solx = formatter(member.solx_stake) ?? 0;
    member.sol_g = formatter(member.affiliateGroup) ?? 0;
    member.solx_g = formatter(member.boostingGroup) ?? 0;
    const length = member?.children?.length;
    for (let i = 0; i < length; i++) {
      standardDataForAffTree(member.children[i], level / 1.3);
    }
    return member;
  } catch (err) {
    console.error("standardDataForAffTree", err);
    return member;
  }
};

export default standardDataForAffTree;
