<template>
  <div
    style="z-index: 99999999998"
    :style="{
      backgroundColor: isDark() ? '#1E1E1E' : '#fff',
    }"
    class="fixed inset-0 flex text-white"
  >
    <div
      class="w-[calc(100vw-32px)] !max-w-[1200px] mx-auto flex flex-col items-center gap-6 justify-center"
    >
      <img
        :src="getIcon('system_maintenance')"
        alt=""
        class="block w-[calc(100vw-32px)] !max-w-[400px]"
      />
      <div class="mt_content_g lg:!text-[18px] text-base">
        <h1 class="mt_title lg:!text-[24px]">
          {{ translation("maintenance_title") }}
        </h1>
        <div class="mt_des">{{ translation("maintenance_des") }}</div>
        <br />
        <div>
          <h3 class="mt_feature_title">
            {{ translation("maintenance_feature_title") }}
          </h3>
          <ul
            v-if="
              store.state.appSetting?.system_enable_maintenance?.features &&
              store.state.appSetting?.system_enable_maintenance?.features
                .length > 0
            "
          >
            <li
              v-for="(e, i) in store.state.appSetting?.system_enable_maintenance
                ?.features"
              :key="i"
            >
              {{ e }}
            </li>
          </ul>
        </div>
        <br />
        <div class="mt_foo_mes">{{ translation("maintenance_foo_mes") }}</div>
      </div>
      <div class="mt_action">
        <PrimaryButton @click="reload">{{
          translation("maintenance_reload")
        }}</PrimaryButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import getIcon from "@/common/function/getIcon";
import translation from "@/common/function/translation";
import PrimaryButton from "./PrimaryButton.vue";
import isDark from "@/common/function/isDark";
import { useStore } from "vuex";
const reload = () => {
  window.location.reload();
};
const store = useStore();
</script>

<style scoped>
.dark .mt_content_g {
  color: var(--text-color-dark-mode-des) !important;
}
.mt_content_g {
  color: #000;
}
.dark .mt_title {
  color: var(--text-color-dark-mode);
}
.mt_title {
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 36px */
  text-transform: capitalize;
}
li {
  list-style: none;
}
.mt_des,
li {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 32.4px */
}
.mt_feature_title {
  font-style: normal;
  font-weight: 700;
  line-height: 180%;
}
.mt_foo_mes {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 32.4px */
}
.mt_action {
}
</style>
