<template>
  <v-dialog v-model="showModalConfirmWithdrawPrincipalOverdue" max-width="1200">
    <div class="flex justify-center">
      <ConfirmWithdrawOverdue
        :data-stake-solx="dataStakeSolx"
        :is-loading-origin="isLoadingOrigin"
        :is-disable="
          (!getAddress() &&
            !store.state.poolInfo?.system_enable_claim_priciple) ||
          (getAddress() &&
            (!store.state.permission ||
              (store.state.permission && store.state.permission?.success == true
                ? !store.state.permission?.data['claim-solx-principle']
                : true)))
        "
        :data-fee="dataFee"
        :on-withdraw-origin="() => handleWithdrawn()"
        :close-modal="() => (showModalConfirmWithdrawPrincipalOverdue = false)"
        :trigger-tab-boost="triggerTabBoost"
      />
    </div>
  </v-dialog>
  <v-dialog v-model="showModalConfirmWithdrawPrincipalFinal" max-width="1200">
    <div class="flex justify-center">
      <ConfirmWithdrawFinal
        :data-stake-solx="dataStakeSolx"
        :is-loading-origin="isLoadingOrigin"
        :is-disable="
          (!getAddress() &&
            !store.state.poolInfo?.system_enable_claim_priciple) ||
          (getAddress() &&
            (!store.state.permission ||
              (store.state.permission && store.state.permission?.success == true
                ? !store.state.permission?.data['claim-solx-principle']
                : true)))
        "
        :data-fee="dataFee"
        :on-withdraw-origin="() => handleWithdrawn()"
        :close-modal="() => (showModalConfirmWithdrawPrincipalFinal = false)"
        :trigger-tab-boost="triggerTabBoost"
      />
    </div>
  </v-dialog>
  <div class="container">
    <div class="modal_withdraw_boost">
      <div class="flex flex-col items-start w-full">
        <div class="header">
          <h1 class="title">
            {{
              withdrawOption == "boost_withdraw_2"
                ? translation("Withdraw_principal_boost_all")
                : translation("Withdraw_principal_boost_available")
            }}
          </h1>
          <div
            @click="() => store.commit('setOpenWithdrawBoost', false)"
            class="cursor_pointer"
          >
            <img
              loading="lazy"
              class="copy_aff_icon"
              :src="getIcon(isDark() ? 'close_white' : 'close')"
              alt=""
            />
          </div>
        </div>
        <p class="m-0 p-0 text-[#636363]">
          {{
            withdrawOption == "boost_withdraw_2"
              ? translation("content_withdraw_boost_all")
              : translation("content_withdraw_boost_available")
          }}
        </p>
      </div>

      <div class="body">
        <div
          class="w-full rounded-[10px] xl:rounded-[16px] bg-boost flex flex-col gap-4"
        >
          <!-- <div
            v-if="
              dataStakeSolx?.activeLate &&
              !checkReceiptsSpecial(store.state.receipts?.stakeHistoryUser) &&
              dataStakeSolx?.principalLatest > 0 &&
              dataStakeSolx?.principalLatest < dataStakeSolx?.principal
            "
            class="flex flex-wrap item-center gap-2 w-full"
          >
            <div
              @click="() => (withdrawOption = 'boost_withdraw_1')"
              class="duration-200 whitespace-nowrap px-4 x-4 py-1 bg-[#ffffff33] cursor-pointer rounded-[100px] border-[2px] border-gray-400 flex-col justify-center items-center inline-flex"
              :class="{
                '!border-black bg-white !border-2 active duration-200':
                  withdrawOption == 'boost_withdraw_1',
              }"
            >
              <div
                class="text-[#848895] text-base font-normal font-['GT Walsheim'] leading-7 text_dark_des relative top-[1px]"
                :class="{
                  'text-black': withdrawOption == 'boost_withdraw_1',
                }"
              >
                {{ translation("Available") }}
              </div>
            </div>
            <div
              @click="() => (withdrawOption = 'boost_withdraw_2')"
              class="duration-200 whitespace-nowrap px-4 x-4 py-1 bg-[#ffffff33] cursor-pointer rounded-[100px] border-[2px] border-gray-400 flex-col justify-center items-center inline-flex"
              :class="{
                '!border-black bg-white active !border-2':
                  withdrawOption == 'boost_withdraw_2',
              }"
            >
              <div
                class="text-[#848895] text-base font-normal font-['GT Walsheim'] leading-7 text_dark_des relative top-[1px]"
                :class="{
                  'text-black': withdrawOption == 'boost_withdraw_2',
                }"
              >
                {{ translation("All") }}
              </div>
            </div>
          </div> -->

          <!-- <div
            class="h-[1px] w-full bg-[#000]"
            :class="{ 'bg-[#fff]': isDark() }"
          ></div> -->
          <v-tabs-window v-model="withdrawOption">
            <v-tabs-window-item
              value="boost_withdraw_1"
              class="flex flex-col gap-4"
            >
              <div
                class="expanded_g flex flex-col gap-4"
                :class="{ expanded: loanExpand }"
              >
                <header
                  class="loan_header"
                  @click="
                    () => {
                      if (
                        store.state.receipts?.stakeHistoryUser.length > 0 &&
                        !dataStakeSolx.closeCollapse
                      ) {
                        loanExpand = !loanExpand;
                      }
                    }
                  "
                >
                  <v-menu>
                    <template v-slot:activator="{ props }">
                      <div
                        class="title-left flex flex-col boost_field"
                        v-bind="props"
                      >
                        <span>
                          <KatexTooltip
                            :class="{
                              '!text-[#636363]':
                                dataStakeSolx.closeCollapse &&
                                !dataStakeSolx.activeLate,
                            }"
                            value="Your_Principle"
                          />
                        </span>
                        <span
                          v-if="
                            (!loanExpand &&
                              Number(dataStakeSolx.principal) > 0) ||
                            dataStakeSolx.closeCollapse
                          "
                          class="latest_date"
                          :class="{
                            '!text-[#636363]':
                              dataStakeSolx.closeCollapse &&
                              !dataStakeSolx.activeLate,
                          }"
                          >{{ translation("Due_date") }}
                          {{ formatDate(dataStakeSolx?.timeLatest) }}</span
                        >
                      </div>
                    </template>
                  </v-menu>
                  <div
                    :class="{
                      '!text-[#636363]':
                        dataStakeSolx.closeCollapse &&
                        !dataStakeSolx.activeLate,
                    }"
                    class="title-right boost_value flex items-center"
                  >
                    <FormatterNumber
                      :class="{
                        '!text-[#636363]':
                          dataStakeSolx.closeCollapse &&
                          !dataStakeSolx.activeLate,
                      }"
                      v-if="Number(dataStakeSolx.principal) > 0"
                      :value="Number(dataStakeSolx.principal) ?? 0"
                    />
                    <span v-if="Number(dataStakeSolx.principal) <= 0">--</span>
                    <div
                      v-if="
                        store.state.receipts?.stakeHistoryUser.length > 0 &&
                        !dataStakeSolx.closeCollapse
                      "
                      class="arrow_container"
                      :class="{ rotate_180: loanExpand }"
                    >
                      <v-icon icon="mdi-chevron-down"></v-icon>
                    </div>
                  </div>
                </header>
                <div
                  v-if="
                    store.state.user?.token &&
                    store.state.receipts?.stakeHistoryUser &&
                    store.state.receipts?.stakeHistoryUser.length > 0 &&
                    !dataStakeSolx.closeCollapse
                  "
                  class="expand_body"
                >
                  <div class="flex gap-1 items-center">
                    <div
                      v-if="!isDark()"
                      class="flex flex-col items-center mt-[-24px]"
                    >
                      <span
                        class="w-[8px] h-[8px] bg-[#000] rounded-full"
                        :class="{
                          '!bg-[#848484]': !dataStakeSolx?.activeLate,
                        }"
                        v-if="dataStakeSolx?.showLongest"
                      ></span>
                      <span
                        :class="{
                          '!bg-[#848484]': !dataStakeSolx?.activeLongest,
                        }"
                        class="w-[2px] h-[50px] bg-[#000] rounded-full"
                        v-if="dataStakeSolx?.showLongest"
                      ></span>
                      <span
                        :class="{
                          '!border-b-[#848484]': !dataStakeSolx?.activeLongest,
                        }"
                        class="triangle rotate-180"
                        v-if="dataStakeSolx?.showLongest"
                      ></span>
                    </div>
                    <div
                      v-if="isDark()"
                      class="flex flex-col items-center mt-[-24px]"
                    >
                      <span
                        class="w-[8px] h-[8px] bg-[#9DCCED] rounded-full"
                        :class="{
                          '!bg-[#fff]': !dataStakeSolx?.activeLate,
                        }"
                        v-if="dataStakeSolx?.showLongest"
                      ></span>
                      <span
                        :class="{
                          '!bg-[#fff]': !dataStakeSolx?.activeLongest,
                        }"
                        class="w-[2px] h-[50px] bg-[#9DCCED] rounded-full"
                        v-if="dataStakeSolx?.showLongest"
                      ></span>
                      <span
                        :class="{
                          '!border-b-[#fff]': !dataStakeSolx?.activeLongest,
                        }"
                        class="triangle rotate-180"
                        v-if="dataStakeSolx?.showLongest"
                      ></span>
                    </div>
                    <div class="flex flex-1 flex-col gap-4">
                      <div class="flex justify-between w-full items-center">
                        <div class="flex flex-col gap-1">
                          <span
                            :class="{
                              '!text-[#141416]':
                                !isDark() &&
                                dataStakeSolx?.activeLate &&
                                !checkReceiptsSpecial(
                                  store.state.receipts?.stakeHistoryUser
                                ),
                              '!text-[#fff]':
                                isDark() &&
                                dataStakeSolx?.activeLate &&
                                !checkReceiptsSpecial(
                                  store.state.receipts?.stakeHistoryUser
                                ),
                            }"
                            class="latest_title"
                            >{{ translation("latest_principal") }}</span
                          >
                          <span
                            :class="{
                              '!text-[#2B2B2B]':
                                !isDark() &&
                                dataStakeSolx?.activeLate &&
                                !checkReceiptsSpecial(
                                  store.state.receipts?.stakeHistoryUser
                                ),
                              '!text-[#fff]':
                                isDark() &&
                                dataStakeSolx?.activeLate &&
                                !checkReceiptsSpecial(
                                  store.state.receipts?.stakeHistoryUser
                                ),
                            }"
                            class="latest_date"
                            >{{ translation("Due_date")
                            }}{{ formatDate(dataStakeSolx?.timeLatest) }}</span
                          >
                        </div>
                        <FormatterNumber
                          :class="{
                            '!text-[#141416]':
                              !isDark() &&
                              dataStakeSolx?.activeLate &&
                              !checkReceiptsSpecial(
                                store.state.receipts?.stakeHistoryUser
                              ),
                            '!text-[#fff]':
                              isDark() &&
                              dataStakeSolx?.activeLate &&
                              !checkReceiptsSpecial(
                                store.state.receipts?.stakeHistoryUser
                              ),
                          }"
                          class="number_lastest"
                          :value="dataStakeSolx?.principalLatest"
                        />
                      </div>
                      <div
                        class="flex justify-between w-full items-center"
                        v-if="dataStakeSolx?.showLongest"
                      >
                        <div class="flex flex-col gap-1">
                          <span
                            :class="{
                              '!text-[#141416]':
                                !isDark() &&
                                dataStakeSolx?.activeLongest &&
                                !checkReceiptsSpecial(
                                  store.state.receipts?.stakeHistoryUser
                                ),
                              '!text-[#fff]':
                                isDark() &&
                                dataStakeSolx?.activeLongest &&
                                !checkReceiptsSpecial(
                                  store.state.receipts?.stakeHistoryUser
                                ),
                            }"
                            class="latest_title"
                            >{{ translation("longest_principal") }}</span
                          >
                          <span
                            :class="{
                              '!text-[#2B2B2B]':
                                !isDark() &&
                                dataStakeSolx?.activeLongest &&
                                !checkReceiptsSpecial(
                                  store.state.receipts?.stakeHistoryUser
                                ),
                              '!text-[#fff]':
                                isDark() &&
                                dataStakeSolx?.activeLongest &&
                                !checkReceiptsSpecial(
                                  store.state.receipts?.stakeHistoryUser
                                ),
                            }"
                            class="latest_date"
                            >{{ translation("Due_date")
                            }}{{ formatDate(dataStakeSolx?.timeLongest) }}</span
                          >
                        </div>
                        <FormatterNumber
                          class="number_lastest"
                          :value="dataStakeSolx?.principalLongest"
                          :class="{
                            '!text-[#141416]':
                              !isDark() &&
                              dataStakeSolx?.activeLongest &&
                              !checkReceiptsSpecial(
                                store.state.receipts?.stakeHistoryUser
                              ),
                            '!text-[#fff]':
                              isDark() &&
                              dataStakeSolx?.activeLongest &&
                              !checkReceiptsSpecial(
                                store.state.receipts?.stakeHistoryUser
                              ),
                          }"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full">
                <img v-if="!isDark()" :src="getIcon('Divider')" alt="" />
                <img v-if="isDark()" :src="getIcon('Divider-dark')" alt="" />
              </div>

              <span class="your_receive">{{
                translation("You’ll_receive")
              }}</span>
              <div class="flex items-center gap-4 w-full justify-center">
                <div
                  class="flex flex-col gap-2 flex-1 justify-center items-center"
                >
                  <span class="number_receive_title">{{
                    translation("solx_principal")
                  }}</span>
                  <FormatterNumber
                    class="number_receive"
                    :value="dataStakeSolx?.principalLatest"
                  />
                </div>
                <div
                  class="w-[1px] bg-[#000] block h-fit"
                  :class="{ 'bg-[#fff]': isDark() }"
                >
                  <div class="invisible">
                    <span class="number_receive_title">{{
                      translation("solx_interest")
                    }}</span>
                    <FormatterNumber
                      class="number_receive"
                      :value="dataStakeSolx.interest"
                    />
                  </div>
                </div>
                <div
                  class="flex flex-col flex-1 gap-2 justify-center items-center"
                >
                  <span class="number_receive_title">{{
                    translation("solx_interest")
                  }}</span>
                  <FormatterNumber
                    class="number_receive"
                    :value="dataStakeSolx.interest"
                  />
                </div>
              </div>
            </v-tabs-window-item>

            <v-tabs-window-item
              value="boost_withdraw_2"
              class="flex flex-col gap-4"
            >
              <div
                class="expanded_g flex flex-col gap-4"
                :class="{ expanded: loanExpand }"
              >
                <header
                  class="loan_header"
                  @click="
                    () => {
                      if (
                        store.state.receipts?.stakeHistoryUser.length > 0 &&
                        !dataStakeSolx.closeCollapse
                      ) {
                        loanExpand = !loanExpand;
                      }
                    }
                  "
                >
                  <v-menu>
                    <template v-slot:activator="{ props }">
                      <div
                        class="title-left flex flex-col boost_field"
                        v-bind="props"
                      >
                        <span>
                          <KatexTooltip
                            :class="{
                              '!text-[#636363]':
                                dataStakeSolx.closeCollapse &&
                                !dataStakeSolx.activeLate,
                            }"
                            value="Your_Principle"
                          />
                        </span>
                        <span
                          v-if="
                            (!loanExpand &&
                              Number(dataStakeSolx.principal) > 0) ||
                            dataStakeSolx.closeCollapse
                          "
                          class="latest_date"
                          :class="{
                            '!text-[#636363]':
                              dataStakeSolx.closeCollapse &&
                              !dataStakeSolx.activeLate,
                          }"
                          >{{ translation("Due_date") }}
                          {{ formatDate(dataStakeSolx?.timeLatest) }}</span
                        >
                      </div>
                    </template>
                  </v-menu>
                  <div
                    :class="{
                      '!text-[#636363]':
                        dataStakeSolx.closeCollapse &&
                        !dataStakeSolx.activeLate,
                    }"
                    class="title-right boost_value flex items-center"
                  >
                    <FormatterNumber
                      :class="{
                        '!text-[#636363]':
                          dataStakeSolx.closeCollapse &&
                          !dataStakeSolx.activeLate,
                      }"
                      v-if="Number(dataStakeSolx.principal) > 0"
                      :value="Number(dataStakeSolx.principal) ?? 0"
                    />
                    <span v-if="Number(dataStakeSolx.principal) <= 0">--</span>
                    <div
                      v-if="
                        store.state.receipts?.stakeHistoryUser.length > 0 &&
                        !dataStakeSolx.closeCollapse
                      "
                      class="arrow_container"
                      :class="{ rotate_180: loanExpand }"
                    >
                      <v-icon icon="mdi-chevron-down"></v-icon>
                    </div>
                  </div>
                </header>
                <div
                  v-if="
                    store.state.user?.token &&
                    store.state.receipts?.stakeHistoryUser &&
                    store.state.receipts?.stakeHistoryUser.length > 0 &&
                    !dataStakeSolx.closeCollapse
                  "
                  class="expand_body"
                >
                  <div class="flex gap-1 items-center">
                    <div
                      v-if="!isDark()"
                      class="flex flex-col items-center mt-[-24px]"
                    >
                      <span
                        class="w-[8px] h-[8px] bg-[#000] rounded-full"
                        :class="{
                          '!bg-[#848484]': !dataStakeSolx?.activeLate,
                        }"
                        v-if="dataStakeSolx?.showLongest"
                      ></span>
                      <span
                        :class="{
                          '!bg-[#848484]': !dataStakeSolx?.activeLongest,
                        }"
                        class="w-[2px] h-[50px] bg-[#000] rounded-full"
                        v-if="dataStakeSolx?.showLongest"
                      ></span>
                      <span
                        :class="{
                          '!border-b-[#848484]': !dataStakeSolx?.activeLongest,
                        }"
                        class="triangle rotate-180"
                        v-if="dataStakeSolx?.showLongest"
                      ></span>
                    </div>
                    <div
                      v-if="isDark()"
                      class="flex flex-col items-center mt-[-24px]"
                    >
                      <span
                        class="w-[8px] h-[8px] bg-[#9DCCED] rounded-full"
                        :class="{
                          '!bg-[#fff]': !dataStakeSolx?.activeLate,
                        }"
                        v-if="dataStakeSolx?.showLongest"
                      ></span>
                      <span
                        :class="{
                          '!bg-[#fff]': !dataStakeSolx?.activeLongest,
                        }"
                        class="w-[2px] h-[50px] bg-[#9DCCED] rounded-full"
                        v-if="dataStakeSolx?.showLongest"
                      ></span>
                      <span
                        :class="{
                          '!border-b-[#fff]': !dataStakeSolx?.activeLongest,
                        }"
                        class="triangle rotate-180"
                        v-if="dataStakeSolx?.showLongest"
                      ></span>
                    </div>
                    <div class="flex flex-1 flex-col gap-4">
                      <div class="flex justify-between w-full items-center">
                        <div class="flex flex-col gap-1">
                          <span
                            :class="{
                              '!text-[#141416]':
                                !isDark() &&
                                dataStakeSolx?.activeLate &&
                                !checkReceiptsSpecial(
                                  store.state.receipts?.stakeHistoryUser
                                ),
                              '!text-[#fff]':
                                isDark() &&
                                dataStakeSolx?.activeLate &&
                                !checkReceiptsSpecial(
                                  store.state.receipts?.stakeHistoryUser
                                ),
                            }"
                            class="latest_title"
                            >{{ translation("latest_principal") }}</span
                          >
                          <span
                            :class="{
                              '!text-[#2B2B2B]':
                                !isDark() &&
                                dataStakeSolx?.activeLate &&
                                !checkReceiptsSpecial(
                                  store.state.receipts?.stakeHistoryUser
                                ),
                              '!text-[#fff]':
                                isDark() &&
                                dataStakeSolx?.activeLate &&
                                !checkReceiptsSpecial(
                                  store.state.receipts?.stakeHistoryUser
                                ),
                            }"
                            class="latest_date"
                            >{{ translation("Due_date")
                            }}{{ formatDate(dataStakeSolx?.timeLatest) }}</span
                          >
                        </div>
                        <FormatterNumber
                          :class="{
                            '!text-[#141416]':
                              !isDark() &&
                              dataStakeSolx?.activeLate &&
                              !checkReceiptsSpecial(
                                store.state.receipts?.stakeHistoryUser
                              ),
                            '!text-[#fff]':
                              isDark() &&
                              dataStakeSolx?.activeLate &&
                              !checkReceiptsSpecial(
                                store.state.receipts?.stakeHistoryUser
                              ),
                          }"
                          class="number_lastest"
                          :value="dataStakeSolx?.principalLatest"
                        />
                      </div>
                      <div
                        class="flex justify-between w-full items-center"
                        v-if="dataStakeSolx?.showLongest"
                      >
                        <div class="flex flex-col gap-1">
                          <span
                            :class="{
                              '!text-[#141416]':
                                !isDark() &&
                                dataStakeSolx?.activeLongest &&
                                !checkReceiptsSpecial(
                                  store.state.receipts?.stakeHistoryUser
                                ),
                              '!text-[#fff]':
                                isDark() &&
                                dataStakeSolx?.activeLongest &&
                                !checkReceiptsSpecial(
                                  store.state.receipts?.stakeHistoryUser
                                ),
                            }"
                            class="latest_title"
                            >{{ translation("longest_principal") }}</span
                          >
                          <span
                            :class="{
                              '!text-[#2B2B2B]':
                                !isDark() &&
                                dataStakeSolx?.activeLongest &&
                                !checkReceiptsSpecial(
                                  store.state.receipts?.stakeHistoryUser
                                ),
                              '!text-[#fff]':
                                isDark() &&
                                dataStakeSolx?.activeLongest &&
                                !checkReceiptsSpecial(
                                  store.state.receipts?.stakeHistoryUser
                                ),
                            }"
                            class="latest_date"
                            >{{ translation("Due_date")
                            }}{{ formatDate(dataStakeSolx?.timeLongest) }}</span
                          >
                        </div>
                        <FormatterNumber
                          class="number_lastest"
                          :value="dataStakeSolx?.principalLongest"
                          :class="{
                            '!text-[#141416]':
                              !isDark() &&
                              dataStakeSolx?.activeLongest &&
                              !checkReceiptsSpecial(
                                store.state.receipts?.stakeHistoryUser
                              ),
                            '!text-[#fff]':
                              isDark() &&
                              dataStakeSolx?.activeLongest &&
                              !checkReceiptsSpecial(
                                store.state.receipts?.stakeHistoryUser
                              ),
                          }"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="w-full">
                <img v-if="!isDark()" :src="getIcon('Divider')" alt="" />
                <img v-if="isDark()" :src="getIcon('Divider-dark')" alt="" />
              </div>
              <span class="your_receive">{{
                translation("You’ll_receive")
              }}</span>
              <div class="flex items-center gap-4 w-full justify-center">
                <div
                  class="flex flex-col gap-2 flex-1 justify-center items-center"
                >
                  <span class="number_receive_title">{{
                    translation("solx_principal")
                  }}</span>
                  <FormatterNumber
                    class="number_receive"
                    :value="dataStakeSolx.principal"
                  />
                </div>
                <div
                  class="w-[1px] bg-[#000] block h-fit"
                  :class="{ 'bg-[#fff]': isDark() }"
                >
                  <div class="invisible">
                    <span class="number_receive_title">{{
                      translation("solx_interest")
                    }}</span>
                    <FormatterNumber
                      class="number_receive"
                      :value="dataStakeSolx.interest"
                    />
                  </div>
                </div>
                <div
                  class="flex flex-col flex-1 gap-2 justify-center items-center"
                >
                  <span class="number_receive_title">{{
                    translation("solx_interest")
                  }}</span>
                  <FormatterNumber
                    class="number_receive"
                    :value="dataStakeSolx.interest"
                  />
                </div>
              </div>
            </v-tabs-window-item>
          </v-tabs-window>
        </div>
      </div>
      <div
        v-if="
          dataStakeSolx.interestNew - dataStakeSolx.interest > 0 &&
          withdrawOption == 'boost_withdraw_2'
        "
        class="flex gap-1"
      >
        <img
          :src="getIcon('error')"
          alt=""
          class="w-[18px] mt-[3px] h-[18px] relative top-[-2px]"
        />
        <span
          class="text-wrap text-[#FF5153] w-full text-start lg:text-[16px] text-sm font-[500] justify-start"
        >
          {{ translation("Withdrawing_your_current_before") }}
          <FormatterNumber
            :value="dataStakeSolx.interestNew - dataStakeSolx.interest"
            class="text-[#000] font-semibold"
            :class="{ 'text-[#fff]': isDark() }"
          />
          {{ translation("Withdrawing__after") }}
        </span>
      </div>
      <div class="flex gap-2 w-full">
        <!-- @click="handleWithdrawn" -->
        <!-- if (withdrawOption == 'boost_withdraw_2') {
          showModalConfirmWithdrawPrincipalFinal = true;
        } else {
          showModalConfirmWithdrawPrincipalOverdue = true;
        } -->
        <PrimaryButton
          :custom-background="isDark() ? '' : '#F0FFB3'"
          :loading="isLoadingOrigin || isLoadingProfit"
          :is-transaction="true"
          :disabled="
            (!getAddress() &&
              !store.state.poolInfo?.system_enable_claim_priciple) ||
            (getAddress() &&
              (!store.state.permission ||
                (store.state.permission &&
                store.state.permission?.success == true
                  ? !store.state.permission?.data['claim-solx-principle']
                  : true)))
          "
          class="btn"
          @click="
            () => {
              handleWithdrawn();
            }
          "
        >
          <div
            class="flex items-center gap-1"
            v-if="
              (!getAddress() &&
                !store.state.poolInfo?.system_enable_claim_priciple) ||
              (getAddress() &&
                (!store.state.permission ||
                  (store.state.permission &&
                  store.state.permission?.success == true
                    ? !store.state.permission?.data['claim-solx-principle']
                    : true)))
            "
          >
            <img
              :src="getIcon('lock')"
              alt=""
              class="w-[18px] h-[18px] relative top-[-2px]"
            />
            <span>{{
              translation("link_boost_claim_principle_coming_soon")
            }}</span>
          </div>
          <div
            v-if="
              (!getAddress() &&
                store.state.poolInfo?.system_enable_claim_priciple) ||
              (getAddress() &&
                (store.state.permission &&
                store.state.permission?.success == true
                  ? store.state.permission?.data['claim-solx-principle']
                  : false))
            "
          >
            {{
              translation(
                `Claim_interest_and_withdraw_${
                  withdrawOption == "boost_withdraw_2" ? "all" : "available"
                }`
              )
            }}
          </div></PrimaryButton
        >
        <PrimaryButton
          v-if="
            dataStakeSolx?.activeLate &&
            !checkReceiptsSpecial(store.state.receipts?.stakeHistoryUser) &&
            dataStakeSolx?.principalLatest > 0 &&
            dataStakeSolx?.principalLatest < dataStakeSolx?.principal
          "
          class="btn flex-1"
          @click="
            () => {
              if (withdrawOption == 'boost_withdraw_2') {
                withdrawOption = 'boost_withdraw_1';
              } else {
                withdrawOption = 'boost_withdraw_2';
              }
            }
          "
        >
          <img :src="getIcon('three_dot')" />
        </PrimaryButton>
      </div>

      <div
        v-if="
          Number(dataStakeSolx.interestNew) - Number(dataStakeSolx.interest) > 0
        "
        class="est-nummber w-full text-[16px] items-center"
      >
        <span>{{ translation("you_receive_before") }}</span>
        <b>
          <FormatterNumber
            :value="
              (
                Number(dataStakeSolx.interestNew) -
                  Number(dataStakeSolx.interest) ?? 0
              ).toFixed(9)
            "
          />
        </b>
        <span
          >{{ translation("you_receive_after")
          }}{{ formatDate(dataStakeSolx?.timeLongest) }}</span
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import translation from "@/common/function/translation";
import PrimaryButton from "./PrimaryButton.vue";
import getIcon from "@/common/function/getIcon";
import store from "@/store";
import { ref, computed, defineProps, onMounted, watch } from "vue";
import { useWallet } from "solana-wallets-vue";
import { isDeviceLaptop } from "@/wallet/solana/Solana";
import isDark from "@/common/function/isDark";
import { formatDate } from "@/service/statictis";
import FormatterNumber from "./FormatterNumber.vue";
import KatexTooltip from "./KatexTooltip.vue";
import ConfirmWithdrawOverdue from "./ConfirmWithdrawOverdue.vue";
import ConfirmWithdrawFinal from "./ConfirmWithdrawFinal.vue";

const showModalConfirmWithdrawPrincipalOverdue = ref<boolean>(false);
const showModalConfirmWithdrawPrincipalFinal = ref<boolean>(false);
const withdrawOption = ref<string>("boost_withdraw_2");
const getAddress = () => {
  let address = null;
  if (isDeviceLaptop()) {
    const { publicKey } = useWallet();

    address = publicKey.value?.toString() || null;
  } else {
    address = store.state.sessionSolana?.public_key?.toString() || null;
  }
  return address;
};
interface WithdrawBoostProps {
  poolStakeInfo?: any;
  total_stake?: number;
  triggerTabBoost: number;
  dataStakeSolx: any;
  dataFee: any;
  isLoadingOrigin: boolean | any;
  isLoadingProfit?: boolean;
  statusCountdownFeture?: string;
  onWithdrawProfit?: () => Promise<void>;
  onWithdrawOrigin?: (e) => Promise<void>;
}
const props = defineProps<WithdrawBoostProps>();
const loanExpand = ref<boolean>(true);
const handleWithdrawn = () => {
  props.onWithdrawOrigin(
    withdrawOption.value == "boost_withdraw_1" ? "available" : "all"
  );
};
const checkReceiptsSpecial = (receipts: any[]) => {
  const length = receipts.length;
  return receipts.filter((re) => re?.auto_subcribe == true).length == length;
};
watch(
  () => props.dataStakeSolx,
  (newData, oldData) => {
    if (props?.dataStakeSolx?.principal == 0) {
      store.commit("setOpenWithdrawBoost", false);
    }
  }
);

watch(
  () => props.triggerTabBoost,
  (newData, oldData) => {
    console.log("🚀 ~ watch ~ newData dataStakeSolx:", newData);
    if (
      props?.dataStakeSolx?.activeLate &&
      !checkReceiptsSpecial(store.state.receipts?.stakeHistoryUser)
    ) {
      withdrawOption.value = "boost_withdraw_1";
    } else {
      withdrawOption.value = "boost_withdraw_2";
    }
  }
);
onMounted(() => {
  if (
    props.dataStakeSolx?.activeLate &&
    !checkReceiptsSpecial(store.state.receipts?.stakeHistoryUser)
  ) {
    withdrawOption.value = "boost_withdraw_1";
  }
});
</script>

<style scoped>
.dark .latest_date,
.dark .latest_title {
  color: var(--Neutral-03, #939393);
}
.dark .boost_field,
.dark .boost_des,
.dark .boost_value {
  color: var(--text-color-dark-mode) !important;
}
.number_receive_title {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%;
}
.number_receive {
  color: var(--Color-SOLX-Chelsea-Gem, #a366ff);
  font-family: "GT Walsheim";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 20px */
}
.dark .est-nummber,
.dark .number_receive_title,
.dark .your_receive {
  color: #fff;
}
.your_receive {
  color: var(--Color-SOLX-Black, #000);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%; /* 32.4px */
}
.latest_title {
  color: var(--Neutral-03, #636363);
  font-family: "GT Walsheim";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  text-transform: capitalize;
}
.latest_date {
  color: var(--Neutral-03, #636363);
  font-family: "GT Walsheim";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 19.5px */
}
.number_lastest {
  color: var(--Neutral-03, #636363);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 18px */
}
.title-left {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%;
}
.title-right {
  color: var(--Color-SOLX-Black, #000);
  font-family: "GT Walsheim";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 20px */
}
.loan_header:hover {
  cursor: pointer;
}
.loan_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}
.collapse_action span {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  /* 32.4px */
}
.collapse_action {
  display: flex;
  align-items: center;
}
.container {
  position: fixed;
  inset: 0;
  z-index: 999999999;
  background: rgba(149, 149, 149, 0.64);
  backdrop-filter: blur(12px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dark .modal_withdraw_boost {
  background-color: var(--dark-black-light);
  border-color: var(--limit-color-dark-mode);
}
.modal_withdraw_boost {
  display: flex;
  width: 500px;
  max-width: calc(100vw - 32px) !important;
  padding: 24px 28px 16px 28px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 24px;
  border: 2px solid var(--Neutral-01, #141416);
  background: var(--Neutral-09, #fff);
}
.expanded_g {
  max-width: 654px !important;
  padding: 0 2px;
  width: 100%;
  align-self: center;
  transition: 500ms linear;
  max-height: 50px;
  overflow: hidden;
}

.expanded {
  max-height: 2000px;
}
@media (max-width: 600px) {
  .modal_withdraw_boost {
    padding: 16px !important;
  }
}
.group_swap_ralative {
  border-bottom: 2px var(--app-questn-com-black) solid;
  display: flex;
  align-items: stretch;
  width: 100%;
  gap: 5%;
  justify-content: space-between;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.dark .title,
.dark .left_footer_des,
.dark .body_des {
  color: var(--text-color-dark-mode);
}
.title {
  color: var(--Neutral-01, #141416);
  font-family: "GT Walsheim";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 32px */
  text-transform: capitalize;
}
.body {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.btn {
  width: 100%;
}
.footer {
  display: flex;
  padding-bottom: 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.triangle {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 6.93px solid #000; /* 34.64px is approximately sqrt(3) / 2 * 40px */
}
.link_g {
  border-bottom: 1px solid var(--Neutral-07, #d1d1d1);
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 20px */
}
.left_footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}
.left_footer_title {
  color: var(--Neutral-01, #141416);
  font-family: "GT Walsheim";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 32px */
  text-transform: capitalize;
  display: flex;
}
.left_footer_des {
  color: var(--Neutral-03, #636363);
  font-family: "GT Walsheim";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 28.8px */
}

.body_des {
  color: var(--Neutral-01, #141416);
  font-family: "GT Walsheim";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  padding-top: 6px;
}
.dark input {
  color: var(--text-color-dark-mode);
}
input {
  flex: 1;
  outline: none;
  padding-bottom: 6px;
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  /* 18px */
  text-transform: capitalize;
  width: 60%;
}
.dark .triangle {
  border-bottom: 6.93px solid #9dcced; /* 34.64px is approximately sqrt(3) / 2 * 40px */
}
</style>
