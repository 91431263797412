<template>
  <!-- <div class="flex flex-col items-center"> -->
  <input
    ref="input"
    type="text"
    inputmode="decimal"
    class="input"
    @input="handleInput"
    v-model="inputValueRef"
  />
  <!-- <small v-show="showErrorMessage" class="text-red-500">
      {{ translation("invalid_number_format") }}
    </small> -->
  <!-- </div> -->
</template>

<script setup lang="ts">
import translation from "@/common/function/translation";
import { ref, defineProps, defineEmits, watch } from "vue";
const input = ref<HTMLInputElement | null>();
const showErrorMessage = ref<boolean>(false);
const props = defineProps<{
  inputValue?: string;
  focus?: boolean;
  inputClass?: string;
}>();
const inputValueRef = ref<string>(props.inputValue ?? "");
const formatInputValue = (value?: string) => {
  if (value === "") {
    return "";
  }
  const dotIndex = value.indexOf(".");
  if (dotIndex > -1) {
    const beforeDot = value.slice(0, dotIndex);
    const afterDot = value.slice(dotIndex + 1);
    return beforeDot.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "." + afterDot;
  } else {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};
const reverseFormatValue = (value: string) => {
  let lastChar = "";
  if (value.endsWith(",")) lastChar = ".";
  const dotIndex = value.indexOf(".");
  if (dotIndex > -1) {
    const beforeDot = value.slice(0, dotIndex);
    const afterDot = value.slice(dotIndex + 1);
    return (
      beforeDot.replace(/[^0-9.]/g, "") + "." + afterDot.replace(/[^0-9.]/g, "")
    );
  } else {
    return value.replace(/[^0-9.]/g, "") + lastChar;
  }
};
const handleInput = (e: any, input?: string) => {
  let value = input ?? (e?.target as HTMLInputElement)?.value;
  if (value == "00") value = "0";
  const length = value?.length;
  let numOfDot = 0;
  for (let i = 0; i < length; i++) {
    if (value[i] == ".") numOfDot++;
    if (numOfDot >= 2 && i == length - 1) {
      value = value.slice(0, i);
      break;
    }
    if (numOfDot >= 2 && i < length - 1) {
      showErrorMessage.value = true;
      inputValueRef.value = "";
      return;
    }
  }
  setTimeout(() => {
    showErrorMessage.value = false;
  }, 3000);
  let temp = reverseFormatValue(value);
  const tempLength = temp.length;
  for (let i = 0; i < tempLength; i++) {
    if (temp[i] == ".") {
      temp = temp.slice(i - 1);
      break;
    }
    if (temp[i] != "0") {
      temp = temp.slice(i);
      break;
    }
  }
  // console.error(temp);
  // const values = temp.split(".");
  // if (values.length > 2) {
  //   const newValue = formatInputValue(
  //     values.slice(0, values.length - 1).join("") +
  //       "." +
  //       values[values.length - 1]
  //   );
  //   inputValueRef.value = newValue;
  //   return;
  // }
  temp = formatInputValue(temp);
  inputValueRef.value = temp;
};
const emit = defineEmits(["update:inputValue"]);
const updateValue = (value) => {
  emit("update:inputValue", value);
};
watch(inputValueRef, () => {
  updateValue(inputValueRef.value);
});
watch(
  () => props.inputValue,
  () => {
    console.error(">>>>>>>>>>>>> prop change");
    handleInput(undefined, props.inputValue ?? "");
  }
);
watch(
  () => props.focus,
  (newFocus, oldFocus) => {
    if (newFocus == true) {
      input.value?.focus();
    }
  },
  { immediate: true }
);
</script>

<style scoped>
.input_mint {
  flex: 1;
  outline: none;
  padding: 12px 16px !important;
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  /* 18px */
  text-transform: capitalize;
  width: 60%;
}
</style>
