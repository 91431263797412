<template>
  <div
    :style="{
      width: 'calc(100vw - 32px)',
      borderRadius: '24px',
      border: '2px black solid',
      maxWidth: '650px !important',
      backgroundColor: isDark() ? '#2B2B2B' : '#fff',
    }"
    class="p-6"
  >
    <div
      class="self-stretch rounded-2xl flex-col justify-start items-start gap-2 flex"
    >
      <div class="self-stretch flex-col justify-start items-start gap-2 flex">
        <div class="flex justify-between items-center w-full pb-2">
          <span class="text-[20px]" :class="{ '!text-[#fff]': isDark() }">{{
            translation("confirm_withdraw_interest")
          }}</span>
          <v-icon
            icon="mdi-close"
            :color="isDark() ? '#fff' : ''"
            @click="closeModal"
          ></v-icon>
        </div>
        <div
          class="h-[2px] bg-[#141416] w-full mb-3 opacity-15"
          :class="{ '!bg-[#fff]': isDark() }"
        ></div>

        <div
          class="self-stretch justify-between items-center inline-flex cursor-pointer"
          @click="() => (showDetailInterest = !showDetailInterest)"
        >
          <div
            class="text-[#D58400] text-[13px] lg:text-[18px] font-medium font-['GT Walsheim'] leading-[140%]"
          >
            {{ translation("solx_interest") }}
          </div>
          <div class="justify-start items-center gap-1.5 flex">
            <div
              class="text-[#D58400] text-[15px] lg:text-[20px] font-bold font-['GT Walsheim'] leading-[21px]"
            >
              <FormatterNumber
                v-if="Number(dataStakeSolx?.interestForSetting) > 0"
                :value="Number(dataStakeSolx?.interestForSetting) ?? 0"
              />
            </div>
            <svg
              class="duration-200 relative -top-[1px]"
              :class="{
                'rotate-[180deg]': showDetailInterest,
              }"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.28854 0.357356L10 5.06881L9.22604 5.84277L5.28854 1.90527L1.35104 5.84277L0.577083 5.06881L5.28854 0.357356Z"
                :fill="isDark() ? '#fff' : '#1C1B1F'"
              />
            </svg>
          </div>
        </div>
        <div
          class="flex items-stretch w-full duration-300 overflow-hidden"
          :style="{
            maxHeight: showDetailInterest ? '100px' : '0px',
          }"
        >
          <div class="relative ml-1">
            <div class="arrow_one"></div>
          </div>
          <div class="flex flex-col gap-2 w-full">
            <div
              class="self-stretch pl-3 justify-between items-center inline-flex"
            >
              <div
                class="text-[#929292] text-[13px] lg:text-[18px] font-medium font-['GT Walsheim'] leading-[140%]"
                :class="{ '!text-[#fff]': isDark() }"
              >
                {{ translation("solx_interest_available") }}
              </div>
              <div
                class="text-[#636363] flex gap-1 items-center text-[15px] lg:text-[20px] font-medium font-['GT Walsheim'] leading-[21px]"
                :class="{ '!text-[#fff]': isDark() }"
              >
                <FormatterNumber
                  :value="Number(dataStakeSolx?.interest) ?? 0"
                />
                <KatexTooltip value="interest_boost_available_tooltip" use-slot>
                  <span class="flex items-center gap-1"
                    ><img
                      loading="lazy"
                      :src="getIcon(isDark() ? 'tooltip_white' : 'tooltip')"
                      alt=""
                      :style="{
                        top: `${useResponsive(-3, -1).value}px`,
                      }"
                      class="icon_w24_h24"
                  /></span>
                </KatexTooltip>
              </div>
            </div>
            <div
              class="self-stretch pl-3 justify-between items-center inline-flex"
            >
              <div
                class="text-[#929292] text-[13px] lg:text-[18px] font-medium font-['GT Walsheim'] leading-[140%]"
                :class="{ 'text-[#fff]': isDark() }"
              >
                {{ translation("solx_interest_unavailable") }}
              </div>
              <div
                class="text-[#636363] flex gap-1 items-center text-[15px] lg:text-[20px] font-medium font-['GT Walsheim'] leading-[21px]"
                :class="{ '!text-[#fff]': isDark() }"
              >
                <FormatterNumber
                  v-if="
                    Number(dataStakeSolx?.interestForSetting) -
                      Number(dataStakeSolx?.interest) >
                    0
                  "
                  :value="
                    Number(dataStakeSolx?.interestForSetting) -
                      Number(dataStakeSolx?.interest) ?? 0
                  "
                />
                <KatexTooltip
                  value="interest_boost_unavailable_tooltip"
                  use-slot
                >
                  <span class="flex items-center gap-1"
                    ><img
                      loading="lazy"
                      :src="getIcon(isDark() ? 'tooltip_white' : 'tooltip')"
                      alt=""
                      :style="{
                        top: `${useResponsive(-3, -1).value}px`,
                      }"
                      class="icon_w24_h24"
                  /></span>
                </KatexTooltip>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="self-stretch h-fit py-3 bg-[#f2f2f2] rounded-2xl border-2 border-[#141416] flex-col justify-start items-start gap-2 flex"
      >
        <div class="self-stretch px-3 justify-between items-center inline-flex">
          <div
            class="text-[#141416] text-[13px] lg:text-[18px] font-medium font-['GT Walsheim'] leading-[140%]"
          >
            Price impact
          </div>
          <div class="justify-start items-center gap-0.5 flex">
            <div
              class="text-[#141416] text-[15px] lg:text-[20px] font-normal font-['SF Pro Display'] leading-[21px]"
            >
              ±
            </div>
            <div
              class="text-[#141416] text-[15px] lg:text-[20px] font-bold font-['GT Walsheim'] leading-[21px]"
            >
              <FormatterNumber
                :value="
                  (Number(Number(dataStakeSolx?.interest)) /
                    store.state.poolInfo?.solx_reserved) *
                    100 <
                  0.01
                    ? '0.01'
                    : (Number(Number(dataStakeSolx?.interest)) /
                        store.state.poolInfo?.solx_reserved) *
                      100
                "
                :unit="'%'"
                :start="
                  (Number(Number(dataStakeSolx?.interest)) /
                    store.state.poolInfo?.solx_reserved) *
                    100 <
                  0.01
                    ? '<'
                    : ''
                "
              />
            </div>
          </div>
        </div>
        <div
          class="self-stretch px-3 flex-col justify-start items-start gap-2 flex"
        >
          <div
            class="self-stretch justify-between items-center inline-flex cursor-pointer"
            @click="() => (showDetailFee = !showDetailFee)"
          >
            <div
              class="text-[#141416] text-[13px] lg:text-[18px] font-medium font-['GT Walsheim'] leading-[140%]"
            >
              Total Fee
            </div>
            <div class="justify-start items-center gap-1.5 flex">
              <div
                class="text-[#141416] text-[15px] lg:text-[20px] font-bold font-['GT Walsheim'] leading-[21px]"
              >
                <FormatterNumber
                  :value="dataFee?.feeNetwork + dataFee?.feeSystem ?? 0"
                />
              </div>
              <svg
                class="duration-200 relative -top-[1px]"
                :class="{
                  'rotate-[180deg]': showDetailFee,
                }"
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.28854 0.357356L10 5.06881L9.22604 5.84277L5.28854 1.90527L1.35104 5.84277L0.577083 5.06881L5.28854 0.357356Z"
                  fill="#1C1B1F"
                />
              </svg>
            </div>
          </div>
          <div
            class="flex items-stretch w-full duration-300 overflow-hidden"
            :style="{
              maxHeight: showDetailFee ? '100px' : '0px',
            }"
          >
            <div class="relative ml-1">
              <div class="arrow"></div>
            </div>
            <div class="flex flex-col gap-2 w-full">
              <div
                class="self-stretch pl-3 justify-between items-center inline-flex"
              >
                <div
                  class="text-[#929292] text-[13px] lg:text-[18px] font-medium font-['GT Walsheim'] leading-[140%]"
                >
                  System Fee
                </div>
                <div
                  class="text-[#636363] text-[15px] lg:text-[20px] font-medium font-['GT Walsheim'] leading-[21px]"
                >
                  <FormatterNumber :value="dataFee?.feeSystem ?? 0" />
                </div>
              </div>
              <div
                class="self-stretch pl-3 justify-between items-center inline-flex"
              >
                <div
                  class="text-[#929292] text-[13px] lg:text-[18px] font-medium font-['GT Walsheim'] leading-[140%]"
                >
                  GAS Fee
                </div>
                <div
                  class="text-[#636363] text-[15px] lg:text-[20px] font-medium font-['GT Walsheim'] leading-[21px]"
                >
                  <FormatterNumber :value="dataFee?.feeNetwork ?? 0" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="self-stretch h-[0px] !border-t border-[#141416] border-dashed"
        :class="{ '!border-[#fff]': isDark() }"
      ></div>
      <div class="self-stretch justify-between items-center inline-flex">
        <span
          class="text-[#8247e5] text-[15px] lg:text-[20px] font-medium font-['GT Walsheim'] leading-[21px]"
          >{{ translation("Total_solx_claim") }}
        </span>
        <div
          class="text-[#8247e5] text-[15px] lg:text-[20px] font-bold font-['GT Walsheim'] leading-[21px]"
        >
          <FormatterNumber :value="Number(dataStakeSolx?.interest) ?? 0" />
        </div>
      </div>
      <div class="self-stretch justify-between items-center inline-flex">
        <div>
          <span
            class="text-[#141416] text-[13px] lg:text-[18px] font-medium font-['GT Walsheim'] leading-[140%]"
            :class="{ '!text-[#fff]': isDark() }"
            >{{ translation("est_solx_claim") }}</span
          >
        </div>
        <div
          class="text-[#141416] text-[18px] lg:text-[20px] font-bold font-['GT Walsheim'] leading-snug"
          :class="{ '!text-[#fff]': isDark() }"
        >
          <FormatterNumber :value="Number(dataStakeSolx?.interest)" />
        </div>
      </div>
    </div>
    <PrimaryButton
      @click="onWithdrawProfit"
      :disabled="isDisable"
      :loading="isLoadingProfit"
      :custom-background="
        getAddress() &&
        Number(dataStakeSolx.principal) > 0 &&
        Number(dataStakeSolx.interest) > 0 &&
        !isDark()
          ? '#F0FFB3'
          : ''
      "
      class="w-full my-4 lg:my-6"
      >{{ translation(pagePrefix + "button") }}</PrimaryButton
    >
  </div>
</template>

<script lang="ts" setup>
import translation from "@/common/function/translation";
import PrimaryButton from "./PrimaryButton.vue";
import { ref, defineProps } from "vue";
import FormatterNumber from "./FormatterNumber.vue";
import { getAddress } from "@/service/statictis";
import isDark from "@/common/function/isDark";
import KatexTooltip from "./KatexTooltip.vue";
import useResponsive from "@/hook/useResponsive";
import getIcon from "@/common/function/getIcon";
import store from "@/store";

interface WithdrawBoostProps {
  dataStakeSolx?: any;
  dataFee?: any;
  isLoadingProfit?: boolean;
  isDisable?: boolean;
  closeModal: () => void;
  onWithdrawProfit?: () => Promise<void>;
}
const props = defineProps<WithdrawBoostProps>();

const pagePrefix = "confirm_modal_";
const showDetailFee = ref(false);
const showDetailInterest = ref(true);
</script>

<style scoped>
.arrow,
.arrow_one {
  color: #636363;
  position: absolute;
  top: 8px;
  bottom: 8px;
  left: 50%;
  border-left: 2px currentColor dashed;
}
.dark .arrow_one {
  color: #fff;
}
.arrow::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 999px;
  background: currentColor;
  position: absolute;
  transform: translate(calc(-50% - 1px), 0%);
}

.arrow::after {
  content: "";
  display: block;
  width: 12px;
  height: 8px;
  background: currentColor;
  position: absolute;
  bottom: 0;
  transform: translate(calc(-50% - 1px), 1.5px);
  clip-path: polygon(0% 0%, 50% 40%, 100% 0%, 50% 100%, 0% 0%);
}

.arrow_one::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 999px;
  background: currentColor;
  position: absolute;
  transform: translate(calc(-50% - 1px), 0%);
}

.arrow_one::after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  background: currentColor;
  position: absolute;
  bottom: 0;
  border-radius: 999px;

  transform: translate(calc(-50% - 1px), 0%);
}
</style>
