export default function formatDateTo(
  dateString: string,
  showHour = true,
  type?: "dd-mm" | "dd-mm-yy" | "dd-short-yy"
) {
  try {
    const timestamp = Date.parse(dateString);
    const date = new Date(timestamp);
    if (type === "dd-mm") {
      return date.toLocaleDateString("vi-VN", {
        day: "2-digit",
        month: "2-digit",
      });
    }
    if (type === "dd-mm-yy") {
      return date.toLocaleDateString("vi-VN", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      });
    }
    if (type === "dd-short-yy") {
      return date.toLocaleDateString("vi-VN", {
        year: "numeric",
        month: "short",
        day: "2-digit",
      });
    }
    if (showHour) {
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hourCycle: "h23",
      });
    } else {
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    }
  } catch (err) {
    console.error(err);
    return "Loading...";
  }
}
