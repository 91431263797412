import store from "@/store";

export default function isLoadingApp(): boolean {
  try {
    for (const e of Object.keys(store.state.loadingApp)) {
      if (store.state.loadingApp[e]) {
        return true;
      }
    }
    return false;
  } catch (err) {
    console.error(">>>>>>>>>>>>>> isLoadingApp", err);
    return true;
  }
}
