<template>
  <div
    class="level_container py-4 px-6 select-none relative overflow-hidden duration-200 lg:rounded-[24px] rounded-[20px] border_1"
    :style="{
      cursor: `${
        false ||
        data?.children === true ||
        (Array.isArray(data?.children) && data?.children.length > 0)
          ? 'pointer'
          : 'default'
      }`,
      backgroundColor: isDark()
        ? data.showingRank > -1
          ? '#323232'
          : '#1E1E1E'
        : data.showingRank > -1
        ? '#E8FF8D'
        : '#F0FFB3',
    }"
    :class="{
      hover_flag:
        !hiddenHoverFlag &&
        (false ||
          data?.children === true ||
          (Array.isArray(data?.children) && data?.children.length > 0)),
      // border_bottom: borderBottom,
      skeleton_loader:
        false && data?.userId == store.state.searchSelected?.userId,
    }"
    :id="data?.userId"
  >
    <div
      v-if="!hideHeader"
      class="border-b border-[#dcdcdc] border-dashed pb-3 mb-3 header_level_rank"
    >
      <div class="flex items-center gap-1">
        <div class="flex flex-col items-start wallet_address lg:flex-row">
          <div v-if="!isRoot" class="flex items-center">
            <div class="">
              {{ compactWalletAddress(data?.wallet_address) }}
            </div>
            <div
              class="rank_name whitespace-nowrap mx-1.5"
              :style="{ color: rankColor }"
            >
              {{
                translation(
                  createKey(
                    createKeyByNumber(data?.ranking?.rank, "_r"),
                    "_rank_name"
                  )
                )
              }}
            </div>
          </div>
          <div v-if="!store.state?.isSearching" class="flex items-center gap-1">
            <span v-if="!isRoot" class="hidden lg:inline">-</span>
            <span>{{ data.totalReferral }}</span>
            <span class="hidden lg:inline">{{
              data.totalReferral > 1
                ? translation("aff_members")
                : translation("aff_member")
            }}</span>
            <img
              :src="getIcon(isDark() ? 'referral_white' : 'referral')"
              alt=""
              class="block w-6 h-6 relative top-[-1px] lg:hidden"
            />
          </div>
        </div>
        <div class="flex items-center gap-2 ml-auto">
          <div
            v-if="Array.isArray(data?.children) && data?.children.length > 0"
            class="cursor-pointer hover:translate-x-[8px] duration-200 view_graph_button whitespace-nowrap"
            @click.stop="() => handleShowTree()"
          >
            <img :src="getIcon('visible')" alt="" class="" />
            {{ translation("hie_view_graph") }}
          </div>
          <div
            v-if="!isRoot && data.children && data?.children?.length > 0"
            class="cursor-pointer hover:translate-x-[8px] duration-200 view_graph_button whitespace-nowrap"
            :class="{
              '!bg-[#CAFF04]': !isDark(),
            }"
          >
            {{ translation("hie_select") }}
          </div>
        </div>
      </div>
    </div>
    <table class="w-full">
      <tr
        class="inline-block lg:hidden"
        :class="{
          'lg:!inline-block':
            isRoot && !+data?.totalPurchase && !+data?.solx_stake,
        }"
      >
        <th>
          <div class="wallet_address">
            <KatexTooltip :value="customPersonalKey ?? 'aff_personal'" />
          </div>
        </th>
      </tr>
      <tr v-if="isRoot && !+data?.totalPurchase && !+data?.solx_stake">
        <td>
          <div class="no_reward_des">
            <KatexTooltip value="no_reward_des_person" />
          </div>
        </td>
      </tr>
      <tr v-if="!isRoot || +data?.totalPurchase || +data?.solx_stake">
        <td class="hidden lg:inline-block">
          <div class="wallet_address">
            <KatexTooltip :value="customPersonalKey ?? 'aff_personal'" />
          </div>
        </td>
        <td class="hidden lg:inline-block"></td>
        <td>
          <div class="flex items-center gap-1">
            <div class="commission_value whitespace-nowrap">
              <FormatterNumber
                tooltip-place="top-left"
                :value="data?.totalPurchase ?? 0"
                :unit="translation('_sol_unit')"
              />
            </div>
            <img
              :src="getIcon(isDark() ? 'commission_white' : 'commission')"
              alt=""
              class="icon_w24_h24 icon_add_tree"
            />
          </div>
        </td>
        <td>
          <div class="flex items-center gap-1">
            <div class="commission_value whitespace-nowrap">
              <FormatterNumber
                tooltip-place="top-left"
                :value="data?.solx_stake ?? 0"
                :unit="translation('_solx_unit')"
              />
            </div>
            <img
              :src="getIcon(isDark() ? 'target_white' : 'target')"
              alt=""
              class="icon_w24_h24 icon_add_tree"
            />
          </div>
        </td>
      </tr>
      <tr
        class="inline-block lg:hidden"
        :class="{
          'lg:!inline-block':
            isRoot && !+data?.affiliateGroup && !+data?.boostingGroup,
        }"
      >
        <th>
          <div class="wallet_address">
            <KatexTooltip :value="customGroupKey ?? 'aff_group'" />
          </div>
        </th>
      </tr>
      <tr v-if="isRoot && !+data?.affiliateGroup && !+data?.boostingGroup">
        <td>
          <div class="no_reward_des">
            <KatexTooltip value="no_reward_des_group" />
          </div>
        </td>
      </tr>
      <tr v-if="!isRoot || +data?.affiliateGroup || +data?.boostingGroup">
        <td class="hidden lg:inline-block">
          <div class="wallet_address">
            <KatexTooltip :value="customGroupKey ?? 'aff_group'" />
          </div>
        </td>
        <td class="hidden lg:inline-block"></td>
        <td>
          <div class="flex items-center gap-1">
            <div class="commission_value whitespace-nowrap">
              <FormatterNumber
                :value="data?.affiliateGroup ?? 0"
                :unit="translation('_sol_unit')"
              />
            </div>
            <img
              :src="getIcon(isDark() ? 'commission_white' : 'commission')"
              alt=""
              class="icon_w24_h24 icon_add_tree"
            />
          </div>
        </td>
        <td>
          <div class="flex items-center gap-1">
            <div class="commission_value whitespace-nowrap">
              <FormatterNumber
                :value="data?.boostingGroup ?? 0"
                :unit="translation('_solx_unit')"
              />
            </div>
            <img
              :src="getIcon(isDark() ? 'target_white' : 'target')"
              alt=""
              class="icon_w24_h24 icon_add_tree"
            />
          </div>
        </td>
      </tr>
    </table>
    <!-- <div class="flex items-center">
      <div class="flex flex-col gap-1.5 flex-1">
        <div class="flex items-center"></div>
      </div>
      <div class="flex flex-col gap-1.5 flex-1 group_analytic">
        <div class="flex items-center">
          <div class="wallet_address">
            <KatexTooltip :value="customGroupKey ?? 'aff_group'" />
          </div>
        </div>
        
        
      </div>
    </div> -->
  </div>
</template>

<script setup lang="ts">
import { getMembersSearch } from "@/service/axios";
import { defineProps, ref, onMounted, watch } from "vue";
import { assignLevelAndBackgroundColor } from "@/common/function/assignLevelAndBackgroundColor";
import filterByRank from "@/common/function/filterByRank";
import setShow from "@/common/function/setShow";
import getF1Rank from "@/common/function/getF1Rank";
import getIcon from "@/common/function/getIcon";
import timeAgo from "@/common/function/timeAgo";
import { getLevelById } from "@/common/function/getLevelById";
import { useStore } from "vuex";
import AllianceInfoBlock from "./AllianceInfoBlock.vue";
import useResponsive from "@/hook/useResponsive";
import { NAV_BAR_HEIGHT } from "@/ui_config";
import { provideApolloClient, useQuery } from "@vue/apollo-composable";
import { userClient } from "@/graphql/client/user-client";
import { getAllianceDataQuery } from "@/graphql/query/getAllianceDataQuery";
import formatDateTo from "@/common/function/formatDate";
import compactWalletAddress from "@/common/function/compactWalletAddress";
import CopyText from "./CopyText.vue";
import compactDecimalPartOfNumber from "@/common/function/compactDecimalPartOfNumber";
import formatter from "@/common/function/formatter";
import translation from "@/common/function/translation";
import { getUserById } from "@/common/function/getUserById";
import createKey from "@/common/function/createKey";
import createKeyByNumber from "@/common/function/createKeyByNumber";
import FormatterNumber from "../FormatterNumber.vue";
import isDark from "@/common/function/isDark";
import scrollToElementIfNeeded from "@/common/function/scrollToElementIfNeeded";
import KatexTooltip from "../KatexTooltip.vue";
export interface LevelRankItemProps {
  data?: any;
  backgroundColor?: string;
  paddingLeft?: string;
  sticky?: boolean;
  borderBottom?: boolean;
  rankColor?: string;
  hiddenHoverFlag?: boolean;
  customPersonalKey?: string;
  customGroupKey?: string;
  isRoot?: boolean;
  hideHeader?: boolean;
}
const props = defineProps<LevelRankItemProps>();
const store = useStore();
const scrollToView = () => {
  // (container.value as HTMLElement).scrollIntoView({ behavior: "smooth" });
};
const triggerReRender = ref<boolean>(false);
watch(
  () => store.state.allianceData,
  () => {
    triggerReRender.value = !triggerReRender.value;
  }
);
const handleShowTree = () => {
  const timeout = setTimeout(() => {
    const chartTree = document.getElementById("chart_tree");
    chartTree && scrollToElementIfNeeded(chartTree);
    clearTimeout(timeout);
  }, 200);
  window.location.hash = "#empty";
  // window.location.hash = "#hierarchy";
  store.commit("setAffShowType", "tree");
  store.commit("setTreeType", "branch");
  const user = getUserById(
    store.state.allianceData?.children,
    props.data?.userId
  );
  console.error("MMMM", user, (user as any)?.parent);
  store.commit(
    "setChartTreeData",
    (user as any)?.parent ?? (user != -1 ? user : props.data)
  );
};
onMounted(() => {
  scrollToView();
});
const getColor = () => {
  // const colors = ["#F5FFCE", "#ECFFA2", "#E1FE71", "#D0ED5E"];
  const colors = ["#F5FFCE", "#F5FFCE", "#F5FFCE", "#F5FFCE"];
  return colors[0];
  // try {
  //   let index = 0;
  //   if (props.data?.clicked_level <= 2) {
  //     index = props.data?.level;
  //     return colors[index];
  //   } else {
  //     index = props.data?.clicked_level - props.data?.level + 1;
  //     const tempColors = colors.reverse();
  //     return tempColors[index];
  //   }
  // } catch (err) {
  //   console.log(err);
  //   return "0xFF0000";
  // }
};

const getLeftPadding = () => {
  // const paddingLeftValues = useResponsive(
  //   ["0px", "16px", "32px", "64px"],
  //   ["0px", "24px", "48px", "72px"]
  // ).value;
  const paddingLeftValues = useResponsive(
    ["0px", "0px", "0px", "0px"],
    ["0px", "0px", "0px", "0px"]
  ).value;
  try {
    let index = 0;
    if (props.data?.clicked_level <= 2) {
      index = props.data?.level;
      return paddingLeftValues[index];
    } else {
      index = props.data?.clicked_level - props.data?.level + 1;
      const tempColors = paddingLeftValues.reverse();
      return tempColors[index];
    }
  } catch (err) {
    console.log(err);
    return "0px";
  }
};
</script>

<style scoped>
.level_rank_item_container {
}
.alliance_info_block {
  flex: 1;
}
.dark .border_bottom {
  border-bottom: 2px solid var(--limit-color-dark-mode) !important;
}
.border_bottom {
  border-bottom: 2px solid var(--Neutral-01, #141416) !important;
}
.sticky {
  position: fixed;
  top: 85px;
  left: 16px;
  right: 16px;
  z-index: 20;
}
.content_container {
  padding: 16px 24px;
  display: flex;
  align-items: center;
  gap: 48px;
  filter: grayscale(20%);
  position: relative;
}
.avatar_group_container {
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1.64473684211;
}
.rank_level_avatar {
  width: 48px;
  height: 48px;
  border-radius: 100px;
  border: 2px solid var(--app-questn-com-black, #000);
  display: block;
}
.nick_name_group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.dark .nick_name,
.dark .wallet_address {
  color: var(--text-color-dark-mode);
}
.nick_name {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
  white-space: nowrap;
}
.date {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.earned_value {
  color: var(--Neutral-01, #141416);
  font-family: "GT Walsheim";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  display: none; /* 14px */
}
.earned_value span:nth-child(1) {
  /* font-weight: 700 !important; */
}
.skeleton_loader {
  background: linear-gradient(90deg, #d0ed5e 25%, #f5ffce 50%, #d0ed5e 75%);
  background-size: 200% 100%;
  animation: loading 1.8s infinite;
  color: transparent;
}

.dark .hover_flag:hover {
  background: var(--dark-black) !important;
}
.hover_flag:hover {
  background: #c6d685 !important;
}

.dark .hover_flag::after {
  background: var(--text-color-dark-mode);
}
.hover_flag::after {
  content: "";
  display: block;
  height: 100%;
  width: 6px;
  background: #000;
  position: absolute;
  top: 0;
  left: -10px;
  transition: 200ms;
}

.hover_flag:hover::after {
  /* left: 0px; */
}
@keyframes loading {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}
.nick_name_copy {
  display: flex;
  align-items: center;
  gap: 8px;
}
@media (max-width: 1279px) {
  .group_analytic {
    align-items: end;
  }
  .icon_arrow_bottom {
    margin-left: 0 !important;
  }
  .wallet_address {
    color: var(--app-questn-com-black, #000);
    font-family: "GT Walsheim";
    font-size: 16px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
  }
  .rank_name {
    color: #2069ff;
    font-family: "GT Walsheim";
    font-size: 16px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .icon_add_tree {
    width: 20px;
    height: 20px;
  }

  .rank_level_avatar {
    width: 36px;
    height: 36px;
  }
  .nick_name {
    color: var(--Neutral-01, #141416);
    font-family: "GT Walsheim";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
  }
  .earned_value {
    display: flex;
    align-items: center;
    gap: 4px;
    margin: 4px;
  }
  .date {
    display: none;
  }
  .alliance_info_block_earned {
    display: none;
  }
  .alliance_info_block_deposit {
    align-items: flex-end;
  }
  .alliance_info_block_referral {
    display: none;
  }
  .alliance_info_block_rank {
    display: none;
  }
  .content_container {
    gap: 8px;
    padding: 16px;
  }
  .level_rank_item_container {
  }
  .copy_wallet_address_icon {
    width: 24px;
    height: 24px;
    display: block;
  }
  .amount_group {
    justify-content: start;
    display: flex;
    gap: 4px;
    align-items: center;
    width: fit-content;
  }
  .tooltip_icon {
    display: block;
    width: 24px;
    height: 24px;
  }
  .level_container {
    padding: 8px 8px !important;
  }

  .commission_value {
    color: var(--app-questn-com-black, #000);
    font-family: "GT Walsheim";
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
}
.wallet_address {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.rank_name {
  color: #8247e5;
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.commission_value {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 111.111% */
}
.dark .view_graph_button {
  background-color: #fff;
}
.view_graph_button {
  display: flex;
  height: 24px;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 24px;
  border: 1px solid #000;
  background: var(--app-questn-com-guardsman-red, #e8ff8d);
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 12px */
}
.dark .commission_value {
  color: var(--text-color-dark-mode);
}
.dark .header_level_rank {
  border-bottom: 1px var(--limit-color-dark-mode-light) dashed !important;
}

th {
  text-align: left;
}
td {
  min-width: 150px;
  padding: 4px 0;
}
tr {
}
.dark .no_reward_des {
  color: #d1d1d1;
}
.no_reward_des {
}
</style>
