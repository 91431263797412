<template>
  <div class="rank_item" :class="{ active: active }">
    <div class="rank_g_container">
      <div class="rank">{{ rank }}</div>
      <div class="text"><KatexTooltip value="aff_rank" /></div>
    </div>
    <div class="text">
      {{ translation(createKey(createKeyByNumber(+rank, "_r"), "_rank_name")) }}
      ({{
        filterByRank(store.state.affTreeHistory[0]?.children)?.find(
          (e) => e[0]?.ranking?.rank == rank
        )?.length ?? 0
      }})
    </div>
  </div>
</template>

<script setup lang="ts">
import createKey from "@/common/function/createKey";
import createKeyByNumber from "@/common/function/createKeyByNumber";
import filterByRank from "@/common/function/filterByRank";
import translation from "@/common/function/translation";
import { defineProps } from "vue";
import { useStore } from "vuex";
import KatexTooltip from "./KatexTooltip.vue";
const store = useStore();
const props = defineProps<{
  rank?: string | number;
  active?: boolean;
}>();
</script>

<style scoped>
.rank_item.active {
  color: black;
  background: #caff04;
}
.dark .rank_item.active {
  color: black;
  background: #fff;
}
.dark .rank_item {
  color: #636363;
}
.rank_item {
  border: 1px currentColor solid;
  border-radius: 999px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 16px 8px 52px;
  height: fit-content;
  box-sizing: border-box;
  cursor: pointer;
  color: #c2c2c2;
}
.dark .rank_item.active .rank_g_container,
.rank_item.active .rank_g_container {
  background-color: #f5ffce;
}
.dark .rank_item .rank_g_container {
  background-color: #2b2b2b;
  border-color: #2b2b2b !important;
  width: 45px;
  height: 46px;
  top: -1px;
}
.rank_item .rank_g_container {
  border: 1px currentColor solid;
  border-radius: 999px;
  width: 44px;
  height: 44px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -1px;
}
.rank_item .rank_g_container .rank {
  leading-trim: both;
  text-edge: cap;
  font-family: "GT Walsheim";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
}
.rank_item .rank_g_container .text {
  leading-trim: both;
  text-edge: cap;
  font-family: "GT Walsheim";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 10px */
  letter-spacing: -0.6px;
}
.rank_item .text {
  font-family: "GT Walsheim";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 28.8px */
}
</style>
