<template>
  <div
    class="border_2 rounded-[12px] p-3 bg-[#F5FFCE] bg_dark_light_most flex flex-col"
  >
    <header class="flex items-center justify-between">
      <div class="flex items-center gap-1">
        <h3 class="card_title text_dark">
          {{ translation(createKey("title", name)) }}
        </h3>
        <n-tooltip trigger="hover" :show-arrow="false">
          <template #trigger>
            <img
              loading="lazy"
              :src="getIcon(isDark() ? 'tooltip_white' : 'tooltip')"
              alt=""
              class="icon_w24_h24_res"
              @click="() => (show = !show)"
            />
          </template>
          {{ translation(createKey("title_tooltip", name)) }}
        </n-tooltip>
      </div>
      <slot name="right-header"></slot>
      <v-menu
        v-if="!hiddenTypeMenu"
        v-model="showSelect"
        class="relative !z-[99999999999999]"
      >
        <template v-slot:activator="{ props }">
          <div
            v-bind="props"
            class="h-fit cursor-pointer flex gap-2 capitalize justify-center self-stretch px-2 py-1.5 text-xs leading-4 whitespace-nowrap bg-amber-400 rounded-[100px] text-neutral-900"
          >
            <div>{{ translation(createKey(chartType, name)) }}</div>
            <img
              loading="lazy"
              :src="getIcon('arrow_bottom')"
              class="shrink-0 my-auto aspect-[1.49] fill-neutral-900 rotate-[180deg] duration-200"
              :style="{
                transform: `rotate(${showSelect ? 0 : 180}deg)`,
              }"
            />
          </div>
        </template>
        <v-list class="list_board !mt-1">
          <v-list-item
            class="list_item capitalize"
            @click="
              () => {
                chartType = e;
              }
            "
            v-for="(e, i) in chartTypes"
            :key="i"
          >
            <ListItem :is-selected="chartType == e">{{
              translation(createKey(e, name))
            }}</ListItem>
          </v-list-item>
        </v-list>
      </v-menu>
    </header>
    <!-- <div class="text-red-500">{{ chartData }}</div> -->
    <!-- <div @click="() => console.log(data)">Show</div> -->
    <div class="w-full">
      <AreaChart
        :data="
          (chartData?.data ?? []).map((e, i) => ({
            ...e,
            name: translation(createKey(e.name, name)),
          }))
        "
        :categories="chartData?.xAxisLabel ?? []"
      />
    </div>
    <section class="analytic-section flex flex-col gap-0.5 lg:gap-1.5">
      <div class="divider"></div>
      <div class="flex items-center flex-wrap gap-2 pt-2">
        <button
          @click="
            () => {
              selectedTimeRange = e;
            }
          "
          :class="{ active: e === selectedTimeRange }"
          custom-padding="8px"
          v-for="(e, i) in timeRange"
          :key="i"
          class="time_range_label whitespace-nowrap"
        >
          <span
            class="time_range_label"
            :style="{
              color:
                e === selectedTimeRange
                  ? isDark()
                    ? '#fff'
                    : '#000'
                  : isDark()
                  ? 'rgba(255, 255, 255, 0.24)'
                  : '#000',
            }"
            >{{ translation(createKey(e, name)) }}</span
          >
        </button>
      </div>
      <div class="time_filter"></div>
      <div class="total_commission_label text_dark">
        {{ translation(createKey("total_commission", name)) }}
      </div>
      <div class="commission_value_g text_dark">
        <FormatterNumber
          :value="chartData?.data?.map((e: any) => (e?.data as Array<number>)?.reduce((result, e) => result +e))?.reduce((result, e) => e+result)"
          :unit="translation(createKey(chartType + '_unit', name))"
        />
      </div>
      <div class="today_g text_dark">
        <span class="text_dark_des">{{
          translation(createKey("anl_today", name))
        }}</span>
        <span>
          <FormatterNumber
            :start="translation(createKey(chartType + '_unit', name))"
            :value="
              data &&
              data[
                `totalToday${
                  chartType?.toLocaleLowerCase() !== 'all'
                    ? toPascalCase(chartType)
                    : ''
                }`
              ]
            "
          />
          <span
            >(<FormatterNumber
              :start="`${
                data &&
                data[
                  `growthTotalToday${
                    chartType?.toLocaleLowerCase() !== 'all'
                      ? toPascalCase(chartType)
                      : ''
                  }`
                ] >= 0
                  ? translation(createKey('add', name))
                  : translation(createKey('anl_subtract', name))
              }`"
              :value="
                data &&
                data[
                  `growthTotalToday${
                    chartType?.toLocaleLowerCase() !== 'all'
                      ? toPascalCase(chartType)
                      : ''
                  }`
                ]
              "
              unit="%"
            />)</span
          >
        </span>
      </div>
      <p class="anc_des text_dark_des_light">
        {{ translation(createKey("anc_des", name)) }}
      </p>
      <div class="divider dark_border"></div>
      <div class="pnl_g grid grid-cols-2 lg:grid-cols-2 gap-1">
        <div class="pnl_col">
          <div
            class="title_g flex items-center text_dark_des gap-0.5 lg:gap-1.5 pb-1.5"
          >
            <span>{{
              translation(createKey("anc_pnl_this_month", name))
            }}</span>
            <n-tooltip trigger="hover" :show-arrow="false">
              <template #trigger>
                <img
                  loading="lazy"
                  :src="getIcon(isDark() ? 'tooltip_white' : 'tooltip')"
                  alt=""
                  class="icon_w24_h24_res"
                  @click="() => (show = !show)"
                />
              </template>
              {{ translation(createKey("this_month_tooltip", name)) }}
            </n-tooltip>
          </div>
          <div
            class="value_line"
            :class="{
              reduce_value:
                data &&
                data[
                  `pnlThisMonth${
                    chartType?.toLocaleLowerCase() !== 'all'
                      ? toPascalCase(chartType)
                      : ''
                  }`
                ] < 0,
            }"
          >
            <FormatterNumber
              :start="`${
                data &&
                data[
                  `pnlThisMonth${
                    chartType?.toLocaleLowerCase() !== 'all'
                      ? toPascalCase(chartType)
                      : ''
                  }`
                ] >= 0
                  ? translation(createKey('add', name))
                  : translation('anl_subtract')
              }${translation(`anl_unit_${chartType}`)}`"
              :value="
                data &&
                data[
                  `pnlThisMonth${
                    chartType?.toLocaleLowerCase() !== 'all'
                      ? toPascalCase(chartType)
                      : ''
                  }`
                ]
              "
            />
            <span
              >(<FormatterNumber
                :start="`${
                  data &&
                  data[
                    `growthPnlThisMonth${
                      chartType?.toLocaleLowerCase() !== 'all'
                        ? toPascalCase(chartType)
                        : ''
                    }`
                  ] >= 0
                    ? translation(createKey('add', name))
                    : translation('anl_subtract')
                }`"
                :value="
                  data &&
                  data[
                    `growthPnlThisMonth${
                      chartType?.toLocaleLowerCase() !== 'all'
                        ? toPascalCase(chartType)
                        : ''
                    }`
                  ]
                "
                unit="%"
              />)</span
            >
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref, watch, defineEmits } from "vue";
import FormatterNumber from "./FormatterNumber.vue";
import translation from "@/common/function/translation";
import getIcon from "@/common/function/getIcon";
import isDark from "@/common/function/isDark";
import useResponsive from "@/hook/useResponsive";
import KatexComponent from "./KatexComponent.vue";
import { NTooltip } from "naive-ui";
import RoundButton from "./RoundButton.vue";
import ListItem from "./ListItem.vue";
import toPascalCase from "@/common/function/toPascalCase";
import createKey from "@/common/function/createKey";
import AreaChart from "./AreaChart.vue";
const selectedTimeRange = ref<string>();
const timeRange = ref<string[]>([]);
const showSelect = ref<boolean>(false);
const chartType = ref<string>("");
const chartTypes = ref<string[]>([]);
const chartData = ref<any>([]);
interface ReportData {
  totalCommissions?: number | string;
  todayValue?: number | string;
  todayGrow?: number | string;
  pnlThisMonth?: number | string;
  pnlLastMonth?: number | string;
}
const props = defineProps<{
  name?: string;
  data?: any;
  isTypePrioritize?: boolean;
  hiddenTypeMenu?: boolean;
  isPieChartType?: boolean;
  reportData?: ReportData;
}>();
const show = ref(false);

const analyticData = ref<any>({});

// const setAllTypesAndAllTimes = (
//   isUpdateChartType = true,
//   isUpdateTimeSelected = true
// ) => {
//   try {
//     if (props.isPieChartType) {
//       timeRange.value = Object.keys(props.data?.dataRankLeft);
//       isUpdateTimeSelected && (selectedTimeRange.value = timeRange.value[0]);
//       return;
//     }
//     if (props.isTypePrioritize) {
//       chartTypes.value = Object.keys(props.data?.chartData ?? {});
//       updateChartType(chartTypes.value[0]);
//       timeRange.value = Object.keys(
//         props.data?.chartData[props.chartType] ?? {}
//       );
//       isUpdateTimeSelected && (selectedTimeRange.value = timeRange.value[0]);
//     } else {
//       timeRange.value = Object.keys(props.data?.data ?? {});
//       isUpdateTimeSelected && (selectedTimeRange.value = timeRange.value[0]);
//       chartTypes.value = props.data?.data[selectedTimeRange.value]["data"]?.map(
//         (e) => e.name
//       );
//       chartTypes.value.unshift("all");
//       isUpdateChartType &&
//         chartTypes.value &&
//         updateChartType(chartTypes.value[0]);
//     }
//   } catch (err) {
//     console.error("setAllTypesAndAllTimes", err);
//   }
// };
// const emit = defineEmits(["update:chartType"]);

// const updateChartType = (value: string) => {
//   emit("update:chartType", value);
// };

const setTimeRange = () => {
  if (!chartType.value || !props.data) return;
  timeRange.value = Object.keys(props.data?.chartData[chartType.value]);
  if (timeRange.value.indexOf(selectedTimeRange.value) != -1) {
    return;
  }
  selectedTimeRange.value = timeRange.value[0];
};

watch(
  () => props.data,
  () => {
    if (!props.data) return;
    chartTypes.value = Object.keys(props.data.chartData);
    !chartType.value && (chartType.value = chartTypes.value[0]);
    setTimeRange();
  },
  { immediate: true }
);

watch(chartType, () => {
  setTimeRange();
});
watch(
  [selectedTimeRange, chartType],
  () => {
    console.log("changed");
    if (!selectedTimeRange.value || !chartType.value) return;
    chartData.value =
      props.data?.chartData[chartType.value][selectedTimeRange.value];
  },
  { immediate: true }
);
</script>

<style scoped>
.card_title {
  color: var(--Neutral-01, #141416);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 19.6px */
  text-transform: capitalize;
}
.total_commission_label {
  color: var(--Neutral-01, #141416);
  font-family: "GT Walsheim";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  text-transform: capitalize;
}
.commission_value_g {
  color: var(--Neutral-01, #141416);
  font-family: "GT Walsheim";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 24px */
}
.today_g {
  color: var(--Neutral-01, #141416);
  font-family: "GT Walsheim";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
}
.anc_des {
  color: var(--Neutral-03, #636363);
  font-family: "GT Walsheim";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 14px */
  text-transform: capitalize;
}

.value_line.reduce_value {
  color: var(--Color-SOLX-Sherpa-Blue, #ff5153);
}

.value_line {
  color: var(--Color-SOLX-Mountain-Meadow, #12ad7e);
  font-family: "GT Walsheim";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
}
.title_g {
  color: var(--Neutral-03, #636363);
  font-family: "GT Walsheim";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 2;
}
.time_range_label {
  color: rgba(0, 0, 0, 0.24);
  font-family: "GT Walsheim";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding: 4px 8px;
  border-radius: 6px;
}
.dark .active {
  color: var(--text-color-dark-mode);
}
.active {
  color: var(--Neutral-01, #141416);
  background: rgba(0, 0, 0, 0.08);
}

@media (max-width: 1279px) {
  .card_title {
    color: var(--Neutral-01, #141416);
    font-family: "GT Walsheim";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 19.6px */
    text-transform: capitalize;
  }
  .total_commission_label {
    color: var(--Neutral-01, #141416);
    font-family: "GT Walsheim";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
    text-transform: capitalize;
  }
  .commission_value_g {
    color: var(--Neutral-01, #141416);
    font-family: "GT Walsheim";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 24px */
  }
  .today_g {
    color: var(--Neutral-01, #141416);
    font-family: "GT Walsheim";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
  }
  .anc_des {
    color: var(--Neutral-03, #636363);
    font-family: "GT Walsheim";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 14px */
    text-transform: capitalize;
  }

  .value_line.reduce_value {
    color: var(--Color-SOLX-Sherpa-Blue, #ff5153);
  }

  .value_line {
    color: var(--Color-SOLX-Mountain-Meadow, #12ad7e);
    font-family: "GT Walsheim";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
  }
  .title_g {
    color: var(--Neutral-03, #636363);
    font-family: "GT Walsheim";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
  }
  .time_range_label {
    color: rgba(0, 0, 0, 0.24);
    font-family: "GT Walsheim";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    padding: 4px 8px;
    border-radius: 6px;
  }
}
</style>
