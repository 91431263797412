export function isEqualOrLatterDate(date: Date, trigger?: number) {
  try {
    console.error("run", trigger);
    const timeCompare = date.getTime();
    const currentTime = new Date().getTime();
    if (currentTime < timeCompare) {
      return true;
    } else return false;
  } catch (err) {
    return false;
  }
}
