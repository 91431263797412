<template>
  <div class="home_history_card">
    <card-container
      class="override_card_content"
      :class="{
        swap_item: isSwapItem,
      }"
      bg-color="purple-pizzazz"
      :padding-top="16"
      :padding-right="16"
      :padding-bottom="16"
      :padding-left="16"
      :displacement-level="0"
    >
      <div class="history_container">
        <div class="cl_1">
          <img
            loading="lazy"
            :src="domainServer + '/assets/' + theProps?.icon"
            alt=""
            class="partner_logo"
          />
        </div>
        <div class="cl_2">
          <h3 class="title">
            <span v-if="(theProps as any)?.referee">{{
              `${translation("_aff_his_title_before")} ${compactWalletAddress(
                (theProps as any)?.referee
              )} ${translation("_aff_his_title_after")}`
            }}</span>
            <span v-else>
              <span class="not_apply_adjust_color_solx">{{
                translation(createKey(theProps?.title, "_before"))
              }}</span>

              <b>
                <FormatterNumber
                  v-if="(theProps as ITransactionHistory).amount != '' && +(theProps as ITransactionHistory).amount != 0"
                  :value="((theProps as ITransactionHistory).method == 'subtract' || isDepositTab ? 1 : 1) * (+(theProps as ITransactionHistory).amount)"
                />
              </b>
              <span
                class="not_apply_adjust_color_solx"
                v-if="checkRankAndLevel()"
                >{{ translation(createKey(theProps?.title, "_middle")) }}</span
              >
              <b
                v-if="(props.theProps as any)?.history_aff_level == 0 || (props.theProps as any)?.history_aff_level"
              >
                {{
                  translation(
                    createKey(
                      createKeyByNumber(
                        (props.theProps as any)?.history_aff_level,
                        "_l"
                      ),
                      "_level_name"
                    )
                  )
                }}
              </b>
              <b
                v-if="(props.theProps as any)?.history_aff_rank == 0 || (props.theProps as any)?.history_aff_rank"
              >
                {{
                  translation(
                    createKey(
                      createKeyByNumber(
                        (props.theProps as any)?.history_aff_rank,
                        "_r"
                      ),
                      "_rank_name"
                    )
                  )
                }}
              </b>
              <!-- <FormatterNumber
                :start="(theProps as ITransactionHistory).method == 'subtract' || isDepositTab?'+':'+'"
                :value="((theProps as ITransactionHistory).method == 'subtract' || isDepositTab? 1 : 1)*(+(theProps as ITransactionHistory).amount)"
                :unit="(theProps as ITransactionHistory)?.currency_transaction_currency_transactionTocurrency?.currency_name"
              /> -->
              <span class="not_apply_adjust_color_solx">{{
                translation(createKey(theProps?.title, "_after"))
              }}</span>
            </span>
          </h3>
          <h3 v-if="(theProps as any)?.date_created" class="subtitle">
            <!-- {{ new Date((theProps as any)?.date_created).toLocaleDateString() }}
            {{ new Date((theProps as any)?.date_created).toLocaleTimeString() }} -->
            {{ formatDateTo((theProps as any)?.date_created) }}
          </h3>
        </div>
        <div class="cl_3" v-if="!!(theProps as ITransactionHistory).status">
          <!-- <div
            style="display: flex; align-items: center; gap: 8px"
            v-if="(theProps as ITransactionHistory)?.status == 'failed'"
          >
            <v-chip color="red">
              <div style="text-transform: capitalize">
                {{ translation((theProps as ITransactionHistory)?.status) }}
              </div>
            </v-chip>
            <img
              loading="lazy"
              :src="getIcon(isDark() ? 'tooltip_white' : 'tooltip')"
              alt=""
              class="icon_w24_h24"
              @click="() => (show = !show)"
            />
            <v-tooltip v-model="show" activator="parent" location="top">
              {{ (theProps as any)?.error_message }}
            </v-tooltip>
          </div> -->

          <div class="amount_group">
            <p
              v-if="true || (theProps as ITransactionHistory)?.hash || (theProps as ITransactionHistory)?.status == 'failed'"
              class="amount"
              :class="{ amount_error: (theProps as ITransactionHistory)?.status == 'failed' }"
            >
              {{
                translation("his_" + (theProps as ITransactionHistory)?.status)
              }}
            </p>
            <a
              v-if="(theProps as ITransactionHistory)?.hash"
              class="guide_button_container"
              target="_blank"
              :href="'https://solscan.io/tx/' + (theProps as ITransactionHistory).hash + clusterApi"
            >
              <img
                loading="lazy"
                class="guide_arrow"
                :src="getIcon(
                (theProps as ITransactionHistory)?.status == 'failed'
                  ? 'arrow_right_red'
                  : 'arrow_right_green'
              )
                "
                alt=""
              />
            </a>
            <div
              v-if="!(theProps as ITransactionHistory)?.hash"
              class="guide_arrow"
            ></div>
          </div>
        </div>
      </div>
    </card-container>
  </div>
</template>

<script setup lang="ts">
import getIcon from "@/common/function/getIcon";
import CardContainer from "./CardContainer.vue";
import { defineProps } from "vue";
import { ITransactionHistory, IAffiliateHistory } from "@/common/type";
import formatter from "@/common/function/formatter";
import compactDecimalPartOfNumber from "@/common/function/compactDecimalPartOfNumber";
import useResponsive from "@/hook/useResponsive";
import compactWalletAddress from "@/common/function/compactWalletAddress";
import translation from "@/common/function/translation";
import FormatterNumber from "./FormatterNumber.vue";
import createKey from "@/common/function/createKey";
import { ref } from "vue";
import { useStore } from "vuex";
import formatDateTo from "@/common/function/formatDate";
import isDark from "@/common/function/isDark";
import createKeyByNumber from "@/common/function/createKeyByNumber";

const domainServer = process.env.VUE_APP_DOMAIN_SERVER;
const clusterApi = process.env.VUE_APP_CLUSTER_URL;
interface CardInfoProps {
  theProps?: ITransactionHistory | IAffiliateHistory;
  isDepositTab?: boolean;
  isSwapItem?: boolean;
}
const store = useStore();
const show = ref<any>(null);
const props = defineProps<CardInfoProps>();

const checkRankAndLevel = () => {
  return (
    (props.theProps as any)?.history_aff_level == 0 ||
    (props.theProps as any)?.history_aff_rank == 0 ||
    (props.theProps as any)?.history_aff_level ||
    (props.theProps as any)?.history_aff_rank
  );
};
</script>

<style scoped>
.history_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* max-width: 100%; */
}

.cl_1 {
}

.cl_2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 12px;
  margin-right: auto;
}

.dark .cl_2 .title {
  color: var(--text-color-dark-mode);
}

.cl_2 .title {
  color: var(--Neutral-01, #141416);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  white-space: wrap !important;
  line-height: 160%;
  /* 28.8px */
}

.dark .cl_2 .subtitle {
  color: var(--text-color-dark-mode);
}

.cl_2 .subtitle {
  color: var(--Neutral-01, #141416);
  font-family: "GT Walsheim";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 180%;
  /* 28.8px */
  font-style: italic;
}

.partner_logo {
  width: 48px;
  height: 48px;
  display: block;
}

.guide_button_container {
  display: flex;
  align-items: flex-start;
  gap: 6px;
}

.dark .guide_title {
  color: var(--text-color-dark-mode);
}

.guide_title {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 21.6px */
}

.guide_arrow {
  width: 24px;
  height: 24px;
  display: block;
}

.cl_3 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: end;
}

.dark .cl_3 .amount {
  /* color: var(--text-color-dark-mode); */
}

.cl_3 .amount.amount_error {
  color: var(--Color-SOLX-Sherpa-Blue, #ff5153);
}

.cl_3 .amount {
  color: var(--Color-SOLX-Mountain-Meadow, #12ad7e);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 21.6px */
}

.amount_group {
  justify-content: end;
  display: flex;
  gap: 4px;
  align-items: center;
  width: fit-content;
  min-width: 50px;
}

.tooltip_icon {
  display: block;
  width: 24px;
  height: 24px;
}

@media screen and (max-width: 1280px) {
  .cl_2 .title {
    font-size: 16px;
  }

  .cl_2 .subtitle {
    font-size: 14px;
  }

  .guide_title {
    font-size: 16px;
    display: none;
  }

  .cl_3 .amount {
    font-size: 16px;
  }

  .guide_arrow {
    width: 20px;
    height: 20px;
    margin-left: auto;
  }

  .guide_button_container {
    display: flex;
    align-items: flex-end;
    gap: 6px;
  }

  .partner_logo {
    width: 28px;
    height: 28px;
  }
}

.dark .override_card_content {
  background-color: var(--dark-black-light-more) !important;
  border: 2px var(--limit-color-dark-mode) solid;
}

.dark .swap_item {
  background-color: transparent !important;
}

.swap_item {
  border: none !important;
  padding: 0 !important;
  background-color: transparent !important;
}
</style>
