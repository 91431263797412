import useResponsive from "@/hook/useResponsive";

export default function compactWalletAddress(walletAddress?: string) {
  try {
    if (!walletAddress) return undefined;
    if (walletAddress.length <= 8) {
      return walletAddress;
    }
    return (
      walletAddress?.slice(0, useResponsive(3, 4).value) +
      "..." +
      walletAddress?.slice(-useResponsive(3, 4).value)
    );
  } catch (err) {
    console.error("compactWalletAddress", err);
    return undefined;
  }
}
