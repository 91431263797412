import { userClient } from "@/graphql/client/user-client";
import { getAllianceDataQuery } from "@/graphql/query/getAllianceDataQuery";
import store from "@/store";
import { provideApolloClient, useQuery } from "@vue/apollo-composable";
import filterByRank from "./filterByRank";
import { assignLevelAndBackgroundColor } from "./assignLevelAndBackgroundColor";
import { basicClient } from "@/graphql/client/basic-client";
import { getBannerQuery } from "@/graphql/query/getBannerQuery";
import { getAppSettingQuery } from "@/graphql/query/getAppSettingQuery";

export default function fetchIcons() {
  try {
    const { result, refetch, onResult } = provideApolloClient(basicClient)(() =>
      useQuery(getAppSettingQuery)
    );
    onResult(() => {
      if (result.value) {
        const iconData = result.value?.getAppSetting?.data_icon;
        store.commit('setIconData', iconData)
      }
    });
  } catch (err) {
    console.error(err);
  }
}