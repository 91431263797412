export function isAncestorOf(data: any, id: string): boolean {
  let result = false;
  if (data?.userId == id) {
    return true;
  }

  if (Array.isArray(data?.children) && data.children.length > 0) {
    data.children.forEach((element: any) => {
      result = result || isAncestorOf(element, id);
    });
    return result;
  } else {
    return result;
  }
}