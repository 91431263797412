import { DEFAULT_REWARD_RANK } from "../const";
import createRankObject from "./createRankObject";

const getAllMembersIgnoreLevel = (data: any) => {
  let members: any[] = [];
  try {
    if (data?.length > 0) {
      data.forEach((member: any) => {
        members = [...members, member];
        if (member.children?.length > 0) {
          members = [...members, ...getAllMembersIgnoreLevel(member.children)];
        }
      });
    }
    return members;
  } catch (err) {
    console.error("getAllMembersIgnoreLevel", err);
    return [];
  }
};

// export default function filterByRank(res: any): any {
//   let allMembers: any = [];
//   const data: any = JSON.parse(JSON.stringify(res)) || [];
//   allMembers = getAllMembersIgnoreLevel(res);
//   console.error("filterByRank data", data);
//   console.error("filterByRank members", allMembers);
//   try {
//     return [];
//   } catch (err) {
//     console.error("filterByRank", err);
//     return [];
//   }
// }
export default function filterByRank(res: any): any {
  const data: any = res || [];
  let result: any = [];
  try {
    //
    let allMembers: any = [];
    allMembers = getAllMembersIgnoreLevel(res).map((e) => ({
      ...e,
      ranking: createRankObject(e.ranking),
    }));
    if (allMembers.length == 0) return [];
    const allRanks = Array.from(
      new Set(allMembers.map((e: any) => e.ranking?.rank))
    ).sort((a, b) => Number(b) - Number(a));
    if (!allRanks || allRanks.length == 0) return [];
    allRanks.forEach((rank: any) => {
      result = [
        ...result,
        allMembers?.filter((member: any) => member.ranking?.rank == rank),
      ];
    });
    //
    return result;
  } catch (err) {
    console.error("filterByRank", err);
    return [];
  }
}
