import store from "@/store";
import Solana from "@/wallet/solana/Solana";

const useSolana = (): Solana => {
  if (store?.state?.solana) {
    console.log("exits");
    return store?.state?.solana as Solana;
  }
  const solanaJustInit = new Solana();
  store?.commit("setSolana", solanaJustInit);
  return store?.state?.solana as Solana;
};

export default useSolana;
