<template>
  <nav>
    <div id="nav_bar" class="nav_container relative">
      <div class="logo_block">
        <PrimaryLogo :src="getIcon('full_logo')" />
      </div>
      <div class="menu_block lg:!flex !hidden">
        <NavItem
          v-for="(e, i) in store.state.user?.token
            ? LOGGED_NAV
            : NOT_LOGGED_NAV"
          :key="i"
          :name="translation(e.name)"
          href="javascript:void(0)"
          @click="changeTab(e.name, e.href)"
        />
      </div>
      <div class="nav_button_g relative">
        <PrimaryButton
          class="hidden lg:block"
          :custom-background="isDark() ? '#2b2b2b' : undefined"
          @click="
            () => {
              if (store.state.themeMode == 'dark') {
                store.commit('setThemeMode', 'light');
                return;
              }
              store.commit('setThemeMode', 'dark');
            }
          "
        >
          <div class="flex items-center gap-2">
            <img
              v-if="!isDark()"
              class="block w-6 h-6"
              :src="
                getIcon(
                  `light_mode${
                    store.state.themeMode == 'dark' ? '' : '_active'
                  }`
                )
              "
              alt=""
            />
            <!-- {{ store.state.isTelegram ? "true" : "false" }} -->
            <!-- <div class="h-6 w-[1px] bg-[#cfd8dc]"></div> -->
            <img
              v-if="isDark()"
              class="block w-6 h-6"
              :src="getIcon(isDark() ? 'mode_active_dark' : 'mode_dark')"
              alt=""
            />
          </div>
        </PrimaryButton>
        <v-menu v-if="(store.state.languages?.length || 0) > 1">
          <template v-slot:activator="{ props }">
            <PrimaryButton v-bind="props">
              <WalletItem
                show-down-arrow
                :name="
                  useResponsive(
                    store.state.selectedLanguage?.name?.indexOf('-') == -1
                      ? store.state.selectedLanguage?.name
                      : store.state.selectedLanguage?.name.slice(
                          0,
                          store.state.selectedLanguage?.name?.indexOf('-')
                        ),
                    store.state.selectedLanguage?.name
                  ).value
                "
                :icon="
                  useResponsive(
                    undefined,
                    remoteImage(store.state.selectedLanguage?.icon)
                  ).value
                "
                :gap="8"
              />
            </PrimaryButton>
          </template>
          <v-list class="list_board">
            <v-list-item
              class="list_item"
              @click="() => store.commit('setSelectedLanguage', item)"
              v-for="(item, index) in store.state.languages"
              :key="index"
              :value="index"
            >
              <ListItem
                :icon="remoteImage(item.icon)"
                :is-selected="store.state.selectedLanguage?.code == item.code"
                >{{ item.name }}</ListItem
              >
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- web -->
        <v-menu v-if="!isNotFoundWallet || !isMobile" v-model="refDesktop">
          <template v-slot:activator="{ props }">
            <PrimaryButton
              v-bind="props"
              @click="handleClickConnectButtonDesktop"
              v-if="store.state?.solana"
              custom-background="#F3BA2F"
              class="button_web"
            >
              <div style="display: flex; gap: 4px">
                <WalletItem
                  wallet-name-color="#000"
                  v-if="solana.wallet?.connected"
                  :name="compactWalletAddress(store.state.walletAddress)"
                  :icon="solana?.getWalletSelectedInfo()?.icon"
                  :gap="8"
                />
                <div v-if="solana.wallet?.connecting">
                  {{ translation("nav_Connecting___") }}
                </div>
                <div
                  v-if="!solana.wallet?.connected && !solana.wallet?.connecting"
                >
                  {{ translation("nav_Connect") }}
                </div>
                <div
                  v-if="solana.wallet?.connected"
                  class="arrow_container text-[#000]"
                  :class="{
                    rotate_180: refDesktop,
                  }"
                >
                  <v-icon icon="mdi-chevron-down"></v-icon>
                </div>
              </div>
              <!-- <div v-if="!isDeviceLaptop()">
                <div v-if="!(solana?.getSolanaStatus() == 'CONNECTED')">
                  Connect
                </div>
                <div v-if="solana?.getSolanaStatus() == 'CONNECTED'">
                  <WalletItem
                    :name="solana?.getShortenedWalletAddress()"
                    :icon="getIcon('wallet')"
                    :gap="4"
                  />
                </div>
              </div> -->
            </PrimaryButton>
          </template>
          <v-list class="list_board">
            <v-list-item
              class="list_item"
              @click="
                () => {
                  if (
                    e.name.toLowerCase() === 'phantom' &&
                    !isNotRepeatPhantomWarning()
                  ) {
                    selectedWallet = e;
                    store.commit('setPhantomWarning', true);
                  } else {
                    solana.closeWalletModal();
                    solana.select(e.name, () => solana.wallet?.connect());
                  }
                }
              "
              v-for="(e, i) in solana?.getAllWallets()"
              :key="i"
              v-show="walletShouldShow(e, walletDetect)"
            >
              <ListItem
                :is-selected="
                  solana.wallet?.connected &&
                  solana.getWalletSelectedInfo()?.name == e.name
                "
                :icon="e.icon"
                >{{ e.name }}
              </ListItem>
            </v-list-item>
            <v-list-item
              class="list_item border_top"
              v-if="solana.wallet?.connected"
            >
              <ListItem
                @click="
                  () => handleCopyWalletAddress(store.state.walletAddress || '')
                "
                >{{ translation("nav_Copy_address") }}
              </ListItem>
            </v-list-item>
            <v-list-item
              class="list_item"
              @click="
                () => {
                  solana?.closeOptionsModal();
                  solana.wallet?.disconnect();
                }
              "
              v-if="solana.wallet?.connected"
            >
              <ListItem>{{ translation("nav_Disconnect") }}</ListItem>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div class="relative min-h-[50px]">
        <!-- mobile in-app -->

        <v-menu
          :close-on-content-click="false"
          v-if="isMobile && isNotFoundWallet && store.state.isTelegram == false"
        >
          <template v-slot:activator="{ props }">
            <div v-if="isMobile" v-bind="props" class="button_block">
              <PrimaryButton
                v-if="solana?.getSolanaStatus() != 'CONNECTED'"
                @click="handleSelectWallet"
                class="connect_btn"
                custom-background="#F3BA2F"
              >
                <div
                  v-if="solana?.getSolanaStatus() == 'NO_WALLET'"
                  class="text"
                >
                  <img
                    loading="lazy"
                    :src="getIcon('add')"
                    alt=""
                    class="icon_button"
                  />
                  <p>
                    {{ translation("nav_Connect") }}
                  </p>
                </div>
                <!-- solana?.getWalletAdapter()?.icon -->
                <div v-if="solana?.getSolanaStatus() == 'SELECTED_WALLET'">
                  <p>{{ translation("nav_Connect") }}</p>
                </div>
              </PrimaryButton>
              <PrimaryButton
                v-if="solana?.getSolanaStatus() == 'CONNECTED'"
                @click="solana.openOptionsModal()"
                class="connect_btn"
                custom-background="#F3BA2F"
              >
                <div
                  v-if="solana?.getSolanaStatus() == 'CONNECTED'"
                  style="display: flex; gap: 4px"
                >
                  <WalletItem
                    wallet-name-color="#000"
                    :name="solana?.getShortenedWalletAddress()"
                    :icon="solana?.getWalletAdapter()?.icon"
                    :gap="4"
                  />
                  <div
                    class="arrow_container"
                    :class="{
                      rotate_180:
                        useResponsive().isMobileScreen &&
                        solana.isOpenOptionsModal,
                    }"
                  >
                    <v-icon icon="mdi-chevron-down"></v-icon>
                  </div>
                </div>
              </PrimaryButton>
            </div>
          </template>
          <v-list
            v-if="useResponsive().isMobileScreen && solana.isOpenWalletModal"
            class="list_board"
          >
            <v-list-item
              hidden-back-layer
              @click="
                () => {
                  solana.setWalletAdapter(e.adapter);
                  wallet = e.adapter.name;
                }
              "
              class="wallet_item"
              v-for="(e, i) in [...(solana.wallet.wallets as any), solana.getOkxProps()].filter(e => isAndroid ? (e.adapter.name !== 'Trust' && e.adapter.name !== 'Mobile Wallet Adapter') : e)"
              :key="i"
            >
              <div class="wallet_option">
                <WalletItem
                  className="wallet_option_one"
                  :name="e.adapter.name"
                  :icon="e.adapter.icon"
                />
                <v-radio
                  :style="{
                    color: isDark() ? 'white' : 'black',
                  }"
                  v-model="wallet"
                  className="wallet_option_two"
                  style="display: flex; justify-content: flex-end"
                  :value="e.adapter.name"
                ></v-radio>
              </div>
            </v-list-item>
            <v-list-item>
              <PrimaryButton
                @click="handleClickConnectButton"
                style="margin-bottom: 12px; width: 98%"
                custom-background="#F3BA2F"
              >
                <div
                  class="text"
                  v-if="solana?.getSolanaStatus() == 'NO_WALLET'"
                >
                  <p>{{ translation("nav_Connect") }}</p>
                </div>
                <div v-if="solana?.getSolanaStatus() == 'SELECTED_WALLET'">
                  <WalletItem
                    wallet-name-color="#000"
                    name="Connect"
                    :icon="solana?.getWalletAdapter()?.icon"
                    :gap="4"
                  />
                </div>
                <div v-if="solana?.getSolanaStatus() == 'CONNECTED'">
                  <WalletItem
                    wallet-name-color="#000"
                    :name="solana?.getShortenedWalletAddress()"
                    :icon="getIcon('wallet')"
                    :gap="4"
                  />
                </div>
              </PrimaryButton>
            </v-list-item>
          </v-list>
          <!-- v-if="useResponsive().isMobileScreen && solana.isOpenOptionsModal" -->
          <v-list v-if="solana.isOpenOptionsModal" class="list_board">
            <v-list-item hidden-back-layer class="wallet_item">
              <CopyText :value="solana.getWalletAddress() || ''">
                <WalletItem :name="'Connected Wallet'" />
              </CopyText>
            </v-list-item>
            <!-- @click="() => solana?.openOptionsModal()" -->
            <v-list-item hidden-back-layer class="wallet_item">
              <CopyText
                class="value_g"
                :value="solana.getWalletAddress() || ''"
              >
                <b
                  >{{
                    compactWalletAddress(solana.getWalletAddress() || "")
                  }} </b
                ><img
                  class="copy_wallet_address_icon"
                  :src="getIcon('copy_icon')"
                  alt=""
                  loading="lazy"
                />
              </CopyText>
            </v-list-item>
            <v-list-item class="wallet_item">
              <div class="wallet_option">
                <PrimaryButton
                  style="margin-bottom: 12px; width: 98%"
                  custom-background="#F3BA2F"
                >
                  <WalletItem
                    wallet-name-color="#000"
                    :name="'Disconnect'"
                    @click="
                      () => {
                        solana?.disconnect();
                      }
                    "
                  />
                </PrimaryButton>
              </div>
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- mobile telegram -->
        <v-menu
          :close-on-content-click="false"
          v-if="isMobile && isNotFoundWallet && store.state.isTelegram == true"
        >
          <template v-slot:activator="{ props }">
            <div v-if="isMobile" v-bind="props" class="button_block">
              <PrimaryButton
                v-if="solana?.getSolanaStatus() != 'CONNECTED'"
                @click="handleSelectWallet"
                class="connect_btn"
                custom-background="#F3BA2F"
              >
                <div
                  v-if="solana?.getSolanaStatus() == 'NO_WALLET'"
                  class="text"
                >
                  <img
                    loading="lazy"
                    :src="getIcon('add')"
                    alt=""
                    class="icon_button"
                  />
                  <p>{{ translation("nav_Connect") }}</p>
                </div>
                <!-- solana?.getWalletAdapter()?.icon -->
                <div v-if="solana?.getSolanaStatus() == 'SELECTED_WALLET'">
                  <p>{{ translation("nav_Connect") }}</p>
                </div>
              </PrimaryButton>
              <PrimaryButton
                v-if="solana?.getSolanaStatus() == 'CONNECTED'"
                @click="solana.openOptionsModal()"
                class="connect_btn"
                custom-background="#F3BA2F"
              >
                <div
                  v-if="solana?.getSolanaStatus() == 'CONNECTED'"
                  style="display: flex; gap: 4px"
                >
                  <WalletItem
                    wallet-name-color="#000"
                    :name="solana?.getShortenedWalletAddress()"
                    :icon="solana?.getWalletAdapter()?.icon"
                    :gap="4"
                  />
                  <div
                    class="arrow_container"
                    :class="{
                      rotate_180:
                        store.state.isTelegram && solana.isOpenOptionsModal,
                    }"
                  >
                    <v-icon icon="mdi-chevron-down"></v-icon>
                  </div>
                </div>
              </PrimaryButton>
            </div>
          </template>
          <v-list
            v-if="useResponsive().isMobileScreen && solana.isOpenWalletModal"
            class="list_board"
          >
            <v-list-item
              hidden-back-layer
              @click="
                () => {
                  solana.setWalletAdapter(e);
                  wallet = e.adapter;
                }
              "
              class="wallet_item"
              v-for="(e, i) in walletAdapter"
              :key="i"
            >
              <div class="wallet_option">
                <WalletItem
                  className="wallet_option_one"
                  :name="e.adapter"
                  :icon="e.icon"
                />
                <v-radio
                  :style="{
                    color: isDark() ? 'white' : 'black',
                  }"
                  v-model="wallet"
                  className="wallet_option_two"
                  style="display: flex; justify-content: flex-end"
                  :value="e.adapter"
                ></v-radio>
              </div>
            </v-list-item>
            <v-list-item>
              <PrimaryButton
                @click="handleClickConnectButton"
                style="margin-bottom: 12px; width: 98%"
                custom-background="#F3BA2F"
              >
                <div
                  class="text"
                  v-if="solana?.getSolanaStatus() == 'NO_WALLET'"
                >
                  <p>{{ translation("nav_Connect") }}</p>
                </div>
                <div v-if="solana?.getSolanaStatus() == 'SELECTED_WALLET'">
                  <WalletItem
                    wallet-name-color="#000"
                    name="Connect"
                    :icon="solana?.getWalletAdapter()?.icon"
                    :gap="4"
                  />
                </div>
                <div v-if="solana?.getSolanaStatus() == 'CONNECTED'">
                  <WalletItem
                    wallet-name-color="#000"
                    :name="solana?.getShortenedWalletAddress()"
                    :icon="getIcon('wallet')"
                    :gap="4"
                  />
                </div>
              </PrimaryButton>
            </v-list-item>
          </v-list>
          <!-- v-if="useResponsive().isMobileScreen && solana.isOpenOptionsModal" -->
          <v-list v-if="solana.isOpenOptionsModal" class="list_board">
            <v-list-item hidden-back-layer class="wallet_item">
              <CopyText :value="solana.getWalletAddress() || ''">
                <WalletItem :name="'Connected Wallet'" />
              </CopyText>
            </v-list-item>
            <!-- @click="() => solana?.openOptionsModal()" -->
            <v-list-item hidden-back-layer class="wallet_item">
              <CopyText
                class="value_g"
                :value="solana.getWalletAddress() || ''"
              >
                <b
                  >{{
                    compactWalletAddress(solana.getWalletAddress() || "")
                  }} </b
                ><img
                  class="copy_wallet_address_icon"
                  :src="getIcon('copy_icon')"
                  alt=""
                  loading="lazy"
                />
              </CopyText>
            </v-list-item>
            <v-list-item class="wallet_item">
              <div class="wallet_option">
                <PrimaryButton
                  style="margin-bottom: 12px; width: 98%"
                  custom-background="#F3BA2F"
                  @click="disconnect"
                >
                  <WalletItem wallet-name-color="#000" :name="'Disconnect'" />
                </PrimaryButton>
              </div>
            </v-list-item>
          </v-list>
        </v-menu>
        <div
          icon-key="low:mdi-alert-circle-outline;medium:mdi-alert-circle;high:mdi-alert"
          :class="`absolute z-[998] right-[0px] top-[calc(100%+16px)] bg-white rounded-[4px] w-[447px] !max-w-[calc(100vw-32px)]`"
        >
          <v-alert
            class=""
            v-if="store.state.statusALert == 'medium'"
            :icon="translation('mdi-alert-circle')"
            v-model="store.state.alert"
            close-label="Close Alert"
            :title="translation('solana_matrics_content')"
            type="warning"
            variant="outlined"
            closable
          >
            {{
              translation(`solana_matrics_content_${store.state.statusALert}`)
            }}
          </v-alert>
          <v-alert
            class="!max-w-[447px]"
            v-if="store.state.statusALert == 'low'"
            :icon="translation('mdi-alert-circle-outline')"
            v-model="store.state.alert"
            close-label="Close Alert"
            :title="translation('solana_matrics_content')"
            type="warning"
            variant="outlined"
            closable
          >
            {{
              translation(`solana_matrics_content_${store.state.statusALert}`)
            }}
          </v-alert>
          <v-alert
            class="!max-w-[447px]"
            v-if="store.state.statusALert == 'high'"
            :icon="translation('mdi-alert')"
            v-model="store.state.alert"
            close-label="Close Alert"
            :title="translation('solana_matrics_content')"
            type="warning"
            variant="outlined"
            closable
          >
            {{
              translation(`solana_matrics_content_${store.state.statusALert}`)
            }}
          </v-alert>
        </div>
      </div>
    </div>
    <PhantomWarning :on-next="handleIgnore" />
  </nav>
</template>

<script setup lang="ts">
/* eslint-disable */
import PrimaryLogo from "./PrimaryLogo.vue";
import PhantomWarning from "./PhantomWarning.vue";
import getIcon from "@/common/function/getIcon";
import NavItem from "./NavItem.vue";
import { LOGGED_NAV } from "@/common/const";
import { NOT_LOGGED_NAV } from "@/common/const";
import PrimaryButton from "./PrimaryButton.vue";
import ListItem from "./ListItem.vue";
import { NAV_BAR_HEIGHT } from "@/ui_config";
import { MAX_WIDTH } from "@/ui_config";
import useSolana from "@/hook/useSolana";
import { walletAdapter } from "@/wallet/solana/Solana";
import { NModal, useMessage } from "naive-ui";
import CardContainer from "./CardContainer.vue";
import WalletItem from "./WalletItem.vue";
import CopyText from "./CopyText.vue";
import { isDeviceLaptop } from "@/wallet/solana/Solana";
import store from "@/store";
import { WalletMultiButton } from "solana-wallets-vue";
import "solana-wallets-vue/styles.css";
import translation from "@/common/function/translation";
import { onMounted, ref, computed } from "vue";
import useTextMessage from "@/hook/useTextMessage";
import createKey from "@/common/function/createKey";
import remoteImage from "@/common/function/remoteImage";
import useResponsive from "@/hook/useResponsive";
import compactWalletAddress from "@/common/function/compactWalletAddress";
import getVuetifyIcon from "@/common/function/getVuetifyIcon";
import MobileDetect from "mobile-detect";
import { Wallet } from "solana-wallets-vue/dist/types";
import isDark from "@/common/function/isDark";
import { url } from "inspector";
import isNotRepeatPhantomWarning from "@/common/function/isNotRepeatPhantomWarning";
import { log } from "console";
const solana = useSolana();
const keyValue = ref<string>("");
const prefix = ref<string>("");
const wallet = ref<string | boolean>("");
const refDesktop = ref(false);
const alert = computed(() => store.state.alert);
const selectedWallet = ref<any>();
const urlConnect = ref<any>();
const handlePasteKey = () => {
  keyValue.value = `translation('${prefix.value}${createKey(keyValue.value)}')`;
  window.navigator.clipboard.writeText(keyValue.value);
  useTextMessage("Copied", "green");
  const timeout = setTimeout(() => {
    keyValue.value = "";
    clearTimeout(timeout);
  }, 2000);
};
const handleIgnore = () => {
  if (isDeviceLaptop() && !isMobile) {
    solana.closeWalletModal();
    solana.select(selectedWallet.value.name, () => solana.wallet?.connect());
  } else {
    window.open(urlConnect.value, "_blank");
    // solana.handleDeeplinkConnectOnMobile(urlRedirect);
  }
};
const handleClickConnectButtonDesktop = () => {
  if (isDeviceLaptop()) {
    const solana = useSolana();
    solana.openOptionsModal();
    // refDesktop.value = !refDesktop.value;
  } else {
    console.log("Eslint nothing");
  }
};
const message = useMessage();
const handleClickConnectButtonCloseModel = () => {
  solana.openOptionsModal();
};
const disconnect = () => {
  solana?.disconnectTele();
};
const handleClickConnectButton = () => {
  if (isDeviceLaptop() && !isMobile) {
    solana.connectWebsite();
  } else {
    //   solana.connectWebsite();
    //   return;
    // }

    if (solana?.getSolanaStatus() == "NO_WALLET") {
      handleSelectWallet();
      return;
    }

    if (solana.getSolanaStatus() == "SELECTED_WALLET") {
      if (store.state.isTelegram == false) {
        const currentUrl = window.location.href;
        const urlParams = new URLSearchParams(window.location.search);
        const affiliateId = urlParams.get("aff_id") ?? null;
        const campaignId = urlParams.get("campaign_id") ?? null;
        const url = new URL(currentUrl);
        url.searchParams.set("aff_id", affiliateId);
        url.searchParams.set("campaign_id", campaignId);
        const urlRedirect = url.toString();

        console.log("URL REDIRECT", urlRedirect);
        console.log(
          "🚀 ~ handleClickConnectButton ~ solana.getWalletAdapter():",
          solana.getWalletAdapter()
        );

        if (
          ((solana.getWalletAdapter() as any)?.name.toLowerCase() ||
            (solana.getWalletAdapter() as any)?.adapter.toLowerCase()) !=
          "phantom"
        ) {
          // solana.select("Solflare" as any, () => solana.wallet?.connect());
          solana.handleDeeplinkConnectOnMobile(urlRedirect);
        } else {
          urlConnect.value = solana.browsePhantomDapp(urlRedirect);
          if (!isNotRepeatPhantomWarning()) {
            store.commit("setPhantomWarning", true);
          } else {
            // window.open(urlConnect.value, "_blank");
            solana.handleDeeplinkConnectOnMobile(urlRedirect);
          }
        }
        return;
      } else {
        solana.connect();
        return;
      }
    }
  }
  // solana.openWalletModal();
};
const handleChangeWallet = () => {
  window.localStorage.clear();
  solana?.closeOptionsModal();
};
const handleSelectWallet = () => {
  solana.openWalletModal();
};
const changeTab = (name: string, href: string) => {
  console.log("🚀 ~ changeTab ~ name:", name);
  if (name == "nav_Mining" || name == "Mining") {
    store.commit("setPowering", "powering");
  }
  if (name == "nav_Stake") {
    store.commit("setPowering", "loan");
  }

  if (name == "nav_Sell") {
    store.commit("setPowering", "sell");
  }
  if (name == "nav_Stake_r") {
    store.commit("setPowering", "investment");
  }
  window.location.href = href;
};
const handleCopyWalletAddress = (value: string) => {
  solana?.closeOptionsModal();
  try {
    window.navigator.clipboard.writeText(value);
    useTextMessage("Copied", "green");
  } catch (err) {
    useTextMessage("Text hasn't been copied yet", "orange");
    console.log(err);
  }
};

const walletDetect = ref<string | null>(null);

const walletShouldShow = (e: any, walletDetect: string | null) => {
  if (!walletDetect)
    return (
      e.name == "Solflare" ||
      e.name == "Phantom" ||
      e.name == "OKX Wallet" ||
      e.name == "Trust" ||
      e.name == "Bitget Wallet"
    );
  if (
    walletDetect == "Phantom" ||
    walletDetect == "OKX Wallet" ||
    walletDetect == "Trust" ||
    walletDetect == "Bitget Wallet"
  ) {
    return e.name == "Solflare" || e.name == walletDetect;
  } else {
    return e.name == "Solflare";
  }
};
const isNotFoundWallet = ref(false);
const onlySolflareAdapter = ref<boolean>(false);
const isMobile = new MobileDetect(window.navigator.userAgent).mobile();
const isAndroid =
  new MobileDetect(window.navigator.userAgent).os() === "AndroidOS";

onMounted(async () => {
  const currentUrl = window.location.href;
  const urlParams = new URLSearchParams(window.location.search);
  const affiliateId = urlParams.get("aff_id") ?? null;
  const campaignId = urlParams.get("campaign_id") ?? null;
  const url = new URL(currentUrl);
  url.searchParams.set("aff_id", affiliateId);
  url.searchParams.set("campaign_id", campaignId);
  const urlRedirect = url.toString();

  console.log("URL REDIRECT", urlRedirect);
  urlConnect.value = solana.browsePhantomDapp(urlRedirect);

  console.log("List wallet", solana.wallet.wallets);

  // if (urlParams.has("telegram_id")) {
  wallet.value = urlParams.has("walletName");
  solana.setWalletAdapter(
    walletAdapter.filter(
      (item) => item.adapter == urlParams.get("walletName")
    )[0]
  );
  // } else {
  // if (solana?.getSolanaStatus() != "CONNECTED") {
  //   wallet.value = "phantom";
  //   solana.setWalletAdapter(solana?.getWalletAdapters()[1]);
  // }
  // }

  wallet.value = urlParams.has("walletName");
  solana.setWalletAdapter(
    walletAdapter.filter(
      (item) => item.adapter == urlParams.get("walletName")
    )[0]
  );
  const findWallet = (solana.wallet.wallets as any).find(
    (w) => w.readyState == "Installed"
  ) as Wallet;

  if (!findWallet) isNotFoundWallet.value = true;

  if (isMobile && findWallet) {
    walletDetect.value = findWallet?.adapter.name;
    if (findWallet.adapter.name === "Solflare")
      onlySolflareAdapter.value = true;
    setTimeout(() => {
      solana.select(findWallet.adapter.name, () => solana.wallet?.connect());
    }, 1500);
  }
});
</script>

<style scoped>
.arrow_container {
  transition: 300ms;
}
.value_g {
  gap: 8px;
  display: flex;
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wallet_option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 180px;
}
.wallet_option_one {
  width: 85%;
  display: flex;
  align-items: center;
  gap: 8px;
}
.wallet_option_two {
  width: 15%;
  display: flex;
  justify-content: flex-end;
}
.dark nav {
  background-color: var(--dark-black);
  border-bottom: 2px solid var(--Neutral-02, #2b2b2b);
}
nav {
  position: relative;
  z-index: 1000;
  display: flex;
  border-bottom: 2px solid var(--app-questn-com-black, #000);
  justify-content: center;
}

.dark .nav_container {
  background-color: #000;
}

.nav_container {
  display: flex;
  align-items: center;
  background: var(--app-questn-com-nero, #fff);
  backdrop-filter: blur(8px);
  height: v-bind(NAV_BAR_HEIGHT + "px");
  padding: 0px 120px;
  width: 100%;
  max-width: v-bind(MAX_WIDTH + "px") !important;
}

.logo_block {
}

.menu_block {
  display: flex;
  align-items: center;
  margin-left: 24px;
  margin-right: auto;
  gap: 24px;
}

.button_block {
  display: flex;
  align-items: center;
  gap: 16px;
}
.rotate_180 {
  transform: rotate(-180deg);
}
.icon_button {
  width: 28px;
  height: 28px;
}

.card_container_modal {
  width: fit-content;
  gap: 16px;
  display: flex;
  flex-direction: column;
}

.wallet_item {
  cursor: pointer;
}

.menu_button {
  /* display: none; */
}
.connect_btn .text {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 19.2px */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.nav_button_g {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 16px;
  margin: 0 8px;
  flex: 1;
}
.language_board {
  margin-top: 16px;
  padding: 0;
  border-radius: 16px !important;
}
.list_item:hover {
  background-color: #f5ffce;
}
.language_item {
  min-width: 180px;
}
.v-input__details {
  display: none !important;
}
@media (max-width: 1279px) {
  .nav_container {
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .logo_block {
  }

  .menu_block {
    /* display: none; */
  }

  .button_block {
    /* display: none; */
  }

  .icon_button {
  }

  .card_container_modal {
  }

  .wallet_item {
    cursor: pointer;
  }

  .menu_button {
    /* display: block; */
  }

  .list_item:hover {
    background-color: #f5ffce;
  }
}
</style>
