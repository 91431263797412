<template>
  <div
    :class="{
      active: active && !isDark(),
      bg_dark_light_more: active && isDark(),
    }"
    class="flex items-center gap-1.5 p-2 text_dark rounded-[12px]"
  >
    <img v-if="icon" class="size-4 block" :src="icon" alt="" />
    <span v-if="textKey">{{ translation(textKey) }}</span>
    <slot></slot>
    <img
      v-show="active"
      class="size-4 ml-auto block"
      :src="getIcon('ovv_option_active' + (isDark() ? '_dark' : ''))"
      alt=""
    />
  </div>
</template>

<script setup lang="ts">
import { defineProps } from "vue";
import KatexTooltip from "./KatexTooltip.vue";
import getIcon from "@/common/function/getIcon";
import translation from "@/common/function/translation";
import { useRoute } from "vue-router";
import isDark from "@/common/function/isDark";
const route = useRoute();
const props = defineProps<{
  textKey?: string;
  icon?: string;
  active?: boolean;
  useForDifferent?: boolean;
}>();
</script>

<style scoped>
.active {
  background-color: #f2f2f2;
}
</style>
