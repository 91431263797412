import { userClient } from "@/graphql/client/user-client";
import { getAllianceDataQuery } from "@/graphql/query/getAllianceDataQuery";
import store from "@/store";
import { provideApolloClient, useQuery } from "@vue/apollo-composable";
import filterByRank from "./filterByRank";
import { assignLevelAndBackgroundColor } from "./assignLevelAndBackgroundColor";
import { affDataFake } from "@/mock-data/affiliate";
import determineParent from "@/components/Affiliate/function/determineParent";
import { getUserById } from "./getUserById";
import createRankObject from "./createRankObject";
import { watch } from "vue";

export default function fetchAllianceData(
  disableRefetch?: boolean,
  onlyUpdateAffAnalytics?: boolean,
  filterData?: { startTime: string; endTime: string; keyword?: string },
  onDone?: (data: any) => any,
  forceUpdateAffHistory?: boolean
) {
  store.commit("setFetching", "alliance-data");
  // alert(store.state.fetching);
  try {
    const { result, refetch, onResult } = provideApolloClient(userClient)(() =>
      useQuery(
        getAllianceDataQuery,
        {
          requestGetAffiliateInput: filterData,
        },
        { fetchPolicy: "network-only" }
      )
    );
    onResult(() => {
      if (store.state.fetching === "alliance-data" && result.value) {
        const res = JSON.parse(JSON.stringify(result.value));
        let allianceData = res?.affiliateData?.data;
        const affiliateData = JSON.parse(JSON.stringify(allianceData));
        // let allianceData = affDataFake?.affiliateData?.data as any;
        if (allianceData) {
          allianceData = {
            ...allianceData,
            ranking: createRankObject(allianceData.ranking),
          };
          // !filterData && store.commit("setAffiliateData", affiliateData);
          if (onlyUpdateAffAnalytics) return;
          determineParent(allianceData);
          store.commit("setChartTreeData", allianceData);
          store.commit("setAllianceData", {
            ...allianceData,
            show: true,
            parent: null,
            showingRank: -1,
          });

          store.commit("setCurrentAffTree", {
            ...allianceData,
            show: true,
            parent: null,
            showingRank: -1,
          });
          if (!onDone || forceUpdateAffHistory) {
            store.commit("setAffTreeHistory", [
              {
                ...allianceData,
                show: true,
                parent: null,
                showingRank: -1,
              },
            ]);
          }
        }
      }
    });
    watch(
      () => result.value,
      () => {
        onDone && onDone(result.value);
      }
    );
    !disableRefetch && refetch();
  } catch (err) {
    console.error(err);
  }
}
