<template>
  <apexchart
    type="bar"
    height="100%"
    :options="options"
    :series="data"
  ></apexchart>
</template>

<script setup lang="ts">
import formatter from "@/common/function/formatter";
import formatterNumber from "@/common/function/formatterNumber";
import isDark from "@/common/function/isDark";
import translation from "@/common/function/translation";
import { color } from "@amcharts/amcharts4/core";
import { ref, watch, defineProps } from "vue";
import { useStore } from "vuex";
const store = useStore();
const props = defineProps<{
  data: any[];
  categories: any[];
}>();
const options = ref<any>({});
watch(
  [() => store.state.themeMode, () => props.categories, () => props.data],
  () => {
    options.value = {
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },
        zoom: {
          enabled: false,
        },
        dropShadow: {
          enabled: false,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          columnWidth: 20,
          horizontal: false,
          borderRadius: 0,
          borderRadiusApplication: "end", // 'around', 'end'
          borderRadiusWhenStacked: "last", // 'all', 'last'
          dataLabels: {
            total: {
              enabled: false,
              style: {
                fontSize: "13px",
                fontWeight: 700,
                color: isDark() ? "#fff" : "#000",
              },
              formatter: function (e) {
                return formatter(e, false, false, true);
              },
            },
          },
        },
      },
      xaxis: {
        type: "string",
        categories: props.categories,
        labels: {
          // formatter: function (e) {
          //   return e + 'k';
          // },
          style: {
            colors: isDark() ? "#fff" : "#000",
          },
        },
      },
      dataLabels: {
        enabled: false,
        formatter: function (e) {
          return formatter(e, false, false, true);
        },
        style: {
          fontSize: "13px",
          fontWeight: 700,
          colors: [isDark() ? "#fff" : "#000"],
        },
      },
      yaxis: {
        labels: {
          // formatter: function (e) {
          //   // if (Number(e)) {
          //   //   // return formatter(e, false, false, true);
          //   //   return e + 1;
          //   // }
          //   // alert(e);
          //   return formatterNumber(e);
          // },
          style: {
            colors: isDark() ? "#fff" : "#000",
          },
        },
      },
      legend: {
        position: "bottom",
        horizontalAlign: "left",
        labels: {
          colors: isDark() ? "#fff" : "#000",
          useSeriesColors: false,
        },
        // offsetY: 20,
        itemMargin: {
          horizontal: 10,
          vertical: 0,
        },
        markers: {
          width: 16,
          height: 10,
          radius: 999,
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
      },

      fill: {
        opacity: 1,
      },
    };
  },
  { immediate: true }
);
</script>

<style scoped></style>
