import { userClient } from "@/graphql/client/user-client";
import { getAllianceDataQuery } from "@/graphql/query/getAllianceDataQuery";
import store from "@/store";
import { provideApolloClient, useQuery } from "@vue/apollo-composable";
import filterByRank from "./filterByRank";
import { assignLevelAndBackgroundColor } from "./assignLevelAndBackgroundColor";
import { basicClient } from "@/graphql/client/basic-client";
import { getBannerQuery } from "@/graphql/query/getBannerQuery";

export default function fetchBannerData() {
  try {
    const { result, refetch, onResult } = provideApolloClient(basicClient)(() =>
      useQuery(getBannerQuery)
    );
    onResult(() => {
      if (result.value) {
        store.commit("setBannerData", result.value?.banner?.result);
      }
    });
  } catch (err) {
    console.error(err);
  }
}
