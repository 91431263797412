<template>
  <button
    :class="{
      grayscale_100: disabled,
    }"
    :disabled="disabled || loading || comingSoon"
    @touchstart="() => runPressButtonAnimation()"
    @touchend="() => runBlurButtonAnimation()"
    @mousedown="() => runPressButtonAnimation()"
    @mouseup="() => runBlurButtonAnimation()"
  >
    <div
      class="content"
      :class="{
        is_pressed: isButtonPressed,
        disabled: disabled,
        custom_background: customBackground,
        custom_padding: customPadding,
      }"
      :style="{
        color: customBackground ? '#000' : 'inherit',
      }"
    >
      <div v-if="!loading && !comingSoon">
        <slot></slot>
      </div>
      <div class="flex items-center" v-if="comingSoon">
        <img
          :src="getIcon('lock')"
          alt=""
          class="w-[18px] h-[18px] relative top-[-2px]"
        />
        <span class="ml-1" v-if="comingSoonContent">{{
          comingSoonContent
        }}</span>
      </div>
      <span v-if="loading" class="flex items-center gap-4">
        <v-progress-circular
          indeterminate
          :size="20"
          :width="2"
          color="black"
        ></v-progress-circular>
        <span v-if="isTransaction" class="leading-[100%]">
          {{ translation("_processing") }}
        </span>
      </span>
    </div>
  </button>
</template>

<script setup lang="ts">
import getIcon from "@/common/function/getIcon";
import translation from "@/common/function/translation";
import { defineProps, ref } from "vue";
interface ButtonComponentProps {
  disabled?: boolean;
  loading?: boolean;
  customBackground?: string;
  customPadding?: string;
  comingSoon?: boolean;
  comingSoonContent?: string;
  isTransaction?: boolean;
}
const props = defineProps<ButtonComponentProps>();
const isButtonPressed = ref(false);
const translateValue = "4px";
const _translateValue = "-4px";

const runPressButtonAnimation = () => {
  !props.disabled && (isButtonPressed.value = true);
};
const runBlurButtonAnimation = () => {
  !props.disabled && (isButtonPressed.value = false);
};
</script>

<style scoped>
.dark button {
  /* color: #fff; */
}
button::before {
  display: block;
  content: "";
  position: absolute;
  inset: 0;
  z-index: 99;
  background-color: transparent;
  border-radius: inherit;
}
button {
  position: relative;
  border-radius: 10px;
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  z-index: 1;
  line-height: 120%; /* 19.2px */
}
.dark .content {
  /* background: #2b2b2b; */
}
.content {
  background-color: var(--app-questn-com-nero, #fff);
  border: 2px solid #000;
  padding: 7px 14px;
  border-radius: inherit;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 100ms;
}
.custom_background {
  background-color: v-bind(customBackground) !important;
}

.custom_padding {
  padding: v-bind(customPadding);
}
.custom_background {
  background-color: v-bind(customBackground);
}
button::after {
  content: "";
  position: absolute;
  top: v-bind(translateValue);
  bottom: v-bind(_translateValue);
  right: v-bind(_translateValue);
  left: v-bind(translateValue);
  border-radius: inherit;
  background: var(--app-questn-com-black, #000);
  z-index: -1;
}
.is_pressed {
  transform: translate(v-bind(translateValue), v-bind(translateValue));
}
.grayscale_100 {
  filter: grayscale(100%);
}
/* .dark .disabled {
  background: #c2c2c2;
  color: #828282 !important;
} */
.disabled {
  background: #b7b7b7;
  color: var(--app-questn-com-nero);
}
.dark .disabled {
  background: #2b2b2b;
  color: #ffffff !important;
}
@media (max-width: 1279px) {
  button {
    font-size: 16px;
  }
}
</style>
