import { provideApolloClient, useMutation } from "@vue/apollo-composable";
import store from "@/store";
import {
  connectBeQuery,
  saveSessionWalletTelegram,
} from "@/graphql/query/homeStatictis";
import { userClient } from "@/graphql/client/user-client";
import { basicClient } from "@/graphql/client/basic-client";
import setUserToLocalStorage from "./setUserToLocalStorage";
import { encryptData } from "./encrypt";
import { fetchPermission } from "@/service/statictis";
import { walletAdapter } from "@/wallet/solana/Solana";
import translation from "./translation";

export default async function login(input: any, onLogged?: () => any) {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const affiliateId =
      window.localStorage.getItem("aff_id") || urlParams.get("aff_id") || null;

    const campaignId =
      window.localStorage.getItem("campaign_id") ||
      urlParams.get("campaign_id") ||
      null;
    window.localStorage.removeItem("aff_id");
    window.localStorage.removeItem("campaign_id");

    const data = await encryptData({
      wallet_address: input.wallet_address,
      aff_id: affiliateId,
      campaign_id: campaignId,
    });

    const { mutate } = provideApolloClient(basicClient)(() =>
      useMutation(connectBeQuery, {
        variables: {
          data,
        },
      })
    );
    const res = await mutate();
    if (res?.data?.connectBE?.status == true) {
      const user: any = {
        token: res?.data?.connectBE?.access_token || "",
        id: res?.data?.connectBE?.user_id || "",
      };

      store.commit("setUser", user);
      setUserToLocalStorage(user);
      fetchPermission();

      if (onLogged) {
        return await onLogged();
      }
    }
  } catch (err) {
    console.log("🚀 ~ login ~ err:", err);
  }
}
export async function saveSessionWallet(input: any) {
  try {
    const data = await encryptData({
      shared_secret_dapp: input.shared_secret_dapp,
    });
    const urlParams = new URLSearchParams(window.location.search);

    const telegram_id = urlParams.get("telegram_id");
    const walletAdapter =
      urlParams.get("walletName") ||
      JSON.parse(window.localStorage.getItem("walletAdapter"))?.adapter;

    const { mutate } = provideApolloClient(basicClient)(() =>
      useMutation(saveSessionWalletTelegram, {
        variables: {
          saveSessionWallet: {
            key_pair_encryption_public: input.key_pair_encryption_public,
            public_key: input.public_key,
            session: input.session,
            shared_secret_dapp: data,
            telegram_id: telegram_id?.toString(),
            wallet_adapter: walletAdapter,
          },
        },
      })
    );
    const res = await mutate();
    const link = translation("link_tele_bot");
    window.location.href = link;
  } catch (err) {
    console.error("🚀 ~ saveSessionWallet ~ err:", err);
    console.error(err);
  }
}
