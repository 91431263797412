import gql from "graphql-tag";
export const getMyReferral = gql`
  query ReferralData($requestGetAffiliateInput: RequestGetAffiliateInput) {
    referralData
    affiliateData(requestGetAffiliateInput: $requestGetAffiliateInput) {
      data
      ranks
    }
  }
`;

export const claimReferral = gql`
  query Query($requestClaimBonusInput: RequestClaimBonusInput) {
    claimBonus(requestClaimBonusInput: $requestClaimBonusInput) {
      result
      success
    }
  }
`;
