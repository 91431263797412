<template>
  <div
    :style="{
      width: 'calc(100vw - 32px)',
      borderRadius: '24px',
      border: '2px black solid',
      maxWidth: '650px !important',
      backgroundColor: isDark() ? '#2B2B2B' : '#fff',
    }"
    class="p-6"
  >
    <div
      class="self-stretch rounded-2xl flex-col justify-start items-start gap-2 flex"
    >
      <div class="flex justify-between items-center w-full pb-2">
        <span class="text-[20px]" :class="{ '!text-[#fff]': isDark() }">{{
          translation("confirm_withdraw_principal_final")
        }}</span>
        <v-icon
          icon="mdi-close"
          :color="isDark() ? '#fff' : ''"
          @click="closeModal"
        ></v-icon>
      </div>
      <div
        class="h-[2px] bg-[#141416] w-full mb-3 opacity-15"
        :class="{ '!bg-[#fff]': isDark() }"
      ></div>
      <div class="self-stretch justify-between items-center inline-flex">
        <div>
          <span
            class="text-[#141416] text-[13px] lg:text-[18px] font-medium font-['GT Walsheim'] leading-[140%]"
            :class="{ '!text-[#fff]': isDark() }"
          >
            {{ translation("Completed_Principal") }}</span
          >
        </div>
        <div
          class="text-[#141416] text-[15px] lg:text-[20px] font-bold font-['GT Walsheim'] leading-[140%]"
        >
          <FormatterNumber
            :class="{ '!text-[#fff]': isDark() }"
            class="number_receive"
            :value="
              dataStakeSolx?.principal - dataStakeSolx?.totalPricipalNotComplete
            "
          />
        </div>
      </div>
      <div class="self-stretch justify-between items-center inline-flex">
        <div>
          <span
            class="text-[#141416] text-[13px] lg:text-[18px] font-medium font-['GT Walsheim'] leading-[140%]"
            :class="{ '!text-[#fff]': isDark() }"
          >
            Available Interest</span
          >
        </div>
        <div
          class="text-[#141416] text-[15px] lg:text-[20px] font-bold font-['GT Walsheim'] leading-[140%]"
        >
          <FormatterNumber
            :class="{ '!text-[#fff]': isDark() }"
            class="number_receive"
            :value="dataStakeSolx?.interest"
          />
        </div>
      </div>
      <div class="self-stretch flex-col justify-start items-start flex">
        <div
          class="self-stretch justify-between items-center inline-flex cursor-pointer"
          @click="() => (showDetailInterest = !showDetailInterest)"
        >
          <div
            class="text-[#D58400] text-[13px] lg:text-[18px] font-medium font-['GT Walsheim'] leading-[140%]"
          >
            SOLX Uncompleted Principal
          </div>
          <div class="justify-start items-center gap-1.5 flex">
            <div
              class="text-[#141416] text-[15px] lg:text-[20px] font-bold font-['GT Walsheim'] leading-[140%]"
            >
              <FormatterNumber
                :class="{ '!text-[#fff]': isDark() }"
                class="number_receive"
                :value="dataStakeSolx?.totalPricipalNotComplete"
              />
            </div>
            <svg
              class="duration-200 relative -top-[1px]"
              :class="{
                'rotate-[180deg]': showDetailInterest,
              }"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.28854 0.357356L10 5.06881L9.22604 5.84277L5.28854 1.90527L1.35104 5.84277L0.577083 5.06881L5.28854 0.357356Z"
                :fill="isDark() ? '#fff' : '#1C1B1F'"
              />
            </svg>
          </div>
        </div>
        <div
          class="flex items-stretch w-full duration-300 overflow-hidden"
          :class="{
            'pt-2': showDetailInterest,
            'pt-0': !showDetailInterest,
          }"
          :style="{
            maxHeight: showDetailInterest ? '120px' : '0px',
          }"
        >
          <div class="relative ml-1">
            <div class="arrow_circle"></div>
          </div>
          <div class="flex flex-col gap-2 w-full">
            <div
              class="self-stretch pl-3 justify-between items-center inline-flex"
            >
              <div
                class="text-[#929292] text-[13px] lg:text-[18px] font-medium font-['GT Walsheim'] leading-[140%]"
              >
                SOLX Unavailable Interest
              </div>
              <div
                class="text-[#636363] text-[15px] lg:text-[20px] font-medium font-['GT Walsheim'] leading-[140%] flex items-center gap-1"
              >
                <span class="line-through">
                  <FormatterNumber
                    :class="{ '!text-[#fff]': isDark() }"
                    class="number_receive line-through"
                    :value="
                      dataStakeSolx?.interestForSetting -
                      dataStakeSolx?.interest
                    "
                /></span>
                <KatexTooltip
                  value="principal_boost_unavailable_tooltip"
                  use-slot
                >
                  <span class="flex items-center gap-1"
                    ><img
                      loading="lazy"
                      :src="getIcon(isDark() ? 'tooltip_white' : 'tooltip')"
                      alt=""
                      :style="{
                        top: `${useResponsive(-3, -1).value}px`,
                      }"
                      class="icon_w24_h24"
                  /></span>
                </KatexTooltip>
              </div>
            </div>
            <div
              class="self-stretch pl-3 justify-between items-center inline-flex"
            >
              <div
                class="text-[#929292] text-[13px] lg:text-[18px] font-medium font-['GT Walsheim'] leading-[140%]"
              >
                SOLX Penalty Fee
              </div>
              <div
                class="text-[#FF5153] text-[15px] lg:text-[20px] font-medium font-['GT Walsheim'] leading-[140%] flex items-center gap-1"
              >
                <span
                  ><FormatterNumber
                    class="number_receive"
                    :value="amountPenalty()"
                /></span>
                <KatexTooltip
                  value="principal_boost_fee_penalty_tooltip"
                  use-slot
                >
                  <span class="flex items-center gap-1"
                    ><img
                      loading="lazy"
                      :src="getIcon(isDark() ? 'tooltip_white' : 'tooltip')"
                      alt=""
                      :style="{
                        top: `${useResponsive(-3, -1).value}px`,
                      }"
                      class="icon_w24_h24"
                  /></span>
                </KatexTooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="self-stretch h-fit py-3 bg-[#f2f2f2] rounded-2xl border-2 border-[#141416] flex-col justify-start items-start gap-2 flex"
      >
        <div class="self-stretch px-3 justify-between items-center inline-flex">
          <div
            class="text-[#141416] text-[13px] lg:text-[18px] font-medium font-['GT Walsheim'] leading-[140%]"
          >
            {{ translation("Price_impact") }}
          </div>
          <div class="justify-start items-center gap-0.5 flex">
            <div
              class="text-[#141416] text-[15px] lg:text-[20px] font-normal font-['SF Pro Display'] leading-[21px]"
            >
              ±
            </div>
            <div
              class="text-[#141416] text-[15px] lg:text-[20px] font-bold font-['GT Walsheim'] leading-[21px]"
            >
              <FormatterNumber
                :value="
                  ((Number(dataStakeSolx?.principal) +
                    Number(dataStakeSolx.interest) -
                    amountPenalty()) /
                    store.state.poolInfo?.solx_reserved) *
                    100 <
                  0.01
                    ? '0.01'
                    : ((Number(dataStakeSolx?.principal) +
                        Number(dataStakeSolx.interest) -
                        amountPenalty()) /
                        store.state.poolInfo?.solx_reserved) *
                      100
                "
                :unit="'%'"
                :start="
                  ((Number(dataStakeSolx?.principal) +
                    Number(dataStakeSolx.interest)) /
                    store.state.poolInfo?.solx_reserved) *
                    100 <
                  0.01
                    ? '<'
                    : ''
                "
              />
            </div>
          </div>
        </div>
        <div
          class="self-stretch px-3 flex-col justify-start items-start gap-2 flex"
        >
          <div
            class="self-stretch justify-between items-center inline-flex cursor-pointer"
            @click="() => (showDetailFee = !showDetailFee)"
          >
            <div
              class="text-[#141416] text-[13px] lg:text-[18px] font-medium font-['GT Walsheim'] leading-[140%]"
            >
              {{ translation("Total_Fee") }}
            </div>
            <div class="justify-start items-center gap-1.5 flex">
              <div
                class="text-[#141416] text-[15px] lg:text-[20px] font-bold font-['GT Walsheim'] leading-[21px]"
              >
                <FormatterNumber
                  :value="dataFee?.feeNetwork + dataFee?.feeSystem ?? 0"
                />
              </div>
              <svg
                class="duration-200 relative -top-[1px]"
                :class="{
                  'rotate-[180deg]': showDetailFee,
                }"
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.28854 0.357356L10 5.06881L9.22604 5.84277L5.28854 1.90527L1.35104 5.84277L0.577083 5.06881L5.28854 0.357356Z"
                  fill="#1C1B1F"
                />
              </svg>
            </div>
          </div>
          <div
            class="flex items-stretch w-full duration-300 overflow-hidden"
            :style="{
              maxHeight: showDetailFee ? '100px' : '0px',
            }"
          >
            <div class="relative ml-1">
              <div class="arrow"></div>
            </div>
            <div class="flex flex-col gap-2 w-full">
              <div
                class="self-stretch pl-3 justify-between items-center inline-flex"
              >
                <div
                  class="text-[#929292] text-[13px] lg:text-[18px] font-medium font-['GT Walsheim'] leading-[140%]"
                >
                  {{ translation("System_Fee") }}
                </div>
                <div
                  class="text-[#636363] text-[15px] lg:text-[20px] font-medium font-['GT Walsheim'] leading-[21px]"
                >
                  <FormatterNumber :value="dataFee?.feeSystem ?? 0" />
                </div>
              </div>
              <div
                class="self-stretch pl-3 justify-between items-center inline-flex"
              >
                <div
                  class="text-[#929292] text-[13px] lg:text-[18px] font-medium font-['GT Walsheim'] leading-[140%]"
                >
                  {{ translation("GAS_Fee") }}
                </div>
                <div
                  class="text-[#636363] text-[15px] lg:text-[20px] font-medium font-['GT Walsheim'] leading-[21px]"
                >
                  <FormatterNumber :value="dataFee?.feeNetwork ?? 0" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="self-stretch h-[0px] !border-t border-[#141416] border-dashed"
        :class="{ '!border-[#fff]': isDark() }"
      ></div>
      <div class="self-stretch justify-between items-center inline-flex">
        <div>
          <span
            class="text-[#8247e5] text-[15px] lg:text-[20px] font-medium font-['GT Walsheim'] leading-[21px]"
            >{{ translation("Total_SOLX_Claim") }}</span
          >
        </div>
        <div
          class="text-[#8247e5] text-[15px] lg:text-[20px] font-bold font-['GT Walsheim'] leading-[21px]"
        >
          <FormatterNumber
            class="number_receive"
            :value="
              Number(dataStakeSolx?.principal) +
              Number(dataStakeSolx.interest) -
              amountPenalty()
            "
          />
        </div>
      </div>
      <div class="self-stretch justify-between items-center inline-flex">
        <div>
          <span
            class="text-[#141416] text-[13px] lg:text-[18px] font-medium font-['GT Walsheim'] leading-[140%]"
            :class="{ '!text-[#fff]': isDark() }"
            >{{ translation("Est.SOLX_Claim") }}
          </span>
        </div>
        <div
          class="text-[#141416] text-[18px] lg:text-[20px] font-bold font-['GT Walsheim'] leading-snug"
          :class="{ '!text-[#fff]': isDark() }"
        >
          <FormatterNumber
            class="number_receive"
            :value="
              Number(dataStakeSolx?.principal) +
              Number(dataStakeSolx.interest) -
              amountPenalty()
            "
          />
        </div>
      </div>
    </div>
    <PrimaryButton
      :custom-background="isDark() ? '' : '#F0FFB3'"
      :loading="isLoadingOrigin"
      :is-transaction="true"
      :disabled="
        (!getAddress() &&
          !store.state.poolInfo?.system_enable_claim_priciple) ||
        (getAddress() &&
          (!store.state.permission ||
            (store.state.permission && store.state.permission?.success == true
              ? !store.state.permission?.data['claim-solx-principle']
              : true)))
      "
      @click="onWithdrawOrigin"
      class="w-full my-4 lg:my-6"
      >{{ translation(pagePrefix + "button") }}</PrimaryButton
    >
  </div>
</template>

<script lang="ts" setup>
import translation from "@/common/function/translation";
import PrimaryButton from "./PrimaryButton.vue";
import { ref, defineProps, watch } from "vue";
import getIcon from "@/common/function/getIcon";
import isDark from "@/common/function/isDark";
import FormatterNumber from "./FormatterNumber.vue";
import store from "@/store";
import { useWallet } from "solana-wallets-vue";
import { isDeviceLaptop } from "@/wallet/solana/Solana";
import useResponsive from "@/hook/useResponsive";
import KatexTooltip from "./KatexTooltip.vue";
const PENALTY_PRINCIPAL_BOOST = "penalty_pricipal_boost";
interface WithdrawOriginBoostProps {
  dataStakeSolx?: any;
  dataFee?: any;
  isLoadingOrigin?: boolean;
  isDisable?: boolean;
  triggerTabBoost: any;
  closeModal: () => void;
  onWithdrawOrigin?: () => void;
}
const props = defineProps<WithdrawOriginBoostProps>();
const pagePrefix = "confirm_modal_";
const showDetailFee = ref(false);
const showDetailInterest = ref(true);
const getAddress = () => {
  let address = null;
  if (isDeviceLaptop()) {
    const { publicKey } = useWallet();

    address = publicKey.value?.toString() || null;
  } else {
    address = store.state.sessionSolana?.public_key?.toString() || null;
  }
  return address;
};
const finalAmount = () => {
  try {
    let totalPrincipal = 0;
    const settingFeePanalty = (store.state.poolInfo?.price_impact as any)?.find(
      (item) => item.key == PENALTY_PRINCIPAL_BOOST
    );
    console.log(
      "🚀 ~ PoolService ~ claimInterestV3 ~ settingFeePanalty:",
      settingFeePanalty
    );

    if (settingFeePanalty && settingFeePanalty?.enable) {
      totalPrincipal =
        totalPrincipal -
        (props.dataStakeSolx?.totalPricipalNotComplete *
          settingFeePanalty?.penalty_percent +
          settingFeePanalty?.penalty_value);
    }
    return totalPrincipal;
  } catch (err) {
    console.log("🚀 ~ finalAmout ~ err:", err);
  }
};
const amountPenalty = () => {
  try {
    const settingFeePanalty = (store.state.poolInfo?.price_impact as any)?.find(
      (item) => item.key == PENALTY_PRINCIPAL_BOOST
    );
    console.log(
      "🚀 ~ amountPenalty ~ props.dataStakeSolx?.totalPricipalNotComplete:",
      props.dataStakeSolx?.totalPricipalNotComplete
    );
    console.log("🚀 ~ amountPenalty ~ settingFeePanalty:", settingFeePanalty);

    return (
      props.dataStakeSolx?.totalPricipalNotComplete *
        (Number(settingFeePanalty?.penalty_percent) / 100) +
      Number(settingFeePanalty?.penalty_value)
    );
  } catch (err) {
    console.log("🚀 ~ finalAmout ~ err:", err);
  }
};
watch(
  () => props.triggerTabBoost,
  (newData, oldData) => {
    props.closeModal();
  }
);
</script>

<style scoped>
.arrow,
.arrow_circle {
  color: #636363;
  position: absolute;
  top: 50%;
  height: calc(50% + 8px);
  left: 50%;
  transform: translate(0, calc(-50% - 2px));
  border-left: 2px currentColor dashed;
}

.arrow::before,
.arrow_circle::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 999px;
  background: currentColor;
  position: absolute;
  transform: translate(calc(-50% - 1px), 0%);
}

.arrow::after {
  content: "";
  display: block;
  width: 12px;
  height: 8px;
  background: currentColor;
  position: absolute;
  bottom: 0;
  transform: translate(calc(-50% - 1px), 1.5px);
  clip-path: polygon(0% 0%, 50% 40%, 100% 0%, 50% 100%, 0% 0%);
}
.arrow_circle::after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  background: currentColor;
  position: absolute;
  bottom: 0;
  transform: translate(calc(-50% - 1px), 1.5px);
  border-radius: 999px;
  /* text-decoration: dashed */
}
</style>
